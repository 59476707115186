import React, { useState } from 'react'

import {
  KpiOptionObject,
  KpiOptionPatchAttributes
} from 'types/GlobalKpiOption'
import { WidgetObject, WidgetType } from 'types/GlobalWidget'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsEditDialog from 'components_new/molecules/SettingsEditDialog'
import SettingsItem from 'components_new/molecules/SettingsItem'

import { getBreakdownOptions } from './utils'
import {
  useBreakdownBy,
  useKpiOptionAttributeOptions
} from 'redux/hooks/AttributeOptions'

interface BreakdownSectionProps {
  kpi: KpiOptionObject
  updateKpiOption: (body: KpiOptionPatchAttributes) => void
  widget: WidgetObject
}

const LEGAL_TYPES = [
  WidgetType.BAR_CHART,
  WidgetType.COMBO_CHART,
  WidgetType.LINE_CHART,
  WidgetType.LIST
]

const BreakdownSection = (props: BreakdownSectionProps) => {
  const { kpi, updateKpiOption, widget } = props

  if (!LEGAL_TYPES.includes(widget.settings.type.selected)) {
    return null
  }

  const breakdownBy = useBreakdownBy(kpi)
  const breakdownOptions = useKpiOptionAttributeOptions(
    widget,
    kpi,
    'breakdown_attribute'
  )

  /*-- dialogs --*/
  const [dialog, setDialog] = useState<{
    open: boolean
    data: {
      options: { label: string; value: any }[]
      value: string
    }
  }>({
    open: false,
    data: {
      options: [],
      value: ''
    }
  })

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const handleOpenDialog = () => {
    setDialog({
      open: true,
      data: {
        options: getBreakdownOptions(breakdownOptions, widget),
        value: kpi.breakdown_by.selected ?? ''
      }
    })
  }

  const handleEditDialogValue = (value: string) => {
    setDialog((prevState) => ({
      ...prevState,
      data: { ...prevState.data, value }
    }))
  }

  const handleSubmitDialog = () => {
    updateKpiOption({
      data: {
        breakdown_by: dialog.data.value === 'none' ? null : dialog.data.value
      }
    })
  }

  const selectedBreakdown = breakdownBy ? breakdownBy.name : null

  return (
    <>
      <SettingsGroup title="Uppdelning">
        <SettingsItem
          disabled={Boolean(widget.settings.comparative_period.selected?.value)}
          disabledText={
            'Uppdelning gå inte att använda då det finns en jämförelse'
          }
          onClick={() => handleOpenDialog()}
          title="Dela upp efter"
          value={selectedBreakdown}
          variant="edit"
        />
      </SettingsGroup>

      {/*-- dialogs --*/}
      <SettingsEditDialog
        onChange={handleEditDialogValue}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        open={dialog.open}
        options={dialog.data.options}
        title={'Dela upp efter'}
        type={'select'}
        value={dialog.data.value}
      />
    </>
  )
}

export default BreakdownSection
