import { avatarImages, employees } from './lib'

function getSubstringBeforeSpecialChar(input: string): string {
  return input.split(/[\.+@]/)[0]
}

export const getProfileAvatarSrc = (email?: string) => {
  if (email && email.includes('@homepal.se')) {
    const name = getSubstringBeforeSpecialChar(email)

    if (employees.includes(name)) {
      return avatarImages[name]
    } else {
      return ''
    }
  }

  return undefined
}
