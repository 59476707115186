import dayjs from 'utils/dayjs'

import {
  ActivationDashboard,
  ActivationDashboardAccount
} from 'types/GlobalTracking'

export function calculateComboChart(input: ActivationDashboard | null) {
  const labels: string[] = []
  const degreeOfActivation: (number | string)[] = []
  const total: (number | string)[] = []
  const nrOfActivatedUsers: (number | string)[] = []

  if (!input) {
    return { labels, degreeOfActivation, total, nrOfActivatedUsers }
  }

  input.activation_over_time.forEach((item) => {
    const calculations = calculate(item.accounts)

    labels.push(item.label)
    degreeOfActivation.push(calculations.degreeOfActivation)
    total.push(calculations.total)
    nrOfActivatedUsers.push(calculations.nrOfActivatedUsers)
  })

  return { labels, degreeOfActivation, total, nrOfActivatedUsers }
}

export function calculate(accounts: ActivationDashboardAccount[]) {
  const total = accounts.length
  const nrOfActivatedUsers = accounts.filter(
    (account) => account.is_activated
  ).length

  return {
    total,
    nrOfActivatedUsers,
    degreeOfActivation:
      total === 0
        ? 0
        : parseInt(((nrOfActivatedUsers / total) * 100).toFixed(0))
  }
}

export function getDateFourWeeksAgo() {
  return dayjs().subtract(3, 'week').startOf('isoWeek').format('YYYY-MM-DD')
}
