import React, { ReactNode, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Card from 'components_new/atoms/Card'
import CardActionArea from 'components_new/atoms/Card/CardActionArea'
import CardActions from 'components_new/atoms/Card/CardActions'
import CardMedia from 'components_new/atoms/Card/CardMedia'
import CardContent from 'components_new/atoms/Card/CardContent'
import Collapse from 'components_new/atoms/Collapse'
import Divider from 'components_new/atoms/Divider'
import Icon, { IconName } from 'components_new/atoms/Icon'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'

interface CreateCardProps {
  body?: string
  children?: ReactNode
  disabled?: boolean
  icon?: IconName
  image?: string
  onClick?: () => void
  sx?: object
  title?: string
  titleAdornments?: ReactNode
  user?: ReactNode
  variant?: 'elevation' | 'outlined' | undefined
}

/**
 * The CreateCard component is used to create
 * something from a list of options.
 */

const CreateCard = React.forwardRef((props: CreateCardProps, ref: any) => {
  const {
    body,
    children,
    disabled,
    icon,
    image,
    onClick,
    title,
    titleAdornments,
    user,
    variant,
    ...rest
  } = props

  const [collapsed, setCollapsed] = useState(false)

  return (
    <Card
      sx={{ width: '100%', bgcolor: disabled ? 'action.hover' : undefined }}
      variant={variant}
      ref={ref}
      {...rest}
    >
      <CardActionArea
        onClick={onClick}
        sx={{
          display: 'flex',
          alignItems: 'stretch',
          pointerEvents: disabled ? 'none' : 'all'
        }}
      >
        {image ? (
          <CardMedia
            component="div"
            image={image}
            sx={{
              width: 160,
              height: 90,
              m: 2
            }}
          />
        ) : null}
        {icon ? (
          <CardMedia
            component="div"
            image={image}
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: 'action.hover',
              borderRadius: 1,
              m: 2
            }}
          >
            <Icon name={icon} sx={{ fontSize: 48 }} color="disabled" />
          </CardMedia>
        ) : null}
        <CardContent sx={{ flexGrow: 1 }}>
          <Stack justifyContent="space-between" sx={{ height: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                maxWidth: '600px'
              }}
            >
              {user}
              <Text
                color={disabled ? 'text.secondary' : 'text.primary'}
                gutterBottom
                variant="h6"
              >
                {title}
              </Text>
              <Text
                color="text.secondary"
                variant="body2"
                sx={
                  collapsed
                    ? undefined
                    : {
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical'
                      }
                }
              >
                {body}
              </Text>
            </Box>
            {titleAdornments ? (
              <Stack
                sx={{
                  gap: 1,
                  ml: 2,
                  py: 1,
                  pointerEvents: disabled ? 'all!important' : undefined
                }}
              >
                {titleAdornments}
              </Stack>
            ) : null}
          </Stack>
        </CardContent>
      </CardActionArea>
      {children ? (
        <>
          <CardActions sx={{ justifyContent: 'center' }}>
            <Button
              fullWidth
              size="small"
              endIcon={<Icon name={collapsed ? 'ExpandLess' : 'ExpandMore'} />}
              variant="text"
              onClick={() => setCollapsed(!collapsed)}
            >
              {collapsed ? 'Visa mindre' : 'Visa mer'}
            </Button>
          </CardActions>
          <Collapse in={collapsed}>
            <Divider />
            <Box sx={{ bgcolor: 'action.hover' }}>{children}</Box>
          </Collapse>
        </>
      ) : null}
    </Card>
  )
})

export default CreateCard
