import React from 'react'

import { IDashboardExample } from 'types/GlobalDashboardExample'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

import UserListItem from 'components_new/molecules/UserListItem'

interface AuthorProps {
  dashboard: IDashboardExample
  sx?: object
}

const Author = (props: AuthorProps) => {
  const { dashboard, sx } = props

  const author = dashboard.fields.author
  const company = (author?.fields as any)?.company

  return (
    <Box sx={sx}>
      <UserListItem
        body={
          company
            ? company.fields.name
            : new Date(dashboard.sys.createdAt).toLocaleDateString('sv')
        }
        component="div"
        disablePadding={true}
        size="medium"
        src={
          company
            ? 'https:' + company.fields.logotype.fields.file?.url
            : undefined
        }
        sx={{ p: 0, mb: 2 }}
        title={author ? (author.fields as any).name : 'Skapad av Homepal'}
        type={author ? 'customer' : 'homepal'}
      />

      <Text color="text.secondary" variant="body2">
        {author ? (
          <>
            Den här dashboarden är skapad av en av våra användare och har
            skickats in för att delas med branschkollegor. Homepal har granskat
            och publicerat den med demodata.
          </>
        ) : (
          <>
            Den här dashboarden är skapad och anpassad av oss på Homepal med vår
            påhittade demodata. Konceptet och idén kommer från en av våra
            kunder.
          </>
        )}
      </Text>
    </Box>
  )
}

export default Author
