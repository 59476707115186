import React, { ElementType, MouseEventHandler, ReactNode } from 'react'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'
import Dialog from 'components_new/atoms/Dialog'
import DialogTitle from 'components_new/atoms/Dialog/DialogTitle'

// These are going to be replaced with our own components later.
import { DialogContent, DialogActions, DialogContentText } from '@mui/material'

interface SimpleDialogProps {
  actions?: ReactNode
  children?: ReactNode
  component?: ElementType<any>
  contentText?: ReactNode
  disableOverflow?: boolean
  disablePortal?: boolean
  disableRestoreFocus?: boolean
  dividers?: boolean
  fullHeight?: boolean
  fullWidth?: boolean
  hero?: {
    body?: ReactNode
    title: string
  }
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  onClose?: () => void
  onMouseDown?: MouseEventHandler<HTMLElement>
  open: boolean
  PaperProps?: object
  sx?: object
  title?: ReactNode
}

/**
 * The SimpleDialog component is a
 * variant of a Dialog that is smaller
 * and simpler, and therefore suitable
 * for displaying short content (i.e.
 * a promt or a short form).
 */

const SimpleDialog = React.forwardRef((props: SimpleDialogProps, ref: any) => {
  const {
    actions,
    children,
    contentText,
    disableOverflow,
    dividers,
    fullHeight,
    hero,
    title,
    ...rest
  } = props

  return (
    <Dialog {...rest} ref={ref}>
      {title ? (
        <DialogTitle
          sx={{
            // Because of antd's global.less
            color: 'text.primary'
          }}
        >
          {title}
        </DialogTitle>
      ) : null}
      <DialogContent
        dividers={dividers}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: fullHeight ? '90vh' : undefined,
          overflow: disableOverflow ? 'hidden' : undefined,
          position: 'relative'
        }}
      >
        {hero ? (
          <Box
            sx={{
              bgcolor: 'brand.background',
              borderRadius: 1,
              mb: 3,
              p: 3
            }}
          >
            <Text
              color="text.primary"
              component="h1"
              gutterBottom={true}
              variant="h3"
            >
              {hero.title}
            </Text>
            {hero.body ? (
              <Text color="text.primary" component="div" variant="body1">
                {hero.body}
              </Text>
            ) : null}
          </Box>
        ) : null}
        {props.contentText ? (
          <DialogContentText sx={{ mb: 2 }}>{contentText}</DialogContentText>
        ) : null}
        {children}
      </DialogContent>
      {actions ? <DialogActions>{actions}</DialogActions> : null}
    </Dialog>
  )
})

SimpleDialog.defaultProps = {
  maxWidth: 'sm',
  fullWidth: true,
  onMouseDown: (e) => e.stopPropagation()
}

SimpleDialog.displayName = 'SimpleDialog'
export default SimpleDialog
