import { useMemo } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'

import { useDashboardGroup, useDashboards } from 'redux/hooks/Dashboards'

import { TabSwitchOption } from 'components_new/molecules/TabSwitch'

export type TDialogType =
  | 'delete_dashboard'
  | 'edit_dashboard_title'
  | 'embed_dashboard'
  | 'copy_dashboard'
  | 'move_dashboard'

export function getDashboardTabs(
  activeDashboardId: string,
  editable: boolean,
  onMove: (dashboardId: string, direction: number) => void,
  onDuplicate: (dashboardId: string) => void,
  handleOpenDialog: (dashboardId: string, copyType: TDialogType) => void
): (TabSwitchOption & { title: string })[] {
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const group = useDashboardGroup(activeDashboardId)
  const dashboardObject = useDashboards()

  return useMemo(() => {
    if (!group) {
      return []
    }

    const { dashboards } = group

    return dashboards.map((dashboardId, i) => {
      const dashboard = dashboardObject[dashboardId]

      return {
        value: dashboardId,
        title: dashboard.title,
        label: dashboard.title,
        disabledTooltip: 'Du saknar tillgång till den här fliken.',
        icon: dashboard.share_settings.is_public
          ? {
              bgcolor: 'success.background',
              name: 'PublicOutlined',
              tooltip: 'Extern åtkomst via publik länk'
            }
          : dashboard.share_settings.api_access
            ? {
                bgcolor: 'secondary.background',
                name: 'VpnLock',
                tooltip: 'Extern åtkomst via skyddad länk'
              }
            : undefined,
        menuOptions: editable
          ? [
              {
                iconName: 'MoveDownOutlined',
                iconRotation: lgUp ? '90deg' : '180deg',
                onClick: () => onMove(dashboard.id, -1),
                title: `Flytta ${lgUp ? 'vänster' : 'upp'}`,
                disabled: i === 0
              },
              {
                iconName: 'MoveUpOutlined',
                iconRotation: lgUp ? '90deg' : '180deg',
                onClick: () => onMove(dashboard.id, 1),
                title: `Flytta ${lgUp ? 'höger' : 'ner'}`,
                divider: true,
                disabled: i === dashboards.length - 1
              },
              {
                iconName: 'EditOutlined',
                onClick: () =>
                  handleOpenDialog(dashboard.id, 'edit_dashboard_title'),
                title: 'Redigera namn',
                divider: true
              },
              {
                iconName: 'ContentCopyOutlined',
                onClick: () => onDuplicate(dashboard.id),
                title: 'Duplicera'
              },
              {
                iconName: 'FileCopyOutlined',
                onClick: () => handleOpenDialog(dashboard.id, 'copy_dashboard'),
                title: 'Kopiera till...'
              },
              {
                iconName: 'DriveFileMoveOutlined',
                onClick: () => handleOpenDialog(dashboard.id, 'move_dashboard'),
                title: 'Flytta till...',
                disabled: dashboards.length === 1,
                divider: true
              },
              {
                iconName: 'CodeOutlined',
                onClick: () =>
                  handleOpenDialog(dashboard.id, 'embed_dashboard'),
                title: 'Bädda in',
                divider: true
              },
              {
                iconName: 'DeleteOutlined',
                onClick: () =>
                  handleOpenDialog(dashboard.id, 'delete_dashboard'),
                title: 'Radera',
                disabled: dashboards.length === 1
              }
            ]
          : undefined
      }
    })
  }, [group, dashboardObject, lgUp, activeDashboardId])
}
