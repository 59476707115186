import { AttributeOption } from 'types/GlobalAttributeOptions'
import {
  Condition,
  FilterConditionFormatBody,
  FilterPatchBody,
  FilterType
} from 'types/GlobalKpiOption'
import { KpiTemplateFilterOptionsBody } from 'types/GlobalKpiTemplates'
import { translateFilterCondition } from 'utils/enumTranslator'
import { FilterOption } from 'utils/types'

export const getFilterValueOptions = (
  filterAttributeOptions: AttributeOption[],
  filterOptions: KpiTemplateFilterOptionsBody | null
) => {
  const filterValueOptions: { [key: string]: FilterOption } = {}

  if (filterOptions) {
    filterAttributeOptions.forEach((option) => {
      const options = filterOptions[option.id]

      filterValueOptions[option.id] = options
    })
  }

  return filterValueOptions
}

export const MISSING_VALUE = 'Saknar värde'

export const parseSingleFilterLabel = (option: string | null) => {
  if (option === '' || option === null) {
    return MISSING_VALUE
  }

  return option
}

export const parseSingleFilterValue = (option: string | null) => {
  if (option === '') {
    return null
  }

  return option
}

export const parseFilterLabelValue = (option: string | null) => {
  if (option === MISSING_VALUE) {
    return null
  }

  return option
}

export const getFilterValue = (filter: FilterConditionFormatBody) => {
  const condition = filter.condition.selected
  const value = filter.value.selected

  if (
    condition &&
    [Condition.IS_NOT_NULL, Condition.IS_NULL].includes(condition)
  ) {
    return translateFilterCondition[condition].title
  }

  if (value === null) {
    return MISSING_VALUE
  } else if (typeof value === 'object' && value.length > 0) {
    return value.map(parseSingleFilterLabel).join(', ')
  } else if (typeof value === 'number') {
    return value.toString()
  } else {
    return MISSING_VALUE
  }
}

export const getFilterBody = (filter: FilterConditionFormatBody) => {
  const numericFilter = filter.from_value && filter.to_value

  if (!numericFilter) return null

  return `${filter.from_value?.selected} - ${filter.to_value?.selected}`
}

/*-- Below is copied from old filter functionality: --*/

const onlyLeftCondition = (condition: Condition | null) =>
  condition && [Condition.IS_NULL, Condition.IS_NOT_NULL].includes(condition)

export const formatUpdateFilterBody = (
  filters: FilterConditionFormatBody[],
  attributeOptionId?: string | null,
  condition?: Condition | null,
  values?: string[],
  numericValue?: number | null,
  fromValue?: string | null,
  toValue?: string | null
) => {
  const formattedFilters: FilterPatchBody[] = []
  const numericValueIsSet = numericValue || numericValue === 0
  const attributOptionAndCondition = attributeOptionId && condition

  filters.forEach((filter) => {
    // New filters are editing a current set one.
    const editCurrentSetFilter =
      attributOptionAndCondition &&
      filter.attribute_option.selected === attributeOptionId &&
      filter.condition.selected === condition
    // New filters are editing a from-to-filter
    const editCurrentFromTo =
      editCurrentSetFilter &&
      filter.from_value?.selected === fromValue &&
      filter.to_value?.selected === toValue
    // New filters are editing a multiple-options-filter
    const editCurrentMultiple =
      editCurrentSetFilter && values && values?.length > 0
    // Not a new filter and a multiple-options-filter
    const keepMultiple =
      !editCurrentSetFilter &&
      filter.value.selected &&
      typeof filter.value.selected === 'object' &&
      filter.value.selected.length > 0

    const editCurrentNumber = editCurrentSetFilter && numericValueIsSet
    const keepCurrentNumber =
      !editCurrentSetFilter && typeof filter.value.selected === 'number'

    // The new condition
    let newCondition: FilterPatchBody | null = null

    // only-left-filter
    if (
      filter.condition.selected &&
      onlyLeftCondition(filter.condition.selected)
    ) {
      newCondition = {
        filter_group_id: filter.filter_group_id,
        attribute_option_id: filter.attribute_option.selected as string,
        condition: filter.condition.selected as Condition,
        value: null,
        type: FilterType.ONLY_LEFT
      }
    } else if (
      // from-to-filter
      filter.type.selected === FilterType.FROM_TO &&
      ((editCurrentFromTo && numericValueIsSet) || !editCurrentFromTo)
    ) {
      newCondition = {
        filter_group_id: filter.filter_group_id,
        attribute_option_id: filter.attribute_option.selected as string,
        condition: filter.condition.selected as Condition,
        type: FilterType.FROM_TO,
        value: editCurrentFromTo
          ? (numericValue as number)
          : (filter.value.selected as number),
        from_value: editCurrentFromTo
          ? (fromValue as string)
          : (filter.from_value?.selected as string),
        to_value: editCurrentFromTo
          ? (toValue as string)
          : (filter.to_value?.selected as string)
      }
      // multiple-options-filter
    } else if (editCurrentMultiple || keepMultiple) {
      const selectedFilterValues = (
        (filter.value.selected || []) as string[]
      ).filter((value) => keepMultiple || !(values as string[]).includes(value))

      selectedFilterValues.forEach((value) =>
        formattedFilters.push({
          filter_group_id: filter.filter_group_id,
          attribute_option_id: filter.attribute_option.selected as string,
          condition: filter.condition.selected as Condition,
          value,
          type: FilterType.INPUT_VALUE
        })
      )
    } else if (editCurrentNumber || keepCurrentNumber) {
      newCondition = {
        filter_group_id: filter.filter_group_id,
        attribute_option_id: filter.attribute_option.selected as string,
        condition: filter.condition.selected as Condition,
        type: FilterType.NUMBER,
        value: editCurrentNumber
          ? (numericValue as number)
          : (filter.value.selected as number)
      }
    }

    if (newCondition) {
      formattedFilters.push(newCondition)
    }
  })

  return formattedFilters
}
