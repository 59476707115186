import React from 'react'

import {
  InnerWidgetPatchBody,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

import Box from 'components_new/atoms/Box'

import EditableText from './EditableText'
import Icon from 'components_new/atoms/Icon'
import Tooltip from 'components_new/atoms/Tooltip'

interface WidgetHeaderProps {
  editMode: boolean
  scaleFactor: number
  updateWidget: (arg0: string, arg1: InnerWidgetPatchBody) => void
  widget: WidgetObject
}

const WidgetHeader = (props: WidgetHeaderProps) => {
  const { editMode, scaleFactor, updateWidget, widget } = props

  const showTitle =
    widget.settings.show_title &&
    widget.settings.type.selected !== WidgetType.COMMENT &&
    widget.settings.type.selected !== WidgetType.IMAGE

  const showSubtitle =
    widget.settings.show_subtitle &&
    widget.settings.type.selected !== WidgetType.COMMENT &&
    widget.settings.type.selected !== WidgetType.IMAGE

  return (
    <Box
      sx={{
        position: 'relative',
        height: 'auto',
        pb: showTitle ? 1 * scaleFactor : undefined
      }}
    >
      {showTitle || showSubtitle ? (
        <Box sx={{ flex: 1, minWidth: 0, width: '100%' }}>
          {showTitle ? (
            <Box
              sx={{
                fontSize: 20 * scaleFactor,
                flex: 1,
                minWidth: 0,
                width: '100%',
                display: 'flex',
                color: 'text.primary'
              }}
            >
              {widget.settings.tooltip !== '' ? (
                <Tooltip title={widget.settings.tooltip}>
                  <Icon
                    fontSize="inherit"
                    name={'InfoOutlined'}
                    sx={{ mr: 0.5 }}
                  />
                </Tooltip>
              ) : null}
              <EditableText
                allowTextOverflow={
                  widget.settings.type.selected === WidgetType.TEXT
                }
                disabled={!editMode}
                onSubmit={(value) => {
                  updateWidget(widget.id, { title: value })
                }}
                scaleFactor={scaleFactor}
                value={widget.title}
                variant="title"
              />
            </Box>
          ) : null}
          {showSubtitle ? (
            <Box sx={{ mt: 0.5 * scaleFactor }}>
              <EditableText
                allowTextOverflow={
                  widget.settings.type.selected === WidgetType.TEXT
                }
                disabled={!editMode}
                onSubmit={(value) => {
                  updateWidget(widget.id, { subtitle: value })
                }}
                scaleFactor={scaleFactor}
                value={widget.settings.subtitle}
                variant="subtitle"
              />
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  )
}

export default WidgetHeader
