import { createSelector } from '@reduxjs/toolkit'

import { ApplicationState } from 'redux/Stores/types'
import {
  AttributeOption,
  DataType,
  GroupedAttributeOptions
} from 'types/GlobalAttributeOptions'
import { KpiOptionObject } from 'types/GlobalKpiOption'
import { WidgetObject, WidgetType } from 'types/GlobalWidget'

// select all attribute options
export const selectAll = createSelector(
  [
    // Input selectors:
    (state: ApplicationState) => state.AttributeOptionStore.data
  ],
  (data) => data
)

// select all group by fact table
export const selectAllGrouped = createSelector(
  [
    // Input selectors:
    (state: ApplicationState) => state.AttributeOptionStore.optionsByFactTableId
  ],
  (optionsByFactTableId) => {
    return Object.entries(optionsByFactTableId).reduce<GroupedAttributeOptions>(
      (acc, [factTableId, options]) => {
        acc[factTableId] = {
          segment: options.filter((option) => option.segment_attribute),
          breakdown: options.filter((option) => option.breakdown_attribute),
          filter: options.filter((option) => option.filter_attribute)
        }

        return acc
      },
      {}
    )
  }
)

function itemsIncludedInAllLists(lists: string[][]): Record<string, string> {
  // Build a frequency map for each item across all lists
  const frequency = lists.reduce<Record<string, number>>((acc, list) => {
    list.forEach((item) => {
      acc[item] = (acc[item] || 0) + 1
    })

    return acc
  }, {})

  // Return an object of items that appear in every list
  return Object.keys(frequency).reduce<Record<string, string>>(
    (commonItems, item) => {
      if (frequency[item] === lists.length) {
        commonItems[item] = item
      }

      return commonItems
    },
    {}
  )
}

/**
 * Validate attribute option based on widget type
 * and attribute option type.
 */
const validateAttributeOption = (
  option: AttributeOption,
  type: 'segment_attribute' | 'breakdown_attribute' | 'filter_attribute',
  widgetType: WidgetType
) => {
  const allow = option[type] && !option.disabled

  if (widgetType === WidgetType.LINE_CHART && type === 'segment_attribute') {
    // line chart is required to be time series
    return allow && option.is_time_series
  }
  if (widgetType === WidgetType.LINE_CHART && type === 'breakdown_attribute') {
    // line chart cant breakdown on calendar
    return allow && option.type !== DataType.DATE
  }

  if (widgetType === WidgetType.FUNNEL) {
    return allow && option.type !== DataType.DATE
  }

  return allow
}

export const selectCommonAttributeOptions = createSelector(
  [
    // Input selectors:
    (state: ApplicationState) =>
      state.AttributeOptionStore.optionsByFactTableId,
    (_: ApplicationState, widget: WidgetObject) => widget,
    (
      _: ApplicationState,
      widget: WidgetObject,
      type: 'segment_attribute' | 'filter_attribute'
    ) => type
  ],
  (optionsByFactTableId, widget, type) => {
    if (widget.settings.kpi_options.length === 0) {
      return []
    }

    // get options for main kpi option
    const mainKpiOptionId = widget.settings.kpi_options.find(
      (item) => item.index === 0
    ) as KpiOptionObject
    const attributeOptions =
      optionsByFactTableId[mainKpiOptionId.fact_table_id] ?? []
    const filteredOptions = attributeOptions.filter((option) =>
      validateAttributeOption(option, type, widget.settings.type.selected)
    )

    if (widget.settings.kpi_options.length === 1) {
      return filteredOptions
    }

    // get all available relation keys for each kpi option
    const segmentAttributeIds = widget.settings.kpi_options.map((kpi) =>
      (optionsByFactTableId[kpi.fact_table_id] ?? [])
        .filter((attr) => attr[type])
        .map((option) => option.relation_key)
    )

    // find all in common among kpi options
    const availableOptions = itemsIncludedInAllLists(segmentAttributeIds)

    // only return common options
    return filteredOptions.filter((opt) => opt.relation_key in availableOptions)
  }
)

export const selectKpiOptionAttributeOptions = createSelector(
  [
    // Input selectors:
    (state: ApplicationState) =>
      state.AttributeOptionStore.optionsByFactTableId,
    (_: ApplicationState, widget: WidgetObject) => widget,
    (_: ApplicationState, widget: WidgetObject, kpiOption: KpiOptionObject) =>
      kpiOption,
    (
      _: ApplicationState,
      widget: WidgetObject,
      kpiOption: KpiOptionObject,
      optionType: 'breakdown_attribute' | 'filter_attribute'
    ) => optionType
  ],
  (optionsByFactTableId, widget, kpiOption, optionType) => {
    const attributeOptions = optionsByFactTableId[kpiOption.fact_table_id] ?? []

    return attributeOptions.filter((option) =>
      validateAttributeOption(option, optionType, widget.settings.type.selected)
    )
  }
)

export const selectSegmentBy = createSelector(
  [
    // Input selectors:
    (state: ApplicationState) => state.AttributeOptionStore.data,
    (_: ApplicationState, widget: WidgetObject) => widget,
    (
      _: ApplicationState,
      widget: WidgetObject,
      customSegmentBy?: string | null
    ) => customSegmentBy
  ],
  (data, widget, customSegmentBy) => {
    if (customSegmentBy) {
      return data[customSegmentBy]
    }

    if (!widget.settings.segment_by.selected) {
      return null
    }

    return data[widget.settings.segment_by.selected]
  }
)

export const selectBreakdownBy = createSelector(
  [
    // Input selectors:
    (state: ApplicationState) => state.AttributeOptionStore.data,
    (_: ApplicationState, kpiOption: KpiOptionObject) => kpiOption
  ],
  (data, kpiOption) => {
    if (!kpiOption.breakdown_by.selected) {
      return null
    }

    return data[kpiOption.breakdown_by.selected]
  }
)
