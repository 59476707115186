import React from 'react'

import ImageSection from './ImageSection'

import {
  WidgetObject,
  InnerWidgetPatchBody,
  WidgetType,
  FunnelStagePutBody,
  TViewport
} from 'types/GlobalWidget'
import {
  KpiOptionPatchAttributes,
  KpiOptionPostAttributes
} from 'types/GlobalKpiOption'
import {
  WidgetTagPatchAttributes,
  WidgetTagPostAttributes
} from 'types/GlobalWidgetTag'
import {
  KpiTemplateFilterOptionsBody,
  KpiTemplateRedux
} from 'types/GlobalKpiTemplates'
import { Customer } from 'types/GlobalCustomer'

import { useCommonAttributeOptions } from 'redux/hooks/AttributeOptions'

import TitleSection from './TitleSection'
import VisualizationSection from './VisualizationSection'
import DatasetsSection from './DatasetsSection'
import SegmentSection from './SegmentSection'
import DatesSection from './DatesSection'
import FilterSection from './FilterSection'
import AreaSection from './AreaSection'
import ViewportSection from './ViewportSection'
import ReOrderSection from './ReOrderSection'
import SettingsDrawer from 'components_new/molecules/SettingsDrawer'

interface WidgetSettingsProps {
  allowBenchmarking: boolean
  createAdditionalSegmentBy: (attributeOptionId: string) => void
  createKpiOption: (body: KpiOptionPostAttributes) => void
  createWidgetTag: (body: WidgetTagPostAttributes) => void
  customer: Customer | null
  deleteAdditionalSegmentBy: (id: string) => void
  deleteKpiOption: (id: string) => void
  deleteWidgetTag: (id: string) => void
  fetchedKpis: boolean
  filterOptions: KpiTemplateFilterOptionsBody | null
  fullSize: boolean
  kpiTemplates: KpiTemplateRedux
  kpiTemplatesFetched: boolean
  onClose: () => void
  open: boolean
  putFunnelStages: (body: FunnelStagePutBody) => void
  setViewport: (value: TViewport) => void
  triggerFakeLoading: () => void
  updateAdditionalSegmentBy: (id: string, attributeOptionId: string) => void
  updateKpiOption: (id: string, body: KpiOptionPatchAttributes) => void
  updateWidget: (arg0: string, arg1: InnerWidgetPatchBody) => void
  updateWidgetTag: (id: string, body: WidgetTagPatchAttributes) => void
  uploadImage: (image: Blob, name: string) => void
  viewport: TViewport
  widget: WidgetObject
}

const WidgetSettings = (props: WidgetSettingsProps) => {
  const {
    allowBenchmarking,
    createAdditionalSegmentBy,
    createKpiOption,
    createWidgetTag,
    customer,
    deleteAdditionalSegmentBy,
    deleteKpiOption,
    deleteWidgetTag,
    fetchedKpis,
    filterOptions,
    fullSize,
    kpiTemplates,
    kpiTemplatesFetched,
    onClose,
    open,
    putFunnelStages,
    setViewport,
    triggerFakeLoading,
    updateAdditionalSegmentBy,
    updateKpiOption,
    updateWidget,
    updateWidgetTag,
    uploadImage,
    viewport,
    widget
  } = props

  const type = widget.settings.type.selected

  const filterAttributeOptions = useCommonAttributeOptions(
    widget,
    'filter_attribute'
  )

  // Remove when map refresh bug is solved:
  const updateWidgetWithFakeloading = (
    id: string,
    body: InnerWidgetPatchBody
  ) => {
    if (widget.settings.type.selected === WidgetType.MAP) {
      triggerFakeLoading()
    }

    updateWidget(id, body)
  }

  return (
    <SettingsDrawer
      backdrop={true}
      backLabel="Stäng"
      onBack={onClose}
      onClose={onClose}
      open={open}
      title={type === WidgetType.IMAGE ? 'Bild' : widget.title}
    >
      {type === WidgetType.IMAGE ? (
        <ImageSection
          widget={widget}
          updateWidget={updateWidgetWithFakeloading}
          uploadImage={uploadImage}
        />
      ) : null}
      {fetchedKpis && type !== WidgetType.IMAGE ? (
        <>
          <TitleSection
            onClose={onClose}
            updateWidget={updateWidgetWithFakeloading}
            widget={widget}
          />
          <VisualizationSection
            updateWidget={updateWidgetWithFakeloading}
            widget={widget}
          />
          <DatasetsSection
            allowBenchmarking={allowBenchmarking}
            createKpiOption={createKpiOption}
            createWidgetTag={createWidgetTag}
            customer={customer}
            deleteKpiOption={deleteKpiOption}
            deleteWidgetTag={deleteWidgetTag}
            filterOptions={filterOptions}
            fullSize={fullSize}
            kpiTemplates={kpiTemplates}
            kpiTemplatesFetched={kpiTemplatesFetched}
            onClose={onClose}
            updateKpiOption={updateKpiOption}
            updateWidget={updateWidgetWithFakeloading}
            updateWidgetTag={updateWidgetTag}
            widget={widget}
          />
          <SegmentSection
            createAdditionalSegmentBy={createAdditionalSegmentBy}
            deleteAdditionalSegmentBy={deleteAdditionalSegmentBy}
            onClose={onClose}
            updateAdditionalSegmentBy={updateAdditionalSegmentBy}
            updateWidget={updateWidgetWithFakeloading}
            widget={widget}
          />
          <DatesSection
            onClose={onClose}
            updateWidget={updateWidgetWithFakeloading}
            widget={widget}
          />
          <FilterSection
            filterAttributeOptions={filterAttributeOptions}
            filters={widget.settings.filters}
            filterOptions={filterOptions}
            onClose={onClose}
            updateFilters={(filters) =>
              updateWidgetWithFakeloading(widget.id, {
                filters: filters
              })
            }
            widget={widget}
          />
          <ReOrderSection putFunnelStages={putFunnelStages} widget={widget} />
          <AreaSection
            updateWidget={updateWidgetWithFakeloading}
            widget={widget}
          />
          <ViewportSection
            setViewport={setViewport}
            updateWidget={updateWidgetWithFakeloading}
            viewport={viewport}
            widget={widget}
          />
        </>
      ) : null}
    </SettingsDrawer>
  )
}

export default WidgetSettings
