import { IconName } from 'components_new/atoms/Icon'
import { convertLocaleToNumber } from 'utils/numbers'

export interface IRealEstateAllocation {
  real_estate_id: string
  share: number
}

export interface IAllocationKey {
  id: string
  name: string
  allocations: IRealEstateAllocation[]
  accounts: string[]
}

export interface IPostAllocationKey {
  name: string
  allocations: IRealEstateAllocation[]
  accounts: string[]
}

export interface IPatchAllocationKey {
  name?: string
  allocations?: IRealEstateAllocation[]
  accounts?: string[]
}

export enum AutoAreaOptions {
  BOA = 'BOA',
  LOA = 'LOA',
  RENTABLE = 'RENTABLE',
  ATEMP = 'ATEMP'
}

export const translateAutoAreaOptions: Record<
  AutoAreaOptions,
  {
    abbreviation: string
    label: string
    iconName: IconName
  }
> = {
  [AutoAreaOptions.ATEMP]: {
    abbreviation: 'ATEMP',
    label: 'Tempererad m² (10 °C)',
    iconName: 'HvacOutlined'
  },
  [AutoAreaOptions.BOA]: {
    abbreviation: 'BOA',
    label: 'Bostad m²',
    iconName: 'CottageOutlined'
  },
  [AutoAreaOptions.LOA]: {
    abbreviation: 'LOA',
    label: 'Lokal m²',
    iconName: 'BusinessOutlined'
  },
  [AutoAreaOptions.RENTABLE]: {
    abbreviation: 'UHY',
    label: 'Uthyrningsbar m²',
    iconName: 'CropFreeOutlined'
  }
}

export const calculateTotalAllocation = (allocations: {
  [key: string]: string
}) => {
  return Number(
    Object.values(allocations)
      .reduce((sum, value) => sum + convertLocaleToNumber(value), 0)
      .toFixed(2)
  )
}

export const validateTotalAllocation = (totalAllocation: number) => {
  if (totalAllocation === 0) {
    return {
      validationPass: false,
      textColor: 'error.text',
      tooltip: 'Den totala fördelning får inte vara 0%.'
    }
  }

  if (totalAllocation > 100) {
    return {
      validationPass: false,
      textColor: 'error.text',
      tooltip: 'Den totala fördelning får inte överskrida 100%.'
    }
  }

  if (totalAllocation < 95) {
    return { validationPass: true, textColor: 'warning.text' }
  }

  return {
    validationPass: true,
    textColor: 'success.text'
  }
}
