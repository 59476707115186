import React from 'react'

import Avatar from 'components_new/atoms/Avatar'
import Icon from 'components_new/atoms/Icon'

import Logotype from 'components_new/molecules/Logotype'

import LogotypeIcon from 'assets/logotypes/LogotypeIcon'

import { getProfileAvatarSrc } from './utils'

interface UserAvatarProps {
  email?: string
  name: string
  size?: 'small' | 'medium' | 'large'
  src?: string
  sx?: object
  type?: 'customer' | 'user' | 'homepal' | 'role' | 'chart' | 'companygroup'
}

const UserAvatar = React.forwardRef((props: UserAvatarProps, ref: any) => {
  const { email, name, size, src, sx, type } = props

  let icon = null
  let avatarBgColor = undefined

  switch (type) {
  case 'customer': {
    if (src) {
      icon = <Logotype mode={'dark'} src={src} />
    } else {
      icon = <Icon color="disabled" name="ApartmentOutlined" />
    }
    avatarBgColor = 'primary.main'
    break
  }
  case 'homepal': {
    avatarBgColor = 'primary.main'
    icon = <LogotypeIcon />
    break
  }
  case 'chart': {
    avatarBgColor = 'transparent'
    icon = <Icon color="disabled" name="AccountTreeOutlined" />
    break
  }
  case 'companygroup': {
    avatarBgColor = 'transparent'
    icon = <Icon color="disabled" name="Business" />
    break
  }
  }

  const imageSrc: string | undefined = getProfileAvatarSrc(email)

  return (
    <Avatar
      data-openreplay-obscured
      ref={ref}
      alt={type === 'role' ? '' : name}
      bgcolor={avatarBgColor}
      size={size}
      src={imageSrc}
      sx={{
        ...(imageSrc
          ? {
              WebkitFilter: 'grayscale(100%)',
              filter: 'grayscale(100%)'
            }
          : null),
        ...sx
      }}
    >
      {icon}
    </Avatar>
  )
})

export default UserAvatar
