import { createSelector } from '@reduxjs/toolkit'

import { ApplicationState } from 'redux/Stores/types'

// Get customer agreements for one customer.
export const selectCustomerAgreements = createSelector(
  [
    // Input selectors:
    (state: ApplicationState, customerId: string) =>
      state.CustomerAgreementsStore[customerId]
  ],
  (agreements) => {
    return {
      data: agreements ? agreements : [],
      fetched: agreements !== undefined
    }
  }
)
