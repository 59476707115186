import { OrderedKpiTemplate } from './GlobalOrderedKpiTemplate'

export enum PeriodType {
  QUARTERLY = 'QUARTERLY',
  MONTHLY = 'MONTHLY',
  MANUALLY = 'MANUALLY'
}

export const translatePeriodType: Record<PeriodType, string> = {
  [PeriodType.QUARTERLY]: 'Kvartalsvis',
  [PeriodType.MONTHLY]: 'Månadsvis',
  [PeriodType.MANUALLY]: 'Manuellt'
}

export enum FrequencyOfDataSynchronization {
  ONCE_PER_DAY = 'ONCE_PER_DAY',
  SEVERAL_TIMES_PER_DAY = 'SEVERAL_TIMES_PER_DAY'
}

export const translateFrequencyOfDataSynchronization: Record<
  FrequencyOfDataSynchronization,
  { display: string; value: FrequencyOfDataSynchronization }
> = {
  [FrequencyOfDataSynchronization.ONCE_PER_DAY]: {
    display: '1 gång per dygn',
    value: FrequencyOfDataSynchronization.ONCE_PER_DAY
  },
  [FrequencyOfDataSynchronization.SEVERAL_TIMES_PER_DAY]: {
    display: 'Flera gånger per dygn',
    value: FrequencyOfDataSynchronization.SEVERAL_TIMES_PER_DAY
  }
}

export enum License {
  INSIGHT = 'INSIGHT',
  PRO = 'PRO',
  STARTER = 'STARTER',
  DEDU_FREEMIUM = 'DEDU_FREEMIUM',
  DH_FREEMIUM = 'DH_FREEMIUM',
  OTHER = 'OTHER'
}

export const translateLicense: Record<
  License,
  { display: string; value: License }
> = {
  [License.INSIGHT]: {
    display: 'Insight',
    value: License.INSIGHT
  },
  [License.PRO]: {
    display: 'Pro',
    value: License.PRO
  },
  [License.STARTER]: {
    display: 'Starter',
    value: License.STARTER
  },
  [License.DEDU_FREEMIUM]: {
    display: 'DeDU Freemium',
    value: License.DEDU_FREEMIUM
  },
  [License.DH_FREEMIUM]: {
    display: 'DH Freemium',
    value: License.DH_FREEMIUM
  },
  [License.OTHER]: {
    display: 'Övrig',
    value: License.OTHER
  }
}

export enum SupportLevel {
  CHAT_AND_EMAIL = 'CHAT_AND_EMAIL',
  CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS',
  SLA = 'SLA'
}

export const translateSupportLevel: Record<
  SupportLevel,
  { display: string; value: SupportLevel }
> = {
  [SupportLevel.CHAT_AND_EMAIL]: {
    display: 'Chat och email',
    value: SupportLevel.CHAT_AND_EMAIL
  },
  [SupportLevel.CUSTOMER_SUCCESS]: {
    display: 'Customer success',
    value: SupportLevel.CUSTOMER_SUCCESS
  },
  [SupportLevel.SLA]: {
    display: 'SLA',
    value: SupportLevel.SLA
  }
}

export type AllowAcess = {
  white_label_colors: boolean
  white_label_logotype: boolean
  insight_studio: boolean
  access_hub: boolean
  sso: boolean
  premium_kpis: boolean
  benchmarking: boolean
  frequency_of_data_synchronization: FrequencyOfDataSynchronization
  support_level: SupportLevel
  embed: boolean
  allowed_historical_time_period: number
}

export enum Segment {
  MunicipalHousingCompany = 'MunicipalHousingCompany',
  RealEstateInvestmentFund = 'RealEstateInvestmentFund',
  MunicipalCommercialRealEstateCompany = 'MunicipalCommercialRealEstateCompany',
  PrivateHousingCompany = 'PrivateHousingCompany',
  PropertyManagementCompany = 'PropertyManagementCompany'
}

export const translateSegment: Record<Segment, { display: string }> = {
  [Segment.MunicipalHousingCompany]: {
    display: 'Kommunalt bostadsbolag'
  },
  [Segment.RealEstateInvestmentFund]: {
    display: 'Fondbolag'
  },
  [Segment.MunicipalCommercialRealEstateCompany]: {
    display: 'Kommunal kommersiell'
  },
  [Segment.PrivateHousingCompany]: {
    display: 'Privat bostadsbolag'
  },
  [Segment.PropertyManagementCompany]: {
    display: 'Förvaltningsbolag'
  }
}

export enum Country {
  sv = 'sv'
}

export const translateCountry: Record<Country, { display: string }> = {
  [Country.sv]: {
    display: 'Sverige'
  }
}

export type Meta = {
  segment: Segment
  country: Country
}

export type InternalCustomer = {
  id: string
  name: string
  number_of_dashboards: number
  number_of_dashboards_in_use: number
  contract_from: Date
  data_from: Date
  license: License
  is_demo: boolean
  using_homepal_star_schema: boolean
  is_freemium: boolean
  is_management_company: boolean
  allow_access: AllowAcess
  ordered_kpi_templates: OrderedKpiTemplate[]
  period_type: PeriodType | null
  latest_closed_period: string | null
  integrate_company_groups: boolean
  meta: Meta
}

export type InternalCustomerRedux = {
  [key: string]: InternalCustomer
}

export interface PatchInternalCustomerBody {
  data: {
    access_hub?: boolean
    benchmarking?: boolean
    contract_from?: Date
    data_from?: Date
    embed?: boolean
    frequency_of_data_synchronization?: FrequencyOfDataSynchronization
    allowed_historical_time_period?: number
    number_of_dashboards?: string | null
    insight_studio?: boolean
    license?: License
    premium_kpis?: boolean
    is_demo?: boolean
    using_homepal_star_schema?: boolean
    is_management_company?: boolean
    sso?: boolean
    support_level?: SupportLevel
    white_label_colors?: boolean
    white_label_logotype?: boolean
    period_type?: PeriodType
    integrate_company_groups?: boolean
    segment?: Segment
    country?: Country
  }
}

export type Customer = {
  customer_id: string
  name: string
  slug: string
  technical_name: string
  allow_access: AllowAcess
  has_idp: boolean
  is_demo: boolean
  is_management_company: boolean
  ordered_kpi_templates: OrderedKpiTemplate[]
  number_of_dashboards_in_use: number
  number_of_dashboards: number | null
  period_type: PeriodType | null
  latest_closed_period: string | null
  integrate_company_groups: boolean
}
