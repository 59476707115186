import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit'

import { useDashboard } from 'redux/hooks/Dashboards'

import * as WidgetActions from 'redux/actions/Widgets'

import SelectSearch, { Option } from 'components_new/molecules/SelectSearch'

import { getShowAsFilter } from 'components_new/organisms/Dashboard/utils'

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  CompanyGroupStore: state.CompanyGroupStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(WidgetActions, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector>

type WidgetOption = Option & {
  id: string
  is_preview: boolean
}

const PreviewWidgets = (props: ComponentProps) => {
  const {
    // redux actions
    tryUpdateWidget,
    // redux stores
    CompanyGroupStore
  } = props

  const [selectedPreviewWidgets, setSelectedPreviewWidgets] = useState<
    WidgetOption[]
  >([])
  const params = useParams<{ id: string }>()
  const dashboard = useDashboard(params.id)

  const widgets: WidgetOption[] = (
    dashboard ? Object.values(dashboard.widgets) : []
  ).map((item) => ({
    id: item.id,
    title: item.title,
    is_preview: item.settings.is_preview
  }))

  useEffect(() => {
    setSelectedPreviewWidgets(widgets.filter((item) => item.is_preview))
  }, [])

  if (!dashboard) {
    return null
  }

  const handleChangePreviewWidgets = (options: WidgetOption[]) => {
    setSelectedPreviewWidgets(options)

    const queryParams = CompanyGroupStore.showAs
      ? {
          filter: [getShowAsFilter(CompanyGroupStore.showAs)]
        }
      : null

    const mapper = new Map(options.map((widget) => [widget.id, true]))

    widgets.forEach((widget) => {
      const isPreview = mapper.get(widget.id) ?? false
      const previousIsPreview = dashboard.widgets[widget.id].settings.is_preview

      if (isPreview && !previousIsPreview) {
        // set as preview widget
        tryUpdateWidget(widget.id, { data: { is_preview: true } }, queryParams)
      } else if (previousIsPreview) {
        // removed as preview widget
        tryUpdateWidget(widget.id, { data: { is_preview: false } }, queryParams)
      }
    })
  }

  return (
    <SelectSearch
      color="primary"
      disableClearable={true}
      multiple={true}
      onChange={(options: Option[]) =>
        handleChangePreviewWidgets(options as WidgetOption[])
      }
      options={widgets}
      selected={selectedPreviewWidgets}
      size={'medium'}
      sort={true}
      variant="select"
    />
  )
}

export default connector(PreviewWidgets)
