import React, { useState } from 'react'

import Text from 'components_new/atoms/Text'

import SegmentVirtualizedTable, {
  TTableRow
} from 'components_new/molecules/SegmentVirtualizedTable'

import { CreateEmailBody, Email } from 'types/GlobalEmails'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import SimpleDialog from 'components_new/molecules/SimpleDialog'
import SelectSearch, { Option } from 'components_new/molecules/SelectSearch'
import TextField from 'components_new/atoms/TextField'
import Textarea from 'components_new/atoms/Textarea'
import { TrackingUser } from 'types/GlobalTracking'
import { DatasetType } from 'types/GlobalWidget'

import { validateEmail } from './utils'

interface InviteMessageTemplateProps {
  createEmail: (
    body: CreateEmailBody,
    callback?: (err?: boolean) => void
  ) => void
  emails: Email[]
  users: TrackingUser[]
}

const INITIAL_SETTINGS: CreateEmailBody = {
  batch_name: '',
  subject: '',
  user_ids: [] as string[],
  html: ''
}

const EmailsTemplate = (props: InviteMessageTemplateProps) => {
  const { createEmail, emails, users } = props
  const [createOpen, setCreateOpen] = useState(false)
  const [emailSettings, setEmailSettings] = useState(INITIAL_SETTINGS)
  const [testUsers, setTestUsers] = useState<
    {
      id: string
      title: string
    }[]
  >([])
  const [errors, setErrors] = useState<string[]>([])
  const [loadingTest, setLoadingTest] = useState(false)
  const [loading, setLoading] = useState(false)

  const selectedUsers = emailSettings.user_ids.map((id) => {
    const user = users.find((u) => u.id === id) as TrackingUser

    return {
      id,
      title: user.full_name
    }
  })

  const userOptions = users.map((user) => ({
    id: user.id,
    title: `${user.full_name} (${user.email})`
  }))

  return (
    <>
      <Text variant="h5" sx={{ lineHeight: 2 }}>
        Email utskick
      </Text>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2
        }}
      >
        <Text sx={{ lineHeight: 2 }}>Här kan man se alla email utskick.</Text>
        <Button onClick={() => setCreateOpen(true)}>Skapa</Button>
      </Box>
      <SegmentVirtualizedTable
        data={emails.map((email) => {
          const row = {
            /*-- segment --*/
            segment: {
              label: email.batch_name
            }
          } as TTableRow

          /*-- data --*/
          row.nr_of_sends = [email.nr_of_sends]
          row.nr_of_bounces = [email.nr_of_bounces]
          row.nr_of_opens = [email.nr_of_opens]
          row.nr_of_clicks = [email.nr_of_clicks]

          return row
        })}
        loading={false}
        columns={[
          {
            id: 'batch_name',
            label: 'Namn på utskick',
            type: DatasetType.STRING
          },
          {
            id: 'nr_of_sends',
            label: 'Antal skickade emails',
            type: DatasetType.NUMBER
          },
          {
            id: 'nr_of_bounces',
            label: 'Antal bounces',
            type: DatasetType.NUMBER
          },
          {
            id: 'nr_of_opens',
            label: 'Antal öppningar',
            type: DatasetType.NUMBER
          },
          {
            id: 'nr_of_clicks',
            label: 'Antal klick',
            type: DatasetType.NUMBER
          }
        ]}
      />
      <SimpleDialog
        open={createOpen}
        onClose={() => setCreateOpen(false)}
        title={'Nytt utskick'}
        actions={
          <Button
            loading={loading}
            onClick={() => {
              setLoading(true)
              const errs = validateEmail(emailSettings)

              if (errs.length === 0) {
                createEmail(emailSettings, (err) => {
                  setLoading(false)
                  if (!err) {
                    setCreateOpen(false)
                    setEmailSettings(INITIAL_SETTINGS)
                  }
                })
              } else {
                setLoading(false)
              }

              setErrors(errs)
            }}
            sx={{ mt: 2 }}
          >
            Skicka email
          </Button>
        }
      >
        <TextField
          placeholder={'Namn på utskick'}
          label={'Namn på utskick'}
          value={emailSettings.batch_name}
          onChange={(e) =>
            setEmailSettings({ ...emailSettings, batch_name: e.target.value })
          }
        />
        <TextField
          placeholder={'Ämnesrad'}
          label={'Ämnesrad'}
          value={emailSettings.subject}
          onChange={(e) =>
            setEmailSettings({ ...emailSettings, subject: e.target.value })
          }
        />
        <SelectSearch
          placeholder={'Användare'}
          label={'Användare'}
          options={userOptions}
          selected={selectedUsers}
          size={'medium'}
          onChange={(options: Option[]) => {
            const userIds = options?.map((opt) => opt.id as string)

            setEmailSettings({
              ...emailSettings,
              user_ids: userIds
            })
          }}
        />
        <Textarea
          aria-label="minimum height"
          minRows={20}
          placeholder="HTML"
          title={'HTML'}
          sx={{ width: '100%', mt: 2 }}
          value={emailSettings.html}
          onChange={(e) =>
            setEmailSettings({ ...emailSettings, html: e.target.value })
          }
        />

        <Text fontWeight="900">Skicka test Email</Text>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}
        >
          <SelectSearch
            sx={{ width: 200 }}
            placeholder={'Testanvändare'}
            label={'Testanvändare'}
            onChange={(options: Option[]) =>
              setTestUsers(options as { id: string; title: string }[])
            }
            options={userOptions}
            selected={testUsers}
          />
          <Button
            loading={loadingTest}
            onClick={() => {
              setLoadingTest(true)
              const body = {
                ...emailSettings,
                user_ids: testUsers.map((user) => user.id),
                is_test: 'true'
              }
              const errs = validateEmail(body)

              if (errs.length === 0) {
                createEmail(body, () => {
                  setLoadingTest(false)
                })
              } else {
                setLoadingTest(false)
              }

              setErrors(errs)
            }}
          >
            Skicka test email
          </Button>
        </Box>
        {errors.map((error, i) => (
          <Text sx={{ color: 'red' }} key={i}>
            {error}
          </Text>
        ))}
      </SimpleDialog>
    </>
  )
}

export default EmailsTemplate
