import React from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import { WidgetObject } from 'types/GlobalWidget'

interface HistoricalDataOutOfBounceProps {
  scaleFactor: number
  widget: WidgetObject
}

const HistoricalDataOutOfBounce = (props: HistoricalDataOutOfBounceProps) => {
  const { widget, scaleFactor } = props

  if (widget.status.data_is_out_of_bounds) {
    return (
      <Tooltip
        title={
          <>
            <Text gutterBottom={true} variant="inherit">
              Den valda tidsperioden omfattar historisk data som går utanför din
              organisations licens.
            </Text>
            <Text gutterBottom={true} variant="inherit">
              Det här kan påverka visualiseringen och dess värden som kan bli
              missvisande.
            </Text>
            <Text gutterBottom={true} variant="inherit">
              För en mer tillförlitligt analys bör du se till att vald
              tidsperiod ligger inom licensens gränser.
            </Text>
          </>
        }
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            position: 'relative',
            zIndex: 1,
            fontSize: 14 * scaleFactor
          }}
        >
          <Icon color="warning" fontSize="inherit" name={'Warning'} />
          <Text
            color="warning.text"
            fontSize="inherit"
            fontWeight="medium"
            sx={{
              ml: '0.25em',
              cursor: 'default'
            }}
            noWrap={true}
          >
            Historisk data saknas för vald tidsperiod
          </Text>
        </Box>
      </Tooltip>
    )
  }

  return null
}

export default HistoricalDataOutOfBounce
