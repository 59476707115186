import React, { useMemo, useState } from 'react'

import { SxProps } from '@mui/material'

import { ISavedDashboardFilter } from 'types/GlobalSavedDashboardFilter'
import { ParsedDashboardFilterOptions } from 'types/GlobalDashboardFilter'

import { sortAlphabeticalAsc } from 'utils/sortHelper'

import Box from 'components_new/atoms/Box'
import Link from 'components_new/atoms/Link'
import List from 'components_new/atoms/List'
import ListSubheader from 'components_new/atoms/List/ListSubheader'

import Text from 'components_new/atoms/Text'

import EmptyPlaceholder from 'components_new/molecules/EmptyPlaceholder'

import SavedFilterItem from './SavedFilterItem'

interface SavedFiltersProps {
  dashboardFilterOptions: Map<string, ParsedDashboardFilterOptions>
  savedDashboardFilters: ISavedDashboardFilter[]
  savedDashboardFilter: null | ISavedDashboardFilter
  setSavedDashboardFilter: (value: ISavedDashboardFilter | null) => void
  sx?: SxProps
}

const SavedFilters = (props: SavedFiltersProps) => {
  const {
    dashboardFilterOptions,
    savedDashboardFilters,
    savedDashboardFilter,
    setSavedDashboardFilter,
    sx
  } = props

  const [showMore, setShowMore] = useState<boolean>(false)

  // all filters should be available as dashboard filter.
  const handledSavedDashboardFilters = useMemo(() => {
    const filters: {
      available: ISavedDashboardFilter[]
      unavailable: ISavedDashboardFilter[]
    } = { available: [], unavailable: [] }

    savedDashboardFilters.forEach((filter) => {
      const unavailable = filter.conditions.some(
        (condition) => !dashboardFilterOptions.get(condition.relation_key)
      )

      if (unavailable) {
        filters.unavailable.push(filter)
      } else {
        filters.available.push(filter)
      }
    })

    filters.available.sort((a, b) => sortAlphabeticalAsc(a.title, b.title))
    filters.unavailable.sort((a, b) => sortAlphabeticalAsc(a.title, b.title))

    return filters
  }, [savedDashboardFilters, dashboardFilterOptions])

  // empty
  if (savedDashboardFilters.length === 0)
    return (
      <EmptyPlaceholder
        background={true}
        height={128}
        title="Du har inga sparade filter."
      />
    )

  // success
  return (
    <Box sx={sx}>
      {/*-- available --*/}
      <List>
        {handledSavedDashboardFilters.available.map((filter) => {
          const selected = filter.id === savedDashboardFilter?.id

          return (
            <SavedFilterItem
              filter={filter}
              key={filter.id}
              onClick={() => setSavedDashboardFilter(filter)}
              selected={selected}
              savedDashboardFilter={savedDashboardFilter}
              setSavedDashboardFilter={setSavedDashboardFilter}
            />
          )
        })}
      </List>

      {/*-- unavailable --*/}
      {handledSavedDashboardFilters.unavailable.length > 0 ? (
        <>
          <List>
            <ListSubheader
              border={true}
              disableSticky={true}
              sx={{ bgcolor: 'transparent', mb: 1 }}
            >
              Ej tillgängliga
            </ListSubheader>
            {handledSavedDashboardFilters.unavailable.map((filter) => {
              const selected = filter.id === savedDashboardFilter?.id

              return (
                <SavedFilterItem
                  disabled={true}
                  filter={filter}
                  key={filter.id}
                  onClick={() => setSavedDashboardFilter(filter)}
                  selected={selected}
                  savedDashboardFilter={savedDashboardFilter}
                  setSavedDashboardFilter={setSavedDashboardFilter}
                />
              )
            })}
          </List>

          <Box sx={{ bgcolor: 'action.hover', p: 1 }}>
            <Text color="text.secondary" component="div" variant="caption">
              <Text color="inherit" fontSize="inherit" gutterBottom={true}>
                De sparade filtren som visas här kan inte användas eftersom de
                innehåller filter som inte är tillgängliga på just den här
                dashboarden.{' '}
                {!showMore ? (
                  <Link onClick={() => setShowMore(true)}>Visa mer...</Link>
                ) : null}
              </Text>
              {showMore ? (
                <>
                  <Text color="inherit" fontSize="inherit" gutterBottom={true}>
                    Det kan bero på att:
                  </Text>
                  <Text color="inherit" fontSize="inherit" gutterBottom={true}>
                    <ul className="ul">
                      <li className="li">
                        Filtret skapades på en annan dashboard.
                      </li>
                      <li className="li">
                        Ägaren har tagit bort det filteralternativ som användes.
                      </li>
                    </ul>
                  </Text>
                  <Text color="inherit" fontSize="inherit" gutterBottom={true}>
                    För att använda ett giltigt filter, skapa ett nytt eller
                    välj ett som är tillgängligt för den här dashboarden.{' '}
                    <Link onClick={() => setShowMore(false)}>Visa mindre</Link>
                  </Text>
                </>
              ) : null}
            </Text>
          </Box>
        </>
      ) : null}
    </Box>
  )
}

export default SavedFilters
