export const SET_SIGNED_IN = 'SET_SIGNED_IN'
export const SET_TEN_OPENS = 'SET_TEN_OPENS'
export const SIGN_OUT = 'SIGN_OUT'
export const VALIDATE_USER = 'VALIDATE_USER'

// Navigation
export const SET_NAVIGATION = 'SET_NAVIGATION'

//ALERT
export const SET_ALERT = 'SET_ALERT'
export const RESET_ALERT = 'RESET_ALERT'

// Snackbar
export const TOGGLE_SNACKBAR_OPEN = 'TOGGLE_SNACKBAR_OPEN'
export const TOGGLE_SNACKBAR_CLOSED = 'TOGGLE_SNACKBAR_CLOSED'

// Api keys
export const GET_ALL_API_KEYS = 'GET_ALL_API_KEYS'
export const GET_ALL_API_KEYS_SUCCESS = 'GET_ALL_API_KEYS_SUCCESS'
export const GET_ALL_API_KEYS_FAILED = 'GET_ALL_API_KEYS_FAILED'

export const CREATE_IFRAME_API_KEY = 'CREATE_IFRAME_API_KEY'
export const CREATE_IFRAME_API_KEY_SUCCESS = 'CREATE_IFRAME_API_KEY_SUCCESS'
export const CREATE_IFRAME_API_KEY_FAILED = 'CREATE_IFRAME_API_KEY_FAILED'

export const DELETE_API_KEY = 'DELETE_API_KEY'
export const DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS'
export const DELETE_API_KEY_FAILED = 'DELETE_API_KEY_FAILED'

// Companies
export const GET_ALL_COMPANIES = 'GET_ALL_COMPANIES'
export const GET_ALL_COMPANIES_SUCCESS = 'GET_ALL_COMPANIES_SUCCESS'
export const GET_ALL_COMPANIES_FAILED = 'GET_ALL_COMPANIES_FAILED'

export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_FAILED = 'UPDATE_COMPANY_FAILED'

// Company groups
export const GET_ALL_COMPANY_GROUPS = 'GET_ALL_COMPANY_GROUPS'
export const GET_ALL_COMPANY_GROUPS_SUCCESS = 'GET_ALL_COMPANY_GROUPS_SUCCESS'
export const GET_ALL_COMPANY_GROUPS_FAILED = 'GET_ALL_COMPANY_GROUPS_FAILED'

export const CREATE_COMPANY_GROUP = 'CREATE_COMPANY_GROUP'
export const CREATE_COMPANY_GROUP_SUCCESS = 'CREATE_COMPANY_GROUP_SUCCESS'
export const CREATE_COMPANY_GROUP_FAILED = 'CREATE_COMPANY_GROUP_FAILED'

export const UPDATE_COMPANY_GROUP = 'UPDATE_COMPANY_GROUP'
export const UPDATE_COMPANY_GROUP_SUCCESS = 'UPDATE_COMPANY_GROUP_SUCCESS'
export const UPDATE_COMPANY_GROUP_FAILED = 'UPDATE_COMPANY_GROUP_FAILED'

export const DELETE_COMPANY_GROUP = 'DELETE_COMPANY_GROUP'
export const DELETE_COMPANY_GROUP_SUCCESS = 'DELETE_COMPANY_GROUP_SUCCESS'
export const DELETE_COMPANY_GROUP_FAILED = 'DELETE_COMPANY_GROUP_FAILED'

export const ADD_COMPANY_TO_COMPANY_GROUP = 'ADD_COMPANY_TO_COMPANY_GROUP'
export const ADD_COMPANY_TO_COMPANY_GROUP_SUCCESS =
  'ADD_COMPANY_TO_COMPANY_GROUP_SUCCESS'
export const ADD_COMPANY_TO_COMPANY_GROUP_FAILED =
  'ADD_COMPANY_TO_COMPANY_GROUP_FAILED'

export const REMOVE_COMPANY_FROM_COMPANY_GROUP =
  'REMOVE_COMPANY_FROM_COMPANY_GROUP'
export const REMOVE_COMPANY_FROM_COMPANY_GROUP_SUCCESS =
  'REMOVE_COMPANY_FROM_COMPANY_GROUP_SUCCESS'
export const REMOVE_COMPANY_FROM_COMPANY_GROUP_FAILED =
  'REMOVE_COMPANY_FROM_COMPANY_GROUP_FAILED'

export const SET_SHOW_AS_COMPANY_GROUP = 'SET_SHOW_AS_COMPANY_GROUP'

// Economy sets
export const GET_ALL_ECONOMY_SETS = 'GET_ALL_ECONOMY_SETS'
export const GET_ALL_ECONOMY_SETS_SUCCESS = 'GET_ALL_ECONOMY_SETS_SUCCESS'
export const GET_ALL_ECONOMY_SETS_FAILED = 'GET_ALL_ECONOMY_SETS_FAILED'

export const CREATE_ECONOMY_SET = 'CREATE_ECONOMY_SET'
export const CREATE_ECONOMY_SET_SUCCESS = 'CREATE_ECONOMY_SET_SUCCESS'
export const CREATE_ECONOMY_SET_FAILED = 'CREATE_ECONOMY_SET_FAILED'

export const UPDATE_ECONOMY_SET = 'UPDATE_ECONOMY_SET'
export const UPDATE_ECONOMY_SET_SUCCESS = 'UPDATE_ECONOMY_SET_SUCCESS'
export const UPDATE_ECONOMY_SET_FAILED = 'UPDATE_ECONOMY_SET_FAILED'

export const DELETE_ECONOMY_SET = 'DELETE_ECONOMY_SET'
export const DELETE_ECONOMY_SET_SUCCESS = 'DELETE_ECONOMY_SET_SUCCESS'
export const DELETE_ECONOMY_SET_FAILED = 'DELETE_ECONOMY_SET_FAILED'

export const UPLOAD_ECONOMY_SET = 'UPLOAD_ECONOMY_SET'
export const UPLOAD_ECONOMY_SET_SUCCESS = 'UPLOAD_ECONOMY_SET_SUCCESS'
export const UPLOAD_ECONOMY_SET_FAILED = 'UPLOAD_ECONOMY_SET_FAILED'

export const CREATE_ECONOMY_ACCOUNT = 'CREATE_ECONOMY_ACCOUNT'
export const CREATE_ECONOMY_ACCOUNT_SUCCESS = 'CREATE_ECONOMY_ACCOUNT_SUCCESS'
export const CREATE_ECONOMY_ACCOUNT_FAILED = 'CREATE_ECONOMY_ACCOUNT_FAILED'

export const UPDATE_ECONOMY_ACCOUNT = 'UPDATE_ECONOMY_ACCOUNT'
export const UPDATE_ECONOMY_ACCOUNT_SUCCESS = 'UPDATE_ECONOMY_ACCOUNT_SUCCESS'
export const UPDATE_ECONOMY_ACCOUNT_FAILED = 'UPDATE_ECONOMY_ACCOUNT_FAILED'

export const DELETE_ECONOMY_ACCOUNT = 'DELETE_ECONOMY_ACCOUNT'
export const DELETE_ECONOMY_ACCOUNT_SUCCESS = 'DELETE_ECONOMY_ACCOUNT_SUCCESS'
export const DELETE_ECONOMY_ACCOUNT_FAILED = 'DELETE_ECONOMY_ACCOUNT_FAILED'

// Emails
export const GET_ALL_EMAILS = 'GET_ALL_EMAILS'
export const GET_ALL_EMAILS_SUCCESS = 'GET_ALL_EMAILS_SUCCESS'
export const GET_ALL_EMAILS_FAILED = 'GET_ALL_EMAILS_FAILED'

export const CREATE_EMAIL = 'CREATE_EMAIL'
export const CREATE_EMAIL_SUCCESS = 'CREATE_EMAIL_SUCCESS'
export const CREATE_EMAIL_FAILED = 'CREATE_EMAIL_FAILED'

// Data quality
export const GET_RULES = 'GET_RULES'
export const GET_RULES_SUCCESS = 'GET_RULES_SUCCESS'
export const GET_RULES_FAILED = 'GET_RULES_FAILED'

export const PUT_RULES = 'PUT_RULES'
export const PUT_RULES_SUCCESS = 'PUT_RULES_SUCCESS'
export const PUT_RULES_FAILED = 'PUT_RULES_FAILED'

export const GET_FORMATS = 'GET_FORMATS'
export const GET_FORMATS_SUCCESS = 'GET_FORMATS_SUCCESS'
export const GET_FORMATS_FAILED = 'GET_FORMATS_FAILED'

export const GET_QUALITY_OVERVIEW = 'GET_QUALITY_OVERVIEW'
export const GET_QUALITY_OVERVIEW_SUCCESS = 'GET_QUALITY_OVERVIEW_SUCCESS'
export const GET_QUALITY_OVERVIEW_FAILED = 'GET_QUALITY_OVERVIEW_FAILED'

export const GET_FLAWS = 'GET_FLAWS'
export const GET_FLAWS_SUCCESS = 'GET_FLAWS_SUCCESS'
export const GET_FLAWS_FAILED = 'GET_FLAWS_FAILED'

export const EXPORT_FLAWS = 'EXPORT_FLAWS'
export const EXPORT_FLAWS_SUCCESS = 'EXPORT_FLAWS_SUCCESS'
export const EXPORT_FLAWS_FAILED = 'EXPORT_FLAWS_FAILED'

// Ontology
export const PUT_CUSTOM_ONTOLOGY = 'PUT_CUSTOM_ONTOLOGY'
export const PUT_CUSTOM_ONTOLOGY_SUCCESS = 'PUT_CUSTOM_ONTOLOGY_SUCCESS'
export const PUT_CUSTOM_ONTOLOGY_FAILED = 'PUT_CUSTOM_ONTOLOGY_FAILED'

export const GET_CUSTOM_ONTOLOGY = 'GET_CUSTOM_ONTOLOGY'
export const GET_CUSTOM_ONTOLOGY_SUCCESS = 'GET_CUSTOM_ONTOLOGY_SUCCESS'
export const GET_CUSTOM_ONTOLOGY_FAILED = 'GET_CUSTOM_ONTOLOGY_FAILED'

export const START_ONTOLOGY = 'START_ONTOLOGY'
export const START_ONTOLOGY_SUCCESS = 'START_ONTOLOGY_SUCCESS'
export const START_ONTOLOGY_FAILED = 'START_ONTOLOGY_FAILED'

export const POLL_ONTOLOGY = 'POLL_ONTOLOGY'
export const POLL_ONTOLOGY_SUCCESS = 'POLL_ONTOLOGY_SUCCESS'
export const POLL_ONTOLOGY_FAILED = 'POLL_ONTOLOGY_FAILED'

export const ACTIVATE_STANDARD = 'ACTIVATE_STANDARD'
export const ACTIVATE_STANDARD_SUCCESS = 'ACTIVATE_STANDARD_SUCCESS'
export const ACTIVATE_STANDARD_FAILED = 'ACTIVATE_STANDARD_FAILED'

export const GET_STANDARD_ONTOLOGY = 'GET_STANDARD_ONTOLOGY'
export const GET_STANDARD_ONTOLOGY_SUCCESS = 'GET_STANDARD_ONTOLOGY_SUCCESS'
export const GET_STANDARD_ONTOLOGY_FAILED = 'GET_STANDARD_ONTOLOGY_FAILED'

export const PUT_STANDARD_ONTOLOGY = 'PUT_STANDARD_ONTOLOGY'
export const PUT_STANDARD_ONTOLOGY_SUCCESS = 'PUT_STANDARD_ONTOLOGY_SUCCESS'
export const PUT_STANDARD_ONTOLOGY_FAILED = 'PUT_STANDARD_ONTOLOGY_FAILED'

//LOCKED_MODE
export const SET_LOCKED_MODE = 'SET_LOCKED_MODE'

// SETTINGS
export const UPDATE_SELF = 'UPDATE_SELF'
export const UPDATE_SELF_SUCCESS = 'UPDATE_SELF_SUCCESS'
export const UPDATE_SELF_FAILED = 'UPDATE_SELF_FAILED'

export const UPDATE_COLOR_SCHEME = 'UPDATE_COLOR_SCHEME'
export const UPDATE_COLOR_SCHEME_SUCCESS = 'UPDATE_COLOR_SCHEME_SUCCESS'
export const UPDATE_COLOR_SCHEME_FAILED = 'UPDATE_COLOR_SCHEME_FAILED'

// CMDB
export const CREATE_CMDB_TABLE = 'CREATE_CMDB_TABLE'
export const CREATE_CMDB_TABLE_SUCCESS = 'CREATE_CMDB_TABLE_SUCCESS'
export const CREATE_CMDB_TABLE_FAILED = 'CREATE_CMDB_TABLE_FAILED'

export const UPDATE_CMDB_TABLE = 'UPDATE_CMDB_TABLE'
export const UPDATE_CMDB_TABLE_SUCCESS = 'UPDATE_CMDB_TABLE_SUCCESS'
export const UPDATE_CMDB_TABLE_FAILED = 'CREATE_CMDB_TABLE_FAILED'

export const GET_ALL_CMDB_TABLES = 'GET_ALL_CMDB_TABLES'
export const GET_ALL_CMDB_TABLES_SUCCESS = 'GET_ALL_CMDB_TABLES_SUCCESS'
export const GET_ALL_CMDB_TABLES_FAILED = 'GET_ALL_CMDB_TABLES_FAILED'

export const DELETE_CMDB_TABLE = 'DELETE_CMDB_TABLE'
export const DELETE_CMDB_TABLE_SUCCESS = 'DELETE_CMDB_TABLE_SUCCESS'
export const DELETE_CMDB_TABLE_FAILED = 'DELETE_CMDB_TABLE_FAILED'

export const PUT_CMDB_ROW = 'PUT_CMDB_ROW'
export const PUT_CMDB_ROW_SUCCESS = 'PUT_CMDB_ROW_SUCCESS'
export const PUT_CMDB_ROW_FAILED = 'PUT_CMDB_ROW_FAILED'

export const GET_ALL_CMDB_ROWS = 'GET_ALL_CMDB_ROWS'
export const GET_ALL_CMDB_ROWS_SUCCESS = 'GET_ALL_CMDB_ROWS_SUCCESS'
export const GET_ALL_CMDB_ROWS_FAILED = 'GET_ALL_CMDB_ROWS_FAILED'

export const DELETE_CMDB_ROW = 'DELETE_CMDB_ROW'
export const DELETE_CMDB_ROW_SUCCESS = 'DELETE_CMDB_ROW_SUCCESS'
export const DELETE_CMDB_ROW_FAILED = 'DELETE_CMDB_ROW_FAILED'

export const EXPORT_CMDB_ROWS = 'EXPORT_CMDB_ROWS'
export const EXPORT_CMDB_ROWS_SUCCESS = 'EXPORT_CMDB_ROWS_SUCCESS'
export const EXPORT_CMDB_ROWS_FAILED = 'EXPORT_CMDB_ROWS_FAILED'

export const GET_ALL_CMDB_APIS = 'GET_ALL_CMDB_APIS'
export const GET_ALL_CMDB_APIS_SUCCESS = 'GET_ALL_CMDB_APIS_SUCCESS'
export const GET_ALL_CMDB_APIS_FAILED = 'GET_ALL_CMDB_APIS_FAILED'

export const GET_ALL_SOURCE_SCHEMAS = 'GET_ALL_SOURCE_SCHEMAS'
export const GET_ALL_SOURCE_SCHEMAS_SUCCESS = 'GET_ALL_SOURCE_SCHEMAS_SUCCESS'
export const GET_ALL_SOURCE_SCHEMAS_FAILED = 'GET_ALL_SOURCE_SCHEMAS_FAILED'

export const GET_ALL_DATASETS = 'GET_ALL_DATASETS'
export const GET_ALL_DATASETS_SUCCESS = 'GET_ALL_DATASETS_SUCCESS'
export const GET_ALL_DATASETS_FAILED = 'GET_ALL_DATASETS_FAILED'

export const GET_ONE_DATASET = 'GET_ONE_DATASET'
export const GET_ONE_DATASET_SUCCESS = 'GET_ONE_DATASET_SUCCESS'
export const GET_ONE_DATASET_FAILED = 'GET_ONE_DATASET_FAILED'

export const CREATE_DATASET = 'CREATE_DATASET'
export const CREATE_DATASET_SUCCESS = 'CREATE_DATASET_SUCCESS'
export const CREATE_DATASET_FAILED = 'CREATE_DATASET_FAILED'

export const UPDATE_DATASET = 'UPDATE_DATASET'
export const UPDATE_DATASET_SUCCESS = 'UPDATE_DATASET_SUCCESS'
export const UPDATE_DATASET_FAILED = 'UPDATE_DATASET_FAILED'

export const DELETE_DATASET = 'DELETE_DATASET'
export const DELETE_DATASET_SUCCESS = 'DELETE_DATASET_SUCCESS'
export const DELETE_DATASET_FAILED = 'DELETE_DATASET_FAILED'

export const PUT_DATASET_SCHEMA = 'PUT_DATASET_SCHEMA'
export const PUT_DATASET_SCHEMA_SUCCESS = 'PUT_DATASET_SCHEMA_SUCCESS'
export const PUT_DATASET_SCHEMA_FAILED = 'PUT_DATASET_SCHEMA_FAILED'

// Output attributes
export const CREATE_OUTPUT_ATTRIBUTE = 'CREATE_OUTPUT_ATTRIBUTE'
export const CREATE_OUTPUT_ATTRIBUTE_SUCCESS = 'CREATE_OUTPUT_ATTRIBUTE_SUCCESS'
export const CREATE_OUTPUT_ATTRIBUTE_FAILED = 'CREATE_OUTPUT_ATTRIBUTE_FAILED'

export const UPDATE_OUTPUT_ATTRIBUTE = 'UPDATE_OUTPUT_ATTRIBUTE'
export const UPDATE_OUTPUT_ATTRIBUTE_SUCCESS = 'UPDATE_OUTPUT_ATTRIBUTE_SUCCESS'
export const UPDATE_OUTPUT_ATTRIBUTE_FAILED = 'UPDATE_OUTPUT_ATTRIBUTE_FAILED'

export const DELETE_OUTPUT_ATTRIBUTE = 'DELETE_OUTPUT_ATTRIBUTE'
export const DELETE_OUTPUT_ATTRIBUTE_SUCCESS = 'DELETE_OUTPUT_ATTRIBUTE_SUCCESS'
export const DELETE_OUTPUT_ATTRIBUTE_FAILED = 'DELETE_OUTPUT_ATTRIBUTE_FAILED'

// shape preview
export const SET_DATASET_PREVIEW_MODEL = 'SET_DATASET_PREVIEW_MODEL'

export const GET_DATASET_PREVIEW = 'GET_DATASET_PREVIEW'
export const GET_DATASET_PREVIEW_SUCCESS = 'GET_DATASET_PREVIEW_SUCCESS'
export const GET_DATASET_PREVIEW_FAILED = 'GET_DATASET_PREVIEW_FAILED'

// Connections
export const GET_ALL_SOURCES = 'GET_ALL_SOURCES'
export const GET_ALL_SOURCES_SUCCESS = 'GET_ALL_SOURCES_SUCCESS'
export const GET_ALL_SOURCES_FAILED = 'GET_ALL_SOURCES_FAILED'

export const GET_ONE_SOURCE = 'GET_ONE_SOURCE'
export const GET_ONE_SOURCE_SUCCESS = 'GET_ONE_SOURCE_SUCCESS'
export const GET_ONE_SOURCE_FAILED = 'GET_ONE_SOURCE_FAILED'

export const CREATE_ACTIVE_SOURCE = 'CREATE_ACTIVE_SOURCE'
export const CREATE_ACTIVE_SOURCE_SUCCESS = 'CREATE_ACTIVE_SOURCE_SUCCESS'
export const CREATE_ACTIVE_SOURCE_FAILED = 'CREATE_ACTIVE_SOURCE_FAILED'

export const CREATE_SFTP_SETTINGS = 'CREATE_SFTP_SETTINGS'
export const CREATE_SFTP_SETTINGS_SUCCESS = 'CREATE_SFTP_SETTINGS_SUCCESS'
export const CREATE_SFTP_SETTINGS_FAILED = 'CREATE_SFTP_SETTINGS_FAILED'

export const UPDATE_ACTIVE_SOURCE = 'UPDATE_ACTIVE_SOURCE'
export const UPDATE_ACTIVE_SOURCE_SUCCESS = 'UPDATE_ACTIVE_SOURCE_SUCCESS'
export const UPDATE_ACTIVE_SOURCE_FAILED = 'UPDATE_ACTIVE_SOURCE_FAILED'
export const ACTIVATE_ACTIVE_SOURCE = 'ACTIVATE_ACTIVE_SOURCE'
export const ACTIVATE_ACTIVE_SOURCE_SUCCESS = 'ACTIVATE_ACTIVE_SOURCE_SUCCESS'
export const ACTIVATE_ACTIVE_SOURCE_FAILED = 'ACTIVATE_ACTIVE_SOURCE_FAILED'

export const DELETE_ACTIVE_SOURCE = 'DELETE_ACTIVE_SOURCE'
export const DELETE_ACTIVE_SOURCE_SUCCESS = 'DELETE_ACTIVE_SOURCE_SUCCESS'
export const DELETE_ACTIVE_SOURCE_FAILED = 'DELETE_ACTIVE_SOURCE_FAILED'

export const UPLOAD_FILE_ACTIVE_SOURCE = 'UPLOAD_FILE_ACTIVE_SOURCE'
export const UPLOAD_FILE_ACTIVE_SOURCE_SUCCESS =
  'UPLOAD_FILE_ACTIVE_SOURCE_SUCCESS'
export const UPLOAD_FILE_ACTIVE_SOURCE_FAILED =
  'UPLOAD_FILE_ACTIVE_SOURCE_FAILED'

export const PUT_AUTH_PARAM_VALUES = 'PUT_AUTH_PARAM_VALUES'
export const PUT_AUTH_PARAM_VALUES_SUCCESS = 'PUT_AUTH_PARAM_VALUES_SUCCESS'
export const PUT_AUTH_PARAM_VALUES_FAILED = 'PUT_AUTH_PARAM_VALUES_FAILED'

export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAILED = 'UPLOAD_FILE_FAILED'

export const CREATE_SOURCE_TABLE = 'CREATE_SOURCE_TABLE'
export const CREATE_SOURCE_TABLE_SUCCESS = 'CREATE_SOURCE_TABLE_SUCCESS'
export const CREATE_SOURCE_TABLE_FAILED = 'CREATE_SOURCE_TABLE_FAILED'

export const UPDATE_SOURCE_TABLE = 'UPDATE_SOURCE_TABLE'
export const UPDATE_SOURCE_TABLE_SUCCESS = 'UPDATE_SOURCE_TABLE_SUCCESS'
export const UPDATE_SOURCE_TABLE_FAILED = 'UPDATE_SOURCE_TABLE_FAILED'

export const UPDATE_SOURCE_COLUMN = 'UPDATE_SOURCE_COLUMN'
export const UPDATE_SOURCE_COLUMN_SUCCESS = 'UPDATE_SOURCE_COLUMN_SUCCESS'
export const UPDATE_SOURCE_COLUMN_FAILED = 'UPDATE_SOURCE_COLUMN_FAILED'

export const GET_ONE_SOURCE_TABLE = 'GET_ONE_SOURCE_TABLE'
export const GET_ONE_SOURCE_TABLE_SUCCESS = 'GET_ONE_SOURCE_TABLE_SUCCESS'
export const GET_ONE_SOURCE_TABLE_FAILED = 'GET_ONE_SOURCE_TABLE_FAILED'

export const GET_LOGS = 'GET_LOGS'
export const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS'
export const GET_LOGS_FAILED = 'GET_LOGS_FAILED'

export const PUT_COLUMNS_AND_FILTERS = 'PUT_COLUMNS_AND_FILTERS'
export const PUT_COLUMNS_AND_FILTERS_SUCCESS = 'PUT_COLUMNS_AND_FILTERS_SUCCESS'
export const PUT_COLUMNS_AND_FILTERS_FAILED = 'PUT_COLUMNS_AND_FILTERS_FAILED'

export const PUT_STREAM_VARIABLES = 'PUT_STREAM_VARIABLES'
export const PUT_STREAM_VARIABLES_SUCCESS = 'PUT_STREAM_VARIABLES_SUCCESS'
export const PUT_STREAM_VARIABLES_FAILED = 'PUT_STREAM_VARIABLES_FAILED'

export const CREATE_STREAM_TABLE = 'CREATE_STREAM_TABLE'
export const CREATE_STREAM_TABLE_SUCCESS = 'CREATE_STREAM_TABLE_SUCCESS'
export const CREATE_STREAM_TABLE_FAILED = 'CREATE_STREAM_TABLE_FAILED'

export const DELETE_STREAM_TABLE = 'DELETE_STREAM_TABLE'
export const DELETE_STREAM_TABLE_SUCCESS = 'DELETE_STREAM_TABLE_SUCCESS'
export const DELETE_STREAM_TABLE_FAILED = 'DELETE_STREAM_TABLE_FAILED'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'

// vitec views

export const GET_VITEC_VIEWS = 'GET_VITEC_VIEWS'
export const GET_VITEC_VIEWS_SUCCESS = 'GET_VITEC_VIEWS_SUCCESS'
export const GET_VITEC_VIEWS_FAILED = 'GET_VITEC_VIEWS_FAILED'

export const CREATE_VITEC_VIEW = 'CREATE_VITEC_VIEW'
export const CREATE_VITEC_VIEW_SUCCESS = 'CREATE_VITEC_VIEW_SUCCESS'
export const CREATE_VITEC_VIEW_FAILED = 'CREATE_VITEC_VIEW_FAILED'

export const DELETE_VITEC_VIEW = 'DELETE_VITEC_VIEW'
export const DELETE_VITEC_VIEW_SUCCESS = 'DELETE_VITEC_VIEW_SUCCESS'
export const DELETE_VITEC_VIEW_FAILED = 'DELETE_VITEC_VIEW_FAILED'

// BUILD
export const SAVE_REGISTER_CHANGES = 'SAVE_REGISTER_CHANGES'
export const SAVE_REGISTER_CHANGES_SUCCESS = 'SAVE_REGISTER_CHANGES_SUCCESS'
export const SAVE_REGISTER_CHANGES_FAILED = 'SAVE_REGISTER_CHANGES_FAILED'

// tags
export const GET_ALL_TAGS = 'GET_ALL_TAGS'
export const GET_ALL_TAGS_SUCCESS = 'GET_ALL_TAGS_SUCCESS'
export const GET_ALL_TAGS_FAILED = 'GET_ALL_TAGS_FAILED'

export const PUT_TAG_LINKER = 'PUT_TAG_LINKER'
export const PUT_TAG_LINKER_SUCCESS = 'PUT_TAG_LINKER_SUCCESS'
export const PUT_TAG_LINKER_FAILED = 'PUT_TAG_LINKER_FAILED'

export const DELETE_TAG_LINKER = 'DELETE_TAG_LINKER'
export const DELETE_TAG_LINKER_SUCCESS = 'DELETE_TAG_LINKER_SUCCESS'
export const DELETE_TAG_LINKER_FAILED = 'DELETE_TAG_LINKER_FAILED'

// ADMIN

// AUTHENTICATION
export const GET_AZURE_AD = 'GET_AZURE_AD'
export const GET_AZURE_AD_SUCCESS = 'GET_AZURE_AD_SUCCESS'
export const GET_AZURE_AD_FAILED = 'GET_AZURE_AD_FAILED'

export const PUT_AZURE_AD = 'PUT_AZURE_AD'
export const PUT_AZURE_AD_SUCCESS = 'PUT_AZURE_AD_SUCCESS'
export const PUT_AZURE_AD_FAILED = 'PUT_AZURE_AD_FAILED'

export const DELETE_AZURE_AD = 'DELETE_AZURE_AD'
export const DELETE_AZURE_AD_SUCCESS = 'DELETE_AZURE_AD_SUCCESS'
export const DELETE_AZURE_AD_FAILED = 'DELETE_AZURE_AD_FAILED'

// USERS
export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'

export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'
export const GET_ALL_USERS_FAILED = 'GET_ALL_USERS_FAILED'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'

export const DELETE_USERS = 'DELETE_USERS'
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS'
export const DELETE_USERS_FAILED = 'DELETE_USERS_FAILED'

export const PUT_ROLES = 'PUT_ROLES'
export const PUT_ROLES_SUCCESS = 'PUT_ROLES_SUCCESS'
export const PUT_ROLES_FAILED = 'PUT_ROLES_FAILED'

// ROLES
export const GET_ALL_ROLES = 'GET_ALL_ROLES'
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS'
export const GET_ALL_ROLES_FAILED = 'GET_ALL_ROLES_FAILED'

export const CREATE_ROLE = 'CREATE_ROLE'
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS'
export const CREATE_ROLE_FAILED = 'CREATE_ROLE_FAILED'

export const UPDATE_ROLE = 'UPDATE_ROLE'
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS'
export const UPDATE_ROLE_FAILED = 'UPDATE_ROLE_FAILED'

export const DELETE_ROLE = 'DELETE_ROLE'
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS'
export const DELETE_ROLE_FAILED = 'DELETE_ROLE_FAILED'

export const DELETE_MANY_ROLES = 'DELETE_MANY_ROLES'
export const DELETE_MANY_ROLES_SUCCESS = 'DELETE_MANY_ROLES_SUCCESS'
export const DELETE_MANY_ROLES_FAILED = 'DELETE_MANY_ROLES_FAILED'

// PERMISSIONS
export const GET_ALL_PERMISSIONS = 'GET_ALL_PERMISSIONS'
export const GET_ALL_PERMISSIONS_SUCCESS = 'GET_ALL_PERMISSIONS_SUCCESS'
export const GET_ALL_PERMISSIONS_FAILED = 'GET_ALL_PERMISSIONS_FAILED'

// APPLICATIONS START HERE
// Template.
export const GET_TEMPLATE_VACANCY_REPORT = 'GET_TEMPLATE_VACANCY_REPORT'
export const GET_TEMPLATE_VACANCY_REPORT_SUCCESS =
  'GET_TEMPLATE_VACANCY_REPORT_SUCCESS'
export const GET_TEMPLATE_VACANCY_REPORT_FAILED =
  'GET_TEMPLATE_VACANCY_REPORT_FAILED'

export const CREATE_TEMPLATE_TABLE = 'CREATE_TEMPLATE_TABLE'
export const CREATE_TEMPLATE_TABLE_SUCCESS = 'CREATE_TEMPLATE_TABLE_SUCCESS'
export const CREATE_TEMPLATE_TABLE_FAILED = 'CREATE_TEMPLATE_TABLE_FAILED'

export const DELETE_TEMPLATE_TABLE = 'DELETE_TEMPLATE_TABLE'
export const DELETE_TEMPLATE_TABLE_SUCCESS = 'DELETE_TEMPLATE_TABLE_SUCCESS'
export const DELETE_TEMPLATE_TABLE_FAILED = 'DELETE_TEMPLATE_TABLE_FAILED'

export const UPDATE_TEMPLATE_TABLE = 'UPDATE_TEMPLATE_TABLE'
export const UPDATE_TEMPLATE_TABLE_SUCCESS = 'UPDATE_TEMPLATE_TABLE_SUCCESS'
export const UPDATE_TEMPLATE_TABLE_FAILED = 'UPDATE_TEMPLATE_TABLE_FAILED'

export const PUT_TEMPLATE_TABLE_ATTRIBUTES = 'PUT_TEMPLATE_TABLE_ATTRIBUTES'
export const PUT_TEMPLATE_TABLE_ATTRIBUTES_SUCCESS =
  'PUT_TEMPLATE_TABLE_ATTRIBUTES_SUCCESS'
export const PUT_TEMPLATE_TABLE_ATTRIBUTES_FAILED =
  'PUT_TEMPLATE_TABLE_ATTRIBUTES_FAILED'

export const PUT_TEMPLATE_TABLE_GROUP_BYS = 'PUT_TEMPLATE_TABLE_GROUP_BYS'
export const PUT_TEMPLATE_TABLE_GROUP_BYS_SUCCESS =
  'PUT_TEMPLATE_TABLE_GROUP_BYS_SUCCESS'
export const PUT_TEMPLATE_TABLE_GROUP_BYS_FAILED =
  'PUT_TEMPLATE_TABLE_GROUP_BYS_FAILED'

export const PUT_TEMPLATE_TABLE_FILTERS = 'PUT_TEMPLATE_TABLE_FILTERS'
export const PUT_TEMPLATE_TABLE_FILTERS_SUCCESS =
  'PUT_TEMPLATE_TABLE_FILTERS_SUCCESS'
export const PUT_TEMPLATE_TABLE_FILTERS_FAILED =
  'PUT_TEMPLATE_TABLE_FILTERS_FAILED'

export const SET_SHOW_COMPARITIVE_FIGURES = 'SET_SHOW_COMPARITIVE_FIGURES'
export const SET_SHOW_COMPARITIVE_FIGURES_SUCCESS =
  'SET_SHOW_COMPARITIVE_FIGURES_SUCCESS'
export const SET_SHOW_COMPARITIVE_FIGURES_FAILED =
  'SET_SHOW_COMPARITIVE_FIGURES_FAILED'

// Archive.
export const CREATE_VACANCY_REPORT = 'CREATE_VACANCY_REPORT'
export const CREATE_VACANCY_REPORT_SUCCESS = 'CREATE_VACANCY_REPORT_SUCCESS'
export const CREATE_VACANCY_REPORT_FAILED = 'CREATE_VACANCY_REPORT_FAILED'

export const GET_VACANCY_REPORTS = 'GET_VACANCY_REPORTS'
export const GET_VACANCY_REPORTS_SUCCESS = 'GET_VACANCY_REPORTS_SUCCESS'
export const GET_VACANCY_REPORTS_FAILED = 'GET_VACANCY_REPORTS_FAILED'

export const GET_VACANCY_REPORT = 'GET_VACANCY_REPORT'
export const GET_VACANCY_REPORT_SUCCESS = 'GET_VACANCY_REPORT_SUCCESS'
export const GET_VACANCY_REPORT_FAILED = 'GET_VACANCY_REPORT_FAILED'

export const DELETE_VACANCY_REPORTS = 'DELETE_VACANCY_REPORTS'
export const DELETE_VACANCY_REPORTS_SUCCESS = 'DELETE_VACANCY_REPORTS_SUCCESS'
export const DELETE_VACANCY_REPORTS_FAILED = 'DELETE_VACANCY_REPORTS_FAILED'

export const UPDATE_VACANCY_REPORT_TABLE = 'UPDATE_VACANCY_REPORT_TABLE'
export const UPDATE_VACANCY_REPORT_TABLE_SUCCESS =
  'UPDATE_VACANCY_REPORT_TABLE_SUCCESS'
export const UPDATE_VACANCY_REPORT_TABLE_FAILED =
  'UPDATE_VACANCY_REPORT_TABLE_FAILED'

// Export.
export const EXPORT_CSV = 'EXPORT_CSV'
export const EXPORT_CSV_SUCCESS = 'EXPORT_CSV_SUCCESS'
export const EXPORT_CSV_FAILED = 'EXPORT_CSV_FAILED'

export const EXPORT_XLSX = 'EXPORT_XLSX'
export const EXPORT_XLSX_SUCCESS = 'EXPORT_XLSX_SUCCESS'
export const EXPORT_XLSX_FAILED = 'EXPORT_XLSX_FAILED'

export const EXPORT_DATASET = 'EXPORT_DATASET'
export const EXPORT_DATASET_SUCCESS = 'EXPORT_DATASET_SUCCESS'
export const EXPORT_DATASET_FAILED = 'EXPORT_DATASET_FAILED'

export const DOWNLOAD_PDF = 'DOWNLOAD_PDF'
export const DOWNLOAD_PDF_SUCCESS = 'DOWNLOAD_PDF_SUCCESS'
export const DOWNLOAD_PDF_FAILED = 'DOWNLOAD_PDF_FAILED'

// Ontology
export const GET_APPS_ONTOLOGY = 'GET_APPS_ONTOLOGY'
export const GET_APPS_ONTOLOGY_SUCCESS = 'GET_APPS_ONTOLOGY_SUCCESS'
export const GET_APPS_ONTOLOGY_FAILED = 'GET_APPS_ONTOLOGY_FAILED'

export const GET_PATHS = 'GET_PATHS'
export const GET_PATHS_SUCCESS = 'GET_PATHS_SUCCESS'
export const GET_PATHS_FAILED = 'GET_PATHS_FAILED'

// Access Hub
export const GET_DATA_PRODUCTS = 'GET_DATA_PRODUCTS'
export const GET_DATA_PRODUCTS_SUCCESS = 'GET_DATA_PRODUCTS_SUCCESS'
export const GET_DATA_PRODUCTS_FAILED = 'GET_DATA_PRODUCTS_FAILED'

export const GET_ONE_DATA_PRODUCT = 'GET_ONE_DATA_PRODUCT'
export const GET_ONE_DATA_PRODUCT_SUCCESS = 'GET_ONE_DATA_PRODUCT_SUCCESS'
export const GET_ONE_DATA_PRODUCT_FAILED = 'GET_ONE_DATA_PRODUCT_FAILED'

export const CREATE_DATA_PRODUCT = 'CREATE_DATA_PRODUCT'
export const CREATE_DATA_PRODUCT_SUCCESS = 'CREATE_DATA_PRODUCT_SUCCESS'
export const CREATE_DATA_PRODUCT_FAILED = 'CREATE_DATA_PRODUCT_FAILED'

export const UPDATE_DATA_PRODUCT = 'UPDATE_DATA_PRODUCT'
export const UPDATE_DATA_PRODUCT_SUCCESS = 'UPDATE_DATA_PRODUCT_SUCCESS'
export const UPDATE_DATA_PRODUCT_FAILED = 'UPDATE_DATA_PRODUCT_FAILED'

export const DELETE_DATA_PRODUCT = 'DELETE_DATA_PRODUCT'
export const DELETE_DATA_PRODUCT_SUCCESS = 'DELETE_DATA_PRODUCT_SUCCESS'
export const DELETE_DATA_PRODUCT_FAILED = 'DELETE_DATA_PRODUCT_FAILED'

export const CREATE_APPS_DATASET = 'CREATE_APPS_DATASET'
export const CREATE_APPS_DATASET_SUCCESS = 'CREATE_APPS_DATASET_SUCCESS'
export const CREATE_APPS_DATASET_FAILED = 'CREATE_APPS_DATASET_FAILED'

export const UPDATE_APPS_DATASET = 'UPDATE_APPS_DATASET'
export const UPDATE_APPS_DATASET_SUCCESS = 'UPDATE_APPS_DATASET_SUCCESS'
export const UPDATE_APPS_DATASET_FAILED = 'UPDATE_APPS_DATASET_FAILED'

export const DELETE_APPS_DATASET = 'DELETE_APPS_DATASET'
export const DELETE_APPS_DATASET_SUCCESS = 'DELETE_APPS_DATASET_SUCCESS'
export const DELETE_APPS_DATASET_FAILED = 'DELETE_APPS_DATASET_FAILED'

export const GET_DATASET_TRANSFORMATION = 'GET_DATASET_TRANSFORMATION'
export const GET_DATASET_TRANSFORMATION_SUCCESS =
  'GET_DATASET_TRANSFORMATION_SUCCESS'
export const GET_DATASET_TRANSFORMATION_FAILED =
  'GET_DATASET_TRANSFORMATION_FAILED'

export const GET_DATASET_FILTER_VARIABLES = 'GET_DATASET_FILTER_VARIABLES'
export const GET_DATASET_FILTER_VARIABLES_SUCCESS =
  'GET_DATASET_FILTER_VARIABLES_SUCCESS'
export const GET_DATASET_FILTER_VARIABLES_FAILED =
  'GET_DATASET_FILTER_VARIABLES_FAILED'

export const GET_RELATED_TABLES = 'GET_RELATED_TABLES'
export const GET_RELATED_TABLES_SUCCESS = 'GET_RELATED_TABLES_SUCCESS'
export const GET_RELATED_TABLES_FAILED = 'GET_RELATED_TABLES_FAILED'

export const CREATE_API_KEY = 'CREATE_API_KEY'
export const CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS'
export const CREATE_API_KEY_FAILED = 'CREATE_API_KEY_FAILED'

export const UPDATE_DATA_PRODUCT_KEY = 'UPDATE_DATA_PRODUCT_KEY'
export const UPDATE_DATA_PRODUCT_KEY_SUCCESS = 'UPDATE_DATA_PRODUCT_KEY_SUCCESS'
export const UPDATE_DATA_PRODUCT_KEY_FAILED = 'UPDATE_DATA_PRODUCT_KEY_FAILED'

export const DELETE_DATA_PRODUCT_KEY = 'DELETE_DATA_PRODUCT_KEY'
export const DELETE_DATA_PRODUCT_KEY_SUCCESS = 'DELETE_DATA_PRODUCT_KEY_SUCCESS'
export const DELETE_DATA_PRODUCT_KEY_FAILED = 'DELETE_DATA_PRODUCT_KEY_FAILED'

export const UPDATE_ENDPOINT = 'UPDATE_ENDPOINT'
export const UPDATE_ENDPOINT_SUCCESS = 'UPDATE_ENDPOINT_SUCCESS'
export const UPDATE_ENDPOINT_FAILED = 'UPDATE_ENDPOINT_FAILED'

export const GET_APPS_DATASET_PREVIEW = 'GET_APPS_DATASET_PREVIEW'
export const GET_APPS_DATASET_PREVIEW_SUCCESS =
  'GET_APPS_DATASET_PREVIEW_SUCCESS'
export const GET_APPS_DATASET_PREVIEW_FAILED = 'GET_APPS_DATASET_PREVIEW_FAILED'

export const START_JOB_RUN = 'START_JOB_RUN'
export const START_JOB_RUN_SUCCESS = 'START_JOB_RUN_SUCCESS'
export const START_JOB_RUN_FAILED = 'START_JOB_RUN_FAILED'

export const POLL_JOB_RUN = 'POLL_JOB_RUN'
export const POLL_JOB_RUN_SUCCESS = 'POLL_JOB_RUN_SUCCESS'
export const POLL_JOB_RUN_FAILED = 'POLL_JOB_RUN_FAILED'

// Insight Studio
export const PUT_TABLE_FILTER_VARIABLES = 'PUT_TABLE_FILTER_VARIABLES'
export const PUT_TABLE_FILTER_VARIABLES_SUCCESS =
  'PUT_TABLE_FILTER_VARIABLES_SUCCESS'
export const PUT_TABLE_FILTER_VARIABLES_FAILED =
  'PUT_TABLE_FILTER_VARIABLES_FAILED'

export const EXPORT_INSIGHT_STUDIO = 'EXPORT_INSIGHT_STUDIO'
export const EXPORT_INSIGHT_STUDIO_SUCCESS = 'EXPORT_INSIGHT_STUDIO_SUCCESS'
export const EXPORT_INSIGHT_STUDIO_FAILED = 'EXPORT_INSIGHT_STUDIO_FAILED'

export const PUT_WIDGETS = 'PUT_WIDGETS'
export const PUT_WIDGETS_SUCCESS = 'PUT_WIDGETS_SUCCESS'
export const PUT_WIDGETS_FAILED = 'PUT_WIDGETS_FAILED'

export const GET_WIDGETS = 'GET_WIDGETS'
export const GET_WIDGETS_SUCCESS = 'GET_WIDGETS_SUCCESS'
export const GET_WIDGETS_FAILED = 'GET_WIDGETS_FAILED'

export const GET_ONE_DASHBOARD_WIDGET = 'GET_ONE_DASHBOARD_WIDGET'
export const GET_ONE_DASHBOARD_WIDGET_SUCCESS =
  'GET_ONE_DASHBOARD_WIDGET_SUCCESS'
export const GET_ONE_DASHBOARD_WIDGET_FAILED = 'GET_ONE_DASHBOARD_WIDGET_FAILED'

export const RESET_WIDGETS_FETCHED = 'RESET_WIDGETS_FETCHED'
export const LOAD_WIDGETS_TO_EDIT = 'LOAD_WIDGETS_TO_EDIT'
export const REMOVE_WIDGET = 'REMOVE_WIDGET'
export const PUT_WIDGET = 'PUT_WIDGET'
export const UPDATE_WIDGET_POSITIONS = 'UPDATE_WIDGET_POSITIONS'

export const CREATE_TAB = 'CREATE_TAB'
export const CREATE_TAB_SUCCESS = 'CREATE_TAB_SUCCESS'
export const CREATE_TAB_FAILED = 'CREATE_TAB_FAILED'

export const DELETE_TAB = 'DELETE_TAB'
export const DELETE_TAB_SUCCESS = 'DELETE_TAB_SUCCESS'
export const DELETE_TAB_FAILED = 'DELETE_TAB_FAILED'

export const UPDATE_TAB = 'UPDATE_TAB'
export const UPDATE_TAB_SUCCESS = 'UPDATE_TAB_SUCCESS'
export const UPDATE_TAB_FAILED = 'UPDATE_TAB_FAILED'

export const CREATE_TABLE_FILTER = 'CREATE_TABLE_FILTER'
export const CREATE_TABLE_FILTER_SUCCESS = 'CREATE_TABLE_FILTER_SUCCESS'
export const CREATE_TABLE_FILTER_FAILED = 'CREATE_TABLE_FILTER_FAILED'

export const DELETE_TABLE_FILTER = 'DELETE_TABLE_FILTER'
export const DELETE_TABLE_FILTER_SUCCESS = 'DELETE_TABLE_FILTER_SUCCESS'
export const DELETE_TABLE_FILTER_FAILED = 'DELETE_TABLE_FILTER_FAILED'

export const GET_LIBRARY_DASHBOARDS = 'GET_LIBRARY_DASHBOARDS'
export const GET_LIBRARY_DASHBOARDS_SUCCESS = 'GET_LIBRARY_DASHBOARDS_SUCCESS'
export const GET_LIBRARY_DASHBOARDS_FAILED = 'GET_LIBRARY_DASHBOARDS_FAILED'

export const ACTIVATE_FROM_LIBRARY = 'ACTIVATE_FROM_LIBRARY'
export const ACTIVATE_FROM_LIBRARY_SUCCESS = 'ACTIVATE_FROM_LIBRARY_SUCCESS'
export const ACTIVATE_FROM_LIBRARY_FAILED = 'ACTIVATE_FROM_LIBRARY_FAILED'

// Dashboards types
export const GET_SIM_RENAMINGS = 'GET_SIM_RENAMINGS'
export const GET_SIM_RENAMINGS_SUCCESS = 'GET_SIM_RENAMINGS_SUCCESS'
export const GET_SIM_RENAMINGS_FAILED = 'GET_SIM_RENAMINGS_FAILED'

export const PUT_SIM_RENAMINGS = 'PUT_SIM_RENAMINGS'
export const PUT_SIM_RENAMINGS_SUCCESS = 'PUT_SIM_RENAMINGS_SUCCESS'
export const PUT_SIM_RENAMINGS_FAILED = 'PUT_SIM_RENAMINGS_FAILED'

export const GET_DASHBOARD_FILTER_OPTIONS = 'GET_DASHBOARD_FILTER_OPTIONS'
export const GET_DASHBOARD_FILTER_OPTIONS_SUCCESS =
  'GET_DASHBOARD_FILTER_OPTIONS_SUCCESS'
export const GET_DASHBOARD_FILTER_OPTIONS_FAILED =
  'GET_DASHBOARD_FILTER_OPTIONS_FAILED'

export const GET_ONE_DASHBOARD_FILTER_DATA = 'GET_ONE_DASHBOARD_FILTER_DATA'
export const GET_ONE_DASHBOARD_FILTER_DATA_SUCCESS =
  'GET_ONE_DASHBOARD_FILTER_DATA_SUCCESS'
export const GET_ONE_DASHBOARD_FILTER_DATA_FAILED =
  'GET_ONE_DASHBOARD_FILTER_DATA_FAILED'

export const PUT_DASHBOARD_FILTER_OPTIONS = 'PUT_DASHBOARD_FILTER_OPTIONS'
export const PUT_DASHBOARD_FILTER_OPTIONS_SUCCESS =
  'PUT_DASHBOARD_FILTER_OPTIONS_SUCCESS'
export const PUT_DASHBOARD_FILTER_OPTIONS_FAILED =
  'PUT_DASHBOARD_FILTER_OPTIONS_FAILED'

export const GET_DASHBOARDS = 'GET_DASHBOARDS'
export const GET_DASHBOARDS_SUCCESS = 'GET_DASHBOARDS_SUCCESS'
export const GET_DASHBOARDS_FAILED = 'GET_DASHBOARDS_FAILED'

export const GET_ONE_DASHBOARD = 'GET_ONE_DASHBOARD'
export const GET_ONE_DASHBOARD_SUCCESS = 'GET_ONE_DASHBOARD_SUCCESS'
export const GET_ONE_DASHBOARD_FAILED = 'GET_ONE_DASHBOARD_FAILED'

export const GET_ONE_PUBLIC_DASHBOARD = 'GET_ONE_PUBLIC_DASHBOARD'
export const GET_ONE_PUBLIC_DASHBOARD_SUCCESS =
  'GET_ONE_PUBLIC_DASHBOARD_SUCCESS'
export const GET_ONE_PUBLIC_DASHBOARD_FAILED = 'GET_ONE_PUBLIC_DASHBOARD_FAILED'

export const CREATE_DASHBOARD = 'CREATE_DASHBOARD'
export const CREATE_DASHBOARD_SUCCESS = 'CREATE_DASHBOARD_SUCCESS'
export const CREATE_DASHBOARD_FAILED = 'CREATE_DASHBOARD_FAILED'

export const DELETE_DASHBOARD = 'DELETE_DASHBOARD'
export const DELETE_DASHBOARD_SUCCESS = 'DELETE_DASHBOARD_SUCCESS'
export const DELETE_DASHBOARD_FAILED = 'DELETE_DASHBOARD_FAILED'

export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD'
export const UPDATE_DASHBOARD_SUCCESS = 'UPDATE_DASHBOARD_SUCCESS'
export const UPDATE_DASHBOARD_FAILED = 'UPDATE_DASHBOARD_FAILED'

export const MOVE_DASHBOARD = 'MOVE_DASHBOARD'
export const MOVE_DASHBOARD_SUCCESS = 'MOVE_DASHBOARD_SUCCESS'
export const MOVE_DASHBOARD_FAILED = 'MOVE_DASHBOARD_FAILED'

export const GET_DASHBOARD_EXAMPLES = 'GET_DASHBOARD_EXAMPLES'
export const GET_DASHBOARD_EXAMPLES_SUCCESS = 'GET_DASHBOARD_EXAMPLES_SUCCESS'
export const GET_DASHBOARD_EXAMPLES_FAILED = 'GET_DASHBOARD_EXAMPLES_FAILED'

export const PUT_DASHBOARD_FAVORITE = 'PUT_DASHBOARD_FAVORITE'
export const PUT_DASHBOARD_FAVORITE_SUCCESS = 'PUT_DASHBOARD_FAVORITE_SUCCESS'
export const PUT_DASHBOARD_FAVORITE_FAILED = 'PUT_DASHBOARD_FAVORITE_FAILED'

export const PUT_DASHBOARD_FAVORITES_LAYOUT = 'PUT_DASHBOARD_FAVORITES_LAYOUT'
export const PUT_DASHBOARD_FAVORITES_LAYOUT_SUCCESS =
  'PUT_DASHBOARD_FAVORITES_LAYOUT_SUCCESS'
export const PUT_DASHBOARD_FAVORITES_LAYOUT_FAILED =
  'PUT_DASHBOARD_FAVORITES_LAYOUT_FAILED'

export const UPDATE_DASHBOARD_ORDER = 'UPDATE_DASHBOARD_ORDER'
export const UPDATE_DASHBOARD_ORDER_SUCCESS = 'UPDATE_DASHBOARD_ORDER_SUCCESS'
export const UPDATE_DASHBOARD_ORDER_FAILED = 'UPDATE_DASHBOARD_ORDER_FAILED'

export const COPY_DASHBOARD_GROUP = 'COPY_DASHBOARD_GROUP'
export const COPY_DASHBOARD_GROUP_SUCCESS = 'COPY_DASHBOARD_GROUP_SUCCESS'
export const COPY_DASHBOARD_GROUP_FAILED = 'COPY_DASHBOARD_GROUP_FAILED'

export const UPDATE_DASHBOARD_GROUP = 'UPDATE_DASHBOARD_GROUP'
export const UPDATE_DASHBOARD_GROUP_SUCCESS = 'UPDATE_DASHBOARD_GROUP_SUCCESS'
export const UPDATE_DASHBOARD_GROUP_FAILED = 'UPDATE_DASHBOARD_GROUP_FAILED'

export const DELETE_DASHBOARD_GROUP = 'DELETE_DASHBOARD_GROUP'
export const DELETE_DASHBOARD_GROUP_SUCCESS = 'DELETE_DASHBOARD_GROUP_SUCCESS'
export const DELETE_DASHBOARD_GROUP_FAILED = 'DELETE_DASHBOARD_GROUP_FAILED'

export const ADD_TEMPORARY_LAST_VISIT_TO_DASHBOARD_GROUP =
  'ADD_TEMPORARY_LAST_VISIT_TO_DASHBOARD_GROUP'

export const GET_ALL_ACCOUNTS = 'GET_ALL_ACCOUNTS'
export const GET_ALL_ACCOUNTS_SUCCESS = 'GET_ALL_ACCOUNTS_SUCCESS'
export const GET_ALL_ACCOUNTS_FAILED = 'GET_ALL_ACCOUNTS_FAILED'

export const GET_ALL_ACCOUNT_ROLES = 'GET_ALL_ACCOUNT_ROLES'
export const GET_ALL_ACCOUNT_ROLES_SUCCESS = 'GET_ALL_ACCOUNT_ROLES_SUCCESS'
export const GET_ALL_ACCOUNT_ROLES_FAILED = 'GET_ALL_ACCOUNT_ROLES_FAILED'

export const GET_KPI_TEMPLATES = 'GET_KPI_TEMPLATES'
export const GET_KPI_TEMPLATES_SUCCESS = 'GET_KPI_TEMPLATES_SUCCESS'
export const GET_KPI_TEMPLATES_FAILED = 'GET_KPI_TEMPLATES_FAILED'

export const UPDATE_KPI_TEMPLATE = 'UPDATE_KPI_TEMPLATE'
export const UPDATE_KPI_TEMPLATE_SUCCESS = 'UPDATE_KPI_TEMPLATE_SUCCESS'
export const UPDATE_KPI_TEMPLATE_FAILED = 'UPDATE_KPI_TEMPLATE_FAILED'

export const CREATE_KPI_TEMPLATE = 'CREATE_KPI_TEMPLATE'
export const CREATE_KPI_TEMPLATE_SUCCESS = 'CREATE_KPI_TEMPLATE_SUCCESS'
export const CREATE_KPI_TEMPLATE_FAILED = 'CREATE_KPI_TEMPLATE_FAILED'

export const DELETE_KPI_TEMPLATE = 'DELETE_KPI_TEMPLATE'
export const DELETE_KPI_TEMPLATE_SUCCESS = 'DELETE_KPI_TEMPLATE_SUCCESS'
export const DELETE_KPI_TEMPLATE_FAILED = 'DELETE_KPI_TEMPLATE_FAILED'

export const GET_KPI_VARIABLES = 'GET_KPI_VARIABLES'
export const GET_KPI_VARIABLES_SUCCESS = 'GET_KPI_VARIABLES_SUCCESS'
export const GET_KPI_VARIABLES_FAILED = 'GET_KPI_VARIABLES_FAILED'

export const PUT_SORTED_KPI_VARIABLES = 'PUT_SORTED_KPI_VARIABLES'
export const PUT_SORTED_KPI_VARIABLES_SUCCESS =
  'PUT_SORTED_KPI_VARIABLES_SUCCESS'
export const PUT_SORTED_KPI_VARIABLES_FAILED = 'PUT_SORTED_KPI_VARIABLES_FAILED'

export const CREATE_KPI_VARIABLE = 'CREATE_KPI_VARIABLE'
export const CREATE_KPI_VARIABLE_SUCCESS = 'CREATE_KPI_VARIABLE_SUCCESS'
export const CREATE_KPI_VARIABLE_FAILED = 'CREATE_KPI_VARIABLE_FAILED'

export const UPDATE_KPI_VARIABLE = 'UPDATE_KPI_VARIABLE'
export const UPDATE_KPI_VARIABLE_SUCCESS = 'UPDATE_KPI_VARIABLE_SUCCESS'
export const UPDATE_KPI_VARIABLE_FAILED = 'UPDATE_KPI_VARIABLE_FAILED'

export const DELETE_KPI_VARIABLE = 'DELETE_KPI_VARIABLE'
export const DELETE_KPI_VARIABLE_SUCCESS = 'DELETE_KPI_VARIABLE_SUCCESS'
export const DELETE_KPI_VARIABLE_FAILED = 'DELETE_KPI_VARIABLE_FAILED'

export const UPDATE_KPI_VARIABLE_OPTIONS = 'UPDATE_KPI_VARIABLE_OPTIONS'
export const UPDATE_KPI_VARIABLE_OPTIONS_SUCCESS =
  'UPDATE_KPI_VARIABLE_OPTIONS_SUCCESS'
export const UPDATE_KPI_VARIABLE_OPTIONS_FAILED =
  'UPDATE_KPI_VARIABLE_OPTIONS_FAILED'

export const GET_ALL_KPI_VARIABLE_OPTIONS = 'GET_ALL_KPI_VARIABLE_OPTIONS'
export const GET_ALL_KPI_VARIABLE_OPTIONS_SUCCESS =
  'GET_ALL_KPI_VARIABLE_OPTIONS_SUCCESS'
export const GET_ALL_KPI_VARIABLE_OPTIONS_FAILED =
  'GET_ALL_KPI_VARIABLE_OPTIONS_FAILED'

export const GET_FILTER_OPTIONS_SUCCESS = 'GET_FILTER_OPTIONS_SUCCESS'

export const GET_ATTRIBUTE_OPTIONS_SUCCESS = 'GET_ATTRIBUTE_OPTIONS_SUCCESS'

export const GET_ONE_WIDGET = 'GET_ONE_WIDGET'
export const GET_ONE_WIDGET_SUCCESS = 'GET_ONE_WIDGET_SUCCESS'
export const GET_ONE_WIDGET_FAILED = 'GET_ONE_WIDGET_FAILED'

export const CREATE_WIDGET = 'CREATE_WIDGET'
export const CREATE_WIDGET_SUCCESS = 'CREATE_WIDGET_SUCCESS'
export const CREATE_WIDGET_FAILED = 'CREATE_WIDGET_FAILED'

export const UPDATE_WIDGET = 'UPDATE_WIDGET'
export const UPDATE_WIDGET_SUCCESS = 'UPDATE_WIDGET_SUCCESS'
export const UPDATE_WIDGET_FAILED = 'UPDATE_WIDGET_FAILED'

export const DELETE_WIDGET = 'DELETE_WIDGET'
export const DELETE_WIDGET_SUCCESS = 'DELETE_WIDGET_SUCCESS'
export const DELETE_WIDGET_FAILED = 'DELETE_WIDGET_FAILED'

export const UPDATE_WIDGET_LAYOUT = 'UPDATE_WIDGET_LAYOUT'
export const UPDATE_WIDGET_LAYOUT_SUCCESS = 'UPDATE_WIDGET_LAYOUT_SUCCESS'
export const UPDATE_WIDGET_LAYOUT_FAILED = 'UPDATE_WIDGET_LAYOUT_FAILED'

export const UPLOAD_WIDGET_IMAGE = 'UPLOAD_WIDGET_IMAGE'
export const UPLOAD_WIDGET_IMAGE_SUCCESS = 'UPLOAD_WIDGET_IMAGE_SUCCESS'
export const UPLOAD_WIDGET_IMAGE_FAILED = 'UPLOAD_WIDGET_IMAGE_FAILED'

export const INVITE_TO_DASHBOARD = 'INVITE_TO_DASHBOARD'
export const INVITE_TO_DASHBOARD_SUCCESS = 'INVITE_TO_DASHBOARD_SUCCESS'
export const INVITE_TO_DASHBOARD_FAILED = 'INVITE_TO_DASHBOARD_FAILED'

export const REVOKE_FROM_DASHBOARD = 'REVOKE_FROM_DASHBOARD'
export const REVOKE_FROM_DASHBOARD_SUCCESS = 'REVOKE_FROM_DASHBOARD_SUCCESS'
export const REVOKE_FROM_DASHBOARD_FAILED = 'REVOKE_FROM_DASHBOARD_FAILED'

export const PUT_WIDGET_FUNNEL_STAGES = 'PUT_WIDGET_FUNNEL_STAGES'
export const PUT_WIDGET_FUNNEL_STAGES_SUCCESS =
  'PUT_WIDGET_FUNNEL_STAGES_SUCCESS'
export const PUT_WIDGET_FUNNEL_STAGES_FAILED = 'PUT_WIDGET_FUNNEL_STAGES_FAILED'

// kpi options
export const CREATE_KPI_OPTION = 'CREATE_KPI_OPTION'
export const CREATE_KPI_OPTION_SUCCESS = 'CREATE_KPI_OPTION_SUCCESS'
export const CREATE_KPI_OPTION_FAILED = 'CREATE_KPI_OPTION_FAILED'

export const UPDATE_KPI_OPTION = 'UPDATE_KPI_OPTION'
export const UPDATE_KPI_OPTION_SUCCESS = 'UPDATE_KPI_OPTION_SUCCESS'
export const UPDATE_KPI_OPTION_FAILED = 'UPDATE_KPI_OPTION_FAILED'

export const DELETE_KPI_OPTION = 'DELETE_KPI_OPTION'
export const DELETE_KPI_OPTION_SUCCESS = 'DELETE_KPI_OPTION_SUCCESS'
export const DELETE_KPI_OPTION_FAILED = 'DELETE_KPI_OPTION_FAILED'

export const REQUEST_KPI_TEMPLATE = 'REQUEST_KPI_TEMPLATE'
export const REQUEST_KPI_TEMPLATE_SUCCESS = 'REQUEST_KPI_TEMPLATE_SUCCESS'
export const REQUEST_KPI_TEMPLATE_FAILED = 'REQUEST_KPI_TEMPLATE_FAILED'

// Widget tags
export const CREATE_WIDGET_TAG = 'CREATE_WIDGET_TAG'
export const CREATE_WIDGET_TAG_SUCCESS = 'CREATE_WIDGET_TAG_SUCCESS'
export const CREATE_WIDGET_TAG_FAILED = 'CREATE_WIDGET_TAG_FAILED'

export const UPDATE_WIDGET_TAG = 'UPDATE_WIDGET_TAG'
export const UPDATE_WIDGET_TAG_SUCCESS = 'UPDATE_WIDGET_TAG_SUCCESS'
export const UPDATE_WIDGET_TAG_FAILED = 'UPDATE_WIDGET_TAG_FAILED'

export const DELETE_WIDGET_TAG = 'DELETE_WIDGET_TAG'
export const DELETE_WIDGET_TAG_SUCCESS = 'DELETE_WIDGET_TAG_SUCCESS'
export const DELETE_WIDGET_TAG_FAILED = 'DELETE_WIDGET_TAG_FAILED'

// Widget tag statuses
export const CREATE_WIDGET_TAG_STATUS = 'CREATE_WIDGET_TAG_STATUS'
export const CREATE_WIDGET_TAG_STATUS_SUCCESS =
  'CREATE_WIDGET_TAG_STATUS_SUCCESS'
export const CREATE_WIDGET_TAG_STATUS_FAILED = 'CREATE_WIDGET_TAG_STATUS_FAILED'

export const UPDATE_WIDGET_TAG_STATUS = 'UPDATE_WIDGET_TAG_STATUS'
export const UPDATE_WIDGET_TAG_STATUS_SUCCESS =
  'UPDATE_WIDGET_TAG_STATUS_SUCCESS'
export const UPDATE_WIDGET_TAG_STATUS_FAILED = 'UPDATE_WIDGET_TAG_STATUS_FAILED'

export const DELETE_WIDGET_TAG_STATUS = 'DELETE_WIDGET_TAG_STATUS'
export const DELETE_WIDGET_TAG_STATUS_SUCCESS =
  'DELETE_WIDGET_TAG_STATUS_SUCCESS'
export const DELETE_WIDGET_TAG_STATUS_FAILED = 'DELETE_WIDGET_TAG_STATUS_FAILED'

// tag options
export const GET_ALL_TAG_OPTIONS_SUCCESS = 'GET_ALL_TAG_OPTIONS_SUCCESS'

// additional segments
export const CREATE_ADDITIONAL_SEGMENT = 'CREATE_ADDITIONAL_SEGMENT'
export const CREATE_ADDITIONAL_SEGMENT_SUCCESS =
  'CREATE_ADDITIONAL_SEGMENT_SUCCESS'
export const CREATE_ADDITIONAL_SEGMENT_FAILED =
  'CREATE_ADDITIONAL_SEGMENT_FAILED'

export const UPDATE_ADDITIONAL_SEGMENT = 'UPDATE_ADDITIONAL_SEGMENT'
export const UPDATE_ADDITIONAL_SEGMENT_SUCCESS =
  'UPDATE_ADDITIONAL_SEGMENT_SUCCESS'
export const UPDATE_ADDITIONAL_SEGMENT_FAILED =
  'UPDATE_ADDITIONAL_SEGMENT_FAILED'

export const DELETE_ADDITIONAL_SEGMENT = 'DELETE_ADDITIONAL_SEGMENT'
export const DELETE_ADDITIONAL_SEGMENT_SUCCESS =
  'DELETE_ADDITIONAL_SEGMENT_SUCCESS'
export const DELETE_ADDITIONAL_SEGMENT_FAILED =
  'DELETE_ADDITIONAL_SEGMENT_FAILED'

// SIM
export const GET_SIM_TABLES = 'GET_SIM_TABLES'
export const GET_SIM_TABLES_SUCCESS = 'GET_SIM_TABLES_SUCCESS'
export const GET_SIM_TABLES_FAILED = 'GET_SIM_TABLES_FAILED'

export const GET_SIM_DATA = 'GET_SIM_DATA'
export const GET_SIM_DATA_SUCCESS = 'GET_SIM_DATA_SUCCESS'
export const GET_SIM_DATA_FAILED = 'GET_SIM_DATA_FAILED'

export const EXPORT_SIM_TABLE = 'EXPORT_SIM_TABLE'
export const EXPORT_SIM_TABLE_SUCCESS = 'EXPORT_SIM_TABLE_SUCCESS'
export const EXPORT_SIM_TABLE_FAILED = 'EXPORT_SIM_TABLE_FAILED'

// Config
export const GET_TARGETS = 'GET_TARGETS'
export const GET_TARGETS_SUCCESS = 'GET_TARGETS_SUCCESS'
export const GET_TARGETS_FAILED = 'GET_TARGETS_FAILED'

export const PUT_TARGET_VALUES = 'PUT_TARGET_VALUES'
export const PUT_TARGET_VALUES_SUCCESS = 'PUT_TARGET_VALUES_SUCCESS'
export const PUT_TARGET_VALUES_FAILED = 'PUT_TARGET_VALUES_FAILED'

export const UPDATE_CONFIG = 'UPDATE_CONFIG'
export const UPDATE_CONFIG_SUCCESS = 'UPDATE_CONFIG_SUCCESS'
export const UPDATE_CONFIG_FAILED = 'UPDATE_CONFIG_FAILED'

// Funnels
export const GET_FUNNELS = 'GET_FUNNELS'
export const GET_FUNNELS_SUCCESS = 'GET_FUNNELS_SUCCESS'
export const GET_FUNNELS_FAILED = 'GET_FUNNELS_FAILED'

export const PUT_FUNNEL_STAGES = 'PUT_FUNNEL_STAGES'
export const PUT_FUNNEL_STAGES_SUCCESS = 'PUT_FUNNEL_STAGES_SUCCESS'
export const PUT_FUNNEL_STAGES_FAILED = 'PUT_FUNNEL_STAGES_FAILED'

// Ordered KPI templates
export const CREATE_ORDERED_KPI_TEMPLATE = 'CREATE_ORDERED_KPI_TEMPLATE'
export const CREATE_ORDERED_KPI_TEMPLATE_SUCCESS =
  'CREATE_ORDERED_KPI_TEMPLATE_SUCCESS'
export const CREATE_ORDERED_KPI_TEMPLATE_FAILED =
  'CREATE_ORDERED_KPI_TEMPLATE_FAILED'

export const UPDATE_ORDERED_KPI_TEMPLATE = 'UPDATE_ORDERED_KPI_TEMPLATE'
export const UPDATE_ORDERED_KPI_TEMPLATE_SUCCESS =
  'UPDATE_ORDERED_KPI_TEMPLATE_SUCCESS'
export const UPDATE_ORDERED_KPI_TEMPLATE_FAILED =
  'UPDATE_ORDERED_KPI_TEMPLATE_FAILED'

export const DELETE_ORDERED_KPI_TEMPLATE = 'DELETE_ORDERED_KPI_TEMPLATE'
export const DELETE_ORDERED_KPI_TEMPLATE_SUCCESS =
  'DELETE_ORDERED_KPI_TEMPLATE_SUCCESS'
export const DELETE_ORDERED_KPI_TEMPLATE_FAILED =
  'DELETE_ORDERED_KPI_TEMPLATE_FAILED'

// Banner redux types
export const GET_STATUS_BANNER = 'GET_STATUS_BANNER'
export const GET_STATUS_BANNER_SUCCESS = 'GET_STATUS_BANNER_SUCCESS'
export const GET_STATUS_BANNER_FAILED = 'GET_STATUS_BANNER_FAILED'

// internal redux types
export const MEASUREMENTS_GET_USERS_USAGE = 'MEASUREMENTS_GET_USERS_USAGE'
export const MEASUREMENTS_GET_USERS_USAGE_SUCCESS =
  'MEASUREMENTS_GET_USERS_USAGE_SUCCESS'
export const MEASUREMENTS_GET_USERS_USAGE_FAILED =
  'MEASUREMENTS_GET_USERS_USAGE_FAILED'

export const GET_ALL_INVITE_MESSAGES = 'GET_ALL_INVITE_MESSAGES'
export const GET_ALL_INVITE_MESSAGES_SUCCESS = 'GET_ALL_INVITE_MESSAGES_SUCCESS'
export const GET_ALL_INVITE_MESSAGES_FAILED = 'GET_ALL_INVITE_MESSAGES_FAILED'

// Customers and licenses
export const GET_OUR_CUSTOMERS = 'GET_OUR_CUSTOMERS'
export const GET_OUR_CUSTOMERS_SUCCESS = 'GET_OUR_CUSTOMERS_SUCCESS'
export const GET_OUR_CUSTOMERS_FAILED = 'GET_OUR_CUSTOMERS_FAILED'

export const UPDATE_ONE_CUSTOMER = 'UPDATE_ONE_CUSTOMER'
export const UPDATE_ONE_CUSTOMER_SUCCESS = 'UPDATE_ONE_CUSTOMER_SUCCESS'
export const UPDATE_ONE_CUSTOMER_FAILED = 'UPDATE_ONE_CUSTOMER_FAILED'

// status banner
export const INTERNAL_GET_STATUS_BANNER = 'INTERNAL_GET_STATUS_BANNER'
export const INTERNAL_GET_STATUS_BANNER_SUCCESS =
  'INTERNAL_GET_STATUS_BANNER_SUCCESS'
export const INTERNAL_GET_STATUS_BANNER_FAILED =
  'INTERNAL_GET_STATUS_BANNER_FAILED'

export const INTERNAL_CREATE_STATUS_BANNER = 'INTERNAL_CREATE_STATUS_BANNER'
export const INTERNAL_CREATE_STATUS_BANNER_SUCCESS =
  'INTERNAL_CREATE_STATUS_BANNER_SUCCESS'
export const INTERNAL_CREATE_STATUS_BANNER_FAILED =
  'INTERNAL_CREATE_STATUS_BANNER_FAILED'

export const INTERNAL_UPDATE_STATUS_BANNER = 'INTERNAL_UPDATE_STATUS_BANNER'
export const INTERNAL_UPDATE_STATUS_BANNER_SUCCESS =
  'INTERNAL_UPDATE_STATUS_BANNER_SUCCESS'
export const INTERNAL_UPDATE_STATUS_BANNER_FAILED =
  'INTERNAL_UPDATE_STATUS_BANNER_FAILED'

export const INTERNAL_DELETE_STATUS_BANNER = 'INTERNAL_DELETE_STATUS_BANNER'
export const INTERNAL_DELETE_STATUS_BANNER_SUCCESS =
  'INTERNAL_DELETE_STATUS_BANNER_SUCCESS'
export const INTERNAL_DELETE_STATUS_BANNER_FAILED =
  'INTERNAL_DELETE_STATUS_BANNER_FAILED'

// SIM flaws
export const INTERNAL_GET_FLAW_SETTINGS = 'INTERNAL_GET_FLAW_SETTINGS'
export const INTERNAL_GET_FLAW_SETTINGS_SUCCESS =
  'INTERNAL_GET_FLAW_SETTINGS_SUCCESS'
export const INTERNAL_GET_FLAW_SETTINGS_FAILED =
  'INTERNAL_GET_FLAW_SETTINGS_FAILED'

export const INTERNAL_GET_SIM_FLAWS = 'INTERNAL_GET_SIM_FLAWS'
export const INTERNAL_GET_SIM_FLAWS_SUCCESS = 'INTERNAL_GET_SIM_FLAWS_SUCCESS'
export const INTERNAL_GET_SIM_FLAWS_FAILED = 'INTERNAL_GET_SIM_FLAWS_FAILED'

export const MEASUREMENTS_GET_ONE_USER_USAGE = 'MEASUREMENTS_GET_ONE_USER_USAGE'
export const MEASUREMENTS_GET_ONE_USER_USAGE_SUCCESS =
  'MEASUREMENTS_GET_ONE_USER_USAGE_SUCCESS'
export const MEASUREMENTS_GET_ONE_USER_USAGE_FAILED =
  'MEASUREMENTS_GET_ONE_USER_USAGE_FAILED'

export const MEASUREMENTS_UPDATE_USER_USAGE = 'MEASUREMENTS_UPDATE_USER_USAGE'
export const MEASUREMENTS_UPDATE_USER_USAGE_SUCCESS =
  'MEASUREMENTS_UPDATE_USER_USAGE_SUCCESS'
export const MEASUREMENTS_UPDATE_USER_USAGE_FAILED =
  'MEASUREMENTS_UPDATE_USER_USAGE_FAILED'

export const GET_ALL_SURVEYS = 'GET_ALL_SURVEYS'
export const GET_ALL_SURVEYS_SUCCESS = 'GET_ALL_SURVEYS_SUCCESS'
export const GET_ALL_SURVEYS_FAILED = 'GET_ALL_SURVEYS_FAILED'

// Dashboard folders.
export const CREATE_DASHBOARD_FOLDER = 'CREATE_DASHBOARD_FOLDER'
export const CREATE_DASHBOARD_FOLDER_SUCCESS = 'CREATE_DASHBOARD_FOLDER_SUCCESS'
export const CREATE_DASHBOARD_FOLDER_FAILED = 'CREATE_DASHBOARD_FOLDER_FAILED'

export const GET_ALL_DASHBOARD_FOLDERS = 'GET_ALL_DASHBOARD_FOLDERS'
export const GET_ALL_DASHBOARD_FOLDERS_SUCCESS =
  'GET_ALL_DASHBOARD_FOLDERS_SUCCESS'
export const GET_ALL_DASHBOARD_FOLDERS_FAILED =
  'GET_ALL_DASHBOARD_FOLDERS_FAILED'

export const UPDATE_DASHBOARD_FOLDER = 'UPDATE_DASHBOARD_FOLDER'
export const UPDATE_DASHBOARD_FOLDER_SUCCESS = 'UPDATE_DASHBOARD_FOLDER_SUCCESS'
export const UPDATE_DASHBOARD_FOLDER_FAILED = 'UPDATE_DASHBOARD_FOLDER_FAILED'

export const DELETE_DASHBOARD_FOLDER = 'DELETE_DASHBOARD_FOLDER'
export const DELETE_DASHBOARD_FOLDER_SUCCESS = 'DELETE_DASHBOARD_FOLDER_SUCCESS'
export const DELETE_DASHBOARD_FOLDER_FAILED = 'DELETE_DASHBOARD_FOLDER_FAILED'

// Navigation menu
export const TOGGLE_NAVIGATION = 'TOGGLE_NAVIGATION'
export const TOGGLE_NAVIGATION_FOLDER = 'TOGGLE_NAVIGATION_FOLDER'

/* Fake feature for sales and review */
export const CREATE_WIDGET_NOTIFICATION = 'CREATE_WIDGET_NOTIFICATION'
export const CREATE_WIDGET_NOTIFICATION_SUCCESS =
  'CREATE_WIDGET_NOTIFICATION_SUCCESS'
export const CREATE_WIDGET_NOTIFICATION_FAILED =
  'CREATE_WIDGET_NOTIFICATION_FAILED'

export const GET_WIDGET_NOTIFICATIONS = 'GET_WIDGET_NOTIFICATIONS'
export const GET_WIDGET_NOTIFICATIONS_SUCCESS =
  'GET_WIDGET_NOTIFICATIONS_SUCCESS'
export const GET_WIDGET_NOTIFICATIONS_FAILED = 'GET_WIDGET_NOTIFICATIONS_FAILED'

export const UPDATE_WIDGET_NOTIFICATION = 'UPDATE_WIDGET_NOTIFICATION'
export const UPDATE_WIDGET_NOTIFICATION_SUCCESS =
  'UPDATE_WIDGET_NOTIFICATION_SUCCESS'
export const UPDATE_WIDGET_NOTIFICATION_FAILED =
  'UPDATE_WIDGET_NOTIFICATION_FAILED'

export const DELETE_WIDGET_NOTIFICATION = 'DELETE_WIDGET_NOTIFICATION'
export const DELETE_WIDGET_NOTIFICATION_SUCCESS =
  'DELETE_WIDGET_NOTIFICATION_SUCCESS'
export const DELETE_WIDGET_NOTIFICATION_FAILED =
  'DELETE_WIDGET_NOTIFICATION_FAILED'

// Real estates
export const GET_ALL_REAL_ESTATES = 'GET_ALL_REAL_ESTATES'
export const GET_ALL_REAL_ESTATES_SUCCESS = 'GET_ALL_REAL_ESTATES_SUCCESS'
export const GET_ALL_REAL_ESTATES_FAILED = 'GET_ALL_REAL_ESTATES_FAILED'

// Allocation keys
export const CREATE_ALLOCATION_KEY = 'CREATE_ALLOCATION_KEY'
export const CREATE_ALLOCATION_KEY_SUCCESS = 'CREATE_ALLOCATION_KEY_SUCCESS'
export const CREATE_ALLOCATION_KEY_FAILED = 'CREATE_ALLOCATION_KEY_FAILED'

export const GET_ALL_ALLOCATION_KEYS = 'GET_ALL_ALLOCATION_KEYS'
export const GET_ALL_ALLOCATION_KEYS_SUCCESS = 'GET_ALL_ALLOCATION_KEYS_SUCCESS'
export const GET_ALL_ALLOCATION_KEYS_FAILED = 'GET_ALL_ALLOCATION_KEYS_FAILED'

export const UPDATE_ALLOCATION_KEY = 'UPDATE_ALLOCATION_KEY'
export const UPDATE_ALLOCATION_KEY_SUCCESS = 'UPDATE_ALLOCATION_KEY_SUCCESS'
export const UPDATE_ALLOCATION_KEY_FAILED = 'UPDATE_ALLOCATION_KEY_FAILED'

export const DELETE_ALLOCATION_KEY = 'DELETE_ALLOCATION_KEY'
export const DELETE_ALLOCATION_KEY_SUCCESS = 'DELETE_ALLOCATION_KEY_SUCCESS'
export const DELETE_ALLOCATION_KEY_FAILED = 'DELETE_ALLOCATION_KEY_FAILED'

// Entities
export const GET_ALL_ENTITIES = 'GET_ALL_ENTITIES'
export const GET_ALL_ENTITIES_SUCCESS = 'GET_ALL_ENTITIES_SUCCESS'
export const GET_ALL_ENTITIES_FAILED = 'GET_ALL_ENTITIES_FAILED'

export const PUT_ENTITY_ATTRIBUTES = 'PUT_ENTITY_ATTRIBUTES'
export const PUT_ENTITY_ATTRIBUTES_SUCCESS = 'PUT_ENTITY_ATTRIBUTES_SUCCESS'
export const PUT_ENTITY_ATTRIBUTES_FAILED = 'PUT_ENTITY_ATTRIBUTES_FAILED'

// Saved dashboard filters
export const GET_SAVED_DASHBOARD_FILTERS = 'GET_SAVED_DASHBOARD_FILTERS'
export const GET_SAVED_DASHBOARD_FILTERS_SUCCESS =
  'GET_SAVED_DASHBOARD_FILTERS_SUCCESS'
export const GET_SAVED_DASHBOARD_FILTERS_FAILED =
  'GET_SAVED_DASHBOARD_FILTERS_FAILED'

export const CREATE_SAVED_DASHBOARD_FILTER = 'CREATE_SAVED_DASHBOARD_FILTER'
export const CREATE_SAVED_DASHBOARD_FILTER_SUCCESS =
  'CREATE_SAVED_DASHBOARD_FILTER_SUCCESS'
export const CREATE_SAVED_DASHBOARD_FILTER_FAILED =
  'CREATE_SAVED_DASHBOARD_FILTER_FAILED'

export const UPDATE_SAVED_DASHBOARD_FILTER = 'UPDATE_SAVED_DASHBOARD_FILTER'
export const UPDATE_SAVED_DASHBOARD_FILTER_SUCCESS =
  'UPDATE_SAVED_DASHBOARD_FILTER_SUCCESS'
export const UPDATE_SAVED_DASHBOARD_FILTER_FAILED =
  'UPDATE_SAVED_DASHBOARD_FILTER_FAILED'

export const DELETE_SAVED_DASHBOARD_FILTER = 'DELETE_SAVED_DASHBOARD_FILTER'
export const DELETE_SAVED_DASHBOARD_FILTER_SUCCESS =
  'DELETE_SAVED_DASHBOARD_FILTER_SUCCESS'
export const DELETE_SAVED_DASHBOARD_FILTER_FAILED =
  'DELETE_SAVED_DASHBOARD_FILTER_FAILED'

// Customer agreements
export const CREATE_CUSTOMER_AGREEMENT = 'CREATE_CUSTOMER_AGREEMENT'
export const CREATE_CUSTOMER_AGREEMENT_SUCCESS =
  'CREATE_CUSTOMER_AGREEMENT_SUCCESS'
export const CREATE_CUSTOMER_AGREEMENT_FAILED =
  'CREATE_CUSTOMER_AGREEMENT_FAILED'
export const GET_CUSTOMER_AGREEMENTS = 'GET_CUSTOMER_AGREEMENTS'
export const GET_CUSTOMER_AGREEMENTS_SUCCESS = 'GET_CUSTOMER_AGREEMENTS_SUCCESS'
export const GET_CUSTOMER_AGREEMENTS_FAILED = 'GET_CUSTOMER_AGREEMENTS_FAILED'
export const UPDATE_CUSTOMER_AGREEMENT = 'UPDATE_CUSTOMER_AGREEMENT'
export const UPDATE_CUSTOMER_AGREEMENT_SUCCESS =
  'UPDATE_CUSTOMER_AGREEMENT_SUCCESS'
export const UPDATE_CUSTOMER_AGREEMENT_FAILED =
  'UPDATE_CUSTOMER_AGREEMENT_FAILED'
export const DELETE_CUSTOMER_AGREEMENT = 'DELETE_CUSTOMER_AGREEMENT'
export const DELETE_CUSTOMER_AGREEMENT_SUCCESS =
  'DELETE_CUSTOMER_AGREEMENT_SUCCESS'
export const DELETE_CUSTOMER_AGREEMENT_FAILED =
  'DELETE_CUSTOMER_AGREEMENT_FAILED'
