import React, { useEffect, useState } from 'react'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Form } from 'antd'

import * as AuthenticationActions from 'redux/actions/Admin/Authentication'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import FormControlLabel from 'components_new/atoms/FormControlLabel'
import Icon from 'components_new/atoms/Icon'
import Switch from 'components_new/atoms/Switch'
import Input from 'components/common/Input'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import CollapsibleSection from 'components_new/molecules/CollapsibleSection'
import SectionHeader from 'components_new/molecules/SectionHeader'

import DetailsDialog from './dialogs/DetailsDialog'
import Domain from './Domain'
import SettingsTable from './SettingsTable'

import { FORM_VALIDATIONS } from 'helpers/Constants'
import { DOMAIN_VALIDATION } from './conf'
import Collapse from 'components_new/atoms/Collapse'
import SettingsTemplate from 'components_new/templates/SettingsTemplate'

const Authentication = ({
  AuthStore,
  tryGetAzureAd,
  tryPutAzureAd,
  tryDeleteAzureAd
}) => {
  const [instructionDialogOpen, setInstructionDialogOpen] = useState(false)
  const [activated, setActivated] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    if (!AuthStore.azureAd.fetched) {
      tryGetAzureAd()
    }
  }, [])

  useEffect(() => {
    if (AuthStore.azureAd.fetched) {
      form.setFieldsValue({
        metadataUrl: AuthStore.azureAd.metadataUrl,
        domains: [...AuthStore.azureAd.domains, { name: '' }],
        group_id: AuthStore.azureAd.groupId
      })
      setActivated(AuthStore.azureAd.metadataUrl ? true : false)
    }
  }, [AuthStore])

  const handleChange = () => {
    setActivated(!activated)
  }

  return (
    <>
      <SettingsTemplate>
        <CollapsibleSection
          title="Microsoft Azure AD"
          sx={{ flexGrow: 1, maxWidth: 'section' }}
        >
          <Collapse in={!AuthStore.customer.allow_access.sso}>
            <Box sx={{ pb: 2 }}>
              <Alert severity="warning" icon={<Icon name="PrivacyTip" />}>
                <strong>Funktionen ingår ej i din organisations licens!</strong>{' '}
                SSO ingår inte i din organisations valda licens, var god
                kontakta <a href={'mailto:hej@homepal.se'}>hej@homepal.se</a> om
                du vill aktivera denna funktion.
              </Alert>
            </Box>
          </Collapse>
          <Collapse in={AuthStore.customer.allow_access.sso}>
            <Box sx={{ pt: 2 }}>
              <Form
                form={form}
                initialValues={{ metadataUrl: '', domains: [{ name: '' }] }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1.5rem'
                }}
              >
                <Box sx={{ maxWidth: 'content' }}>
                  <Text color="text.secondary" variant="body2">
                    Aktivera Microsoft Azure AD i autentisering för att tillåta
                    användare att logga in på Homepal via deras{' '}
                    <b>Microsoft Azure AD</b>-konto.
                  </Text>
                  <FormControlLabel
                    control={<Switch />}
                    checked={activated}
                    onChange={handleChange}
                    label="Aktivera Microsoft Azure AD"
                    labelPlacement="end"
                  />
                </Box>
                <SectionHeader title="Uppgifter från Homepal" divider={true} />
                <Box sx={{ maxWidth: 'content' }}>
                  <Text color="text.secondary" variant="body2">
                    Sätt upp <b>SAML SSO</b> i Microsoft Azure AD med hjälp av
                    följande uppgifter:
                  </Text>
                </Box>
                <SettingsTable />
                <SectionHeader
                  title="Uppgifter från Microsoft Azure AD"
                  divider={true}
                />
                <Box sx={{ maxWidth: 'content' }}>
                  <Text color="text.secondary" variant="body2" sx={{ mb: 2 }}>
                    Lägg in <b>App Federation Metadata URL</b> från Microsoft
                    Azure AD här:
                  </Text>
                  <Form.Item
                    name={'metadataUrl'}
                    rules={[FORM_VALIDATIONS.REQUIRED]}
                  >
                    <Input
                      label={'App Federation Metadata URL'}
                      disabled={!activated}
                    />
                  </Form.Item>
                  <Text color="text.secondary" variant="body2" sx={{ mb: 2 }}>
                    Lägg in <b>Grupp ID</b> här (Detta behövs endast om ni har
                    flertalet homepal-uppsättningar i er organisation.):
                  </Text>
                  <Form.Item name={'group_id'}>
                    <Input label={'Grupp ID'} disabled={!activated} />
                  </Form.Item>
                </Box>
                <SectionHeader title="Maildomäner" divider={true} />
                <Box sx={{ maxWidth: 'content' }}>
                  <Text color="text.secondary" variant="body2" sx={{ mb: 2 }}>
                    Ange vilka maildomäner som ska få logga in med Microsoft
                    Azure AD. Domänen ska skrivas utan &apos;@&apos; (ex:{' '}
                    <b>fastigheter.se</b>
                    ).
                  </Text>
                  <Alert severity="warning">
                    <strong>Tänk på!</strong> Det går inte att ange publika
                    domäner (så som <b>gmail.com</b> eller <b>outlook.com</b>
                    ). Ange domänen utan @.
                  </Alert>
                </Box>
                <Form.List name={'domains'}>
                  {(fields, { add, remove }) => {
                    return fields.reverse().map(({ name }, i) => (
                      <Form.Item
                        key={i}
                        name={[name, 'name']}
                        rules={
                          i === 0 && fields.length > 1
                            ? []
                            : [FORM_VALIDATIONS.REQUIRED, DOMAIN_VALIDATION]
                        }
                      >
                        <Domain
                          isNew={i === 0}
                          add={add}
                          remove={() => remove(name)}
                        />
                      </Form.Item>
                    ))
                  }}
                </Form.List>
              </Form>
            </Box>
            <Box>
              <Divider />
              <Box
                sx={{
                  p: 1,
                  bgcolor: 'grey.200',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Tooltip title="Klicka för att få mer detaljerade instruktioner om hur du ska gå tillväga för att aktivera Microsoft Azure AD.">
                  <Button
                    onClick={() => setInstructionDialogOpen(true)}
                    startIcon={<Icon name="InfoOutlined" />}
                    variant="text"
                  >
                    Detaljerade instruktioner
                  </Button>
                </Tooltip>
                <Button
                  onClick={async () => {
                    if (activated) {
                      try {
                        await form.validateFields()

                        const values = form.getFieldsValue()

                        if (values.domains.length > 1) {
                          values.domains.pop() // Remove not added domain.
                        }

                        tryPutAzureAd({
                          data: {
                            attributes: {
                              metadata_url: values.metadataUrl,
                              domains: values.domains,
                              group_id: values.group_id
                            }
                          }
                        })
                      } catch (e) {}
                    } else {
                      tryDeleteAzureAd()
                    }
                  }}
                >
                  Spara
                </Button>
              </Box>
            </Box>
          </Collapse>
        </CollapsibleSection>

        {/* ----- Dialogs ----- */}
        <DetailsDialog
          open={instructionDialogOpen}
          onClose={() => setInstructionDialogOpen(false)}
        />
      </SettingsTemplate>
    </>
  )
}

function mapStateToProps({
  AuthStore,
  NavigationMenuStore,
  StatusBannerStore
}) {
  return { AuthStore, NavigationMenuStore, StatusBannerStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AuthenticationActions }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Authentication))
