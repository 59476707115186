import React, { useEffect, useState } from 'react'

import { Popover, ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListSubheader from 'components_new/atoms/List/ListSubheader'
import MenuItem from 'components_new/atoms/Menu/MenuItem'

import DateRangePicker from 'components_new/molecules/DateRangePicker'

import {
  getMinDate,
  getMaxDate,
  getPeriodFilterOptions
} from 'components_new/organisms/DashboardUserFilter/utils'
import { getDatesOfPeriod } from '../utils'
import { CustomPeriodFilter, PeriodFilter } from 'types/GlobalWidget'
import { isCustomPeriodFilter, isPeriodFilterEnum } from 'utils/functions'

interface PeriodMenuProps {
  anchorEl: HTMLElement | null
  onClose: () => void
  onSubmit: (periodFilter: PeriodFilter | CustomPeriodFilter | null) => void
  periodFilter: PeriodFilter | CustomPeriodFilter | null
}

const PeriodMenu = (props: PeriodMenuProps) => {
  const { anchorEl, onClose, onSubmit, periodFilter } = props

  const open = Boolean(anchorEl)

  const isDefault = periodFilter === null
  const isPreset = !!periodFilter && isPeriodFilterEnum(periodFilter)
  const isCustom = !!periodFilter && isCustomPeriodFilter(periodFilter)

  const [dates, setDates] = useState<CustomPeriodFilter>(
    isCustom ? periodFilter : { from: null, to: null }
  )
  const [newPeriodFilter, setNewPeriodFilter] = useState<PeriodFilter | null>(
    isPreset ? periodFilter : null
  )
  const [newCustomPeriod, setNewCustomPeriod] = useState<boolean>(isCustom)

  useEffect(() => {
    setDefaultStates()
  }, [periodFilter])

  const setDefaultStates = () => {
    if (isDefault) {
      setDates({ from: null, to: null })
      setNewPeriodFilter(null)
      setNewCustomPeriod(false)
    }
    if (isCustom) {
      setDates(periodFilter)
      setNewPeriodFilter(null)
      setNewCustomPeriod(true)
    }
    if (isPreset) {
      setDates(getDatesOfPeriod(periodFilter))
      setNewPeriodFilter(periodFilter)
      setNewCustomPeriod(false)
    }
  }

  const getValidity = () => {
    if (newCustomPeriod) {
      if (!dates.from || !dates.to) {
        return false
      }
    }

    return true
  }

  return (
    <>
      <ThemeProvider theme={getTheme('light')}>
        <Popover
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            setDefaultStates()
            onClose()
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Box
              sx={{
                width: 244,
                height: 480,
                overflow: 'auto',
                borderRightStyle: 'solid',
                borderRightWidth: 1,
                borderRightColor: 'divider',
                '& .Mui-selected': {
                  color: 'primary.dark'
                },
                '& .Mui-selected > .MuiListItemIcon-root': {
                  color: 'primary.dark'
                }
              }}
            >
              <Box
                sx={{
                  pt: 2
                }}
              >
                <MenuItem
                  selected={!newCustomPeriod && !newPeriodFilter}
                  onClick={() => {
                    setDates({ from: null, to: null })
                    setNewPeriodFilter(null)
                    setNewCustomPeriod(false)
                  }}
                >
                  <ListItemIcon>
                    <Icon name="EventRepeatOutlined" />
                  </ListItemIcon>
                  Standard
                </MenuItem>
                <MenuItem
                  disabled={!newCustomPeriod}
                  selected={newCustomPeriod}
                  sx={{
                    pointerEvents: 'none'
                  }}
                >
                  <ListItemIcon>
                    <Icon name="EditCalendarOutlined" />
                  </ListItemIcon>
                  Anpassad
                </MenuItem>
              </Box>
              <ListSubheader
                component="div"
                disableSticky={false}
                sx={{
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 1,
                  borderBottomColor: 'divider',
                  mb: 1
                }}
              >
                Snabbval
              </ListSubheader>
              {getPeriodFilterOptions().map((option, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={option.value}
                    onClick={() => {
                      if (option.value) {
                        setDates(getDatesOfPeriod(option.value))
                        setNewPeriodFilter(option.value)
                        setNewCustomPeriod(false)
                      }
                    }}
                    selected={newPeriodFilter === option.value}
                  >
                    {option.label}
                  </MenuItem>
                )
              })}
            </Box>
            <Box sx={{ p: 2 }}>
              <DateRangePicker
                minDate={getMinDate()}
                maxDate={getMaxDate()}
                value={dates}
                onChange={(value) => {
                  const customPeriod = { from: value.from, to: value.to }

                  setDates(customPeriod)
                  setNewPeriodFilter(null)
                  setNewCustomPeriod(true)
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              borderTopStyle: 'solid',
              borderTopWidth: 1,
              borderTopColor: 'divider',
              display: 'flex',
              justifyContent: 'flex-end',
              p: 1
            }}
          >
            <Button
              variant="text"
              onClick={() => {
                setDefaultStates()
                onClose()
              }}
            >
              Avbryt
            </Button>
            <Button
              disabled={!getValidity()}
              onClick={() => {
                if (newCustomPeriod) {
                  onSubmit(dates)
                  onClose()
                } else {
                  onSubmit(newPeriodFilter)
                  onClose()
                }
              }}
            >
              Applicera
            </Button>
          </Box>
        </Popover>
      </ThemeProvider>
    </>
  )
}

export default PeriodMenu
