import React, { useEffect, useState, useMemo } from 'react'

import { StatusBannerBody, StatusBannerData } from 'utils/types'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon, { IconName } from 'components_new/atoms/Icon'
import MenuItem from 'components_new/atoms/Menu/MenuItem'

import Grid from 'components_new/atoms/Grid'

import SelectSearch, { Option } from 'components_new/molecules/SelectSearch'
import TextField from 'components_new/atoms/TextField'

import Banner from 'components_new/molecules/Banner'
import InputAdornment from 'components_new/atoms/InputAdornment'
import SectionHeader from 'components_new/molecules/SectionHeader'
import SimpleDialog from 'components_new/molecules/SimpleDialog'
import SwitchItem from 'components_new/molecules/SwitchItem'

interface StatusBannerTemplateProps {
  submit: (data: StatusBannerBody) => void
  customers: object
  data?: StatusBannerData
  onClose: () => void
  open: boolean
}

const INIT_STATE: StatusBannerBody = {
  customer_ids: [],
  description: '',
  icon: 'InfoOutlined',
  link: null,
  link_label: null,
  severity: 'info',
  show: true,
  show_public: false,
  title: ''
}

const StatusBannerTemplate = (props: StatusBannerTemplateProps) => {
  const { customers, data, onClose, open, submit } = props
  const [formData, setFormData] = useState<StatusBannerBody>(INIT_STATE)
  const isEdit = !!data

  useEffect(() => {
    if (data) {
      setFormData(data)
    } else {
      setFormData(INIT_STATE)
    }
  }, [data])

  const customerOptions = useMemo(
    () =>
      Object.values(customers).map((customer) => ({
        id: customer.id,
        title: customer.name
      })),
    [customers]
  )

  return (
    <SimpleDialog
      actions={
        <>
          <Button variant="text" onClick={() => onClose()}>
            Avbryt
          </Button>
          <Button onClick={() => submit(formData)}>
            {isEdit ? 'Spara' : 'Publicera'}
          </Button>
        </>
      }
      onClose={onClose}
      open={open}
      title={isEdit ? 'Uppdatera banner' : 'Skapa banner'}
      maxWidth="lg"
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <SectionHeader title="Innehåll" />
            <Box sx={{ pb: 2 }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <TextField
                  label="Ikon"
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      icon: event.target.value as IconName
                    })
                  }
                  select={true}
                  size="small"
                  sx={{ width: 72 }}
                  value={formData.icon}
                >
                  <MenuItem value="InfoOutlined">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Icon name={'InfoOutlined'} fontSize="small" />
                    </Box>
                  </MenuItem>
                  <MenuItem value="WarningAmberOutlined">
                    <Icon name={'WarningAmberOutlined'} fontSize="small" />{' '}
                  </MenuItem>
                </TextField>

                <TextField
                  label="Titel"
                  onChange={(event) =>
                    setFormData({ ...formData, title: event.target.value })
                  }
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {formData.title.length}/25
                      </InputAdornment>
                    ),
                    inputProps: {
                      maxLength: 25
                    }
                  }}
                  value={formData.title}
                  required
                />
              </Box>

              <TextField
                label="Beskrivning"
                multiline={true}
                minRows={2}
                maxRows={3}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    description: event.target.value
                  })
                }
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {formData.description.length}/100
                    </InputAdornment>
                  ),
                  inputProps: {
                    maxLength: 100
                  }
                }}
                value={formData.description}
                required
              />
            </Box>

            <SectionHeader title="Länk" />
            <Box sx={{ pb: 2 }}>
              <TextField
                label="URL"
                onChange={(event) =>
                  setFormData({ ...formData, link: event.target.value })
                }
                size="small"
                value={formData.link ?? ''}
              />
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {formData.link_label?.length ?? 0}/25
                    </InputAdornment>
                  ),
                  inputProps: {
                    maxLength: 25
                  }
                }}
                label="Text"
                onChange={(event) =>
                  setFormData({ ...formData, link_label: event.target.value })
                }
                size="small"
                value={formData.link_label ?? ''}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <SectionHeader title="Kunder" />
            <Box sx={{ pb: 2 }}>
              <SelectSearch
                helperText={
                  <>
                    Om ingen kund är vald kommer <b>alla</b> att se bannern.
                  </>
                }
                onChange={(options: Option[]) =>
                  setFormData({
                    ...formData,
                    customer_ids: options.map((option) => option.id as string)
                  })
                }
                multiple
                selected={customerOptions.filter((option) =>
                  formData.customer_ids.includes(option.id)
                )}
                options={Object.values(customers).map((customer) => ({
                  id: customer.id,
                  title: customer.name
                }))}
              />
            </Box>

            <SectionHeader title="Synlighet" />
            <Box sx={{ pb: 2 }}>
              <Box>
                <SwitchItem
                  checked={formData.show}
                  label={'Visa i portal'}
                  onChange={() =>
                    setFormData({ ...formData, show: !formData.show })
                  }
                />
              </Box>
              <Box>
                <SwitchItem
                  checked={formData.show_public}
                  label={'Visa i inbäddningar'}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      show_public: !formData.show_public
                    })
                  }
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <SectionHeader title="Förhandsvisning" />
      {(formData.show || formData.show_public) && (
        <Banner
          body={formData.description}
          href={formData.link}
          iconName={formData.icon}
          linkLabel={formData.link_label}
          title={formData.title}
        />
      )}
    </SimpleDialog>
  )
}

export default StatusBannerTemplate
