import React, { ElementType, MouseEventHandler, ReactNode } from 'react'
import { Button as MUIButton, useTheme } from '@mui/material'
import { FillSpinner } from 'react-spinners-kit'
import { Color } from 'themes'

import LinkWrapper from '../LinkWrapper'

interface ButtonProps {
  children?: ReactNode
  color?:
    | Color
    | 'inherit'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'brand'
    | 'black'
    | 'white'
    | 'neutral'
  component?: ElementType<any>
  disabled?: boolean
  disableElevation?: boolean
  disableRipple?: boolean
  endIcon?: ReactNode
  fullHeight?: boolean
  fullWidth?: boolean
  href?: string
  loading?: boolean
  loadingTitle?: string
  newTab?: boolean
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseDown?: (() => void) | MouseEventHandler<HTMLElement>
  size?: 'small' | 'medium' | 'large' | 'gigantic'
  startIcon?: ReactNode
  sx?: object
  tabIndex?: number
  to?: string
  type?: 'button' | 'submit' | 'reset'
  variant?: 'contained' | 'outlined' | 'text'
}

/**
 * The Button component allow users to take
 * actions, and make choices, with a single tap.
 */

const Button = React.forwardRef((props: ButtonProps, ref: any) => {
  const {
    loading,
    fullHeight,
    startIcon,
    endIcon,
    disabled,
    href,
    loadingTitle = 'Laddar',
    newTab,
    size,
    sx,
    to,
    ...rest
  } = props

  const theme = useTheme()

  return (
    <LinkWrapper
      disabled={disabled}
      href={to ?? href ?? undefined}
      newTab={newTab}
    >
      <MUIButton
        sx={{
          height: fullHeight ? '100%' : null,
          ...(!props.variant || props.variant === 'contained'
            ? {
                bgcolor: `${props.color}.background`,
                '&:hover': {
                  bgcolor: `${props.color}.backgroundDark`
                }
              }
            : null),
          whiteSpace: 'nowrap',
          ...(size === 'gigantic' && {
            padding: '18px 32px',
            fontSize: theme.typography.h6.fontSize
          }),
          ...sx
        }}
        ref={ref}
        endIcon={loading ? null : endIcon}
        startIcon={
          loading ? (
            <FillSpinner
              size={14}
              color={theme.palette.action.focus}
              loading={props.loading}
            />
          ) : (
            startIcon
          )
        }
        disabled={loading ? loading : disabled}
        size={size === 'gigantic' ? undefined : size}
        {...rest}
      >
        {loading ? `${loadingTitle}...` : props.children}
      </MUIButton>
    </LinkWrapper>
  )
})

Button.defaultProps = {
  variant: 'contained'
}

Button.displayName = 'Button'
export default Button
