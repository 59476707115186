import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import * as WidgetActions from 'redux/actions/Widgets'

import { LayoutItem } from 'redux/reducers/Dashboards'
import { ApplicationState } from 'redux/Stores/types'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'

import OptionsMenu from 'components_new/molecules/OptionsMenu'

import NotificationSettings from './NotificationSettings'

import CopyToDashboardDialog from '../dialogs/CopyToDashboardDialog'
import DeleteDialog from '../dialogs/DeleteDialog'
import EmbeddedDialog from '../dialogs/EmbeddedDialog'
import InformationDialog from '../dialogs/InformationDialog'

import * as UserEvents from 'redux/api/internal/UserEvents'

import { Action } from 'types/GlobalUserEvents'
import {
  FormattedWidgetData,
  ParsedSegmentPath,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'
import { KpiTemplateRedux } from 'types/GlobalKpiTemplates'
import { ParsedDashboardFilterOptions } from 'types/GlobalDashboardFilter'
import { DETAILS_DISABLE_TEXT } from 'utils/constants'
import { getShowAsFilter } from 'components_new/organisms/Dashboard/utils'

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  KPIDashboardStore: state.KPIDashboardStore,
  CompanyGroupStore: state.CompanyGroupStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...WidgetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

const NON_DATA_WIDGET_TYPES = [
  WidgetType.TEXT,
  WidgetType.COMMENT,
  WidgetType.IMAGE
]

interface WidgetMenuProps {
  availableSpace: LayoutItem | null
  customSegmentBy: string | null
  dashboardFilterOptions: Map<string, ParsedDashboardFilterOptions>
  editMode: boolean
  exportWidgetData: () => void
  formattedData: FormattedWidgetData
  isHomepalOrAdmin: boolean
  kpiTemplates: KpiTemplateRedux
  loading: boolean
  embedded: boolean
  enableFullscreen?: boolean
  fullSize: boolean
  scaleFactor: number
  setEdit: () => void
  setFullSizeDialogOpen: (arg0: boolean) => void
  showMenu?: boolean
  showUnderlyingContent: (
    segmentPaths: ParsedSegmentPath[],
    initialKpiOptionId: string | null
  ) => void
  widget: WidgetObject
}

export type ComponentProps = ConnectedProps<typeof connector> & WidgetMenuProps

const WidgetMenu = (props: ComponentProps) => {
  const {
    availableSpace,
    CompanyGroupStore,
    customSegmentBy,
    dashboardFilterOptions,
    editMode,
    embedded,
    enableFullscreen,
    exportWidgetData,
    formattedData,
    fullSize,
    isHomepalOrAdmin,
    kpiTemplates,
    loading,
    scaleFactor,
    setEdit,
    setFullSizeDialogOpen,
    showMenu = true,
    showUnderlyingContent,
    tryCreateWidget,
    tryDeleteWidget,
    tryUpdateWidget,
    widget
  } = props

  const [informationDialogOpen, setInformationDialogOpen] =
    useState<boolean>(false)

  const [copyToDashboardDialogOpen, setCopyToDashboardDialogOpen] =
    useState<boolean>(false)

  const [anchorPosition, setAnchorPosition] = useState<{
    x: number
    y: number
  } | null>(null)
  const open = Boolean(anchorPosition)

  const handleClick: MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation()
    event.preventDefault()

    setAnchorPosition({ x: event.clientX, y: event.clientY })
  }

  const handleClose = () => {
    setAnchorPosition(null)
  }

  useEffect(() => {
    if (!showMenu) {
      handleClose()
    }
  }, [showMenu])

  const hideDetails = useMemo(() => {
    return !!widget.settings.kpi_options.every(
      (option) => !option.can_show_details
    )
  }, [widget.settings.kpi_options])

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [embeddedDialogOpen, setEmbeddedDialogOpen] = useState(false)

  const type = widget.settings.type.selected

  const widgetWithData = !NON_DATA_WIDGET_TYPES.includes(type)

  const ICON_BUTTON_SIZE = fullSize ? 32 : 26
  const ICON_SIZE = fullSize ? 24 : 20

  const isPublic = location.pathname.includes('public')

  const getDisabledTitle = () => {
    if (isPublic) {
      return 'Logga in i Homepal för att få tillgång till den här funktionaliteten'
    } else {
      return undefined
    }
  }

  return (
    <>
      {!embedded ||
      (enableFullscreen &&
        type !== WidgetType.TEXT &&
        type !== WidgetType.COMMENT) ? (
            <Box
              className="widget-menu"
              sx={{
                position: 'absolute',
                height: 'auto',
                width: 'auto',
                top: 0,
                right: 0,
                transition: 'all 0.5s ease',
                pt: 1.5 * scaleFactor,
                px: 1.5 * scaleFactor,
                pb: 0.75 * scaleFactor,
                zIndex: 3
              }}
            >
              {/*-- buttons --*/}
              <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                {type !== WidgetType.TEXT && type !== WidgetType.COMMENT ? (
                  <IconButton
                    color="disabled"
                    onClick={() => {
                      if (!fullSize) {
                        UserEvents.log({
                          action: Action.WIDGET_ENLARGED,
                          context: widget.settings.type.selected,
                          dashboard_id: widget.dashboard_id,
                          dashboard_group_id: null
                        })
                      }

                      setFullSizeDialogOpen(!fullSize)
                    }}
                    onMouseDown={(event) => event.stopPropagation()}
                    size="small"
                    sx={{
                      width: ICON_BUTTON_SIZE,
                      height: ICON_BUTTON_SIZE
                    }}
                  >
                    <Icon
                      name={!fullSize ? 'ZoomInOutlined' : 'ZoomOutOutlined'}
                      sx={{
                        fontSize: ICON_SIZE
                      }}
                    />
                  </IconButton>
                ) : null}
                {/* Fake feature for sales and review */}
                {!customSegmentBy ? (
                  <NotificationSettings
                    buttonSize={ICON_BUTTON_SIZE}
                    embedded={embedded}
                    iconSize={ICON_SIZE}
                    loading={loading}
                    widget={widget}
                  />
                ) : null}
                {!embedded &&
            !customSegmentBy &&
            editMode &&
            !loading &&
            type !== WidgetType.TEXT &&
            type !== WidgetType.COMMENT ? (
                      <IconButton
                        color="disabled"
                        onClick={() => setEdit()}
                        onMouseDown={(event) => event.stopPropagation()}
                        size="small"
                        sx={{
                          width: ICON_BUTTON_SIZE,
                          height: ICON_BUTTON_SIZE
                        }}
                      >
                        <Icon
                          fontSize="small"
                          name="Edit"
                          sx={{
                            fontSize: ICON_SIZE
                          }}
                        />
                      </IconButton>
                    ) : null}
                {embedded || (!widgetWithData && !editMode) ? null : (
                  <IconButton
                    color="disabled"
                    onClick={handleClick}
                    onMouseDown={(event) => event.stopPropagation()}
                    size="small"
                    sx={{
                      width: ICON_BUTTON_SIZE,
                      height: ICON_BUTTON_SIZE
                    }}
                  >
                    <Icon
                      fontSize="small"
                      name="MoreVertOutlined"
                      sx={{
                        fontSize: ICON_SIZE
                      }}
                    />
                  </IconButton>
                )}
              </Box>

              {/*-- background --*/}
              <Box
                sx={{
                  bgcolor: 'background.widget',
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  top: 0,
                  right: 0,
                  opacity: 0.9,
                  borderBottomLeftRadius: 8 * scaleFactor,
                  borderBottomRightRadius: customSegmentBy
                    ? 8 * scaleFactor
                    : undefined,
                  zIndex: -1
                }}
              />
            </Box>
          ) : null}

      {/*-- menu --*/}
      <ThemeProvider theme={getTheme('light')}>
        <OptionsMenu
          anchorReference="anchorPosition"
          anchorPosition={
            anchorPosition
              ? { top: anchorPosition?.y, left: anchorPosition?.x }
              : undefined
          }
          disableScrollLock={true}
          onClose={handleClose}
          open={open && showMenu}
          options={[
            // show info
            ...(widgetWithData
              ? [
                  {
                    disabled: isPublic,
                    iconName: 'InfoOutlined' as const,
                    infoTip: getDisabledTitle(),
                    onClick: () => {
                      setInformationDialogOpen(true)

                      UserEvents.log({
                        action: Action.KPI_INFORMATION_SHOWNED,
                        context: widget.settings.type.selected,
                        dashboard_id: widget.dashboard_id,
                        dashboard_group_id: null
                      })
                    },
                    title: 'Visa information'
                  }
                ]
              : []),
            // show details
            ...(widgetWithData
              ? [
                  {
                    disabled: isPublic || hideDetails,
                    divider:
                      editMode ||
                      (type !== WidgetType.KEY_FIGURE &&
                        type !== WidgetType.FUNNEL),
                    iconName: 'TableChartOutlined' as const,
                    infoTip: hideDetails
                      ? DETAILS_DISABLE_TEXT
                      : getDisabledTitle(),
                    onClick: () => {
                      showUnderlyingContent([], null)
                    },
                    title: 'Visa innehåll'
                  }
                ]
              : []),
            // export to excel
            ...(widgetWithData &&
            type !== WidgetType.KEY_FIGURE &&
            type !== WidgetType.FUNNEL &&
            !loading
              ? [
                  {
                    disabled: isPublic,
                    iconName: 'Download' as const,
                    infoTip: getDisabledTitle(),
                    onClick: () => {
                      exportWidgetData()
                    },
                    title: 'Exportera till Excel'
                  }
                ]
              : []),
            // embed
            ...(editMode && widgetWithData && isHomepalOrAdmin
              ? [
                  {
                    divider: true,
                    iconBadge: widget.settings.embeddable,
                    iconName: 'CodeOutlined' as const,
                    onClick: () => {
                      setEmbeddedDialogOpen(true)
                    },
                    title: 'Bädda in'
                  }
                ]
              : []),
            // duplicate
            ...(editMode
              ? [
                  {
                    disabled: !availableSpace,
                    iconName: 'ContentCopyOutlined' as const,
                    onClick: () => {
                      if (availableSpace) {
                        tryCreateWidget({
                          data: {
                            dashboard_id: widget.dashboard_id,
                            kpi_id: null,
                            widget_id: widget.id,
                            width: availableSpace.w,
                            height:
                              type === WidgetType.TEXT ? 1 : availableSpace.h,
                            x: availableSpace.x,
                            y: availableSpace.y
                          }
                        })
                      }
                    },
                    title: 'Duplicera',
                    tooltip: availableSpace
                      ? undefined
                      : 'Din dashboard är full.'
                  }
                ]
              : []),
            // copy
            ...(editMode
              ? [
                  {
                    divider: true,
                    iconName: 'DriveFileMoveOutlined' as const,
                    onClick: () => {
                      setCopyToDashboardDialogOpen(true)
                    },
                    title: 'Kopiera till'
                  }
                ]
              : []),
            // delete
            ...(editMode
              ? [
                  {
                    iconName: 'DeleteOutlined' as const,
                    onClick: () => {
                      setDeleteDialogOpen(true)
                    },
                    title: 'Radera'
                  }
                ]
              : [])
          ]}
        />
      </ThemeProvider>

      {/*-- dialogs --*/}
      <ThemeProvider theme={getTheme('light')}>
        <DeleteDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          title={widget.title}
          handleDelete={() => {
            tryDeleteWidget(widget.id, widget.dashboard_id)

            setDeleteDialogOpen(false)
          }}
        />

        <EmbeddedDialog
          dashboardFilterOptions={dashboardFilterOptions}
          open={embeddedDialogOpen}
          onClose={() => setEmbeddedDialogOpen(false)}
          onChange={(embeddable) =>
            tryUpdateWidget(
              widget.id,
              { data: { embeddable } },
              CompanyGroupStore.showAs
                ? { filter: [getShowAsFilter(CompanyGroupStore.showAs)] }
                : null
            )
          }
          loading={loading}
          widget={widget}
        />

        <InformationDialog
          kpiTemplates={kpiTemplates}
          onClose={() => setInformationDialogOpen(false)}
          open={informationDialogOpen}
          widget={widget}
        />

        <CopyToDashboardDialog
          formattedData={formattedData}
          onClose={() => setCopyToDashboardDialogOpen(false)}
          open={copyToDashboardDialogOpen}
          widget={widget}
        />
      </ThemeProvider>
    </>
  )
}

export default connector(WidgetMenu)
