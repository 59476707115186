import React, { ReactElement } from 'react'

import { Color } from 'themes'

import { Severity } from 'utils/types'
import { TEmbedAccess, TEmbedComponent } from './types'

import { IconName } from 'components_new/atoms/Icon'

export const REmbedComponent: Record<
  TEmbedComponent,
  {
    title: string
  }
> = {
  ['tab']: {
    title: 'Flik'
  },
  ['widget']: {
    title: 'Widget'
  }
}

export const REmbedAccess: Record<
  TEmbedAccess,
  {
    body: ReactElement | string
    color: Color | Severity
    iconName: IconName
    title: string
  }
> = {
  ['api-access']: {
    body: (
      <>
        Aktörer med en API-nyckel kan generera en skyddad och tillfällig länk
        som ger åtkomst till fliken i upp till en timme.
      </>
    ),
    color: 'secondary',
    iconName: 'VpnLock',
    title: 'Skyddad länk'
  },
  ['public']: {
    body: (
      <>
        Alla som har tillgång till den publika länken kan se fliken utan att
        vara inloggad.
      </>
    ),
    color: 'success',
    iconName: 'PublicOutlined',
    title: 'Publik länk'
  }
}
