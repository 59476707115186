import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import * as UserEvents from 'redux/api/internal/UserEvents'

import * as DashboardGroupActions from 'redux/actions/DashboardGroups'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { ApplicationState } from 'redux/Stores/types'

import { DashboardGroup, DashboardStatus } from 'redux/reducers/Dashboards'
import { RichText } from 'components_new/molecules/RichTextEditor/types'

import Button from 'components_new/atoms/Button'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'
import Tooltip from 'components_new/atoms/Tooltip'
import TooltipWrapper from 'components_new/atoms/TooltipWrapper'

import FormSection from 'components_new/molecules/FormSection'
import RichTextEditor from 'components_new/molecules/RichTextEditor'
import SelectSearch, { Option } from 'components_new/molecules/SelectSearch'
import SimpleDialog from 'components_new/molecules/SimpleDialog'
import StatusBox from 'components_new/molecules/StatusBox'
import UserListItem from 'components_new/molecules/UserListItem'

import { dict, getIntroText, INFO_TEXT } from './lib'

import { formatUserOption } from 'utils/functions'
import { getFolderChip, isValidTitle } from './utils'
import { Action } from 'types/GlobalUserEvents'
import { AccountRole } from 'types/GlobalUser'
import { useDashboardFolders } from 'redux/hooks/DashboardFolders'

export type TVariant = 'edit' | 'info' | 'publish' | 'welcome'

interface AboutDashboardDialogProps {
  dashboardGroup?: DashboardGroup // when used outside of dashboard
  onClose: () => void
  open: boolean
  variant: TVariant
}

const AboutDashboardDialog = (props: ComponentProps) => {
  const {
    // component
    dashboardGroup,
    onClose,
    open,
    // redux
    AccountStore,
    AuthStore,
    tryUpdateDashboardGroup
  } = props

  const user = AuthStore.user
  const ownerId = dashboardGroup?.owner
  const isOwner = ownerId === user?.id
  const isAdmin = user?.role === AccountRole.ADMIN
  const isHomepal = Boolean(user?.is_homepal_user)
  const accounts = AccountStore.data
  const folders = useDashboardFolders()

  const [variant, setVariant] = useState<TVariant>(props.variant)

  const isEdit = variant === 'edit'
  const isInfo = variant === 'info'
  const isPublish = variant === 'publish'
  const isWelcome = variant === 'welcome'

  const showEdit = (isOwner || isAdmin || isHomepal) && (isWelcome || isInfo)
  const showImportance = isEdit || isPublish

  // loading
  if (!dashboardGroup || !ownerId) {
    return null
  }

  const [description, setDescription] = useState<RichText | null>(
    dashboardGroup.description
  )
  const [owner, setOwner] = useState<Option | null>(
    accounts[ownerId] ? formatUserOption(accounts[ownerId]) : null
  )
  const [title, setTitle] = useState<string | null>(dashboardGroup.title)

  const [folder, setFolder] = useState<string | null>(dashboardGroup.folder_id)

  // On open
  useEffect(() => {
    if (open) {
      handleClear(true, false)
    }
  }, [open])

  const handleClear = (resetFields: boolean, resetVariant: boolean) => {
    if (resetFields) {
      setDescription(dashboardGroup.description)
      setOwner(accounts[ownerId] ? formatUserOption(accounts[ownerId]) : null)
      setTitle(dashboardGroup.title)
      setFolder(dashboardGroup.folder_id)
    }

    if (resetVariant) {
      setVariant(props.variant)
    }
  }

  const handleClose = () => {
    if (isEdit && props.variant === 'info') {
      setVariant(props.variant)
    } else {
      onClose()
    }

    // clear after animation
    setTimeout(() => handleClear(true, true), 300)
  }

  const handleSubmit = () => {
    if (isValidTitle(title) && owner) {
      tryUpdateDashboardGroup(
        dashboardGroup.id,
        {
          data: {
            status: isPublish ? DashboardStatus.PUBLISHED : undefined,
            description,
            title: title as string,
            owner: owner.id as string,
            folder_id: folder
          }
        },
        (e) => {
          if (!e) {
            if (props.variant === 'publish') {
              UserEvents.log({
                action: Action.DASHBOARD_PUBLISHED,
                context: null,
                dashboard_id: null,
                dashboard_group_id: dashboardGroup.id
              })
              onClose()
            }

            if (props.variant === 'edit') {
              onClose()
            }

            setTimeout(() => handleClear(false, true), 300)
          }
        }
      )
    }
  }

  const userOptions: Option[] = useMemo(() => {
    return Object.values(accounts).map((account) => formatUserOption(account))
  }, [accounts])

  return (
    <ThemeProvider theme={getTheme('light')}>
      <SimpleDialog
        actions={
          isEdit || isPublish ? (
            <>
              <Button variant="text" onClick={handleClose}>
                {dict[variant].closeLabel}
              </Button>
              <Button
                disabled={!isValidTitle(title)}
                onClick={handleSubmit}
                variant="contained"
              >
                {dict[variant].submitLabel}
              </Button>
            </>
          ) : (
            <Button
              variant={isInfo ? 'text' : 'contained'}
              onClick={handleClose}
            >
              {dict[variant].closeLabel}
            </Button>
          )
        }
        disableRestoreFocus={true}
        dividers={isEdit || isPublish}
        hero={
          isWelcome
            ? {
                body: getIntroText(dashboardGroup),
                title: `Välkommen hit, ${user?.first_name} 👋`
              }
            : undefined
        }
        onClose={handleClose}
        open={open}
        title={dict[variant].dialogTitle}
      >
        {isEdit || isPublish ? (
          <StatusBox severity="info" sx={{ mb: 2 }}>
            {INFO_TEXT}
          </StatusBox>
        ) : null}
        {isEdit || isPublish ? (
          <FormSection
            helperText={dict[variant].titleHelperText}
            importance={showImportance ? 'required' : undefined}
            onEdit={
              showEdit
                ? () => {
                    setVariant('edit')
                  }
                : undefined
            }
            title={dict[variant].titleTitle}
          >
            <TextField
              autoComplete="off"
              autoFocus={isEdit || isPublish}
              error={!isValidTitle(title)}
              fullWidth
              helperText={
                !isValidTitle(title) ? dict[variant].titleErrorText : undefined
              }
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                setTitle(event.target.value)
              }}
              placeholder="Ex. Översikt av hyresbortfall"
              required={true}
              value={title}
            />
          </FormSection>
        ) : null}

        <FormSection
          helperText={dict[variant].descriptionHelperText}
          importance={showImportance ? 'recommended' : undefined}
          onEdit={
            showEdit
              ? () => {
                  setVariant('edit')
                }
              : undefined
          }
          title={dict[variant].descriptionTitle}
        >
          {(isInfo || isWelcome) && !description ? (
            <Text color="text.disabled">
              {dict[variant].descriptionEmptyText}
            </Text>
          ) : (
            <RichTextEditor
              edit={isEdit || isPublish}
              noGutter={true}
              onChange={(value) => setDescription(value)}
              placeholder={dict[variant].descriptionPlaceholder}
              value={description}
              sx={!isEdit ? { mt: 2 } : undefined}
            />
          )}
        </FormSection>

        {!isWelcome ? (
          <FormSection
            helperText={dict[variant].folderHelperText}
            importance={showImportance ? 'recommended' : undefined}
            onEdit={
              showEdit
                ? () => {
                    setVariant('edit')
                  }
                : undefined
            }
            title={dict[variant].folderTitle}
          >
            {isEdit || isPublish ? (
              <Tooltip title={dict[variant].folderDisabledText}>
                <TextField
                  fullWidth={true}
                  label="Välj mapp..."
                  select={true}
                  autoComplete="off"
                  value={folder}
                  onChange={(event) => setFolder(event.target.value)}
                  sx={{ mt: 1, mb: 2 }}
                  disabled={
                    !isPublish &&
                    dashboardGroup.status === DashboardStatus.DRAFT
                  }
                >
                  <MenuItem value={null}>
                    <em>Ingen</em>
                  </MenuItem>
                  {folders.map((opt) => (
                    <MenuItem key={opt.id} value={opt.id}>
                      {opt.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            ) : (
              getFolderChip(
                folders.find((opt) => opt.id === folder) || null,
                dashboardGroup.status
              )
            )}
          </FormSection>
        ) : null}

        <FormSection
          helperText={dict[variant].ownerHelperText}
          importance={showImportance ? 'required' : undefined}
          onEdit={
            showEdit
              ? () => {
                  setVariant('edit')
                }
              : undefined
          }
          title={dict[variant].ownerTitle}
        >
          {isEdit || isPublish ? (
            <TooltipWrapper title={dict[variant].ownerDisabledText}>
              <SelectSearch
                color="primary"
                disableClearable={true}
                disabled={
                  !isPublish && dashboardGroup.status === DashboardStatus.DRAFT
                }
                onChange={(option: Option) => {
                  setOwner(option)
                }}
                multiple={false}
                options={userOptions}
                selected={owner}
                size={'medium'}
                sort={true}
                variant="select"
              />
            </TooltipWrapper>
          ) : (
            <UserListItem
              title={owner?.title ?? 'Ingen ägare'}
              body={owner?.body}
              type={owner?.title ? undefined : 'role'}
            />
          )}
        </FormSection>
      </SimpleDialog>
    </ThemeProvider>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  AuthStore: state.AuthStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...DashboardGroupActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  AboutDashboardDialogProps

export default connector(AboutDashboardDialog)
