import React from 'react'
import { Editor, NodeEntry, Element as SlateElement, Transforms } from 'slate'

import {
  CustomAlignTypes,
  CustomBlockType,
  CustomEditor,
  CustomElement,
  CustomElementTypes,
  CustomMarks,
  RichText
} from './types'

import { LIST_TYPES, TEXT_ALIGN_TYPES } from './lib'
import { UserAgent } from 'types/GlobalUser'

export const isBlockOnly = (
  editor: CustomEditor,
  format: CustomElement['type'] | CustomAlignTypes,
  blockType: CustomBlockType
) => {
  const { selection } = editor

  if (!selection) return false

  const isActive = isBlockActive(editor, format, blockType)

  if (!isActive) return false

  const match = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => SlateElement.isElement(n) && n.type === format
    })
  )

  if (match.length === 0) return false

  const [, path] = match[0] as NodeEntry<SlateElement>
  const [parentNode] = Editor.parent(editor, path)

  if (parentNode) {
    return (
      SlateElement.isElement(parentNode) && parentNode.children.length === 1
    )
  }

  return false
}

export const isBlockEmpty = (
  editor: CustomEditor,
  format: CustomElement['type'] | CustomAlignTypes,
  blockType: CustomBlockType
) => {
  const { selection } = editor

  if (!selection) return false

  const isActive = isBlockActive(editor, format, blockType)

  if (!isActive) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => {
        if (blockType in n) {
          return Editor.isEmpty(editor, n)
        }

        return false
      }
    })
  )

  return !!match
}

export const isBlockActive = (
  editor: CustomEditor,
  format: CustomElement['type'] | CustomAlignTypes,
  blockType: CustomBlockType
) => {
  const { selection } = editor

  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => {
        if (blockType in n) {
          return (
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n[blockType as keyof CustomElement] === format
          )
        }

        return false
      }
    })
  )

  return !!match
}

export const toggleBlock = (
  editor: CustomEditor,
  format: CustomElementTypes
) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true
  })

  let newProperties: Partial<SlateElement>

  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format
    }
  } else {
    newProperties = {
      type: isActive
        ? 'paragraph'
        : isList
          ? 'list-item'
          : (format as CustomElement['type'])
    }
  }

  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block: SlateElement = {
      type: format as CustomElement['type'],
      children: []
    }

    Transforms.wrapNodes(editor, block)
  }
}

export const isMarkActive = (editor: CustomEditor, format: CustomMarks) => {
  const marks = Editor.marks(editor)

  return marks ? marks[format] === true : false
}

export const toggleMark = (editor: CustomEditor, format: CustomMarks) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const handleKeyDown = (
  editor: CustomEditor,
  event: React.KeyboardEvent<HTMLDivElement>,
  userAgent: UserAgent
) => {
  // ⌘ + [key] or Ctrl + [key]
  if (
    (userAgent === 'mac' && event.metaKey) ||
    (userAgent === 'windows' && event.ctrlKey)
  ) {
    switch (event.key) {
    case 'b': {
      event.preventDefault()
      toggleMark(editor, 'bold')
      break
    }
    case 'i': {
      event.preventDefault()
      toggleMark(editor, 'italic')
      break
    }
    case 'u': {
      event.preventDefault()
      toggleMark(editor, 'underline')
      break
    }
    case 'z': {
      event.preventDefault()
      console.log('undo') // @TODO: remove
      editor.undo()
      break
    }
    }
  }

  // Enter
  if (event.key === 'Enter') {
    // empty list item
    if (isBlockEmpty(editor, 'list-item', 'type')) {
      event.preventDefault()

      Transforms.setNodes(editor, { type: 'paragraph' })

      Transforms.unwrapNodes(editor, {
        match: (n) =>
          SlateElement.isElement(n) &&
          (n.type === 'bulleted-list' || n.type === 'numbered-list'),
        split: true
      })
    }
  }

  // Backspace
  if (event.key === 'Backspace') {
    // empty and only list item
    if (
      isBlockEmpty(editor, 'list-item', 'type') &&
      isBlockOnly(editor, 'list-item', 'type')
    ) {
      event.preventDefault()

      Transforms.setNodes(editor, { type: 'paragraph' })

      Transforms.unwrapNodes(editor, {
        match: (n) =>
          SlateElement.isElement(n) &&
          (n.type === 'bulleted-list' || n.type === 'numbered-list'),
        split: true
      })
    }
  }
}

export const isEditorEmpty = (value: RichText): boolean => {
  return (
    value.length === 1 &&
    value[0].type === 'paragraph' &&
    value[0].children.length === 1 &&
    value[0].children[0].text === ''
  )
}
