import React, { ReactNode, useState } from 'react'

import { SxProps } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Text from 'components_new/atoms/Text'

import { dict } from './lib'

interface StatusBoxProps {
  children: ReactNode
  severity: 'info'
  sx?: SxProps
}

const StatusBox = (props: StatusBoxProps) => {
  const { children, severity, sx } = props

  const [open, setOpen] = useState<boolean>(true)

  return (
    <Box
      sx={{
        bgcolor: `${severity}.background`,
        borderRadius: 1,
        display: open ? 'flex' : 'none',
        gap: 1,
        px: 1,
        py: 2,
        ...sx
      }}
    >
      <Icon name={dict[severity].iconName} sx={{ color: `${severity}.text` }} />
      <Text component="div" variant="body1">
        {children}
      </Text>
      <Box>
        <IconButton
          onClick={() => setOpen(false)}
          size="small"
          sx={{ mt: -0.75 }}
        >
          <Icon fontSize="small" name="Close" />
        </IconButton>
      </Box>
    </Box>
  )
}

export default StatusBox
