import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTheme } from '@mui/material'

import { cloneDeep } from 'lodash'

import Autocomplete from 'components_new/atoms/Autocomplete'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Checkbox from 'components_new/atoms/Checkbox'
import Chip from 'components_new/atoms/Chip'
import Collapse from 'components_new/atoms/Collapse'
import FormControl from 'components_new/atoms/FormControl'
import InputLabel from 'components_new/atoms/InputLabel'
import ListItem from 'components_new/atoms/List/ListItem'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import OptionMenuItem from 'components_new/organisms/Widget/WidgetMenu/OptionMenuItem'
import Select from 'components_new/atoms/Select'
import Stack from 'components_new/atoms/Stack'
import TextField from 'components_new/atoms/TextField'
import Icon from 'components_new/atoms/Icon'

import { AttributeOption, DataType } from 'types/GlobalAttributeOptions'
import {
  Condition,
  FilterConditionFormatBody,
  FilterPatchBody,
  FilterType
} from 'types/GlobalKpiOption'
import { FilterOption } from 'utils/types'
import { translateFilter, translateFilterCondition } from 'utils/enumTranslator'
import EmptyPlaceholder from 'components_new/molecules/EmptyPlaceholder'
import { KpiTemplateFilterOptionsBody } from 'types/GlobalKpiTemplates'
import { sortAlphabeticalAsc } from 'utils/sortHelper'
import {
  formatUpdateFilterBody,
  parseFilterLabelValue,
  parseSingleFilterLabel
} from './utils'

interface NativeFilterGroupSectionProps {
  filterGroupId?: string | null
  filterAttributeOptions: AttributeOption[]
  filterOptions: KpiTemplateFilterOptionsBody | null
  filters: FilterConditionFormatBody[]
  updateFilters: (filters: FilterPatchBody[]) => void
}

/** Information:
 * This file is copied and pasted from our native filter group section.
 * It should be re-written och merged with 'NativeFilterSection'.
 */

const NativeFilterGroupSection = (props: NativeFilterGroupSectionProps) => {
  const {
    filterGroupId,
    filterAttributeOptions,
    filterOptions,
    filters,
    updateFilters
  } = props
  const theme = useTheme()

  const filterValueOptions = getFilterValueOptions(
    filterAttributeOptions,
    filterOptions
  )
  const filterConditionOptions = filters[0].condition.options

  const [attributeOption, setAttributeOption] =
    useState<AttributeOption | null>(null)
  const [condition, setCondition] = useState<Condition | null>(null)

  // Value state-handlers
  const [selectedValueOptions, setSelectedValueOptions] = useState<string[]>([])
  const [numericValue, setNumericValue] = useState<number | null>(null)

  const [selectedFilterOptionValue, setSelectedFilterOptionValue] =
    useState<FilterOption | null>(null)
  const [fromValue, setFromValue] = useState<string | null>(null)
  const [toValue, setToValue] = useState<string | null>(null)

  // The filter to pick values from, i.e. attribute_option and condition is set
  const [activeFilter, setActiveFilter] =
    useState<FilterConditionFormatBody | null>(null)

  useEffect(() => {
    if (attributeOption && condition && fromValue && toValue) {
      const tmpActiveFilter =
        filters.find(
          (filter) =>
            filter.attribute_option.selected === attributeOption.id &&
            filter.condition.selected === condition &&
            filter.from_value?.selected === fromValue &&
            filter.to_value?.selected === toValue
        ) || null

      if (tmpActiveFilter) {
        setNumericValue(tmpActiveFilter.value.selected as number)
        setSelectedValueOptions([])
      } else {
        setNumericValue(null)
      }

      setActiveFilter(tmpActiveFilter)
    } else if (
      attributeOption &&
      condition &&
      !isNumericFilter(attributeOption.type)
    ) {
      const tmpActiveFilter =
        filters.find(
          (filter) =>
            filter.attribute_option.selected === attributeOption.id &&
            filter.condition.selected === condition &&
            (!filter.filter_group_id ||
              (filterGroupId && filter.filter_group_id === filterGroupId))
        ) || null

      if (tmpActiveFilter) {
        setNumericValue(null)
      }

      setActiveFilter(tmpActiveFilter)
    }
  }, [attributeOption, condition, fromValue, toValue])

  const onChangeAttributeOption = (attributeOptionId: string | null) => {
    if (!attributeOptionId) {
      setCondition(null)
      setSelectedValueOptions([])
      setNumericValue(null)
      setAttributeOption(null)
      setFromValue(null)
      setToValue(null)
      setSelectedFilterOptionValue(null)
    } else {
      setSelectedFilterOptionValue(filterValueOptions[attributeOptionId])
    }

    const option =
      filterAttributeOptions.find(
        (attributeOption) => attributeOption.id === attributeOptionId
      ) || null

    if (option && !isNumericFilter(option.type)) {
      setFromValue(null)
      setToValue(null)
    }

    if (condition && !validConditionOption(condition, option)) {
      setCondition(null)
    }

    setAttributeOption(option)
  }

  const filterGroups = useMemo(() => {
    const groups: {
      [groupId: string]: {
        filterGroupId?: string | null
        filters: FilterConditionFormatBody[]
      }
    } = {}

    filters
      .filter((filter) => filter.condition.selected)
      .forEach((filter, i) => {
        const group = filter.filter_group_id || i

        if (group in groups) {
          groups[group].filters.push(filter)
        } else {
          groups[group] = {
            filterGroupId: filter.filter_group_id,
            filters: [filter]
          }
        }
      })

    return Object.values(groups).sort((a, b) =>
      (a.filters[0].attribute_option.selected as string).localeCompare(
        b.filters[0].attribute_option.selected as string
      )
    )
  }, [filters])

  return (
    <Box sx={{ mb: 2 }}>
      <ListItem>
        <FormControl fullWidth size="small">
          <InputLabel id="left-attribute-label">Filtrera på</InputLabel>
          <Select
            labelId="left-attribute-label"
            label="Filtrera på"
            value={attributeOption ? attributeOption.id : ''}
          >
            <MenuItem value={''} onClick={() => onChangeAttributeOption(null)}>
              <em>Ingen</em>
            </MenuItem>
            {filterAttributeOptions.map((option) => (
              <OptionMenuItem
                value={option.id}
                disabled={option.disabled}
                name={option.name || 'Är tom'}
                key={option.id}
                onClick={() => onChangeAttributeOption(option.id)}
                selected={
                  attributeOption ? attributeOption.id === option.id : false
                }
              >
                {option.name}
              </OptionMenuItem>
            ))}
          </Select>
        </FormControl>
      </ListItem>
      <Collapse
        in={
          !!(
            selectedFilterOptionValue &&
            selectedFilterOptionValue.type === 'FROM_TO'
          )
        }
      >
        <ListItem>
          <Stack>
            <FormControl fullWidth size="small" sx={{ minWidth: 125 }}>
              <InputLabel id="from-value-label">Från</InputLabel>
              <Select
                labelId="from-value-label"
                label="Från"
                value={fromValue ?? ''}
                onChange={(event) => {
                  setFromValue(event.target.value)
                }}
              >
                <MenuItem value="">
                  <em>Ingen</em>
                </MenuItem>
                {selectedFilterOptionValue?.from_values?.map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                )) || []}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small" sx={{ minWidth: 125 }}>
              <InputLabel id="to-value-label">Till</InputLabel>
              <Select
                labelId="to-value-label"
                label="Till"
                value={toValue ?? ''}
                onChange={(event) => {
                  setToValue(event.target.value)
                }}
              >
                <MenuItem value="">
                  <em>Ingen</em>
                </MenuItem>
                {selectedFilterOptionValue?.to_values?.map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                )) || []}
              </Select>
            </FormControl>
          </Stack>
        </ListItem>
      </Collapse>
      <Collapse in={Boolean(attributeOption)}>
        <ListItem>
          <FormControl fullWidth size="small">
            <InputLabel id="condition-label">Välj villkor</InputLabel>
            <Select
              labelId="condition-label"
              label="Välj villkor"
              value={condition ?? ''}
              onChange={(event) => {
                if (
                  !event.target.value ||
                  onlyLeftCondition(event.target.value as Condition)
                ) {
                  setSelectedValueOptions([])
                  setNumericValue(null)
                }

                setCondition(event.target.value as Condition)
              }}
            >
              <MenuItem value="">
                <em>Ingen</em>
              </MenuItem>
              {filterConditionOptions
                .filter((option) =>
                  validConditionOption(option, attributeOption)
                )
                .map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {translateFilterCondition[option].title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </ListItem>
      </Collapse>
      <Collapse
        in={
          Boolean(attributeOption) &&
          Boolean(condition) &&
          !onlyLeftCondition(condition)
        }
      >
        <ListItem>
          <FormControl fullWidth size="small">
            <FilterValueInput
              filterValueOptions={filterValueOptions}
              activeFilter={activeFilter}
              selectedValueOptions={selectedValueOptions}
              setSelectedValueOptions={setSelectedValueOptions}
              numericValue={numericValue}
              setNumericValue={setNumericValue}
              attributeOption={attributeOption}
            />
          </FormControl>
        </ListItem>
      </Collapse>
      <Collapse in={Boolean(attributeOption) && Boolean(condition)}>
        <ListItem>
          <Button
            variant="contained"
            fullWidth={true}
            onClick={() => {
              if (
                attributeOption &&
                validFilter(
                  attributeOption.id,
                  condition,
                  selectedValueOptions.length === 0
                    ? numericValue
                    : selectedValueOptions
                )
              ) {
                let newFilters: FilterPatchBody[] = []

                if (onlyLeftCondition(condition)) {
                  newFilters = [
                    {
                      attribute_option_id: attributeOption.id,
                      condition: condition as Condition,
                      value: null,
                      type: FilterType.ONLY_LEFT
                    }
                  ]
                } else if (selectedValueOptions.length > 0) {
                  newFilters = selectedValueOptions.map((value) => ({
                    attribute_option_id: attributeOption.id,
                    condition: condition as Condition,
                    value: parseFilterLabelValue(value),
                    type: FilterType.INPUT_VALUE
                  }))
                } else if (
                  !!(numericValue || numericValue === 0) &&
                  activeFilter?.attribute_option.selected !==
                    attributeOption.id &&
                  fromValue &&
                  toValue
                ) {
                  newFilters = [
                    {
                      attribute_option_id: attributeOption.id,
                      condition: condition as Condition,
                      value: numericValue,
                      type: FilterType.FROM_TO,
                      from_value: fromValue as string,
                      to_value: toValue as string
                    }
                  ]
                }

                // If no filter it set, only set the new filter
                if (
                  filters.length === 1 &&
                  !validFilter(
                    filters[0].attribute_option.selected,
                    filters[0].condition.selected,
                    filters[0].value.selected
                  )
                ) {
                  updateFilters(newFilters)
                } else {
                  /**
                   * If there is a current filter, format saved body to
                   * a valid format for the filter update
                   */
                  updateFilters([
                    ...formatUpdateFilterBody(
                      filters,
                      attributeOption.id,
                      condition,
                      selectedValueOptions,
                      numericValue,
                      fromValue,
                      toValue
                    ),
                    ...newFilters
                  ])
                }

                setCondition(null)
                setSelectedValueOptions([])
                setNumericValue(null)
                setAttributeOption(null)
                setFromValue(null)
                setToValue(null)
                setActiveFilter(null)
                setSelectedFilterOptionValue(null)
              }
            }}
          >
            Lägg till
          </Button>
        </ListItem>
      </Collapse>

      {filterGroups.length === 0 ? (
        <ListItem>
          <EmptyPlaceholder
            background={true}
            sx={{ width: '100%' }}
            title="Det finns inga aktiva filter."
          />
        </ListItem>
      ) : (
        filterGroups.map((group, i) => {
          const groupFilters = group.filters
          const isGrouped = !!group.filterGroupId && groupFilters.length > 1

          const chips = groupFilters
            .sort((a, b) =>
              (a.attribute_option.selected as string).localeCompare(
                b.attribute_option.selected as string
              )
            )
            .map((filter, j) => {
              const filterAttribute = filterAttributeOptions.find(
                (option) => filter.attribute_option.selected === option.id
              )

              let value: string | number = ''

              if (
                filter.value.selected &&
                typeof filter.value.selected === 'object' &&
                filter.value.selected.length > 0
              ) {
                value = filter.value.selected.join(', ')
              } else if (
                filter.value.selected &&
                typeof filter.value.selected === 'number'
              ) {
                value = filter.value.selected
              }

              return (
                <>
                  <Chip
                    key={j}
                    label={translateFilter(
                      filterAttribute ? filterAttribute.name : '',
                      filter.condition.selected as Condition,
                      value as string,
                      filter.from_value?.selected,
                      filter.to_value?.selected
                    )}
                    size="small"
                    sx={{
                      width: 'fit-content',
                      justifyContent: 'flex-start',
                      my: 0.25
                    }}
                    onDelete={
                      isGrouped
                        ? undefined
                        : () => {
                            const newFilters = cloneDeep(filters).filter(
                              (f) =>
                                f.attribute_option.selected !==
                                  filter.attribute_option.selected ||
                                f.condition.selected !==
                                  filter.condition.selected ||
                                f.type.selected !== filter.type.selected ||
                                f.filter_group_id !== filter.filter_group_id
                            )

                            updateFilters(formatUpdateFilterBody(newFilters))
                          }
                    }
                  />
                </>
              )
            })

          return (
            <>
              <ListItem key={`filter-${i}`}>
                {isGrouped ? (
                  <Box
                    sx={{
                      border: '1px dashed black',
                      padding: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative',
                      width: '100%'
                    }}
                  >
                    <Box
                      onClick={() => {
                        const newFilters = cloneDeep(filters).filter(
                          (f) => f.filter_group_id !== group.filterGroupId
                        )

                        updateFilters(formatUpdateFilterBody(newFilters))
                      }}
                    >
                      <Icon
                        name={'Cancel'}
                        sx={{
                          position: 'absolute',
                          top: 4,
                          right: 4,
                          fontSize: 16,
                          color: theme.palette.primary.transparentDark,
                          cursor: 'pointer'
                        }}
                      />
                    </Box>
                    {chips}
                  </Box>
                ) : (
                  chips
                )}
              </ListItem>
            </>
          )
        })
      )}
    </Box>
  )
}

export const FilterValueInput: FC<{
  filterValueOptions: { [attribute_option_id: string]: FilterOption }
  activeFilter: FilterConditionFormatBody | null
  selectedValueOptions: string[] | number
  setSelectedValueOptions: (options: string[]) => void
  numericValue: number | null
  setNumericValue: (option: number) => void
  attributeOption: AttributeOption | null
}> = ({
  filterValueOptions,
  activeFilter,
  selectedValueOptions,
  setSelectedValueOptions,
  numericValue,
  setNumericValue,
  attributeOption
}) => {
  const sx = {
    minWidth: 200,
    '& .MuiFormLabel-root': {
      // avoid label under clear icon.
      maxWidth: 'calc(100% - 72px)'
    },
    '& .MuiFormControl-root': {
      margin: 0
    }
  }

  const options = cloneDeep(
    (attributeOption && filterValueOptions[attributeOption.id]?.values) || []
  ).sort(sortAlphabeticalAsc)

  return isNumericFilter(attributeOption?.type as DataType) ? (
    <TextField
      type="number"
      label="Värde"
      InputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*'
      }}
      onChange={(event) => setNumericValue(parseInt(event.target.value))}
      size="small"
      value={numericValue ?? ''}
      sx={{ ...sx, my: 0 }}
    />
  ) : (
    <Autocomplete
      multiple
      options={options
        .map(parseSingleFilterLabel)
        .filter((option) =>
          activeFilter &&
          activeFilter.value.selected &&
          typeof activeFilter.value.selected !== 'number' &&
          activeFilter.value.selected?.length > 0
            ? !activeFilter.value.selected?.includes(option)
            : true
        )}
      getOptionLabel={(opt) => opt}
      value={selectedValueOptions}
      onChange={(event, options) => {
        setSelectedValueOptions(options)
      }}
      isOptionEqualToValue={(option, value) => option === value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`Välj ${
            attributeOption && attributeOption?.name.toLowerCase()
          }`}
          placeholder="Sök..."
        />
      )}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option}>
          <Checkbox sx={{ mr: 1 }} checked={selected} />
          {option}
        </li>
      )}
      size={'small'}
      sx={sx}
    />
  )
}

const getFilterValueOptions = (
  filterAttributeOptions: AttributeOption[],
  filterOptions: KpiTemplateFilterOptionsBody | null
) => {
  const filterValueOptions: { [key: string]: FilterOption } = {}

  if (filterOptions) {
    filterAttributeOptions.forEach((option) => {
      const options = filterOptions[option.id]

      filterValueOptions[option.id] = options
    })
  }

  return filterValueOptions
}

const isNumericFilter = (type: DataType) =>
  [DataType.BIGINT, DataType.DOUBLE].includes(type)

const onlyLeftCondition = (condition: Condition | null) =>
  condition && [Condition.IS_NULL, Condition.IS_NOT_NULL].includes(condition)

const validConditionOption = (
  condition: Condition,
  attributeOption: AttributeOption | null
) => {
  if (attributeOption && isNumericFilter(attributeOption.type)) {
    return [Condition.LT, Condition.GT].includes(condition)
  } else if (attributeOption) {
    return [
      Condition.EQ,
      Condition.NE,
      Condition.IS_NULL,
      Condition.IS_NOT_NULL
    ].includes(condition)
  }

  return false
}

export const validFilter = (
  attributeOption: string | null,
  condition: Condition | null,
  values: number | (string | null)[] | null
) => {
  if (attributeOption && condition && onlyLeftCondition(condition)) {
    return true
  } else if (
    attributeOption &&
    condition &&
    typeof values === 'number' &&
    values !== null &&
    !isNaN(values)
  ) {
    return true
  } else if (
    attributeOption &&
    condition &&
    values &&
    (values as (string | null)[])?.length > 0
  ) {
    return true
  }

  return false
}

export default NativeFilterGroupSection
