import React, { useState } from 'react'

import Icon, { IconName } from 'components_new/atoms/Icon'
import ListItemButton from 'components_new/atoms/List/ListItemButton'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Collapse from 'components_new/atoms/Collapse'
import ListItem from 'components_new/atoms/List/ListItem'
import Tooltip from 'components_new/atoms/Tooltip'

export interface ILocationItem {
  allow: boolean // if item is allowed to be selected
  disabled?: boolean
  disabledHint?: string
  iconName?: IconName
  id: string
  name: string
  subItems?: ILocationItem[]
}

interface LocationItemProps {
  indent?: number
  item: ILocationItem
  onClick: (value: string) => void
  selected: string | null
  sx?: object
  tooltip?: string | null
}

const LocationItem = (props: LocationItemProps) => {
  const { indent = 0, item, onClick, selected, sx } = props
  const {
    allow,
    disabled = false,
    disabledHint,
    iconName,
    id,
    subItems,
    name
  } = item

  const [expanded, setExpanded] = useState(false)
  const active = id === selected

  return (
    <>
      <Tooltip title={disabled ? disabledHint : undefined}>
        <ListItem disablePadding sx={{ height: 32 }}>
          <ListItemButton
            dense={true}
            disabled={disabled}
            onClick={() => (allow ? onClick(id) : setExpanded(!expanded))}
            selected={active}
            sx={{
              height: 32,
              borderRadius: 16,
              '&.Mui-selected': {
                bgcolor: 'primary.background',
                color: 'primary.contrastText',
                '&:hover': {
                  bgcolor: 'primary.background',
                  color: 'primary.contrastText'
                }
              },
              pl: indent,
              py: 0.5,
              ...sx
            }}
          >
            {iconName ? (
              <ListItemIcon
                sx={{ minWidth: 36 }}
                onClick={() => (disabled ? {} : setExpanded(!expanded))}
              >
                {subItems && subItems.length > 0 ? (
                  <Icon
                    name={
                      expanded ? 'ArrowDropDownOutlined' : 'ArrowRightOutlined'
                    }
                    sx={{
                      color: active ? 'primary.contrastText' : undefined,
                      cursor: 'default',
                      position: 'absolute',
                      top: 6,
                      // TODO: check this
                      left: (indent - 2) * 8,
                      fontSize: 16
                    }}
                  />
                ) : null}

                <Icon
                  name={iconName}
                  sx={{ color: active ? 'primary.contrastText' : undefined }}
                />
              </ListItemIcon>
            ) : null}
            <ListItemText
              primaryTypographyProps={{
                color: active ? 'primary.contrastText' : undefined,
                variant: 'subtitle2'
              }}
            >
              {name}
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </Tooltip>
      {subItems && subItems.length > 0 ? (
        <Collapse in={expanded}>
          {subItems.map((item) => (
            <LocationItem
              item={item}
              key={item.id}
              onClick={onClick}
              selected={selected}
              indent={indent + 2}
            />
          ))}
        </Collapse>
      ) : null}
    </>
  )
}

export default LocationItem
