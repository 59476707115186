import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { UserEventBody } from 'types/GlobalUserEvents'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}measurements/events`

export function log(body: UserEventBody) {
  axios.post(URL, { data: body }, parseAuthenticationHeader()).catch(() => {})
}
