import React, { ReactNode, useMemo, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Paper from 'components_new/atoms/Paper'
import { default as AtomTable } from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'

import LoadingSection from 'components_new/molecules/LoadingSection'
import SelectSearch, { Option } from 'components_new/molecules/SelectSearch'

import {
  InternalCustomerRedux,
  License,
  translateLicense
} from 'types/GlobalCustomer'

import { getCustomerData } from './utils'

interface OurCustomersProps {
  customers: InternalCustomerRedux
}

interface TableProps {
  headers: {
    label: string
    align?: 'center' | 'left' | 'right'
  }[]
  loading?: boolean
  rows: {
    label: ReactNode
    isHeader?: boolean
    values: {
      value: ReactNode
      color?: string
      align?: 'center' | 'left' | 'right'
    }[]
  }[]
  sx?: object
}

const OurCustomers = (props: OurCustomersProps) => {
  const [customerFilter, setCustomerFilter] = useState<Option[]>([])
  const [licenseFilter, setLicenseFilter] = useState<Option[]>([])
  const [typeFilter, setTypeFilter] = useState<Option[]>([
    { id: 'false', title: 'Riktig' }
  ])

  const customers = useMemo(() => {
    const sortedCustomers = Object.values(props.customers).sort((a, b) =>
      a.name.localeCompare(b.name)
    )

    return sortedCustomers
  }, [props.customers])

  const customerData = useMemo(() => {
    return getCustomerData(customers, customerFilter, licenseFilter, typeFilter)
  }, [customers, customerFilter, licenseFilter, typeFilter])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 4,
          bgcolor: 'background.paper'
        }}
      >
        <SelectSearch
          label={'Kund'}
          onChange={(options: Option[]) => setCustomerFilter(options)}
          multiple={true}
          options={customers.map((customer) => ({
            id: customer.id,
            title: customer.name
          }))}
          selected={customerFilter}
          sort={true}
          sx={{ maxWidth: '40ch' }}
        />
        <SelectSearch
          label={'Licens'}
          onChange={(options: Option[]) => setLicenseFilter(options)}
          multiple={true}
          options={Object.values(License).map((license) => ({
            id: license,
            title: translateLicense[license].display
          }))}
          selected={licenseFilter}
          sort={true}
          sx={{ maxWidth: '40ch' }}
        />
        <SelectSearch
          label={'Typ av kund'}
          onChange={(options: Option[]) => setTypeFilter(options)}
          multiple={true}
          options={[
            { id: 'true', title: 'Demo' },
            { id: 'false', title: 'Riktig' }
          ]}
          selected={typeFilter}
          sort={true}
          sx={{ maxWidth: '40ch' }}
        />
      </Box>
      <Box sx={{ flex: '1 1 auto', overflowY: 'auto' }}>
        <Table rows={customerData.rows} headers={customerData.headers} />
      </Box>
    </Box>
  )
}

const Table = (props: TableProps) => {
  if (props.loading) {
    return (
      <Paper sx={{ mt: 2, width: '100%', height: 520 }}>
        <LoadingSection
          titles={[
            'Hämtar information om våra kunder',
            'Hämtar information om våra kunders nyckeltal'
          ]}
          loading
        />
      </Paper>
    )
  }

  return (
    <TableContainer
      component={Paper}
      sx={{ mt: 2, ...(props.sx ? props.sx : {}) }}
    >
      <AtomTable sx={{ minWidth: 650 }} aria-label="caption table" stickyHeader>
        <TableHead>
          <TableRow>
            {props.headers.map((header) => (
              <TableCell
                sx={{ fontWeight: 'bold' }}
                align={header.align}
                key={header.label}
              >
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, i) => (
            <TableRow
              key={i}
              sx={{ bgcolor: row.isHeader ? 'grey.200' : undefined }}
            >
              <TableCell scope="row">{row.label}</TableCell>
              {row.values.map((value, j) => (
                <TableCell
                  key={`${i}-${j}`}
                  align={value.align}
                  sx={{ color: value.color }}
                >
                  {value.value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </AtomTable>
    </TableContainer>
  )
}

export default OurCustomers
