import React, { ChangeEvent, useState } from 'react'

import Button from 'components_new/atoms/Button'
import TextField from 'components_new/atoms/TextField'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

import { ISavedDashboardFilter } from 'types/GlobalSavedDashboardFilter'

interface SaveUserFilterDialogProps {
  onClose: () => void
  onSave: (value: string) => void
  open: boolean
  savedDashboardFilters: ISavedDashboardFilter[]
}

const SaveUserFilterDialog = (props: SaveUserFilterDialogProps) => {
  const { onClose, onSave, open, savedDashboardFilters } = props

  const [title, setTitle] = useState<string>('')
  const [error, setError] = useState<boolean>(false)

  const handleOnClose = () => {
    setTitle('')
    setError(false)
    onClose()
  }

  return (
    <SimpleDialog
      title="Spara filter"
      open={open}
      onClose={handleOnClose}
      actions={
        <>
          <Button variant="text" onClick={handleOnClose}>
            Avbryt
          </Button>
          <Button
            disabled={!title || error}
            onClick={() => {
              const exists = savedDashboardFilters.find(
                (item) => item.title === title
              )

              if (exists) {
                setError(true)
              } else {
                onSave(title)
                handleOnClose()
              }
            }}
            variant="contained"
          >
            Spara
          </Button>
        </>
      }
    >
      <TextField
        fullWidth
        error={error}
        helperText={
          error ? 'Det finns redan ett sparat filter med det här namnet.' : null
        }
        label={'Namn'}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
          setTitle(event.target.value)

          if (error) {
            setError(false)
          }
        }}
        value={title}
      />
    </SimpleDialog>
  )
}

export default SaveUserFilterDialog
