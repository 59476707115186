import React, { useMemo, useState } from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import * as DashboardActions from 'redux/actions/Dashboards'
import { ApplicationState } from 'redux/Stores/types'
import { PlatformDashboard } from 'redux/reducers/Dashboards'
import { useDashboard } from 'redux/hooks/Dashboards'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Collapse from 'components_new/atoms/Collapse'
import Text from 'components_new/atoms/Text'

import DashboardTreeSelector from 'components_new/organisms/DashboardTreeSelector'
import LoadingSection from 'components_new/molecules/LoadingSection'
import SimpleDialog from 'components_new/molecules/SimpleDialog'
import CardMedia from 'components_new/atoms/Card/CardMedia'

import { getThumbnail } from 'utils/functions'
import { Context } from 'types/GlobalUserEvents'
import { addRef } from 'utils/queryHandler'

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  DashboardFolderStore: state.DashboardFolderStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...DashboardActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface CopyOrMoveDashboardDialogProps {
  dashboardId: string
  open: boolean
  onClose: () => void
  type: 'copy' | 'move'
}

type ComponentProps = ConnectedProps<typeof connector> &
  CopyOrMoveDashboardDialogProps

const getContentText = (type: 'copy' | 'move') => {
  if (type === 'copy') {
    return 'Välj den dashboard som du vill att din flik ska kopieras till.'
  }

  return (
    <>
      <Text fontSize="inherit" gutterBottom>
        Välj den dashboard som du vill att din flik ska flyttas till.
      </Text>
      <Text variant="body2" color="inherit">
        <b>Tänk på!</b> Om du flyttar fliken kommer den att försvinna från
        nuvarande dashboard. Även användarstatistik kommer att flyttas med till
        den nya dashboarden.
      </Text>
    </>
  )
}

const CopyOrMoveDashboardDialog = (props: ComponentProps) => {
  const {
    dashboardId,
    onClose,
    open,
    type,
    tryCreateDashboard,
    tryMoveDashboard
  } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [targetDashboardGroup, setTargetDashboardGroup] = useState<
    string | null
  >(null)
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const dashboard = useDashboard<PlatformDashboard>(params.id)

  const thumbnail = useMemo(
    () => getThumbnail(dashboard ? dashboard.thumbnail : null),
    [dashboard?.thumbnail]
  )

  const handleCloseDialog = () => {
    onClose()
    setLoading(false)
  }

  const handleCopy = () => {
    if (!targetDashboardGroup || !dashboard) {
      // no target dashboard group selected
      return
    }

    setLoading(true)

    if (type === 'copy') {
      tryCreateDashboard(
        {
          data: {
            dashboard_id: dashboardId,
            dashboard_group_id: targetDashboardGroup
          }
        },
        (createdId) => {
          handleCloseDialog()
          history.push(addRef(`/dashboards/${createdId}`, Context.DUPLICATE))
        }
      )
    } else {
      tryMoveDashboard(
        dashboardId,
        targetDashboardGroup,
        dashboard.dashboard_group_id,
        (err) => {
          if (err) {
            setLoading(false)
          } else {
            // successfully moved dashboard
            if (params.id !== dashboardId) {
              // if the dashboard is not the current dashboard, navigate to it
              history.push(addRef(`/dashboards/${dashboardId}`, Context.MOVE))
            }

            handleCloseDialog()
          }
        }
      )
    }
  }

  return (
    <SimpleDialog
      actions={
        loading ? null : (
          <>
            <Button
              disabled={loading}
              onClick={handleCloseDialog}
              variant="text"
            >
              Avbryt
            </Button>
            <Button disabled={loading || !dashboard} onClick={handleCopy}>
              {type === 'copy' ? 'Kopiera till' : 'Flytta till'}
            </Button>
          </>
        )
      }
      contentText={loading ? undefined : getContentText(type)}
      maxWidth="xs"
      onClose={handleCloseDialog}
      open={open}
      title={type === 'copy' ? 'Kopiera till' : 'Flytta till'}
    >
      <Box
        sx={{
          position: 'relative'
        }}
      >
        {loading ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
              bgcolor: 'rgba(255, 255, 255, 0.92)'
            }}
          >
            <LoadingSection
              loading={true}
              sx={{ py: 2 }}
              titles={[
                `${type === 'copy' ? 'Kopierar' : 'Flyttar'} till dashboard`
              ]}
            />
          </Box>
        ) : null}

        {/* preview of dashboard */}
        <CardMedia
          image={thumbnail}
          sx={{
            height: 240
          }}
        />

        <Collapse in={!loading}>
          <DashboardTreeSelector
            selected={targetDashboardGroup}
            setSelected={setTargetDashboardGroup}
            type="dashboard"
          />
        </Collapse>
      </Box>
    </SimpleDialog>
  )
}

export default connector(CopyOrMoveDashboardDialog)
