import React, { ReactNode } from 'react'

import Icon, { IconName } from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Link from 'components_new/atoms/Link'
import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

import { SxProps, ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

interface BannerProps {
  title: string
  body: ReactNode
  iconName?: IconName
  href?: string | null
  linkLabel?: string | null
  onClose?: () => void
  sx?: SxProps
}

const Banner = React.forwardRef((props: BannerProps, ref: any) => {
  const { title, body, iconName, href, linkLabel, onClose, sx } = props

  return (
    <ThemeProvider theme={getTheme('dark')}>
      <Box
        ref={ref}
        sx={{
          position: 'sticky',
          top: 0,
          left: 0,
          zIndex: 1100,
          bgcolor: 'background.paper',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'flex-start', md: 'center' },
          gap: 1,
          py: { xs: 2, md: 1 },
          px: 2,
          ...sx
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', md: 'center' },
            gap: 0.5
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1
            }}
          >
            <Icon color="primary" name={iconName ?? 'InfoOutlined'} />
            <Text color="text.primary" fontWeight="bold" variant="body1">
              {title}
            </Text>
          </Box>
          <Text color="text.primary" variant="body1">
            {body}
          </Text>
        </Box>
        {href ? (
          <Link
            href={href}
            newTab
            target="_blank"
            rel="noreferrer"
            underline="always"
            variant="button"
          >
            {linkLabel || href}
          </Link>
        ) : undefined}
        {onClose ? (
          <IconButton
            onClick={onClose}
            size="small"
            sx={{
              position: { xs: 'absolute', md: 'relative' },
              right: { xs: 8, md: 0 },
              top: { xs: 8, md: 0 }
            }}
          >
            <Icon fontSize="small" name="Close" />
          </IconButton>
        ) : null}
      </Box>
    </ThemeProvider>
  )
})

Banner.displayName = 'Banner'

export default Banner
