import React, { ReactNode } from 'react'

import Box from 'components_new/atoms/Box'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'

import Icon, { IconName } from 'components_new/atoms/Icon'
import { useTheme } from '@mui/material'

interface EmptyPlaceholderProps {
  actions?: ReactNode
  background?: boolean
  body?: string
  height?: number | string
  icon?: IconName
  scaleFactor?: number
  size?: 'small' | 'medium'
  src?: string
  sx?: object
  title?: string
}

/**
 * The EmptyPlaceholder component is used
 * to show a placholder text when there
 * is not any items to display.
 *
 * It expands, and fills up, all
 * available space.
 */

const EmptyPlaceholder = React.forwardRef(
  (props: EmptyPlaceholderProps, ref: any) => {
    const theme = useTheme()

    return (
      <Box
        ref={ref}
        sx={{
          height: props.height ?? '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: props.background ? theme.palette.action.hover : undefined,
          borderRadius: props.background
            ? `${theme.shape.borderRadius}px`
            : undefined,
          gap: 1,
          p: 2,
          fontSize: props.scaleFactor ? 16 * props.scaleFactor : undefined,
          ...props.sx
        }}
      >
        {props.icon ? (
          <Icon
            color="disabled"
            name={props.icon}
            sx={{ fontSize: props.scaleFactor ? props.scaleFactor * 40 : 40 }}
          />
        ) : null}

        {props.src ? (
          <Box
            sx={{
              py: 2,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <img src={props.src} width="60%" />
          </Box>
        ) : null}

        {props.title ? (
          <Text
            align="center"
            color={props.size === 'medium' ? 'text.primary' : 'text.disabled'}
            fontWeight={props.size === 'medium' ? undefined : 'medium'}
            variant={props.size === 'medium' ? 'h5' : 'body2'}
            fontSize={props.scaleFactor ? 'inherit' : undefined}
          >
            {props.title}
          </Text>
        ) : null}

        {props.body ? (
          <Text
            align="center"
            color={props.size === 'medium' ? 'text.secondary' : 'text.disabled'}
            variant={props.size === 'medium' ? 'body1' : 'body2'}
            fontSize={props.scaleFactor ? 'inherit' : undefined}
          >
            {props.body}
          </Text>
        ) : null}

        {props.actions ? (
          <Stack
            alignItems="center"
            sx={{ gap: 1, mt: props.size === 'medium' ? 2 : undefined }}
          >
            {props.actions}
          </Stack>
        ) : null}
      </Box>
    )
  }
)

EmptyPlaceholder.displayName = 'EmptyPlaceholder'

export default EmptyPlaceholder
