import React from 'react'

import { KpiTemplate } from 'types/GlobalKpiTemplates'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import FormControlLabel from 'components_new/atoms/FormControlLabel'
import List from 'components_new/atoms/List'
import ListSubheader from 'components_new/atoms/List/ListSubheader'
import Skeleton from 'components_new/atoms/Skeleton'
import Switch from 'components_new/atoms/Switch'
import Text from 'components_new/atoms/Text'

import IconTip from 'components_new/molecules/IconTip'
import SidebarNavItem from 'components_new/molecules/SidebarNavItem'

import { sortAlphabeticalAsc } from 'utils/sortHelper'
import { other } from './utils'
import { Category } from 'redux/reducers/Dashboards'
import { translateCategory } from 'utils/enumTranslator'

interface SidebarProps {
  categories: Category[]
  kpis: KpiTemplate[]
  kpisFetched: boolean
  setShowAvailable: (value: boolean) => void
  setTab: (value: 'ALL' | Category | 'OTHER') => void
  showAvailable: boolean
  tab: 'ALL' | Category | 'OTHER'
  variant: 'add' | 'create'
  recommendSameUnit: boolean
  setShowSameUnit: (value: boolean) => void
  showSameUnit: boolean
}

const Sidebar = (props: SidebarProps) => {
  const {
    categories,
    kpis,
    kpisFetched,
    setShowAvailable,
    setTab,
    showAvailable,
    tab,
    variant,
    recommendSameUnit,
    setShowSameUnit,
    showSameUnit
  } = props

  return (
    <>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <List sx={{ flex: 1 }}>
          <ListSubheader
            component="div"
            disableSticky={false}
            sx={{
              borderBottomStyle: 'solid',
              borderBottomWidth: 1,
              borderBottomColor: 'divider',
              mb: 1,
              mx: 2
            }}
          >
            Nyckeltal
          </ListSubheader>
          {kpisFetched ? (
            <>
              <SidebarNavItem
                number={kpis.length}
                onClick={() => setTab('ALL')}
                selected={tab === 'ALL'}
                sx={{ px: 5 }}
                title={'Alla'}
              />
              {categories
                .sort((a, b) =>
                  sortAlphabeticalAsc(
                    translateCategory[a].title,
                    translateCategory[b].title
                  )
                )
                .map((category, i) => {
                  return (
                    <SidebarNavItem
                      key={i}
                      number={
                        kpis.filter((kpi) => kpi.category === category).length
                      }
                      onClick={() => setTab(category)}
                      selected={tab === category}
                      sx={{ px: 5 }}
                      title={translateCategory[category].title}
                    />
                  )
                })}
            </>
          ) : (
            <>
              {Array.from({ length: 6 }).map((_, index) => (
                <Box
                  sx={{
                    height: 42,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    px: 5
                  }}
                  key={index}
                >
                  <Skeleton variant="text" width={'calc(100% - 32px)'} />
                  <Skeleton variant="circular" height={24} width={24} />
                </Box>
              ))}
            </>
          )}

          {variant === 'create' ? (
            <>
              <ListSubheader
                component="div"
                disableSticky={false}
                sx={{
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 1,
                  borderBottomColor: 'divider',
                  mb: 1,
                  mt: 2,
                  mx: 2
                }}
              >
                Tillbehör
              </ListSubheader>
              <SidebarNavItem
                number={other.length}
                onClick={() => setTab('OTHER')}
                selected={tab === 'OTHER'}
                sx={{ px: 5 }}
                title={'Alla'}
              />
            </>
          ) : null}
        </List>

        <Box sx={{ p: 2 }}>
          <Divider sx={{ mb: 2 }} />
          {recommendSameUnit ? (
            <FormControlLabel
              control={<Switch color="primary" size="small" />}
              checked={showSameUnit}
              onChange={() => setShowSameUnit(!showSameUnit)}
              label={
                <>
                  <Text variant="caption">
                    Visa endast nyckeltal med samma basenhet
                  </Text>
                  <IconTip
                    size="small"
                    title={
                      <>
                        För vald visualiseringstyp är det inte rekommenderat att
                        kombinera olika enheter.
                      </>
                    }
                  />
                </>
              }
              labelPlacement="end"
              sx={{ ml: 0, mb: 2 }}
            />
          ) : null}
          <FormControlLabel
            control={<Switch color="primary" size="small" />}
            checked={showAvailable}
            onChange={() => setShowAvailable(!showAvailable)}
            label={
              <>
                <Text variant="caption">Visa endast tillgängliga</Text>
                <IconTip
                  size="small"
                  title={
                    <>
                      Visa endast de nyckeltal som är implementerade och som går
                      att <b>Lägga till</b>.
                    </>
                  }
                />
              </>
            }
            labelPlacement="end"
            sx={{ ml: 0 }}
          />
        </Box>
      </Box>
    </>
  )
}

export default Sidebar
