import React, { useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import { ConnectedProps, connect } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import { IDashboardExample } from 'types/GlobalDashboardExample'

import Button from 'components_new/atoms/Button'
import Portal from 'components_new/atoms/Portal'
import Snackbar from 'components_new/atoms/Snackbar'
import Text from 'components_new/atoms/Text'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import DashboardExampleThumbnail from 'components_new/organisms/DashboardExampleThumbnail'

import { description, title } from './content'
import { sendMessageToSlack } from './utils'

interface OrderDashboardExampleDialogProps {
  dashboard: IDashboardExample
  onClose: () => void
  open: boolean
}

const OrderDashboardExampleDialog = (props: ComponentProps) => {
  const { AuthStore, dashboard, onClose, open } = props

  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false)

  const handleSubmit = () => {
    const email = AuthStore.user?.email ?? ''

    sendMessageToSlack({ title: dashboard.fields.title }, email)
      .then(() => {
        onClose()
        setSubmitSuccess(true)
      })
      .catch((error) => console.log(error))
  }

  return (
    <>
      <ThemeProvider theme={getTheme('light')}>
        <SimpleDialog
          actions={
            <>
              <Button onClick={onClose} variant="text">
                Avbryt
              </Button>
              <Button onClick={handleSubmit}>Skicka förfrågan</Button>
            </>
          }
          maxWidth="xs"
          onClose={onClose}
          open={open}
          title={title}
        >
          <Text color="text.secondary" variant="body1">
            {description}
          </Text>

          <DashboardExampleThumbnail
            dashboard={dashboard}
            disabled={true}
            key={dashboard.fields.id}
            sx={{ mt: 2 }}
            user={AuthStore.user}
          />
        </SimpleDialog>
      </ThemeProvider>

      {/*-- snackbars --*/}
      <Portal>
        <Snackbar
          open={submitSuccess}
          autoHideDuration={3000}
          onClose={(event, reason) => {
            if (reason !== 'clickaway') {
              setSubmitSuccess(false)
            }
          }}
          message="Förfrågan skickad"
        />
      </Portal>
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore
})

const connector = connect(mapStateToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  OrderDashboardExampleDialogProps

export default connector(OrderDashboardExampleDialog)
