import React, { ReactNode } from 'react'

import { OptionProps } from 'components_new/molecules/OptionsMenu'

import SegmentMenuSubContent from './SegmentMenuSubContent'

import * as UserEvents from 'redux/api/internal/UserEvents'

import { AttributeOption } from 'types/GlobalAttributeOptions'
import { Action, Context } from 'types/GlobalUserEvents'
import { ParsedSegmentPath, WidgetObject } from 'types/GlobalWidget'
import { DETAILS_DISABLE_TEXT } from 'utils/constants'

/**
 * Get all available segment options.
 * @param attributeOptionId - Current segment attribute option id.
 * @param segmentOptions - All available segmentation options for widget.
 * @returns - Available segmentations for "Dela upp efter".
 */
const getAvailableSegmentOptions = (
  customSegmentBy: string,
  segmentOptions: AttributeOption[]
): AttributeOption[] => {
  const selectedOption = segmentOptions.find(
    (option) => option.id === customSegmentBy
  )

  if (!selectedOption) {
    return []
  }

  return segmentOptions.filter(
    (option) => option.allow_custom_segment_by && option.id !== customSegmentBy
  )
}

const getSegmentOptionsMenuContent = (
  segments: ParsedSegmentPath[],
  segmentOptions: AttributeOption[],
  setCustomSegmentBy: (
    attributeOptionId: string,
    segments: ParsedSegmentPath[]
  ) => void
): ReactNode => {
  const clickedSegment = segments[segments.length - 1]
  const segmentAttributeOptionId = clickedSegment.attribute_option_id as string

  const customSegmentOptions = getAvailableSegmentOptions(
    segmentAttributeOptionId,
    segmentOptions
  )

  if (customSegmentOptions.length === 0) {
    return null
  }

  return (
    <SegmentMenuSubContent
      segmentOptions={customSegmentOptions}
      setCustomSegmentBy={(attributeOptionId) => {
        setCustomSegmentBy(attributeOptionId, segments)
      }}
    />
  )
}

export const getOptions = (
  widget: WidgetObject,
  segments: ParsedSegmentPath[],
  segmentOptions: AttributeOption[],
  setFilters: (segments: ParsedSegmentPath[]) => void,
  setDetailsDialog?: ((segments: ParsedSegmentPath[]) => void) | null,
  customSegmentBy?: string | null,
  setCustomSegmentBy?:
    | ((attributeOptionId: string, segments: ParsedSegmentPath[]) => void)
    | null,
  onClickExternalLink?: ((link: string | null) => void) | null,
  link: string | null = null,
  isPublic?: boolean
): OptionProps[] => {
  const segmentContent =
    customSegmentBy !== undefined && setCustomSegmentBy
      ? getSegmentOptionsMenuContent(
        segments,
        segmentOptions,
        setCustomSegmentBy
      )
      : null

  const getCustomSegmentDisabledTitle = () => {
    if (isPublic) {
      return 'Logga in i Homepal för att få tillgång till den här funktionaliteten'
    } else if (!segmentContent) {
      return 'Det går inte att dela upp det här segmentet ytterligare'
    } else {
      return undefined
    }
  }

  const getDetailsDisabledTitle = () => {
    if (isPublic) {
      return 'Logga in i Homepal för att få tillgång till den här funktionaliteten'
    } else if (!Boolean(setDetailsDialog)) {
      return DETAILS_DISABLE_TEXT
    } else {
      return undefined
    }
  }

  return [
    {
      iconName: 'FilterAltOutlined' as const,
      onClick: () => setFilters(segments),
      title: 'Lägg till i filter'
    },
    {
      disabled: isPublic || !Boolean(setDetailsDialog),
      iconName: 'TableRowsOutlined' as const,
      infoTip: getDetailsDisabledTitle(),
      onClick: setDetailsDialog ? () => setDetailsDialog(segments) : () => {},
      title: 'Visa innehåll'
    },
    // if customSegmentBy is undefined then dont show option
    // e.g. for line charts
    ...(customSegmentBy !== undefined
      ? [
          {
            disabled: isPublic || !segmentContent,
            iconName: 'AutoFixHighOutlined' as const,
            infoTip: getCustomSegmentDisabledTitle(),
            onClick: () => {},
            title: 'Dela upp efter...',
            content: segmentContent
          }
        ]
      : []),
    ...(onClickExternalLink
      ? [
          {
            disabled: !link,
            iconName: 'LinkOutlined' as const,
            onClick: () => {
              UserEvents.log({
                action: Action.NAVIGATED_TO_SOURCE,
                context: Context.FROM_WIDGET,
                dashboard_id: widget.dashboard_id,
                dashboard_group_id: null
              })

              onClickExternalLink(link)
            },
            title: link
              ? 'Öppna i källsystem'
              : 'Saknar koppling till källsystem'
          }
        ]
      : [])
  ]
}
