import React, { useState, useMemo, useRef } from 'react'

import { useTheme } from '@mui/material/styles'

import 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100'

import Box from 'components_new/atoms/Box'

import {
  horizontalLinePlugin,
  boxBorderDashPlugin,
  verticalLinePlugin,
  forecastIsActive
} from '../utils'

import { getBars, getOptions } from './utils'

import { translateWidgetType } from 'utils/enumTranslator'

import NoValue from '../../errors/NoValue'
import SegmentMenu from '../../SegmentMenu'

import {
  FormattedWidgetData,
  ParsedSegmentPath,
  ParsedWidgetDataLabel,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

interface BarChartProps {
  customSegmentBy: string | null
  formattedData: FormattedWidgetData
  isComparativeButIllegal: boolean
  scaleFactor: number
  setCustomSegmentBy:
    | ((attributeOptionId: string, segment: ParsedSegmentPath[]) => void)
    | null
  setDetailsDialog: ((segment: ParsedSegmentPath[]) => void) | null
  setSegmentFilters: ((segment: ParsedSegmentPath[]) => void) | null
  widget: WidgetObject
}

const BarChart = (props: BarChartProps) => {
  const {
    customSegmentBy,
    formattedData,
    isComparativeButIllegal,
    scaleFactor,
    setCustomSegmentBy,
    setDetailsDialog,
    setSegmentFilters,
    widget
  } = props

  const chartRef = useRef<any>(null)
  const [anchorPosition, setAnchorPosition] = useState<{
    top: number
    left: number
  } | null>(null)
  const [selectedSegment, setSelectedSegment] =
    useState<ParsedWidgetDataLabel | null>(null)
  const allowOnClick = !!setSegmentFilters || !!setDetailsDialog

  const theme = useTheme()

  if (formattedData.datasets.length === 0) {
    return (
      <NoValue
        iconName={translateWidgetType[WidgetType.BAR_CHART].iconName}
        scaleFactor={scaleFactor}
      />
    )
  }

  const hasBreakdown =
    formattedData.datasets.length > 1 ||
    !!widget.settings.kpi_options.find(
      (option) => !!option.breakdown_by.selected
    )

  const bars = useMemo(
    () => getBars(theme, hasBreakdown, formattedData, forecastIsActive(widget)),
    [hasBreakdown, formattedData, widget]
  )
  const options = useMemo(
    () =>
      getOptions(
        widget,
        hasBreakdown,
        theme,
        allowOnClick,
        formattedData,
        isComparativeButIllegal,
        scaleFactor
      ),
    [
      widget,
      hasBreakdown,
      allowOnClick,
      formattedData,
      isComparativeButIllegal,
      scaleFactor
    ]
  )

  const handleClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    dataLabel: ParsedWidgetDataLabel
  ) => {
    event.stopPropagation()

    setAnchorPosition({ left: event.clientX, top: event.clientY })
    setSelectedSegment(dataLabel)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()

    setAnchorPosition(null)
    setSelectedSegment(null)
  }

  return (
    <>
      <Box sx={{ position: 'relative', flexGrow: 1, minHeight: 0 }}>
        <Chart
          ref={chartRef}
          type="bar"
          data={bars}
          options={options}
          plugins={[
            horizontalLinePlugin,
            ChartDataLabels,
            boxBorderDashPlugin,
            verticalLinePlugin,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ChartjsPluginStacked100
          ]}
          onClick={
            allowOnClick
              ? (event) => {
                  if (chartRef.current) {
                    let index = null

                    const { top } = chartRef.current.chartArea

                    // If click is below legend
                    if (event.nativeEvent.offsetY > top) {
                      if (widget.settings.horizontal) {
                        index = chartRef.current.scales.y.getValueForPixel(
                          event.nativeEvent.offsetY
                        )
                      } else {
                        index = chartRef.current.scales.x.getValueForPixel(
                          event.nativeEvent.offsetX
                        )
                      }

                      const segment = bars.dataLabels[index]

                      if (segment) {
                        handleClick(event, segment)
                      }
                    }
                  }
                }
              : undefined
          }
        />
      </Box>
      {allowOnClick && selectedSegment && (
        <SegmentMenu
          anchorPosition={anchorPosition}
          customSegmentBy={customSegmentBy}
          onClose={handleClose}
          segments={[selectedSegment]}
          setCustomSegmentBy={setCustomSegmentBy}
          setDetailsDialog={setDetailsDialog}
          setFilters={setSegmentFilters}
          widget={widget}
        />
      )}
    </>
  )
}

export default BarChart
