import React, { useState, useRef, useEffect } from 'react'

import Dashboard from 'components_new/organisms/Dashboard'
import NewDashboardFromExampleDialog from 'components_new/organisms/dialogs/NewDashboardFromExampleDialog'

import DashboardTemplate from 'components_new/templates/DashboardTemplate'

import { DashboardFilter } from 'types/GlobalDashboardFilter'

import * as AccountStoreActions from 'redux/actions/Accounts'
import * as DashboardFolderStoreActions from 'redux/actions/DashboardFolders'
import * as KPIDashboardStoreActions from 'redux/actions/Dashboards'
import * as WidgetActions from 'redux/actions/Widgets'
import * as CompanyGroupActions from 'redux/actions/CompanyGroups'
import {
  useDashboard,
  useDashboardGroup,
  useGroupDashboards
} from 'redux/hooks/Dashboards'
import { PlatformDashboard } from 'redux/reducers/Dashboards'

import * as UserEvents from 'redux/api/internal/UserEvents'

import { Action, Context } from 'types/GlobalUserEvents'
import { CustomPeriodFilter, PeriodFilter } from 'types/GlobalWidget'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { getDataForWidgets } from 'components_new/organisms/Dashboard/utils'
import { usePrevious } from 'helpers/Functions'
import { isEditableDashboard } from 'utils/functions'
import { ISavedDashboardFilter } from 'types/GlobalSavedDashboardFilter'

/**
 * Get context of how dashboard was opened,
 * and remove from query params.
 */
const getContext = (searchParams: URLSearchParams): Context | null => {
  const context = searchParams.get('ref')?.toUpperCase()

  // remove context from search params
  searchParams.delete('ref')

  // update the URL without reloading the page
  const newUrl =
    window.location.pathname +
    (searchParams.toString() ? `?${searchParams.toString()}` : '')

  window.history.replaceState({}, '', newUrl)

  // validate context
  if (!context || !(Object.values(Context) as string[]).includes(context)) {
    return null
  }

  return context as Context
}

const DashboardPage = (props: ComponentProps) => {
  const params = useParams<{ id: string }>()
  const dashboard = useDashboard<PlatformDashboard>(params.id)
  const group = useDashboardGroup(params.id)
  const dashboards = useGroupDashboards(params.id)
  const previousShowAs = usePrevious(props.CompanyGroupStore.showAs) as
    | string
    | undefined
  const previousDashboard = usePrevious(dashboard) as
    | PlatformDashboard
    | null
    | undefined
  const hasLoggedOpenedDashboard = useRef(false)

  const history = useHistory()
  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)
  const newlyCreated = searchParams.get('new_dashboard')

  const editable = isEditableDashboard(props.AuthStore, group)

  // states
  const [dashboardFilter, setDashboardFilter] = useState<DashboardFilter>([])
  const [periodFilter, setPeriodFilter] = useState<
    PeriodFilter | CustomPeriodFilter | null
  >(null)
  const [customSegmentByMapper, setCustomSegmentByMapper] = useState<{
    [widgetId: string]: string | null
  }>({})
  const [savedDashboardFilter, setSavedDashboardFilter] =
    useState<ISavedDashboardFilter | null>(null)
  const [editMode, setEditMode] = useState(false)

  const gridRef = useRef(null)

  useEffect(() => {
    const {
      AccountStore,
      CompanyGroupStore,
      DashboardFolderStore,
      KPIDashboardStore,
      tryGetAllAccounts,
      tryGetAllCompanyGroups,
      tryGetAllDashboards,
      tryGetAllDashboardFolders
    } = props

    if (!CompanyGroupStore.fetched && !CompanyGroupStore.fetching) {
      tryGetAllCompanyGroups()
    }

    if (!AccountStore.fetched) {
      tryGetAllAccounts()
    }

    if (!DashboardFolderStore.fetched) {
      tryGetAllDashboardFolders()
    }

    if (!KPIDashboardStore.fetched && !KPIDashboardStore.fetching) {
      tryGetAllDashboards()
    }
  }, [])

  useEffect(() => {
    // if dashboard has changed then allow log.
    if (
      hasLoggedOpenedDashboard.current &&
      dashboard &&
      previousDashboard &&
      previousDashboard.id !== dashboard.id
    ) {
      hasLoggedOpenedDashboard.current = false
    }

    if (dashboard && !hasLoggedOpenedDashboard.current) {
      const context = getContext(searchParams)

      UserEvents.log({
        action: Action.DASHBOARD_OPENED,
        context: context,
        dashboard_id: dashboard.id,
        dashboard_group_id: dashboard.dashboard_group_id
      })

      // Make sure we only log one open dashboard.
      hasLoggedOpenedDashboard.current = true
    }

    if (
      dashboard &&
      previousDashboard &&
      previousDashboard.dashboard_group_id !== dashboard.dashboard_group_id
    ) {
      // check if group has changed --> reset states
      setDashboardFilter([])
      setPeriodFilter(null)
      setSavedDashboardFilter(null)
    }
  }, [dashboard?.id])

  useEffect(() => {
    // will trigger after above effect
    if (dashboard) {
      getDataForWidgets(
        props.tryGetOneWidget,
        dashboard,
        dashboardFilter,
        periodFilter,
        props.CompanyGroupStore.showAs,
        customSegmentByMapper
      )
    }
  }, [props.KPIDashboardStore.activeFetchOne])

  // Reset on dashboard change.
  useEffect(() => {
    if (Boolean(newlyCreated)) {
      setEditMode(true)
    } else {
      setEditMode(false)
    }
  }, [params.id])

  const handleToggleEditMode = () => {
    if (Boolean(newlyCreated)) {
      history.replace(`/dashboards/${params.id}`)
    }

    setEditMode(!editMode)
  }

  // If a dashboard is open and user change company group, update dashboard.
  useEffect(() => {
    const parsedPrevious = previousShowAs || null

    if (dashboard && parsedPrevious !== props.CompanyGroupStore.showAs) {
      getDataForWidgets(
        props.tryGetOneWidget,
        dashboard,
        dashboardFilter,
        periodFilter,
        props.CompanyGroupStore.showAs,
        customSegmentByMapper
      )
    }
  }, [props.CompanyGroupStore.showAs])

  const handleSetPeriodFilter = (
    period: PeriodFilter | CustomPeriodFilter | null
  ) => {
    if (!dashboard) return
    setPeriodFilter(period)

    getDataForWidgets(
      props.tryGetOneWidget,
      dashboard,
      dashboardFilter,
      period,
      props.CompanyGroupStore.showAs,
      customSegmentByMapper,
      true
    )
  }

  const handleSetDashboardFilter = (
    newDashboardFilter: DashboardFilter,
    newPeriodFilter?: CustomPeriodFilter
  ) => {
    if (!dashboard) return
    const period =
      newPeriodFilter === undefined ? periodFilter : newPeriodFilter

    getDataForWidgets(
      props.tryGetOneWidget,
      dashboard,
      newDashboardFilter,
      period,
      props.CompanyGroupStore.showAs,
      customSegmentByMapper
    )

    setDashboardFilter(newDashboardFilter)

    if (newPeriodFilter !== undefined) {
      setPeriodFilter(newPeriodFilter)
    }

    if (savedDashboardFilter) {
      // if dashboard filter is changed, then remove selected saved.
      setSavedDashboardFilter(null)
    }
  }

  const handleSetSegmentBy = (
    widgetId: string,
    attributeOptionId: string | null,
    dbFilter?: DashboardFilter,
    newPeriodFilter?: CustomPeriodFilter
  ) => {
    if (!dashboard) return
    const newSegmentByMapper = {
      ...customSegmentByMapper,
      [widgetId]: attributeOptionId
    }
    const activeDbFilter = dbFilter || dashboardFilter
    const activePeriod =
      newPeriodFilter === undefined ? periodFilter : newPeriodFilter

    setCustomSegmentByMapper(newSegmentByMapper)

    if (dbFilter !== undefined) {
      setDashboardFilter(dbFilter)
    }
    if (newPeriodFilter !== undefined) {
      setPeriodFilter(newPeriodFilter)
    }

    getDataForWidgets(
      props.tryGetOneWidget,
      dashboard,
      activeDbFilter,
      activePeriod,
      props.CompanyGroupStore.showAs,
      newSegmentByMapper
    )
  }

  const handleSetSavedDashboardFilter = (
    saved: ISavedDashboardFilter | null,
    skipFetch = false
  ) => {
    if (!dashboard) return
    setSavedDashboardFilter(saved)

    // deselected = deleted --> do not clear filter
    // or if selected already selected then do nothing
    if (!saved || skipFetch || saved.id === savedDashboardFilter?.id) return

    const savedFilters = saved.conditions.flatMap((condition) =>
      condition.values.map((value) => ({
        relation_key: condition.relation_key,
        attribute_option_id: null,
        attribute: condition.attribute_id,
        value
      }))
    )

    // set dashboard filter
    setDashboardFilter(savedFilters)

    // refetch data
    getDataForWidgets(
      props.tryGetOneWidget,
      dashboard,
      savedFilters,
      periodFilter,
      props.CompanyGroupStore.showAs,
      customSegmentByMapper
    )
  }

  const handleResetAllTempStates = () => {
    if (!dashboard) return

    // filters
    setDashboardFilter([])
    setPeriodFilter(null)
    setSavedDashboardFilter(null)

    // segments
    setCustomSegmentByMapper({})

    getDataForWidgets(
      props.tryGetOneWidget,
      dashboard,
      [],
      null,
      props.CompanyGroupStore.showAs,
      {}
    )

    UserEvents.log({
      action: Action.DASHBOARD_RESETTED,
      context: null,
      dashboard_id: dashboard.id,
      dashboard_group_id: dashboard.dashboard_group_id
    })
  }

  return (
    <DashboardTemplate
      bgcolor={props.CustomizationStore.colors?.background_color}
      dashboardFilter={dashboardFilter}
      displayTabs={editable || dashboards.length > 1}
      editMode={editMode}
      gridRef={gridRef}
      handleToggleEditMode={handleToggleEditMode}
      periodFilter={periodFilter}
      resetAllTempStates={handleResetAllTempStates}
      savedDashboardFilter={savedDashboardFilter}
      setDashboardFilter={handleSetDashboardFilter}
      setPeriodFilter={handleSetPeriodFilter}
      setSavedDashboardFilter={handleSetSavedDashboardFilter}
      userIsCompanyGroup={!!props.AuthStore.user?.is_company_group}
    >
      <Dashboard
        customSegmentByMapper={customSegmentByMapper}
        dashboardFilter={dashboardFilter}
        displayTabs={dashboards.length > 1} // only for mobile
        editMode={editMode}
        gridRef={gridRef}
        periodFilter={periodFilter}
        resetAllTempStates={handleResetAllTempStates}
        setDashboardFilter={handleSetDashboardFilter}
        setPeriodFilter={handleSetPeriodFilter}
        setCustomSegmentBy={handleSetSegmentBy}
        variant={{
          embedded: false,
          type: 'portal'
        }}
      />
      <NewDashboardFromExampleDialog />
    </DashboardTemplate>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  AuthStore: state.AuthStore,
  CompanyGroupStore: state.CompanyGroupStore,
  CustomizationStore: state.CustomizationStore,
  DashboardFolderStore: state.DashboardFolderStore,
  KPIDashboardStore: state.KPIDashboardStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountStoreActions,
      ...CompanyGroupActions,
      ...DashboardFolderStoreActions,
      ...KPIDashboardStoreActions,
      ...WidgetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ComponentProps = ConnectedProps<typeof connector>

export default connector(DashboardPage)
