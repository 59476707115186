import React from 'react'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Collapse from 'components_new/atoms/Collapse'

import { translateDateFilter } from 'utils/enumTranslator'
import { isPeriodFilterEnum } from 'utils/functions'
import { getParsedDateSpan } from 'utils/dateParser'

import { DashboardUserFilterBarProps } from '.'

const Desktop = (props: DashboardUserFilterBarProps) => {
  const {
    dashboardFilter,
    periodFilter,
    resetAllTempStates,
    setDashboardFilter,
    setPeriodFilter,
    sx
  } = props

  const isPreset = periodFilter && isPeriodFilterEnum(periodFilter)

  // success
  return (
    <Collapse in={dashboardFilter.length > 0 || Boolean(periodFilter)}>
      <Box
        sx={{
          bgcolor: 'background.default',
          p: 1,
          width: '100%',
          display: 'flex',
          gap: 0.5,
          borderTop: '1px solid',
          borderColor: 'divider',
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            height: 5
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'action.disabled',
            borderRadius: 2.5,
            outline: 'none'
          },
          ...sx
        }}
      >
        {dashboardFilter.map((filter, i) => (
          <Chip
            key={i}
            color="primary"
            label={filter.value === null ? 'Saknar värde' : filter.value}
            onDelete={() =>
              setDashboardFilter(
                dashboardFilter.filter(
                  (f) =>
                    f.relation_key !== filter.relation_key ||
                    f.value !== filter.value
                )
              )
            }
            size="small"
          />
        ))}
        {periodFilter ? (
          <Chip
            color="primary"
            label={
              isPreset
                ? translateDateFilter[periodFilter]
                : getParsedDateSpan(periodFilter.from, periodFilter.to)
            }
            onDelete={() => setPeriodFilter(null)}
            size="small"
          />
        ) : null}
        <Chip
          clickable={true}
          label="Rensa alla"
          onClick={resetAllTempStates}
          size="small"
        />
      </Box>
    </Collapse>
  )
}

export default Desktop
