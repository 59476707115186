import React from 'react'

import { IconName } from 'components_new/atoms/Icon'

import { DashboardStatus, WhoCanView } from 'redux/reducers/Dashboards'
import { Category } from 'redux/reducers/Dashboards'
import {
  BreakdownByDisplayType,
  ComparativePeriod,
  DateFilterType,
  FunnelStatus,
  PeriodFilter,
  PieChartValueVisualizationType,
  WidgetType
} from 'types/GlobalWidget'
import { ChipProps } from '@mui/material'
import {
  ComparativeDisplayType,
  Condition,
  RollingType
} from 'types/GlobalKpiOption'
import { Color } from 'themes'
import { AccountRole } from 'types/GlobalUser'

export const translateDateFilter: Record<PeriodFilter, string> = {
  // PERIOD FILTERS
  [PeriodFilter.TODAY]: 'Idag',
  [PeriodFilter.YESTERDAY]: 'Igår',
  [PeriodFilter.THIS_WEEK_TO_TODAY]: 'Hittills den här veckan',
  [PeriodFilter.THIS_MONTH_TO_TODAY]: 'Hittills den här månaden',
  [PeriodFilter.THIS_QUARTER_TO_TODAY]: 'Hittills det här kvartalet',
  [PeriodFilter.THIS_YEAR_TO_TODAY]: 'Hittills det här året',
  [PeriodFilter.LAST_WEEK]: 'Förra veckan',
  [PeriodFilter.LAST_MONTH]: 'Förra månaden',
  [PeriodFilter.LAST_QUARTER]: 'Förra kvartalet',
  [PeriodFilter.LAST_YEAR]: 'Förra året',
  [PeriodFilter.LAST_7_DAYS]: 'Senaste 7 dagarna',
  [PeriodFilter.LAST_30_DAYS]: 'Senaste 30 dagarna',
  [PeriodFilter.LAST_90_DAYS]: 'Senaste 90 dagarna',
  [PeriodFilter.LAST_365_DAYS]: 'Senaste 365 dagarna',
  [PeriodFilter.LATEST_MONTH_WITH_DATA]: 'Senaste månaden med data',
  [PeriodFilter.LAST_3_MONTHS_TO_TODAY]: 'Hittills de senaste 3 månaderna',
  [PeriodFilter.LAST_6_MONTHS_TO_TODAY]: 'Hittills de senaste 6 månaderna',
  [PeriodFilter.LAST_12_MONTHS_TO_TODAY]: 'Hittills de senaste 12 månaderna',
  [PeriodFilter.LAST_24_MONTHS_TO_TODAY]: 'Hittills de senaste 24 månaderna',
  [PeriodFilter.LAST_36_MONTHS_TO_TODAY]: 'Hittills de senaste 36 månaderna',
  [PeriodFilter.LAST_2_YEARS_TO_TODAY]: 'Hittills de senaste 2 åren',
  [PeriodFilter.LAST_3_YEARS_TO_TODAY]: 'Hittills de senaste 3 åren',
  [PeriodFilter.LAST_4_QUARTERS_TO_TODAY]: 'Hittills de senaste 4 kvartalen',
  [PeriodFilter.LAST_8_QUARTERS_TO_TODAY]: 'Hittills de senaste 8 kvartalen',
  [PeriodFilter.LAST_12_QUARTERS_TO_TODAY]: 'Hittills de senaste 12 kvartalen'
}

export const translateComparativeDisplayType: Record<
  ComparativeDisplayType,
  { iconName: IconName; title: string; tooltip: string }
> = {
  [ComparativeDisplayType.PERCENTAGE]: {
    iconName: 'PercentOutlined',
    title: 'Förändring',
    tooltip: 'Visa endast förändring'
  },
  [ComparativeDisplayType.VALUE]: {
    iconName: 'AlignVerticalBottomOutlined',
    title: 'Värden',
    tooltip: 'Visa endast värden'
  },
  [ComparativeDisplayType.PERCENTAGE_AND_VALUE]: {
    iconName: 'StackedBarChartOutlined',
    title: 'Båda',
    tooltip: 'Visa både värde och förändring'
  },
  [ComparativeDisplayType.HIDDEN]: {
    iconName: 'VisibilityOffOutlined',
    title: 'Ingen',
    tooltip: 'Dölj förändring'
  }
}

export const translateComparativePeriod: Record<
  DateFilterType,
  Record<
    PeriodFilter | 'default',
    {
      [key in ComparativePeriod]?: string
    }
  >
> = {
  [DateFilterType.PERIOD]: {
    // PERIOD FILTERS
    ['default']: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående period',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år',
      [ComparativePeriod.AVERAGE_LAST_180_DAYS]: 'Genomsnitt för en period'
    },
    [PeriodFilter.THIS_WEEK_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående vecka',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år',
      [ComparativePeriod.AVERAGE_LAST_180_DAYS]: 'Genomsnitt för 7 dagar'
    },
    [PeriodFilter.THIS_MONTH_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående månad',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år',
      [ComparativePeriod.AVERAGE_LAST_180_DAYS]: 'Genomsnitt för en månad'
    },
    [PeriodFilter.THIS_QUARTER_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående kvartal',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    },
    [PeriodFilter.THIS_YEAR_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående år',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    },
    [PeriodFilter.TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Igår',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år',
      [ComparativePeriod.AVERAGE_LAST_180_DAYS]: 'Genomsnitt för en dag'
    },
    [PeriodFilter.YESTERDAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'I förrgår',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år',
      [ComparativePeriod.AVERAGE_LAST_180_DAYS]: 'Genomsnitt för en dag'
    },
    [PeriodFilter.LAST_WEEK]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående vecka',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år',
      [ComparativePeriod.AVERAGE_LAST_180_DAYS]: 'Genomsnitt för 7 dagar'
    },
    [PeriodFilter.LAST_MONTH]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående månad',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år',
      [ComparativePeriod.AVERAGE_LAST_180_DAYS]: 'Genomsnitt för en månad'
    },
    [PeriodFilter.LAST_QUARTER]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående kvartal',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    },
    [PeriodFilter.LAST_YEAR]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående år',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    },
    [PeriodFilter.LAST_7_DAYS]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående 7 dagar',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år',
      [ComparativePeriod.AVERAGE_LAST_180_DAYS]: 'Genomsnitt för 7 dagar'
    },
    [PeriodFilter.LAST_30_DAYS]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående 30 dagar',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år',
      [ComparativePeriod.AVERAGE_LAST_180_DAYS]: 'Genomsnitt för 30 dagar'
    },
    [PeriodFilter.LAST_90_DAYS]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående 90 dagar',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    },
    [PeriodFilter.LAST_365_DAYS]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående 365 dagar',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    },
    [PeriodFilter.LATEST_MONTH_WITH_DATA]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående månad',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    },
    [PeriodFilter.LAST_3_MONTHS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående 3 månader',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    },
    [PeriodFilter.LAST_6_MONTHS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående 6 månader',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    },
    [PeriodFilter.LAST_12_MONTHS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående 12 månader',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    },
    [PeriodFilter.LAST_4_QUARTERS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående 4 kvartalen',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    },
    [PeriodFilter.LAST_24_MONTHS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående 24 månader',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    },
    [PeriodFilter.LAST_36_MONTHS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående 36 månader',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    },
    [PeriodFilter.LAST_2_YEARS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående 2 år',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    },
    [PeriodFilter.LAST_3_YEARS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående 3 år',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    },
    [PeriodFilter.LAST_8_QUARTERS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående 8 kvartal',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    },
    [PeriodFilter.LAST_12_QUARTERS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Föregående 12 kvartal',
      [ComparativePeriod.LAST_YEAR]: 'Samma period föregående år'
    }
  },
  [DateFilterType.POINT]: {
    // POINTS
    ['default']: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag föregående period',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.THIS_WEEK_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag föregående vecka',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.THIS_MONTH_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag föregående månad',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.THIS_QUARTER_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag föregående kvartal',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.THIS_YEAR_TO_TODAY]: {
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år',
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag föregående år'
    },
    [PeriodFilter.TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Igår',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.YESTERDAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'I förrgår',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_WEEK]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag föregående vecka',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_MONTH]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag föregående månad',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_QUARTER]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag föregående kvartal',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_YEAR]: {
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_7_DAYS]: {
      [ComparativePeriod.LAST_PERIOD]: '7 dagar tidigare',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_30_DAYS]: {
      [ComparativePeriod.LAST_PERIOD]: '30 dagar tidigare',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_90_DAYS]: {
      [ComparativePeriod.LAST_PERIOD]: '90 dagar tidigare',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_365_DAYS]: {
      [ComparativePeriod.LAST_PERIOD]: '365 dagar tidigare',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LATEST_MONTH_WITH_DATA]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag föregående månad',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_3_MONTHS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag 3 månader tidigare',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_6_MONTHS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag 6 månader tidigare',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_12_MONTHS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag 12 månader tidigare',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_4_QUARTERS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag 4 kvartal tidigare',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_24_MONTHS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag 24 månader tidigare',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_36_MONTHS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag 36 månader tidigare',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_2_YEARS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag 2 år tidigare',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_3_YEARS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag 3 år tidigare',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_8_QUARTERS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag 8 kvartal tidigare',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    },
    [PeriodFilter.LAST_12_QUARTERS_TO_TODAY]: {
      [ComparativePeriod.LAST_PERIOD]: 'Samma dag 12 kvartal tidigare',
      [ComparativePeriod.LAST_YEAR]: 'Samma dag föregående år'
    }
  }
}

export const translateWidgetType: Record<
  WidgetType,
  {
    disabledTitle: string
    iconName: IconName
    title: string
  }
> = {
  [WidgetType.KEY_FIGURE]: {
    disabledTitle: 'Detta nyckeltal går inte att visa som siffra',
    iconName: 'OneKOutlined',
    title: 'Siffra'
  },
  [WidgetType.BAR_CHART]: {
    disabledTitle: 'Detta nyckeltal går inte att visa som staplar',
    iconName: 'BarChartOutlined',
    title: 'Staplar'
  },
  [WidgetType.BUBBLE_CHART]: {
    disabledTitle: 'Detta nyckeltal går inte att visa som ett bubbeldiagram',
    iconName: 'BubbleChartOutlined',
    title: 'Bubblor'
  },
  [WidgetType.LINE_CHART]: {
    disabledTitle: 'Detta nyckeltal går inte att visa över tid',
    iconName: 'ShowChartOutlined',
    title: 'Tid'
  },
  [WidgetType.LIST]: {
    disabledTitle: 'Detta nyckeltal går inte att visa som lista eller tabell',
    iconName: 'ListOutlined',
    title: 'Tabell'
  },
  [WidgetType.MAP]: {
    disabledTitle: 'Detta nyckeltal går inte att visa på karta',
    iconName: 'MapOutlined',
    title: 'Karta'
  },
  [WidgetType.COMBO_CHART]: {
    disabledTitle:
      'Detta nyckeltal går inte att visa i ett kombineringsdiagram',
    iconName: 'AddchartOutlined',
    title: 'Kombo'
  },
  [WidgetType.PIE_CHART]: {
    disabledTitle: 'Detta nyckeltal går inte att visa som tårtdiagram',
    iconName: 'PieChartOutlined',
    title: 'Cirkel'
  },
  [WidgetType.FUNNEL]: {
    disabledTitle: 'Detta nyckeltal går inte att visa som flöde',
    iconName: 'CalendarViewWeekOutlined',
    title: 'Flöde'
  },
  [WidgetType.TEXT]: {
    disabledTitle: 'Går inte att visa som text',
    iconName: 'TitleOutlined',
    title: 'Text'
  },
  [WidgetType.COMMENT]: {
    disabledTitle: 'Går inte att visa som kommentar',
    iconName: 'StickyNote2Outlined',
    title: 'Kommentar'
  },
  [WidgetType.IMAGE]: {
    disabledTitle: 'Går inte att visa som bild',
    iconName: 'ImageOutlined',
    title: 'Bild'
  }
}

export const translateFilterCondition: Record<
  Condition,
  { iconName: IconName; title: string }
> = {
  [Condition.IS_NULL]: {
    iconName: 'CheckBoxOutlineBlankOutlined',
    title: 'är tom'
  },
  [Condition.EQ]: {
    iconName: 'CheckCircleOutlined',
    title: 'är lika med'
  },
  [Condition.NE]: {
    iconName: 'UnpublishedOutlined',
    title: 'är inte lika med'
  },
  [Condition.LTE]: {
    iconName: 'ArrowUpwardOutlined', // change?
    title: 'är mindre eller lika med'
  },
  [Condition.LT]: {
    iconName: 'ArrowUpwardOutlined',
    title: 'är mindre än'
  },
  [Condition.GTE]: {
    iconName: 'ArrowUpwardOutlined', // change?
    title: 'är större eller lika med'
  },
  [Condition.GT]: {
    iconName: 'ArrowUpwardOutlined',
    title: 'är större än'
  },
  [Condition.IS_NOT_NULL]: {
    iconName: 'CheckBoxOutlined',
    title: 'är inte tom'
  }
}

export const translateFilter = (
  leftAttribute: string,
  condition: Condition,
  value: string | number,
  fromValue?: string | null,
  toValue?: string | null
) => {
  if (fromValue && toValue) {
    return (
      <>
        <b>{leftAttribute}</b> från <b>{fromValue}</b> till <b>{toValue}</b>{' '}
        {translateFilterCondition[condition].title} <b>{value}</b>
      </>
    )
  }

  return (
    <>
      <b>{leftAttribute}</b> {translateFilterCondition[condition].title}{' '}
      <b>{value}</b>
    </>
  )
}

export const translateGroupByType = {
  SEGMENT_BY: 'Visa per',
  BREAKDOWN_BY: 'Dela upp efter'
}

export const translateWhoCanView: Record<
  WhoCanView,
  {
    title: string
    description: string
    iconName: IconName
    color: string
  }
> = {
  [WhoCanView.ONLY_ADDED_USERS]: {
    title: 'Bara tillagda användare',
    description: 'Privat och delas bara med tillagda användare',
    iconName: 'LockPersonOutlined',
    color: 'action.active'
  },
  [WhoCanView.ORGANIZATION]: {
    title: 'Hela organisationen',
    description: 'Tillgänglig och kan visas av alla användare i organisationen',
    iconName: 'Business',
    color: 'secondary'
  }
}

export const translateDashboardStatus: Record<
  DashboardStatus,
  {
    title: string
    color: ChipProps['color']
  }
> = {
  [DashboardStatus.DRAFT]: {
    title: 'Utkast',
    color: 'info'
  },
  [DashboardStatus.PUBLISHED]: {
    title: 'Publicerad',
    color: 'success'
  },
  [DashboardStatus.OPEN]: {
    title: 'Öppen',
    color: 'success'
  },
  [DashboardStatus.COMPLETED]: {
    title: 'Avslutad',
    color: 'default'
  }
}

export const translateCategory: Record<
  Category,
  {
    title: string
    color: Color
    icon: IconName
  }
> = {
  [Category.ENERGY]: {
    title: 'Hållbarhet',
    color: 'quintary',
    icon: 'EnergySavingsLeaf'
  },
  [Category.RENTAL]: {
    title: 'Uthyrning',
    color: 'secondary',
    icon: 'AddBusiness'
  },
  [Category.REAL_ESTATE_PORTFOLIO]: {
    title: 'Beståndet',
    color: 'senary',
    icon: 'Business'
  },
  [Category.TECHNICAL_MANAGEMENT]: {
    title: 'Teknisk förvaltning',
    color: 'tertiary',
    icon: 'Construction'
  },
  [Category.ECONOMY]: {
    title: 'Ekonomi',
    color: 'primary',
    icon: 'AccountBalance'
  },
  [Category.HOMEPAL]: {
    title: 'Homepal',
    color: 'primary',
    icon: 'AccountBalance'
  }
}

export const translateFunnelStatusType: Record<
  FunnelStatus,
  {
    iconName: IconName
    color: 'error' | 'success'
  } | null
> = {
  [FunnelStatus.TERMINATED]: {
    iconName: 'CancelTwoTone',
    color: 'error'
  },
  [FunnelStatus.UNSET]: null,
  [FunnelStatus.COMPLETED]: {
    iconName: 'CheckCircleTwoTone',
    color: 'success'
  }
}

export const translateStatus: Record<
  string,
  {
    colorName: 'error' | 'success' | 'warning'
    iconName: IconName
  }
> = {
  error: {
    colorName: 'error',
    iconName: 'Report'
  },
  success: {
    colorName: 'success',
    iconName: 'CheckCircle'
  },
  warning: {
    colorName: 'warning',
    iconName: 'Error'
  }
}

export const TRANSLATE_ROLES: { [key in AccountRole]: string } = {
  ADMIN: 'Admin',
  VIEW: 'Användare',
  ACCESS_HUB: 'Access hub'
}

export const TRANSLATE_ROLLING: Record<
  RollingType,
  { abbreviation: string; iconName: IconName; title: string }
> = {
  [RollingType.R7]: {
    abbreviation: 'R7',
    iconName: 'CalendarMonthOutlined',
    title: 'Rullande 7 dagar'
  },
  [RollingType.R30]: {
    abbreviation: 'R30',
    iconName: 'CalendarMonthOutlined',
    title: 'Rullande 30 dagar'
  },
  [RollingType.R365]: {
    abbreviation: 'R365',
    iconName: 'CalendarMonthOutlined',
    title: 'Rullande 365 dagar'
  },
  [RollingType.R12]: {
    abbreviation: 'R12',
    iconName: 'EventNoteOutlined',
    title: 'Rullande 12 månader'
  }
}

export const translateBreakdownByDisplayType: Record<
  BreakdownByDisplayType,
  {
    iconName: IconName
    title: string
  }
> = {
  [BreakdownByDisplayType.CLOSE_BY]: {
    iconName: 'BarChart',
    title: 'Närliggande per segmentering'
  },
  [BreakdownByDisplayType.STACKED]: {
    iconName: 'StackedBarChart',
    title: 'Staplade per segmentering'
  },
  [BreakdownByDisplayType.STACKED_100_PERCENT]: {
    iconName: 'ViewColumn',
    title: '100% staplade per segmentering'
  }
}

export const translatePieChartValueVisualizationType: Record<
  PieChartValueVisualizationType,
  { title: string; iconName: IconName }
> = {
  [PieChartValueVisualizationType.VALUES]: {
    title: 'Värden',
    iconName: 'LooksOneOutlined'
  },
  [PieChartValueVisualizationType.PERCENTAGE]: {
    title: 'Procent',
    iconName: 'PercentOutlined'
  },
  [PieChartValueVisualizationType.VALUES_AND_PERCENTAGE]: {
    title: 'Värden och procent',
    iconName: 'Filter1Outlined'
  }
}
