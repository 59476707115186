import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { isValidDate } from 'helpers/Functions/Date'
import { parseFilterBody } from 'helpers/Functions/Filters'
import { LayoutItem } from 'redux/reducers/Dashboards'
import {
  GetOneWidget,
  WidgetPostBody,
  WidgetPatchBody,
  PeriodFilter,
  FunnelStagePutBody
} from 'types/GlobalWidget'
import { getWithRedirect } from 'utils/axios'
import { FilterBody, Sort } from 'utils/types'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/widgets`

export interface QueryParams {
  filter?: FilterBody[]
  segment_by?: string | null
  sort?: Sort | null
  period_filter?: PeriodFilter
  from_date?: string
  to_date?: string
}

const getManualPeriodQuery = (from: string, to: string) => {
  let specificDateQuery = ''

  const fromDate = new Date(from)
  const toDate = new Date(to)

  // Make sure it is same date
  fromDate.setHours(6)
  toDate.setHours(6)

  const parsedFromDate = fromDate.toLocaleDateString('sv')
  const parsedToDate = toDate.toLocaleDateString('sv')

  specificDateQuery = `from_date=${parsedFromDate}&to_date=${parsedToDate}`

  return specificDateQuery
}

export const parseQueryParam = (queryParam: QueryParams, rawQuery = '') => {
  let queryString = rawQuery

  if (queryParam.period_filter) {
    queryString = `period_filter=${queryParam.period_filter}`
  }

  if (queryParam.filter && queryParam.filter.length > 0) {
    if (queryString) {
      queryString += '&'
    }

    queryString += queryParam.filter
      .map((filter) => parseFilterBody(filter.attributeId, filter))
      .filter(Boolean)
      .join('&')
  }

  if (queryParam.segment_by) {
    if (queryString) {
      queryString += '&'
    }

    queryString += `segment_by=${encodeURIComponent(queryParam.segment_by)}`
  }

  if (queryParam.sort) {
    if (queryString) {
      queryString += '&'
    }

    queryString += `sort=${queryParam?.sort.direction === 'desc' ? '-' : ''}${
      queryParam?.sort.attribute
    }`
  }

  if (
    queryParam.from_date &&
    queryParam.to_date &&
    isValidDate(new Date(queryParam.from_date)) &&
    isValidDate(new Date(queryParam.to_date))
  ) {
    if (queryString) {
      queryString += '&'
    }

    queryString += getManualPeriodQuery(
      queryParam.from_date,
      queryParam.to_date
    )
  }

  if (queryString && queryString[0] !== '?') {
    queryString = '?' + queryString
  }

  return queryString
}

export async function getOneAdditionalSegment(
  id: string,
  additionalSegmentId: string,
  queryParam: QueryParams | null
) {
  const parsedQueryParams = queryParam ? parseQueryParam(queryParam) : ''
  const url = `${URL}/${id}/${additionalSegmentId}${parsedQueryParams}`

  return getWithRedirect(url)
}

export async function getOneAdditionalSegmentPublic(
  id: string,
  additionalSegmentId: string,
  queryParam: QueryParams | null,
  rawQueryParams = ''
) {
  const parsedQueryParams = queryParam
    ? parseQueryParam(queryParam, rawQueryParams)
    : rawQueryParams
  const url = `${BASE_URL}v1/public/widgets/${id}/${additionalSegmentId}${parsedQueryParams}`

  return getWithRedirect(url, {})
}

export async function getOneAdditionalSegmentApiAccess(
  id: string,
  token: string,
  additionalSegmentId: string,
  queryParam: QueryParams | null,
  rawQueryParams = ''
) {
  const parsedQueryParams = queryParam
    ? parseQueryParam(queryParam, rawQueryParams)
    : rawQueryParams
  const url = `${BASE_URL}v1/api-access/widgets/${id}/${additionalSegmentId}${parsedQueryParams}`

  return getWithRedirect(url, {
    headers: { Authorization: `Bearer ${token}` }
  })
}

export async function getOneTotal(id: string, queryParam: QueryParams | null) {
  const parsedQueryParams = queryParam ? parseQueryParam(queryParam) : ''
  const url = `${URL}/${id}/total${parsedQueryParams}`

  return getWithRedirect(url)
}

export async function getOneTotalPublic(
  id: string,
  queryParam: QueryParams | null,
  rawQueryParams = ''
) {
  const parsedQueryParams = queryParam
    ? parseQueryParam(queryParam, rawQueryParams)
    : rawQueryParams
  const url = `${BASE_URL}v1/public/widgets/${id}/total${parsedQueryParams}`

  return getWithRedirect(url, {})
}

export async function getOneTotalApiAccess(
  id: string,
  token: string,
  queryParam: QueryParams | null,
  rawQueryParams = ''
) {
  const parsedQueryParams = queryParam
    ? parseQueryParam(queryParam, rawQueryParams)
    : rawQueryParams
  const url = `${BASE_URL}v1/api-access/widgets/${id}/total${parsedQueryParams}`

  return getWithRedirect(url, {
    headers: { Authorization: `Bearer ${token}` }
  })
}

export async function getOne(id: string, queryParam: QueryParams | null) {
  const parsedQueryParams = queryParam ? parseQueryParam(queryParam) : ''
  const url = `${URL}/${id}${parsedQueryParams}`

  return getWithRedirect<GetOneWidget>(url)
}

export async function create(body: WidgetPostBody) {
  return axios.post(URL, body, parseAuthenticationHeader())
}

export async function update(
  id: string,
  body: WidgetPatchBody,
  queryParam: QueryParams | null
) {
  const parsedQueryParams = queryParam ? parseQueryParam(queryParam) : ''

  return axios.patch(
    `${URL}/${id}${parsedQueryParams}`,
    body,
    parseAuthenticationHeader()
  )
}

export async function destroy(id: string) {
  return axios.delete<{ available_space: LayoutItem | null }>(
    `${URL}/${id}`,
    parseAuthenticationHeader()
  )
}

// Get data from an embedded widget
export async function getOnePublic(
  id: string,
  queryParam: QueryParams | null = null,
  rawQueryParams = ''
) {
  const parsedQueryParams = queryParam
    ? parseQueryParam(queryParam, rawQueryParams)
    : rawQueryParams
  const url = `${BASE_URL}v1/public/widgets/${id}${parsedQueryParams}`

  return getWithRedirect<GetOneWidget>(url, {})
}

// Get data from a widget with api access
export async function getOneApiAccess(
  id: string,
  token: string,
  queryParam: QueryParams | null = null,
  rawQueryParams = ''
) {
  const parsedQueryParams = queryParam
    ? parseQueryParam(queryParam, rawQueryParams)
    : rawQueryParams
  const url = `${BASE_URL}v1/api-access/widgets/${id}${parsedQueryParams}`

  return getWithRedirect<GetOneWidget>(url, {
    headers: { Authorization: `Bearer ${token}` }
  })
}

export async function putImage(
  id: string,
  image: Blob,
  name: string,
  queryParam: QueryParams | null
) {
  const formData = new FormData()
  const parsedQueryParams = queryParam ? parseQueryParam(queryParam) : ''

  formData.append('image', image)
  formData.append('name', name)

  return axios.put(
    `${URL}/${id}/image${parsedQueryParams}`,
    formData,
    parseAuthenticationHeader()
  )
}

export async function putFunnelStages(
  id: string,
  funnelStages: FunnelStagePutBody
) {
  return axios.put(
    `${URL}/${id}/funnel-stages`,
    { data: { funnel_stages: funnelStages } },
    parseAuthenticationHeader()
  )
}
