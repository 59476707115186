import { nanoid } from 'nanoid'
import {
  CustomPeriodFilter,
  DatasetDataValue,
  DatasetType,
  ParsedSegmentPath,
  PeriodFilter
} from 'types/GlobalWidget'
import { Filters } from './types'
import { Condition, FilterType } from 'types/GlobalKpiOption'
import {
  Dashboard,
  DashboardFilters,
  DashboardGroup,
  DashboardOwnership,
  PublicDashboard
} from 'redux/reducers/Dashboards'
import {
  DashboardFolder,
  DashboardFolderReduxData
} from 'types/GlobalDashboardFolder'
import { AccountRole, User, UserType } from 'types/GlobalUser'
import { Customer } from 'types/GlobalCustomer'
import { AuthenticationState } from 'types/GlobalAuthentication'
import { DashboardFilterReducerType } from 'redux/reducers/DashboardFilterOptions'
import {
  AccountBody,
  AccountReducerType,
  AccountType
} from 'redux/reducers/Accounts'
import { ParsedDashboardFilterOptions } from 'types/GlobalDashboardFilter'
import { AttributeOption } from 'types/GlobalAttributeOptions'

// TODO: fix, utils functions ska ej importera från en komponent
import { Option } from 'components_new/molecules/SelectSearch'

/**
 * Get thumbnail for dashboard.
 * Adding reload query parameter to avoid caching.
 * @param thumbnail
 * @returns
 */
export const getThumbnail = (thumbnail: string | null) =>
  thumbnail
    ? `${thumbnail}?reload=${nanoid()}`
    : '/assets/media/dashboard_thumbnail.svg'

export const getDashboardImageUrl = (id: string) => {
  return `https://homepal-dashboard-thumbnails.s3.eu-north-1.amazonaws.com/production/${id}.png`
}

// Type guard function for PeriodFilter
export function isPeriodFilterEnum(
  value: PeriodFilter | CustomPeriodFilter
): value is PeriodFilter {
  return typeof value === 'string' && value in PeriodFilter
}

// Type guard function for CustomPeriodFilter
export function isCustomPeriodFilter(
  value: PeriodFilter | CustomPeriodFilter
): value is CustomPeriodFilter {
  return typeof value === 'object' && 'from' in value
}

export function getFilterFromSegmentPaths(
  segmentPaths: ParsedSegmentPath[],
  attributeOptions: { [id: string]: AttributeOption }
): Filters {
  const filters: Filters = {}

  segmentPaths.forEach((item) => {
    if (
      !item.attribute_option_id ||
      item.period ||
      !attributeOptions[item.attribute_option_id]
    ) {
      return
    }

    const selectedAttributeOption = attributeOptions[item.attribute_option_id]

    if (selectedAttributeOption.relation_key in filters) {
      (
        filters[selectedAttributeOption.relation_key].value as (
          | string
          | number
          | null
        )[]
      ).push(item.label)
    } else {
      filters[selectedAttributeOption.relation_key] = {
        attributeId: selectedAttributeOption.relation_key,
        condition: Condition.EQ,
        value: [item.label],
        type: FilterType.INPUT_VALUE
      }
    }
  })

  return filters
}

/**
 * Find the last occuring item in a list.
 * @param array - List to find item in.
 * @param validate - Function if item is the one to search for.
 * @returns last index that fulfills validate, -1 if not found.
 */
export function lastIndexOf<T>(array: T[], validate: (item: T) => boolean) {
  for (let i = array.length - 1; i >= 0; i--) {
    if (validate(array[i])) {
      return i
    }
  }

  return -1
}

export function getAvailableGroups(groups: { [key: string]: DashboardGroup }) {
  const groupArray = Object.values(groups)

  return groupArray.filter(
    (group) =>
      group.ownership === DashboardOwnership.SHARED_WITH_ME ||
      group.ownership === DashboardOwnership.OWNED_BY_ME
  )
}

export function getAvailableFolders(
  groups: DashboardGroup[],
  folders: DashboardFolderReduxData,
  user: User | null
): DashboardFolder[] {
  if (!user) {
    return []
  }

  const folderArray = Object.values(folders)

  folderArray.sort((a, b) => a.name.localeCompare(b.name, 'sv'))

  // Helper function to get the latest dashboard updated_at for a folder
  const getLatestUpdatedAt = (folderId: string) => {
    const folderGroups = groups.filter((group) => group.folder_id === folderId)

    if (folderGroups.length === 0) {
      return null
    }

    return folderGroups.reduce((latest, group) => {
      const groupUpdatedAt = new Date(group.updated_at)

      return groupUpdatedAt > latest ? groupUpdatedAt : latest
    }, new Date(0)) // Start with the earliest possible date
  }

  // If admin or Homepal we return all folders.
  if (user.role === AccountRole.ADMIN || user.is_homepal_user) {
    return folderArray.map((folder) => ({
      ...folder,
      // Override folder update with latest dashboard update:
      updated_at: getLatestUpdatedAt(folder.id) ?? folder.updated_at
    }))
  }

  // If user we return folders with content for the user.
  return folderArray
    .filter((folder) => {
      return groups.some((group) => group.folder_id === folder.id)
    })
    .map((folder) => ({
      ...folder,
      // Override folder update with latest dashboard update:
      updated_at: getLatestUpdatedAt(folder.id) ?? folder.updated_at
    }))
}

/**
 * Parses array to object which uses the id as key.
 */
export function arrayToObject<T>(arr: (T & { id: string })[]) {
  const obj: { [id: string]: T } = {}

  arr.forEach((item) => {
    obj[item.id] = item
  })

  return obj
}

/**
 * Groups array to object of arrays by key.
 */
export function groupBy<T>(arr: (T & { [key: string]: any })[], key: string) {
  return arr.reduce<Record<string, T[]>>((acc, item) => {
    if (!acc[item[key]]) {
      acc[item[key]] = []
    }

    acc[item[key]].push(item)

    return acc
  }, {})
}

export function getDashboardLimitReached(auth: AuthenticationState) {
  const customer = auth.customer as Customer
  const isHomepal = auth.user?.is_homepal_user ?? false

  if (isHomepal) {
    return false
  }

  const numberOfDashboardsInLicense = customer.number_of_dashboards
  const numberOfDashboardsInUse = customer.number_of_dashboards_in_use

  return numberOfDashboardsInLicense
    ? numberOfDashboardsInUse >= numberOfDashboardsInLicense
    : false
}

/**
 * Retrieve value from data based on DatasetType.
 * - Special parsing for NUMBER_TAG
 */
export function parseDatasetValue(
  dataValue: DatasetDataValue,
  datasetType: DatasetType = DatasetType.STRING
) {
  if (datasetType === DatasetType.NUMBER_TAG) {
    // pick the first one because response should only
    // return at most one item in the array.
    return (dataValue as number[])[0] ?? null
  }

  return dataValue
}

/**
 * Check if Dataset is of type number (single number/list of numbers).
 */
export function isNumberDatasetType(dtype?: DatasetType) {
  return dtype && [DatasetType.NUMBER, DatasetType.NUMBER_TAG].includes(dtype)
}

/**
 * Get a specific sub path by index.
 * @param path the full original path
 * @param i the index of the subpath
 * @returns the sub path on index i
 */
export const getSubPath = (path: string, i: number) => {
  const subPaths = path.split(/[/?]/)

  return subPaths[i + 1]
}

/**
 * Checks if a dashboard (in a group) is editable for the
 * current user based on AuthStore amd KPIDashboardStore.
 * @returns true if editable.
 */
export function isEditableDashboard(
  AuthStore: AuthenticationState,
  group: DashboardGroup | null
) {
  const { user } = AuthStore

  if (!user || !group) {
    return false
  }

  const isAdmin = user.role === AccountRole.ADMIN
  const isHomepal = Boolean(user.is_homepal_user)
  const isOwner = group.owner === user.id

  return isAdmin || isHomepal || isOwner
}

// Type guard function for public dashboards
export function isPublicDashboard(
  dashboard?: Dashboard | PublicDashboard
): dashboard is PublicDashboard {
  return !!dashboard && 'customer_id' in dashboard
}

/**
 * Retreive resource which has the dashboard_filters.
 * Platform:  Dashboard group
 * Public:    Dashboard
 */
export function resolveDashboardFilterResource(
  dashboard: Dashboard,
  dashboardGroup: DashboardGroup | null
): PublicDashboard | DashboardGroup | null {
  if (isPublicDashboard(dashboard)) {
    return dashboard
  }

  return dashboardGroup
}

export function formatDashboardFilterOptions(
  dashboardFilters: DashboardFilters[],
  DashboardFilterOptionsStore: DashboardFilterReducerType,
  companyGroupId: string | null
): Map<string, ParsedDashboardFilterOptions> {
  return new Map(
    dashboardFilters.map((filter) => {
      const option = DashboardFilterOptionsStore.options.find(
        (opt) => opt.relation_key === filter.relation_key
      )

      return [
        filter.relation_key,
        {
          attribute_id: filter.attribute_id,
          title: option?.title || '',
          relation_key: filter.relation_key,
          relation_name: option?.relation_name ?? null,
          index: filter.index,
          options:
            DashboardFilterOptionsStore.data[
              `${filter.relation_key}${companyGroupId || ''}`
            ]
        }
      ]
    })
  )
}

export const getPermissions = (
  AccountStore: AccountReducerType,
  dashboardGroup: DashboardGroup,
  signedInUser: User | null,
  ownerId: string | null
): UserType[] => {
  if (AccountStore.data && signedInUser) {
    const owner = getOwner(AccountStore.data, ownerId)

    const admins = Object.values(AccountStore.data)
      .filter(
        (account) =>
          account.role === AccountRole.ADMIN && account.id !== ownerId
      )
      .map((account) => formatUser(account))

    const viewers = dashboardGroup.share_settings.permissions
      .filter(
        (permission) =>
          permission.account_id && AccountStore.data[permission.account_id]
      )
      .map((permission) => {
        const account = AccountStore.data[permission.account_id as string]

        return formatUser(account, account.id !== signedInUser.id)
      })

    return [...(owner ? [owner] : []), ...admins, ...viewers]
  }

  return []
}

export function getOwner(
  accounts: AccountBody,
  ownerId: string | null
): UserType | null {
  if (!ownerId || !accounts[ownerId]) {
    return null
  }

  return formatUser(accounts[ownerId], false, true)
}

export const formatUser = (
  account: AccountType,
  allowRevoke = false,
  owner = false
): UserType => {
  return {
    accountId: account.id,
    companyGroupId: account.company_group_id,
    name: formatUsername(account),
    email: account.email,
    role: account.role,
    allowRevoke,
    owner
  }
}

export const formatUsername = (account: AccountType) => {
  return account.first_name || account.last_name
    ? `${account.first_name} ${account.last_name}`
    : 'Oregistrerad användare'
}

export const formatUserOption = (account: AccountType): Option => {
  return {
    avatar: true,
    body: account.email,
    id: account.id,
    title: formatUsername(account)
  }
}
