import { createSelector } from '@reduxjs/toolkit'

import { ApplicationState } from 'redux/Stores/types'

// select all saved dashboard filters
export const selectAllDashboardFilters = createSelector(
  [
    // Input selectors:
    (state: ApplicationState) => state.SavedDashboardFilterStore.data
  ],
  (data) => Object.values(data)
)

export const selectShouldFetchSavedDashboardFilters = createSelector(
  (state: ApplicationState) => state.SavedDashboardFilterStore.fetched,
  (state: ApplicationState) => state.SavedDashboardFilterStore.fetching,
  (fetched, fetching) => !fetched && !fetching
)
