import { TNavigationItem } from 'components_new/molecules/NavigationItem'

import { DashboardGroup, DashboardStatus } from 'redux/reducers/Dashboards'
import { DashboardFolder } from 'types/GlobalDashboardFolder'
import { Context } from 'types/GlobalUserEvents'
import { addRef } from 'utils/queryHandler'

export const NO_FOLDER = 'NO_FOLDER'
export const FAVORITES = 'FAVORITES'
export const DRAFTS = 'DRAFTS'

export const groupDashboards = (
  groups: DashboardGroup[],
  folders: DashboardFolder[]
) => {
  const groupedDashboards: { [key: string]: DashboardGroup[] } = {
    [NO_FOLDER]: groups.filter(
      (group) =>
        !folders.some((folder) => folder.id === group.folder_id) &&
        group.status === DashboardStatus.PUBLISHED
    ),
    [FAVORITES]: groups.filter(
      (group) =>
        group.favorite.active && group.status === DashboardStatus.PUBLISHED
    ),
    [DRAFTS]: groups.filter((group) => group.status === DashboardStatus.DRAFT)
  }

  folders.forEach((folder) => {
    groupedDashboards[folder.id] = groups.filter(
      (group) =>
        group.folder_id === folder.id &&
        group.status === DashboardStatus.PUBLISHED
    )
  })

  return groupedDashboards
}

export const getFolderTree = (
  groupedDashboards: { [key: string]: DashboardGroup[] },
  folders: DashboardFolder[],
  isHomepal: boolean,
  userId?: string
): TNavigationItem[] => {
  return [
    {
      icon: 'GridViewOutlined',
      href: '/dashboards',
      title: 'Alla dashboards',
      subItems: [
        ...folders.map((folder) => {
          return {
            icon: 'FolderOutlined' as const,
            href: `/dashboards/folders/${folder.id}`,
            title: folder.name,
            subItems: (groupedDashboards[folder.id] || [])
              .sort((a, b) => a.title.localeCompare(b.title, 'sv'))
              .map((group) => {
                return {
                  activePaths: group.dashboards,
                  icon: 'AnalyticsOutlined' as const,
                  href: `/dashboards/${group.dashboards[0]}`,
                  search: addRef('', Context.FROM_NAVBAR),
                  title: group.title
                }
              })
          }
        }),
        // without folder
        ...(groupedDashboards[NO_FOLDER] || [])
          .sort((a, b) => a.title.localeCompare(b.title, 'sv'))
          .map((group) => {
            return {
              activePaths: group.dashboards,
              icon: 'AnalyticsOutlined' as const,
              href: `/dashboards/${group.dashboards[0]}`,
              search: addRef('', Context.FROM_NAVBAR),
              title: group.title
            }
          })
      ]
    },
    {
      icon: 'StarOutlined',
      href: '/dashboards/favorites',
      title: 'Favoriter',
      subItems: (groupedDashboards[FAVORITES] || [])
        .sort((a, b) => a.title.localeCompare(b.title, 'sv'))
        .map((group) => {
          return {
            activePaths: group.dashboards,
            icon: 'AnalyticsOutlined',
            href: `/dashboards/${group.dashboards[0]}`,
            search: addRef('', Context.FROM_NAVBAR),
            title: group.title
          }
        })
    },
    ...(groupedDashboards[DRAFTS].length > 0
      ? [
          {
            divider: true,
            icon: 'DesignServicesOutlined' as const,
            href: '/dashboards/drafts',
            title: isHomepal ? 'Alla utkast' : 'Mina utkast',
            subItems: groupedDashboards[DRAFTS].sort((a, b) =>
              a.title.localeCompare(b.title, 'sv')
            ).map((group) => {
              return {
                activePaths: group.dashboards,
                icon: 'AnalyticsOutlined' as const,
                href: `/dashboards/${group.dashboards[0]}`,
                search: addRef('', Context.FROM_NAVBAR),
                title: group.title,
                infoChip:
                  isHomepal && group.owner === userId ? 'Ägare' : undefined
              }
            })
          }
        ]
      : [])
  ]
}
