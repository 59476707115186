import { WidgetType } from './GlobalWidget'

export enum Action {
  DASHBOARD_CREATED = 'DASHBOARD_CREATED',
  DASHBOARD_OPENED = 'DASHBOARD_OPENED',
  DASHBOARD_EDITED = 'DASHBOARD_EDITED',
  DASHBOARD_DELETED = 'DASHBOARD_DELETED',
  WIDGET_CREATED = 'WIDGET_CREATED',
  WIDGET_EDITED = 'WIDGET_EDITED',
  WIDGET_DELETED = 'WIDGET_DELETED',
  INVITE_SENT_TO_HOMEPAL = 'INVITE_SENT_TO_HOMEPAL',
  INVITE_ACCEPTED_TO_HOMEPAL = 'INVITE_ACCEPTED_TO_HOMEPAL',
  INVITE_SENT_TO_DASHBOARD = 'INVITE_SENT_TO_DASHBOARD',
  DASHBOARD_IMAGE_EXPORTED = 'DASHBOARD_IMAGE_EXPORTED',
  SHOW_POSTS = 'SHOW_POSTS',
  COLUMNS_IN_SHOW_POSTS_EDITED = 'COLUMNS_IN_SHOW_POSTS_EDITED',
  SHOW_POSTS_EXPORTED = 'SHOW_POSTS_EXPORTED',
  TIME_PERIOD_FILTERED = 'TIME_PERIOD_FILTERED',
  TIME_PERIOD_RESETTED = 'TIME_PERIOD_RESETTED',
  SELECTION_FILTERED = 'SELECTION_FILTERED',
  SELECTION_RESETTED = 'SELECTION_RESETTED',
  CUSTOMIZED_SEGMENT_BY_CHANGED = 'CUSTOMIZED_SEGMENT_BY_CHANGED',
  CUSTOMIZED_SEGMENT_BY_CLEARED = 'CUSTOMIZED_SEGMENT_BY_CLEARED',
  DASHBOARD_RESETTED = 'DASHBOARD_RESETTED',
  WIDGET_DATA_EXPORTED = 'WIDGET_DATA_EXPORTED',
  WIDGET_ENLARGED = 'WIDGET_ENLARGED',
  LIST_SORTED = 'LIST_SORTED',
  NAVIGATED_TO_SOURCE = 'NAVIGATED_TO_SOURCE',
  KPI_INFORMATION_SHOWNED = 'KPI_INFORMATION_SHOWNED',
  EXTENSIVE_KPI_INFORMATION_SHOWNED = 'EXTENSIVE_KPI_INFORMATION_SHOWNED',
  MARKED_DASHBOARD_AS_FAVORITE = 'MARKED_DASHBOARD_AS_FAVORITE',
  REMOVED_DASHBOARD_AS_FAVORITE = 'REMOVED_DASHBOARD_AS_FAVORITE',
  DASHBOARD_EXAMPLE_OPENED = 'DASHBOARD_EXAMPLE_OPENED',
  SAVED_DASHBOARD_FILTER_CREATED = 'SAVED_DASHBOARD_FILTER_CREATED',
  SAVED_DASHBOARD_FILTER_EDITED = 'SAVED_DASHBOARD_FILTER_EDITED',
  SAVED_DASHBOARD_FILTER_DELETED = 'SAVED_DASHBOARD_FILTER_DELETED',
  DASHBOARD_PUBLISHED = 'DASHBOARD_PUBLISHED',
  DASHBOARD_UNPUBLISHED = 'DASHBOARD_UNPUBLISHED'
}

export enum Context {
  DUPLICATE = 'DUPLICATE',
  FROM_SCRATCH = 'FROM_SCRATCH',
  FROM_EXPLORE = 'FROM_EXPLORE',
  FROM_LANDING_PAGE = 'FROM_LANDING_PAGE',
  FROM_FAVORITES = 'FROM_FAVORITES',
  FROM_FOLDER = 'FROM_FOLDER',
  FROM_DRAFTS = 'FROM_DRAFTS',
  FROM_NAVBAR = 'FROM_NAVBAR',
  NAVIGATE_TO_NEW_TAB = 'NAVIGATE_TO_NEW_TAB',
  FROM_SEARCH = 'FROM_SEARCH',
  FROM_WIDGET = 'FROM_WIDGET',
  FROM_FILTER_MENU = 'FROM_FILTER_MENU',
  FROM_SAVED_DASHBOARD_FILTER = 'FROM_SAVED_DASHBOARD_FILTER',
  FROM_SUGGESTIONS = 'FROM_SUGGESTIONS',
  FROM_SHOW_POSTS = 'FROM_SHOW_POSTS',
  MOVE = 'MOVE',
  SHARE_LINK = 'SHARE_LINK',
  NOTIFICATION = 'NOTIFICATION',
  // SAVED_DASHBOARD_FILTER_EDITED
  TITLE = 'TITLE',
  CONDITIONS = 'CONDITIONS'
}

export interface UserEventBody {
  dashboard_group_id: string | null
  dashboard_id: string | null
  action: Action
  context: Context | WidgetType | null
}
