import React, { useEffect, useState } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'

import { DashboardFolder } from 'types/GlobalDashboardFolder'
import { DashboardGroup, DashboardStatus } from 'redux/reducers/Dashboards'
import { Context } from 'types/GlobalUserEvents'

import { ApplicationState } from 'redux/Stores/types'
import { Dispatch, bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'

import * as AccountActions from 'redux/actions/Accounts'
import * as CompanyGroupActions from 'redux/actions/CompanyGroups'
import * as DashboardActions from 'redux/actions/Dashboards'
import * as DashboardFavoriteActions from 'redux/actions/DashboardFavorites'
import * as DashboardFolderActions from 'redux/actions/DashboardFolders'
import * as DashboardGroupActions from 'redux/actions/DashboardGroups'
import * as KPITemplateActions from 'redux/actions/KPITemplates'
import * as WidgetActions from 'redux/actions/Widgets'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Tooltip from 'components_new/atoms/Tooltip'

import AboutDashboardDialog from 'components_new/organisms/dialogs/AboutDashboardDialog'
import ActivityDialog from 'components_new/organisms/dialogs/ActivityDialog'
import DeleteDialog from 'components_new/organisms/dialogs/DeleteDialog'
import EditDashboardMenu from 'components_new/organisms/EditDashboardMenu'
import EditTitleDialog from 'components_new/organisms/dialogs/EditTitleDialog'
import ExportDialog from 'components_new/organisms/dialogs/ExportDialog'

import PageTitle from 'components_new/organisms/PageTitle'

import { addRef } from 'utils/queryHandler'

interface DesktopProps {
  gridRef: any | null
  editMode: boolean
  editable: boolean
  folders: DashboardFolder[]
  group: DashboardGroup
  groupDashboards: {
    id: string
    title: string
  }[]
  handleToggleEditMode: () => void
  isAdmin: boolean
  isHomepal: boolean
  isOwner: boolean
  selectedFolder: DashboardFolder | undefined
}

const Desktop = (props: ComponentProps) => {
  const {
    gridRef,
    editable,
    editMode,
    folders,
    group,
    groupDashboards,
    isAdmin,
    isHomepal,
    // isOwner,
    handleToggleEditMode,
    selectedFolder,
    // redux actions:
    addTemporaryLastVisitDashboardGroup,
    tryDeleteDashboard,
    tryPutDashboardFavorite,
    tryUpdateDashboard,
    tryUpdateDashboardGroup
  } = props

  const history = useHistory()

  const [editMenuAnchorEl, setEditMenuAnchorEl] =
    useState<HTMLAnchorElement | null>(null)

  const handleOpenEditMenu = (event: any) => {
    setEditMenuAnchorEl(event.currentTarget)
  }

  const handleCloseEditMenu = () => {
    setEditMenuAnchorEl(null)
  }

  type TDialogType =
    | 'about_dashboard'
    | 'dashboard_activity'
    | 'delete_dashboard'
    | 'edit_dashboard_settings'
    | 'edit_dashboard_title'
    | 'edit_template'
    | 'export_dashboard'
    | 'move_group'
    | 'welcome_dashboard'

  const [dialog, setDialog] = useState<{
    type: TDialogType
    open: boolean
    id: string
  }>({
    type: 'delete_dashboard',
    open: false,
    id: ''
  })

  const handleOpenDialog = (type: TDialogType, id?: string) => {
    setDialog({
      type: type,
      open: true,
      id: id ?? ''
    })
  }

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const isNewDashboard = Boolean(searchParams.get('new_dashboard'))

  // show welcome dialog if not visited, and update visited
  useEffect(() => {
    if (group.status === DashboardStatus.PUBLISHED && !group.latest_visit) {
      handleOpenDialog('welcome_dashboard')

      addTemporaryLastVisitDashboardGroup(group.id)
    }
  }, [group.id])

  // show dashboard settings if just created
  useEffect(() => {
    if (isNewDashboard) {
      handleOpenDialog('edit_dashboard_settings')
    }
  }, [group.id])

  return (
    <>
      {/* @TODO: Check ellipse overflow of title */}
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flex: '0 1 auto',
          gap: 1,
          minWidth: 96,
          mr: 0.5
        }}
      >
        <PageTitle
          editable={editable}
          folder={selectedFolder}
          onSubmit={(title: string) => {
            tryUpdateDashboardGroup(group.id, { data: { title } })
          }}
          title={group.title}
        />

        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Tooltip
            title={
              group.status !== DashboardStatus.PUBLISHED
                ? 'Utkast kan inte favoritmarkeras'
                : group.favorite.active
                  ? 'Ta bort dashboard från favoriter'
                  : 'Lägg till dashboard i favoriter'
            }
          >
            <Box>
              <IconButton
                onClick={() => {
                  tryPutDashboardFavorite(group.id, group.favorite.active)
                }}
                size="small"
                disabled={group.status !== DashboardStatus.PUBLISHED}
              >
                <Icon
                  fontSize="small"
                  name={
                    group.favorite.active
                      ? 'StarOutlined'
                      : 'StarBorderOutlined'
                  }
                  color={group.favorite.active ? 'primary' : 'inherit'}
                />
              </IconButton>
            </Box>
          </Tooltip>
          <Tooltip title={'Visa information'}>
            <IconButton
              onClick={() => handleOpenDialog('about_dashboard')}
              size="small"
            >
              <Icon fontSize="small" name="InfoOutlined" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ flex: '1 1 auto' }} />
      <Box sx={{ display: 'flex', alignItems: 'center', flex: '0 1 auto' }}>
        {editable ? (
          <>
            <Tooltip
              title={lgUp ? '' : editMode ? 'Avsluta redigering' : 'Redigera'}
            >
              {lgUp ? (
                <Button
                  color={editMode ? 'brand' : 'primary'}
                  onClick={handleToggleEditMode}
                  startIcon={
                    <Icon
                      name={
                        editMode ? 'EditOffOutlined' : 'ModeEditOutlineOutlined'
                      }
                    />
                  }
                  variant={editMode ? 'contained' : 'text'}
                >
                  {editMode ? 'Avsluta redigering' : 'Redigeringsläge'}
                </Button>
              ) : (
                <IconButton
                  color={editMode ? 'brand' : undefined}
                  onClick={handleToggleEditMode}
                  variant={editMode ? 'contained' : undefined}
                >
                  <Icon
                    name={
                      editMode ? 'EditOffOutlined' : 'ModeEditOutlineOutlined'
                    }
                  />
                </IconButton>
              )}
            </Tooltip>
            <Divider flexItem={true} orientation="vertical" sx={{ mx: 1 }} />
          </>
        ) : null}
        <Tooltip title="Exportera">
          <IconButton onClick={() => handleOpenDialog('export_dashboard')}>
            <Icon name="FileDownloadOutlined" />
          </IconButton>
        </Tooltip>
        {isHomepal || isAdmin ? (
          <Tooltip
            title={
              group.status !== DashboardStatus.PUBLISHED
                ? 'Utkast har ingen aktivitet'
                : 'Aktivitetsöversikt'
            }
          >
            <Box>
              <IconButton
                onClick={() => handleOpenDialog('dashboard_activity')}
                disabled={group.status !== DashboardStatus.PUBLISHED}
              >
                <Icon name="TrendingUpOutlined" />
              </IconButton>
            </Box>
          </Tooltip>
        ) : null}
        {editable ? (
          <Tooltip title="Alternativ">
            <IconButton
              onClick={(e) => {
                handleOpenEditMenu(e)

                e.preventDefault()
              }}
            >
              <Icon name="MoreVertOutlined" />
            </IconButton>
          </Tooltip>
        ) : null}
      </Box>
      {/*-- dialogs --*/}

      {/*-- activity dashboard --*/}
      <ActivityDialog
        dashboardGroupId={group.id}
        open={dialog.open && dialog.type === 'dashboard_activity'}
        onClose={handleCloseDialog}
      />

      {/*-- export dashboard --*/}
      <ExportDialog
        gridRef={gridRef}
        open={dialog.open && dialog.type === 'export_dashboard'}
        onClose={handleCloseDialog}
        onOpen={() => handleOpenDialog('export_dashboard')}
      />

      {/*-- delete dashboard --*/}
      <DeleteDialog
        handleClose={handleCloseDialog}
        handleDelete={(callback: () => void) => {
          tryDeleteDashboard(dialog.id, () => {
            const nextDashboardId = group.dashboards.filter(
              (dashboardId) => dashboardId !== dialog.id
            )[0]

            history.push(
              addRef(
                `/dashboards/${nextDashboardId}`,
                Context.NAVIGATE_TO_NEW_TAB
              )
            )
            handleCloseDialog()
            callback()
          })
        }}
        open={dialog.open && dialog.type === 'delete_dashboard'}
        title={groupDashboards.find((item) => item.id === dialog.id)?.title}
        type="flik"
      />

      {/*-- edit dashboard title --*/}
      <EditTitleDialog
        onClose={handleCloseDialog}
        onSubmit={(value) => {
          tryUpdateDashboard(dialog.id, { data: { title: value } })
        }}
        open={dialog.open && dialog.type === 'edit_dashboard_title'}
        title={groupDashboards.find((item) => item.id === dialog.id)?.title}
      />

      {/*-- show dashboard info --*/}
      <AboutDashboardDialog
        dashboardGroup={group}
        onClose={handleCloseDialog}
        open={dialog.open && dialog.type === 'about_dashboard'}
        variant={'info'}
      />

      {/*-- edit dashboard info --*/}
      <AboutDashboardDialog
        dashboardGroup={group}
        onClose={handleCloseDialog}
        open={dialog.open && dialog.type === 'edit_dashboard_settings'}
        variant={'edit'}
      />

      {/*-- welcome dashboard info --*/}
      <AboutDashboardDialog
        dashboardGroup={group}
        onClose={handleCloseDialog}
        open={dialog.open && dialog.type === 'welcome_dashboard'}
        variant={'welcome'}
      />

      {/*-- edit dashboard group --*/}
      <EditDashboardMenu
        deleteCallback={() => history.push('/dashboards')}
        folders={folders}
        group={group}
        handleClose={handleCloseEditMenu}
        menuAnchorEl={editMenuAnchorEl}
      />
    </>
  )
}

Desktop.displayName = 'Desktop'

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  DashboardFolderStore: state.DashboardFolderStore,
  KPIDashboardStore: state.KPIDashboardStore,
  KPITemplateStore: state.KPITemplateStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountActions,
      ...CompanyGroupActions,
      ...DashboardActions,
      ...DashboardFavoriteActions,
      ...DashboardFolderActions,
      ...DashboardGroupActions,
      ...KPITemplateActions,
      ...WidgetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & DesktopProps

export default connector(Desktop)
