import React from 'react'

import { getKpiFormulaString } from '../utils'

import Box from 'components_new/atoms/Box'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import TextField from 'components_new/atoms/TextField'
import Tooltip from 'components_new/atoms/Tooltip'

import EmptyPlaceholder from 'components_new/molecules/EmptyPlaceholder'
import SectionHeader from 'components_new/molecules/SectionHeader'

import KpiFormulaBlock from './KpiFormulaBlock'

import { KPI_UNIT } from 'types/Enums'

import { handleDeleteBlock, handleEditType, handleEditValue } from './utils'
import {
  KpiTemplate,
  KpiTemplateCalculationBlock
} from 'types/GlobalKpiTemplates'
import { KpiVariable, KpiVariableWithChildren } from 'types/GlobalKpiVariable'

interface EditKpiSectionProps {
  formattedVariables: KpiVariableWithChildren[]
  editFormula: KpiTemplateCalculationBlock
  editName: string
  editUnit: KPI_UNIT
  homepalKpi?: boolean
  setEditFormula: (value: any) => void
  setEditName: (value: string) => void
  setEditUnit?: (value: KPI_UNIT) => void
  validName: boolean
  variables: KpiVariable[]
  templates: KpiTemplate[]
}

const EditKpiSection = (props: EditKpiSectionProps) => {
  const {
    formattedVariables,
    editFormula,
    editName,
    editUnit,
    homepalKpi = false,
    setEditFormula,
    setEditName,
    setEditUnit,
    validName,
    variables,
    templates
  } = props

  return (
    <>
      <Tooltip
        title={
          homepalKpi
            ? 'Nyckeltalet är en del av Homepals standard och därför går varken namn eller enhet att redigera.'
            : ''
        }
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}
        >
          <TextField
            label="Namn"
            disabled={homepalKpi}
            error={!validName}
            helperText={
              validName ? undefined : 'Ett nyckeltal med det namnet finns redan'
            }
            value={editName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEditName(event.target.value)
            }}
          />
          <TextField
            disabled={homepalKpi || !setEditUnit}
            label={'Enhet'}
            select={true}
            sx={{ width: 96 }}
            value={editUnit}
          >
            {Object.values(KPI_UNIT).map((unitOption) => (
              <MenuItem
                value={unitOption}
                key={unitOption}
                onClick={() => (setEditUnit ? setEditUnit(unitOption) : {})}
                disabled={homepalKpi}
              >
                {unitOption}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Tooltip>
      <Box sx={{ mt: 2 }}>
        <SectionHeader title="Formel" />
        <KpiFormulaBlock
          block={editFormula}
          formattedVariables={formattedVariables}
          editType={(id, side, value) => {
            const edited = handleEditType(editFormula, id, side, value)

            setEditFormula(edited)
          }}
          editValue={(id, key, value) => {
            const edited = handleEditValue(editFormula, id, key, value)

            setEditFormula(edited)
          }}
          deleteBlock={(id, side) => {
            const edited = handleDeleteBlock(editFormula, id, side)

            setEditFormula(edited)
          }}
          variables={variables}
          templates={templates}
        />
      </Box>

      <Box sx={{ mt: 2 }}>
        <SectionHeader title="Förhandsvisning" />
        {editFormula ? (
          <code>{getKpiFormulaString(editFormula, variables, templates)}</code>
        ) : (
          <EmptyPlaceholder title="Formel saknas" />
        )}
      </Box>
    </>
  )
}

export default EditKpiSection
