import { useSelector } from 'react-redux'

import {
  selectAllDashboardFilters,
  selectShouldFetchSavedDashboardFilters
} from 'redux/selectors/SavedDashboardFilters'

export const useSavedDashboardFilters = () => {
  return useSelector(selectAllDashboardFilters)
}

export const useShouldFetchSavedDashboardFilters = () => {
  return useSelector(selectShouldFetchSavedDashboardFilters)
}
