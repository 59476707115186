import Axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { getWithRedirect } from 'utils/axios'

import {
  ICreateSavedDashboardFilterBody,
  ISavedDashboardFilter,
  IUpdateSavedDashboardFilterBody
} from 'types/GlobalSavedDashboardFilter'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/saved-dashboard-filters`

export async function getAll() {
  return getWithRedirect<ISavedDashboardFilter[]>(URL)
}

export async function create(body: { data: ICreateSavedDashboardFilterBody }) {
  return Axios.post<{ data: ISavedDashboardFilter }>(
    URL,
    body,
    parseAuthenticationHeader()
  )
}

export async function update(
  id: string,
  body: { data: IUpdateSavedDashboardFilterBody }
) {
  return Axios.patch<{ data: ISavedDashboardFilter }>(
    `${URL}/${id}`,
    body,
    parseAuthenticationHeader()
  )
}

export async function destroy(id: string) {
  return Axios.delete(`${URL}/${id}`, parseAuthenticationHeader())
}
