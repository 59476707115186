import React, { ElementType, Suspense } from 'react'

import { Color } from 'themes'

import Square from '@mui/icons-material/Square'

import { icons } from './lib'

export type IconName = keyof typeof icons

interface IconProps {
  className?: string
  color?:
    | Color
    | 'inherit'
    | 'action'
    | 'brand'
    | 'default'
    | 'disabled'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'black'
    | 'white'
  component?: ElementType<any>
  fontSize?: 'large' | 'medium' | 'small' | 'inherit'
  name: IconName
  sx?: object
}

/**
 * The Icon component dynamically imports Material Icons with lazy loading from a
 * fixed set of icons to reduce bundle size (and keep performance).
 *
 * All icon names can be found in Material UI's
 * documentation: https://mui.com/material-ui/material-icons/
 */

const Icon = React.forwardRef((props: IconProps, ref: any) => {
  const { name, sx, ...rest } = props

  const LazyIcon = icons[name] || icons['Square']

  return (
    <Suspense
      fallback={<Square sx={{ ...sx, opacity: 0 }} {...rest} ref={ref} />}
    >
      <LazyIcon sx={sx} {...rest} ref={ref} />
    </Suspense>
  )
})

Icon.displayName = 'Icon'
export default Icon
