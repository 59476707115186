export const employees: string[] = [
  'axel',
  'amanda',
  'frida',
  'jonatan',
  'kevin',
  'love',
  'niklas',
  'rasmus'
]

export const avatarImages: Record<string, string> = {
  ['axel']:
    'https://images.ctfassets.net/1k6n64mfastn/2CRgtgJgt5eHJlXNye9ZH4/a8e5e1a2a84cda8224141bcb34cc5efa/P1280419_small.jpg',
  ['amanda']:
    'https://images.ctfassets.net/1k6n64mfastn/5ZXw94eyO3GcUykOBDPQkZ/2ab0f8f339f999210089480d19524b79/P1280707_1x1_small.jpg',
  ['frida']:
    'https://images.ctfassets.net/1k6n64mfastn/6wYj3wxv8RmTQr5YOpjXUC/511774ac90f98f6ab0fde634c93121ba/P1280278_small.jpg',
  ['jonatan']:
    'https://images.ctfassets.net/1k6n64mfastn/6Sf4fH0wZTD9B932letFxA/8b5b022c0e129e748ac64a32fff68193/P1280171_small.jpg',
  ['kevin']:
    'https://images.ctfassets.net/1k6n64mfastn/6yyNmTS5iw2zJhIdvdAqRa/8f64e84e83f79e9137beb9c8e9ac3c55/P1280340_small.jpg',
  ['love']:
    'https://images.ctfassets.net/1k6n64mfastn/79WfhtcMGGvOJZMpumVL26/8a1b57c00b02632ac4e668a063c642e8/P1280232.jpg',
  ['niklas']:
    'https://images.ctfassets.net/1k6n64mfastn/i3oPqIL0kUWSWPkkXLkRQ/56d5178b802e754814701a44f5d86b54/P1280285_small.jpg',
  ['rasmus']:
    'https://images.ctfassets.net/1k6n64mfastn/6EmMjNWCsDwLScNyGkkxeh/853b8dd31ea8d28a2ac77cca1ce5e9b6/P1280383_small.jpg'
}
