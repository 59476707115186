import React, { useMemo, useState, useEffect } from 'react'
import { getTheme } from 'themes'
import { Theme } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import FormControl from 'components_new/atoms/FormControl'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import InputLabel from 'components_new/atoms/InputLabel'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Pagination from 'components_new/atoms/Pagination'
import Select from 'components_new/atoms/Select'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import Table from 'components_new/atoms/Table'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableRow from 'components_new/atoms/Table/TableRow'
import TableBody from 'components_new/atoms/Table/TableBody'
import Tag from 'components_new/atoms/Tag'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import EmptyPlaceholder from 'components_new/molecules/EmptyPlaceholder'
import LoadingSection from 'components_new/molecules/LoadingSection'
import SectionHeader from 'components_new/molecules/SectionHeader'
import SelectSearch, { Option } from 'components_new/molecules/SelectSearch'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import {
  FlawRow,
  flawTypeColor,
  translateFlawType,
  SettingRow,
  FlawType
} from 'types/GlobalSimFlaw'
import { InternalCustomerRedux } from 'types/GlobalCustomer'

const PAGE_SIZE = 10

interface DataQualityProps {
  customers: InternalCustomerRedux
  data: FlawRow[]
  fetchFlaws: (
    customer: string,
    tables: string[],
    offset: number,
    flawType: FlawType | null
  ) => void
  fetching: boolean
  settings: {
    [key: string]: SettingRow
  }
  totalCount: number
}

const getColor = (theme: Theme, numberOfFlaws: number) => {
  if (numberOfFlaws === 0) {
    return theme.palette.success.text
  }

  if (numberOfFlaws <= 5) {
    return theme.palette.warning.text
  }

  return theme.palette.error.text
}

const DataQuality = (props: DataQualityProps) => {
  const { customers, data, fetchFlaws, fetching, settings, totalCount } = props
  const theme = getTheme('dark')

  const customerOptions: { id: string; title: string }[] = useMemo(
    () => [
      ...Object.values(customers).map((customer) => ({
        id: customer.id,
        title: customer.name
      })),
      { id: 'null', title: 'Demo' }
    ],
    [customers]
  )

  const [showHelp, setShowHelp] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [selectedCustomer, setSelectedCustomer] = useState<string>(
    customerOptions[0].id as string
  )
  const [selectedTables, setSelectedTables] = useState<Option[]>([])
  const [selectedType, setSelectedType] = useState<FlawType | null>(null)
  const offset = (page - 1) * PAGE_SIZE

  const parsedData = useMemo(() => {
    return data
      .map((row) => {
        const setting = settings[row.table_name]

        if (!setting) {
          return row
        }

        const attribute = setting.attributes.find(
          (item) => item.value === row.attribute_name
        )

        return {
          ...row,
          table_name: setting.label,
          attribute_name: attribute?.label ?? row.attribute_name
        }
      })
      .sort((a, b) => {
        if (a.table_name === b.table_name) {
          return a.attribute_name.localeCompare(b.attribute_name)
        }

        return a.table_name.localeCompare(b.table_name)
      })
  }, [data])

  useEffect(() => {
    if (selectedCustomer) {
      fetchFlaws(
        selectedCustomer,
        selectedTables.map((item) => item.id as string),
        offset,
        selectedType
      )
    }
  }, [selectedCustomer, selectedTables, offset, selectedType])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          p: 2,
          bgcolor: 'background.paper'
        }}
      >
        <FormControl size="small" sx={{ minWidth: 200 }} color="primary">
          <InputLabel id="customer-select">Kund</InputLabel>
          <Select
            labelId="customer-select"
            label={'Kund'}
            value={selectedCustomer}
            onChange={(event) => {
              setSelectedCustomer(event.target.value)
              setPage(1)
            }}
          >
            {customerOptions.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <SelectSearch
          label={'Tabell'}
          onChange={(options: Option[]) => {
            setSelectedTables(options)
            setPage(1)
          }}
          multiple
          options={Object.values(settings).map((table) => ({
            id: table.value,
            title: table.label
          }))}
          selected={selectedTables}
          sort
          sx={{ maxWidth: '40ch', mt: 0 }}
        />
        <FormControl size="small" sx={{ minWidth: 200 }} color="primary">
          <InputLabel id="type-select" shrink>
            Typ
          </InputLabel>
          <Select
            labelId="type-select"
            label={'Typ'}
            value={selectedType}
            onChange={(event) => {
              setSelectedType(event.target.value as FlawType | null)
              setPage(1)
            }}
            displayEmpty
          >
            {Object.values(FlawType).map((type) => (
              <MenuItem key={type} value={type}>
                {translateFlawType[type]}
              </MenuItem>
            ))}
            <MenuItem key={'null'} value={null}>
              Alla
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 8 }}>
        <Text variant="h4" sx={{ py: 2 }}>
          Antalet brister:
        </Text>
        <Text variant="h4" color={getColor(theme, totalCount)}>
          {totalCount}
        </Text>
        <Tooltip title="Vad valideras?">
          <IconButton onClick={() => setShowHelp(true)} size="medium">
            <Icon name="HelpOutline" />
          </IconButton>
        </Tooltip>
      </Box>
      <Divider />
      {fetching ? (
        <LoadingSection loading titles={['Hämtar brister']} sx={{ pt: 4 }} />
      ) : (
        <Box>
          <TableContainer
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ bgcolor: 'background.paper' }}>
                    Tabell
                  </TableCell>
                  <TableCell sx={{ bgcolor: 'background.paper' }}>
                    Attribut
                  </TableCell>
                  <TableCell sx={{ bgcolor: 'background.paper' }}>ID</TableCell>
                  <TableCell sx={{ bgcolor: 'background.paper' }}>
                    Värde
                  </TableCell>
                  <TableCell sx={{ bgcolor: 'background.paper' }}>
                    Typ
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!fetching && data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <EmptyPlaceholder
                        background
                        sx={{ width: '100%' }}
                        title="Det finns inga brister."
                      />
                    </TableCell>
                  </TableRow>
                ) : (
                  parsedData.map((row) => (
                    <TableRow
                      key={row.row_id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 }
                      }}
                    >
                      <TableCell>{row.table_name}</TableCell>
                      <TableCell>{row.attribute_name}</TableCell>
                      <TableCell>{row.row_id}</TableCell>
                      <TableCell>{row.row_value}</TableCell>
                      <TableCell>
                        <Tag
                          label={translateFlawType[row.type]}
                          color={flawTypeColor[row.type]}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={Math.ceil(totalCount / PAGE_SIZE)}
            onChange={(_, newPage) => setPage(newPage)}
            page={page}
            variant="outlined"
            shape="rounded"
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 2
            }}
          />
        </Box>
      )}

      <SimpleDialog
        actions={
          <Button variant="text" onClick={() => setShowHelp(false)}>
            Stäng
          </Button>
        }
        onClose={() => setShowHelp(false)}
        open={showHelp}
        title={'Vad validerar vi för data?'}
        maxWidth="md"
      >
        <TableContainer
          sx={{
            borderWidth: 1,
            borderColor: 'divider',
            borderStyle: 'solid',
            borderRadius: 1
          }}
        >
          <Table size="small" stickyHeader={true}>
            <TableHead
              sx={{
                bgcolor: 'grey.100'
              }}
            >
              <TableRow>
                <TableCell>Tabell</TableCell>
                <TableCell>Attribut</TableCell>
                <TableCell>Typ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(settings).map((setting) => (
                <>
                  <TableRow
                    key={setting.label}
                    sx={{
                      position: 'sticky',
                      top: 37,
                      zIndex: 1,
                      bgcolor: 'grey.200'
                    }}
                  >
                    <TableCell colSpan={3}>
                      <SectionHeader
                        size="small"
                        title={setting.label}
                        sx={{
                          pt: 0.5,
                          '& .MuiStack-root': { mb: 0.25 }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  {setting.attributes.map((attribute) => {
                    return (
                      <TableRow key={attribute.value} hover={true}>
                        <TableCell> </TableCell>
                        <TableCell>
                          <Text variant="body1">{attribute.label}</Text>
                        </TableCell>
                        <TableCell>
                          {Object.values(FlawType).map((type) => (
                            <Tag
                              key={type}
                              label={translateFlawType[type]}
                              color={flawTypeColor[type]}
                            />
                          ))}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SimpleDialog>
    </>
  )
}

export default DataQuality
