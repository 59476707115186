import React, { ElementType, ReactElement, ReactNode } from 'react'

import { Tooltip as MUITooltip, ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

export interface TooltipProps {
  arrow?: boolean
  children: ReactElement
  component?: ElementType<any>
  disableMaxWidth?: boolean
  disableHoverListener?: boolean
  enterDelay?: number
  followCursor?: boolean
  onClose?: () => void
  onDragEnd?: () => void
  onDragStart?: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  onOpen?: () => void
  open?: boolean
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  sx?: object
  title: ReactNode
}

/**
 * The Tooltip component display informative text
 * when users hover over, focus on, or tap an element.
 */

const Tooltip = React.forwardRef((props: TooltipProps, ref: any) => {
  const { disableMaxWidth, sx, title, ...rest } = props

  const theme = getTheme('dark')

  return (
    <MUITooltip
      PopperProps={{
        sx: { zIndex: 9999 },
        onClick: (ev) => ev.stopPropagation()
      }}
      title={
        title ? <ThemeProvider theme={theme}>{title}</ThemeProvider> : undefined
      }
      slotProps={{
        tooltip: {
          sx: {
            ...(disableMaxWidth ? { maxWidth: 'none' } : null)
          }
        }
      }}
      sx={{
        position: 'relative',
        ...sx
      }}
      {...rest}
      ref={ref}
    />
  )
})

Tooltip.defaultProps = {
  arrow: true
}

Tooltip.displayName = 'Tooltip'
export default Tooltip
