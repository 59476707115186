import React, { ElementType, ReactNode } from 'react'

import Badge from 'components_new/atoms/Badge'
import Box from 'components_new/atoms/Box'
import Link from 'components_new/atoms/Link'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemAvatar from 'components_new/atoms/List/ListItemAvatar'
import ListItemText from 'components_new/atoms/List/ListItemText'

import UserAvatar from 'components_new/molecules/UserAvatar'

import { getAvatarSize } from 'components_new/atoms/Avatar'

interface UserListItemProps {
  actions?: ReactNode
  body?: string
  component?: ElementType<any>
  customTitle?: ReactNode
  disablePadding?: boolean
  title: string
  titleAdornment?: ReactNode
  size?: 'small' | 'medium' | 'large'
  src?: string
  status?: 'error' | 'info' | 'success' | 'warning'
  sx?: object
  type?: 'customer' | 'user' | 'homepal' | 'role' | 'chart' | 'companygroup'
}

/**
 * UserListItem displays a user (or a company)
 * in a structured way suited for a list, menu
 * or a table.
 */

const UserListItem = React.forwardRef((props: UserListItemProps, ref: any) => {
  const {
    actions,
    component,
    customTitle,
    disablePadding,
    title,
    titleAdornment,
    body,
    type,
    size,
    src,
    status,
    sx
  } = props

  const isEmail = body && body.includes('@')

  return (
    <ListItem
      disablePadding={disablePadding}
      component={component ?? undefined}
      dense={size === 'small'}
      ref={ref}
      secondaryAction={actions}
      sx={sx}
    >
      <ListItemAvatar sx={{ minWidth: getAvatarSize(size).width * 1.5 }}>
        <Badge variant="dot" invisible={!status} color={status}>
          <UserAvatar
            email={body}
            name={title}
            size={size}
            src={src}
            type={type}
          />
        </Badge>
      </ListItemAvatar>
      <ListItemText
        data-openreplay-obscured
        primary={
          <>
            {customTitle ?? title}
            {titleAdornment}
          </>
        }
        primaryTypographyProps={{
          variant:
            size === 'small'
              ? 'body2'
              : size === 'large'
                ? 'subtitle1'
                : undefined,
          fontWeight: size === 'small' ? undefined : 500,
          noWrap: true
        }}
        secondary={isEmail ? <Link to={`mailto:${body}`}>{body}</Link> : body}
        secondaryTypographyProps={{
          color: 'text.secondary',
          component: Box,
          noWrap: true,
          sx: size === 'small' ? { ml: '0.5rem' } : undefined
        }}
        sx={
          size === 'small'
            ? { display: 'flex', flexDirection: 'row', minWidth: 0 }
            : undefined
        }
      />
    </ListItem>
  )
})

export default UserListItem
