import { DashboardGroup } from 'redux/reducers/Dashboards'
import { Context } from 'types/GlobalUserEvents'
import { DRAFTS, FAVORITES, NO_FOLDER } from '../FinderNavigation/utils'

export enum Variant {
  ALL = 'ALL',
  DRAFTS = 'DRAFTS',
  FAVORITES = 'FAVORITES',
  FOLDER = 'FOLDER'
}

export const translateVariant: Record<
  Variant,
  {
    title: string
    homepalTitle?: string
    onOpenContext: Context
    emptyState: {
      title: string
      body: string
      src: string
      routeToAll: boolean
    }
  }
> = {
  [Variant.ALL]: {
    title: 'Alla dashboards',
    onOpenContext: Context.FROM_LANDING_PAGE,
    emptyState: {
      title: 'Inga dashboards här',
      body: 'Det finns inga dashboards att visa från din organisation.',
      src: '/assets/illustrations/find_dashboard.svg',
      routeToAll: false
    }
  },
  [Variant.DRAFTS]: {
    title: 'Mina utkast',
    homepalTitle: 'Alla utkast',
    onOpenContext: Context.FROM_DRAFTS,
    emptyState: {
      title: 'Inga utkast här',
      body: 'Utkast hamnar här innan de har publicerats.',
      src: '/assets/illustrations/find_dashboard.svg',
      routeToAll: true
    }
  },
  [Variant.FAVORITES]: {
    title: 'Favoriter',
    onOpenContext: Context.FROM_FAVORITES,
    emptyState: {
      title: 'Inga favoriter här',
      body: 'Favoritmarkera dashboards så hamnar de här.',
      src: '/assets/illustrations/star_dashboard.svg',
      routeToAll: true
    }
  },
  [Variant.FOLDER]: {
    title: 'Alla dashboards',
    onOpenContext: Context.FROM_FOLDER,
    emptyState: {
      title: 'Inga dashboards här',
      body: 'Flytta en dashboard till mappen så hamnar de här.',
      src: '/assets/illustrations/find_dashboard.svg',
      routeToAll: true
    }
  }
}

export const getEmptyState = (showFavorites: boolean) => {
  if (showFavorites) {
    return {
      title: 'Inga favoriter här',
      body: 'Favoritmarkera dashboards så hamnar de här.',
      src: '/assets/illustrations/star_dashboard.svg',
      routeToAll: true
    }
  }

  return {
    title: 'Inga dashboards här',
    body: 'Det finns inga dashboards att visa från din organisation.',
    src: '/assets/illustrations/find_dashboard.svg',
    routeToAll: false
  }
}

export const getSelectedGroups = (
  groupedDashboards: { [key: string]: DashboardGroup[] },
  showFavorites: boolean,
  showDrafts: boolean,
  folderId?: string | null
) => {
  if (showFavorites) {
    return groupedDashboards[FAVORITES] || []
  }

  if (folderId) {
    return groupedDashboards[folderId] || []
  }

  if (showDrafts) {
    return groupedDashboards[DRAFTS] || []
  }

  return groupedDashboards[NO_FOLDER] || []
}

export enum SortByMethod {
  LAST_VIEWED = 'LAST_VIEWED',
  ALPHABETICAL = 'ALPHABETICAL',
  UPDATED_AT = 'UPDATED_AT'
}
