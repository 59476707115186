import * as Types from 'redux/Types'
import { cloneDeep } from 'lodash'
import { createAction, createReducer } from '@reduxjs/toolkit'
import { arrayToObject } from 'helpers/Functions'
import { FilterOption } from 'utils/types'
import {
  KpiTemplateReducerType,
  KpiTemplate,
  KpiTemplateFilterOptionsBody,
  KpiTemplateRedux
} from 'types/GlobalKpiTemplates'

// Initial state
const INITIAL_STATE: KpiTemplateReducerType = {
  data: {},
  fetchedKpis: false,
  fetchingKpis: false,
  fetchedFilterOptions: false,
  filterOptions: {}
}

// Actions
const getAllActionKpis = createAction(Types.GET_KPI_TEMPLATES)
const getAllActionKpisSuccess = createAction<{
  data: KpiTemplate[]
}>(Types.GET_KPI_TEMPLATES_SUCCESS)

const updateKpiTemplate = createAction<{
  data: KpiTemplate
}>(Types.UPDATE_KPI_TEMPLATE_SUCCESS)
const createKpiTemplate = createAction<{
  data: KpiTemplate
}>(Types.CREATE_KPI_TEMPLATE_SUCCESS)
const deleteKpiTemplate = createAction<string>(
  Types.DELETE_KPI_TEMPLATE_SUCCESS
)

const getAllFilterOptionsActionSuccess = createAction<{
  data: FilterOption[]
}>(Types.GET_FILTER_OPTIONS_SUCCESS)
const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const kpiTemplateReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAllActionKpis, (state) => {
      return {
        ...state,
        fetchedKpis: false,
        fetchingKpis: true
      }
    })
    .addCase(getAllActionKpisSuccess, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: arrayToObject(payload.data) as KpiTemplateRedux,
        fetchedKpis: true,
        fetchingKpis: false
      }
    })
    .addCase(getAllFilterOptionsActionSuccess, (state, action) => {
      const { payload } = action

      const filterOptions: KpiTemplateFilterOptionsBody = {}

      Object.values(payload.data).forEach((option) => {
        filterOptions[option.id] = option
      })

      return {
        ...state,
        filterOptions,
        fetchedFilterOptions: true
      }
    })
    .addCase(updateKpiTemplate, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: { ...state.data, [payload.data.id]: payload.data }
      }
    })
    .addCase(createKpiTemplate, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: { ...state.data, [payload.data.id]: payload.data }
      }
    })
    .addCase(deleteKpiTemplate, (state, action) => {
      const { payload } = action
      const newData = cloneDeep(state.data)

      delete newData[payload]

      return {
        ...state,
        data: newData
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default kpiTemplateReducer
