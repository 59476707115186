import React, { useState } from 'react'

import { useDashboard, useDashboardGroup } from 'redux/hooks/Dashboards'

import * as DashboardActions from 'redux/actions/Dashboards'

import { ApplicationState } from 'redux/Stores/types'
import { Dispatch, bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'

import { formatDashboardFilterOptions } from 'utils/functions'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'

import SidebarDialog from 'components_new/molecules/SidebarDialog'

import EmbedSection from 'components_new/organisms/EmbedSection'
import DisabledWrapper from 'components_new/organisms/EmbedSection/components/DisabledWrapper'
import Filter, {
  TFilter
} from 'components_new/organisms/EmbedSection/components/Filter'

interface EmbedDashboardDialogProps {
  id: string
  onClose: () => void
  open: boolean
}

const EmbedDashboardDialog = (props: ComponentProps) => {
  const {
    id,
    onClose,
    open,
    // redux stores
    AuthStore,
    DashboardFilterOptionsStore,
    CompanyGroupStore,
    // redux actions
    tryUpdateDashboard
  } = props

  const handleClose = () => {
    onClose()
  }

  const [filter, setFilter] = useState<TFilter>({})

  const dashboard = useDashboard(id)
  const group = useDashboardGroup(id)

  if (!dashboard || !group) return null

  const allowEmbed = Boolean(AuthStore.customer?.allow_access?.embed)
  const apiAccess = dashboard.share_settings?.api_access ?? false
  const apiAccessLink = dashboard.share_settings?.api_access_link ?? ''
  const isPublic = dashboard.share_settings?.is_public ?? false
  const publicLink = dashboard.share_settings?.public_link ?? ''
  const isManagementCompany = Boolean(AuthStore.customer?.is_management_company)

  const dashboardFilterOptions = formatDashboardFilterOptions(
    group.dashboard_filters,
    DashboardFilterOptionsStore,
    CompanyGroupStore.showAs
  )

  const setApiAccess = (value: boolean) => {
    tryUpdateDashboard(dashboard.id, {
      data: { api_access: value, is_public: false }
    })
  }

  const setIsPublic = (value: boolean) => {
    tryUpdateDashboard(dashboard.id, {
      data: { is_public: value, api_access: false }
    })
  }

  return (
    <SidebarDialog
      actions={
        <Button onClick={handleClose} variant="text">
          Stäng
        </Button>
      }
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 'md'
        }
      }}
      rightSidebar={
        <Box sx={{ p: 2 }}>
          <DisabledWrapper disabled={!isPublic && !apiAccess}>
            <Filter
              embedComponent={'tab'}
              filter={filter}
              filterOptions={dashboardFilterOptions}
              setFilter={setFilter}
            />
          </DisabledWrapper>
        </Box>
      }
      title={`Bädda in ${dashboard.title}`}
    >
      <Box>
        <EmbedSection
          allowEmbed={allowEmbed}
          apiAccess={apiAccess}
          apiAccessLink={apiAccessLink}
          controlledFilter={filter}
          embedComponent="tab"
          filterOptions={dashboardFilterOptions}
          id={id}
          isManagementCompany={isManagementCompany}
          isPublic={isPublic}
          publicLink={publicLink}
          setApiAccess={setApiAccess}
          setIsPublic={setIsPublic}
        />
      </Box>
    </SidebarDialog>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  DashboardFilterOptionsStore: state.DashboardFilterOptionsStore,
  CompanyGroupStore: state.CompanyGroupStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...DashboardActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  EmbedDashboardDialogProps

export default connector(EmbedDashboardDialog)
