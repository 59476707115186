import { cloneDeep } from 'lodash'
import { DashboardFilter } from 'types/GlobalDashboardFilter'
import {
  ComparativePeriod,
  CustomPeriodFilter,
  DateFilterType,
  ParsedSegmentPath,
  PeriodFilter,
  WidgetObject
} from 'types/GlobalWidget'
import { translateComparativePeriod } from 'utils/enumTranslator'

export const getSegmentSetters = (
  widget: WidgetObject,
  dashboardFilter: DashboardFilter,
  setDashboardFilter: (
    dbFilter: DashboardFilter,
    periodFilter?: CustomPeriodFilter
  ) => void,
  showUnderlyingContent: (
    segmentPaths: ParsedSegmentPath[],
    initialKpiOptionId: string | null
  ) => void,
  setCustomSegmentBy: (
    attributeOptionId: string,
    dbFilter: DashboardFilter,
    periodFilter?: CustomPeriodFilter
  ) => void,
  standaloneWidget: boolean,
  embedded: boolean,
  interactive: boolean
) => {
  const allowClick =
    widget.settings.segment_by.allow_click && !embedded && interactive

  const allowFilter =
    widget.settings.segment_by.allow_click && !standaloneWidget && interactive

  const allowDetails =
    allowClick &&
    widget.settings.kpi_options.some((option) => option.can_show_details)

  const allowExternalLink =
    allowClick &&
    widget.settings.external_link.available &&
    widget.settings.external_link.show

  return {
    onClickExternalLink: allowExternalLink
      ? (link: string | null) => (link ? window.open(link, '_blank') : {})
      : null,
    setCustomSegmentBy: allowClick
      ? (attributeOptionId: string, filters: ParsedSegmentPath[]) => {
          const newRelationKeys = filters.map((item) => item.relation_key)

          // remove any affected filters
          const newDashboardFilter = cloneDeep(dashboardFilter).filter(
            (filter) => !newRelationKeys.includes(filter.relation_key)
          )

          let customPeriodFilter: CustomPeriodFilter | undefined = undefined

          filters.forEach((filter) => {
            if (filter.period) {
              customPeriodFilter = {
                from: filter.period.from_date,
                to: filter.period.to_date
              }
            } else {
              newDashboardFilter.push({
                relation_key: filter.relation_key,
                attribute_option_id: filter.attribute_option_id,
                attribute: filter.attribute,
                value: filter.label
              })
            }
          })

          setCustomSegmentBy(
            attributeOptionId,
            newDashboardFilter,
            customPeriodFilter
          )
        }
      : null,
    setDetailsDialog: allowDetails
      ? (filters: ParsedSegmentPath[]) => showUnderlyingContent(filters, null)
      : null,
    setSegmentFilters: allowFilter
      ? (filters: ParsedSegmentPath[]) => {
          const newRelationKeys = filters.map((item) => item.relation_key)

          // remove any affected filters
          const newDashboardFilter = cloneDeep(dashboardFilter).filter(
            (filter) => !newRelationKeys.includes(filter.relation_key)
          )

          let customPeriodFilter: CustomPeriodFilter | undefined = undefined

          filters.forEach((filter) => {
            if (filter.period) {
              customPeriodFilter = {
                from: filter.period.from_date,
                to: filter.period.to_date
              }
            } else {
              newDashboardFilter.push({
                relation_key: filter.relation_key,
                attribute_option_id: filter.attribute_option_id,
                attribute: filter.attribute,
                value: filter.label
              })
            }
          })

          setDashboardFilter(newDashboardFilter, customPeriodFilter)
        }
      : null
  }
}

export const getIsComparativeButIllegal = (
  dateFilter: PeriodFilter,
  comparativePeriod: ComparativePeriod | null,
  dateFilterType: DateFilterType
) => {
  if (comparativePeriod) {
    return !(
      comparativePeriod in
      translateComparativePeriod[dateFilterType][dateFilter]
    )
  }

  return false
}
