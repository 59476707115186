// Component copied from MUI docs
import * as React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { VariableSizeList, ListChildComponentProps } from 'react-window'
import ListSubheader from 'components_new/atoms/List/ListSubheader'

const LISTBOX_PADDING = 8 // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  const dataSet = data[index]

  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  }

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    )
  }

  const { key } = dataSet

  return <li key={key} {...dataSet.props} style={inlineStyle} />
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)

  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)

  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])

  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement> & { itemHeight?: number }
>(function ListboxComponent(props, ref) {
  const { children, itemHeight, ...other } = props

  const itemData: React.ReactElement<any>[] = []

  ;(children as React.ReactElement<any>[]).forEach(
    (
      item: React.ReactElement<any> & { children?: React.ReactElement<any>[] }
    ) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    }
  )
  const isGroup =
    children &&
    (children as any[]).length > 0 &&
    (children as any[])[0].hasOwnProperty('group')

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true
  })
  const itemCount = itemData.length
  let itemSize = smUp ? 36 : 48

  if (itemHeight) {
    itemSize = itemHeight
  }

  const getChildSize = (child: React.ReactElement<any>) => {
    // Header
    if (child.hasOwnProperty('group')) {
      return 48
    }

    // items
    if (isGroup) {
      return 56
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }

    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

export default ListboxComponent
