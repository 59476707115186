import React, { useState } from 'react'

import { ParsedDashboardFilterOptions } from 'types/GlobalDashboardFilter'

import Box from 'components_new/atoms/Box'
import Collapse from 'components_new/atoms/Collapse'

import FormSection from 'components_new/molecules/FormSection'
import IconTip from 'components_new/molecules/IconTip'
import SectionHeader from 'components_new/molecules/SectionHeader'

import AccessToggle from './components/AccessToggle'
import ApiDetails from './components/ApiDetails'
import Dimensions, { TDimensions } from './components/Dimensions'
import DisabledWrapper from './components/DisabledWrapper'
import Features from './components/Features'
import Filter, { TFilter } from './components/Filter'
import NotInLicense from './components/NotInLicense'
import Other from './components/Other'
import Output, { TOutputMode } from './components/Output'
import ResponsiveMode from './components/ResponsiveMode'

import { TEmbedComponent } from './types'
import { REmbedComponent } from './lib'

interface EmbedSectionProps {
  allowEmbed: boolean
  apiAccess?: boolean
  apiAccessLink: string
  controlledFilter?: TFilter
  embedComponent: TEmbedComponent
  filterOptions: Map<string, ParsedDashboardFilterOptions>
  id: string
  isManagementCompany?: boolean
  isPublic: boolean
  publicLink: string
  setApiAccess?: (apiAccess: boolean) => void
  setIsPublic: (value: boolean) => void
}

const EmbedSection = (props: EmbedSectionProps) => {
  const {
    allowEmbed,
    apiAccess,
    apiAccessLink,
    controlledFilter,
    embedComponent,
    filterOptions,
    isManagementCompany = false,
    isPublic,
    publicLink,
    setApiAccess,
    setIsPublic
  } = props

  const [breakpoint, setBreakpoint] = useState<string | null>(null)
  const [dimensions, setDimensions] = useState<TDimensions>({
    width: '100%',
    height: '100%'
  })
  const [filter, setFilter] = useState<TFilter>({})
  const [hideFooter, setHideFooter] = useState<boolean>(false)
  const [imageExport, setImageExport] = useState<boolean>(false)
  const [mobile, setMobile] = useState<boolean>(false)
  const [outputMode, setOutputMode] = useState<TOutputMode>('iframe')
  const [userFilter, setUserFilter] = useState<boolean>(false)

  return (
    <>
      {allowEmbed ? (
        <>
          <FormSection
            helperText={
              <>
                För att kunna bädda in din{' '}
                {REmbedComponent[embedComponent].title.toLowerCase()} måste du
                välja en extern åtkomsttyp.
              </>
            }
            title="Aktivera extern åtkomst"
          >
            <AccessToggle
              apiAccess={apiAccess}
              embedComponent={embedComponent}
              isManagementCompany={isManagementCompany}
              isPublic={isPublic}
              setApiAccess={setApiAccess}
              setIsPublic={setIsPublic}
            />
          </FormSection>
          <DisabledWrapper disabled={!isPublic && !apiAccess}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ flex: 1 }}>
                {apiAccess ? <ApiDetails /> : null}
                <FormSection
                  helperText={
                    <>
                      Använd koden nedan i din tredjepartsapplikation (eller
                      öppna länken direkt i din webbläsare) för att visa din{' '}
                      {REmbedComponent[embedComponent].title.toLowerCase()}. Du
                      kan använda filtret till höger eller inställningarna nedan
                      för att modifiera din kod (eller länk) utifrån dina behov.
                    </>
                  }
                  title="Kopiera och klistra in"
                >
                  <Output
                    apiAccess={apiAccess}
                    apiAccessLink={apiAccessLink}
                    breakpoint={breakpoint}
                    dimensions={dimensions}
                    embedComponent={embedComponent}
                    filter={controlledFilter ?? filter}
                    hideFooter={hideFooter}
                    imageExport={imageExport}
                    isPublic={isPublic}
                    mobile={mobile}
                    outputMode={outputMode}
                    publicLink={publicLink}
                    setOutputMode={setOutputMode}
                    sx={{ mb: 4 }}
                    userFilter={userFilter}
                  />
                </FormSection>
                <SectionHeader
                  sx={{ mb: 2 }}
                  title="Inställningar"
                  titleAdornment={
                    <IconTip title="De här inställningarna sparas inte permanent utan används tillfälligt för att hjälpa dig lägga till dem i din länk eller ditt kodblock." />
                  }
                />
                <Box
                  sx={{
                    p: 2,
                    bgcolor: 'action.hover',
                    borderRadius: 1
                  }}
                >
                  <Collapse in={outputMode === 'iframe'}>
                    <Dimensions
                      dimensions={dimensions}
                      embedComponent={embedComponent}
                      setDimensions={setDimensions}
                    />
                  </Collapse>
                  <Collapse in={embedComponent === 'tab'}>
                    <ResponsiveMode
                      breakpoint={breakpoint}
                      embedComponent={embedComponent}
                      mobile={mobile}
                      setBreakpoint={setBreakpoint}
                      setMobile={setMobile}
                    />
                  </Collapse>
                  <Collapse in={embedComponent === 'tab' && apiAccess}>
                    <Features
                      embedComponent={embedComponent}
                      imageExport={imageExport}
                      setImageExport={setImageExport}
                      setUserFilter={setUserFilter}
                      userFilter={userFilter}
                    />
                  </Collapse>
                  <Collapse in={embedComponent === 'tab' && apiAccess}>
                    <Other
                      embedComponent={embedComponent}
                      hideFooter={hideFooter}
                      setHideFooter={setHideFooter}
                    />
                  </Collapse>
                </Box>
              </Box>
              {!controlledFilter ? (
                <Box sx={{ width: 320, px: 2 }}>
                  <Filter
                    embedComponent={embedComponent}
                    filter={filter}
                    filterOptions={filterOptions}
                    setFilter={setFilter}
                  />
                </Box>
              ) : null}
            </Box>
          </DisabledWrapper>
        </>
      ) : (
        <NotInLicense />
      )}
    </>
  )
}

export default EmbedSection
