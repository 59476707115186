import React, { useEffect, useState } from 'react'

import Avatar from 'components_new/atoms/Avatar'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Divider from 'components_new/atoms/Divider'
import FormControl from 'components_new/atoms/FormControl'
import Grid from 'components_new/atoms/Grid'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import InputLabel from 'components_new/atoms/InputLabel'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemAvatar from 'components_new/atoms/List/ListItemAvatar'
import ListItemText from 'components_new/atoms/List/ListItemText'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Paper from 'components_new/atoms/Paper'
import Select from 'components_new/atoms/Select'
import Tag from 'components_new/atoms/Tag'
import Tooltip from 'components_new/atoms/Tooltip'

import AdvancedDialog from 'components_new/molecules/AdvancedDialog'
import UserListItem from 'components_new/molecules/UserListItem'

import {
  ActivationType,
  TrackingUser,
  translateActivationType,
  UserTitle
} from 'types/GlobalTracking'
import {
  translateTitle,
  translateUserStatus,
  translateUserTag
} from 'components_new/pages/internal/UsersTracking/utils'
import { formatValue } from 'components_new/organisms/Widget/types/utils'
import { getParsedDate } from 'utils/dateParser'
import { getTitleOptions } from 'components_new/templates/internal/UsersTracking/UserTrackingKanban/utils'

import Loading from './loading'
import FormControlLabel from 'components_new/atoms/FormControlLabel'
import Switch from 'components_new/atoms/Switch'
import { PatchUserBody } from 'types/GlobalUser'

interface UserTrackingSidebarProps {
  updateUser: (body: PatchUserBody) => void
  user: TrackingUser
}

interface AvatarSectionProps {
  user: TrackingUser
}

interface InformationSectionProps {
  user: TrackingUser
  updateUser: (body: PatchUserBody) => void
}

interface TagSectionProps {
  user: TrackingUser
}

interface EditUserModalProps {
  onClose: () => void
  open: boolean
  updateUser: (body: PatchUserBody) => void
  user: TrackingUser
}

const UserTrackingSidebar = (props: UserTrackingSidebarProps) => {
  const { updateUser, user } = props

  const [editUser, setEditUser] = useState(false)

  if (!Boolean(user)) {
    return <Loading />
  }

  return (
    <>
      <Paper
        sx={{
          height: '100%',
          width: '100%',
          p: 2
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            startIcon={<Icon name="ArrowBack" />}
            variant="text"
            to={'/tracking/users'}
          >
            Tillbaka
          </Button>
          <Box>
            <Tooltip
              title={
                user.open_replay_url
                  ? 'Visa inspelningar'
                  : 'Det finns inga inspelningar'
              }
            >
              <IconButton
                color={user.open_replay_url ? 'primary' : 'disabled'}
                onClick={
                  user.open_replay_url
                    ? () =>
                        window.open(user.open_replay_url as string, '_blank')
                    : () => {}
                }
                disabled={!Boolean(user.open_replay_url)}
                size={'small'}
              >
                <Icon name="EmergencyRecordingOutlined" />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Redigera användare'}>
              <IconButton
                color="primary"
                onClick={() => setEditUser(true)}
                size={'small'}
              >
                <Icon name="EditOutlined" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <AvatarSection user={user} />
        <Divider />
        <InformationSection
          user={user}
          updateUser={(body) => updateUser(body)}
        />
        <Divider />
        <TagSection user={user} />
      </Paper>
      <EditUserModal
        user={user}
        open={editUser}
        onClose={() => setEditUser(false)}
        updateUser={(body) => updateUser(body)}
      />
    </>
  )
}

const AvatarSection = (props: AvatarSectionProps) => {
  const { user } = props

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        py: 1
      }}
    >
      <UserListItem
        body={user.customer.name}
        component="div"
        size="large"
        status={translateUserStatus[user.swimlane].severity}
        sx={{ p: 0, width: 'auto' }}
        title={user.full_name ?? user.email}
      />
      {user.is_admin ? <Chip label={'Admin'} size="small" /> : null}
    </Box>
  )
}

const InformationSection = (props: InformationSectionProps) => {
  const { user, updateUser } = props

  return (
    <List dense={true}>
      <ListItem>
        <ListItemText
          primary="Status"
          secondary={
            <Tooltip title={translateUserStatus[user.swimlane].body}>
              <Box component="span">
                <Tag
                  color={translateUserStatus[user.swimlane].severity}
                  label={translateUserStatus[user.swimlane].title}
                />
              </Box>
            </Tooltip>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemText primary="Email" secondary={user.email} />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Roll"
          secondary={user.title ? translateTitle[user.title] : 'Saknas 🤥'}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Inbjuden"
          secondary={getParsedDate(user.data.invited_at)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Accepterad"
          secondary={
            user.data.invite_accepted_at
              ? getParsedDate(user.data.invite_accepted_at)
              : '–'
          }
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Angiven roll"
          secondary={
            user.additional_information ? user.additional_information.role : '–'
          }
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Angiven preferens"
          secondary={
            user.additional_information
              ? user.additional_information.preference
              : '–'
          }
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Ignorera för statistik"
          secondary={
            <FormControlLabel
              control={<Switch color="primary" size={'small'} />}
              checked={user.ignore_from_statistics}
              onChange={() =>
                updateUser({
                  data: { ignore_from_statistics: !user.ignore_from_statistics }
                })
              }
              label=""
              labelPlacement="end"
            />
          }
        />
      </ListItem>
    </List>
  )
}

const TagSection = (props: TagSectionProps) => {
  const { user } = props

  return (
    <List dense={true}>
      {user.data.activated_at ? (
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              {
                translateActivationType[
                  user.data.activation_type as ActivationType
                ].icon
              }
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              translateActivationType[
                user.data.activation_type as ActivationType
              ].title
            }
            primaryTypographyProps={{ variant: 'subtitle2' }}
            secondary={
              translateActivationType[
                user.data.activation_type as ActivationType
              ].body
            }
          />
        </ListItem>
      ) : null}
      {user.tags.map((tag) => {
        if (tag.value) {
          return (
            <ListItem key={tag.id}>
              <ListItemAvatar>
                <Avatar>{translateUserTag[tag.id].icon}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={translateUserTag[tag.id].title}
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={
                  <>
                    {formatValue(tag.count, null, 0, true, null)}
                    {tag.count
                      ? ` ${translateUserTag[tag.id].body.toLowerCase()}`
                      : translateUserTag[tag.id].body}
                  </>
                }
              />
            </ListItem>
          )
        }

        return null
      })}
    </List>
  )
}

const EditUserModal = (props: EditUserModalProps) => {
  const { onClose, open, user, updateUser } = props

  const [title, setTitle] = useState('')

  const titleOptions = getTitleOptions()

  useEffect(() => {
    if (open) {
      setTitle(user.title || '')
    }
  }, [open])

  return (
    <AdvancedDialog
      title={'Redigera användare'}
      open={open}
      onClose={onClose}
      actions={[
        <Button variant="text" onClick={onClose} key="close">
          Stäng
        </Button>,
        <Button
          onClick={() => {
            updateUser({
              data: { title: title as UserTitle }
            })

            onClose()
          }}
          key="update"
          disabled={title === ''}
        >
          Uppdatera
        </Button>
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth size="small" sx={{ mt: 2, mb: 1 }}>
            <InputLabel id="license-label">{'Titel'}</InputLabel>
            <Select
              label={'Titel'}
              labelId="license-label"
              onChange={(event) => setTitle(event.target.value)}
              value={title ?? ''}
            >
              {titleOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </AdvancedDialog>
  )
}

export default UserTrackingSidebar
