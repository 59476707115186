import React, { useMemo, useState } from 'react'

import { SxProps } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import InputAdornment from 'components_new/atoms/InputAdornment'
import Snackbar from 'components_new/atoms/Snackbar'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'
import ToggleButton from 'components_new/atoms/ToggleButton'
import Tooltip from 'components_new/atoms/Tooltip'

import { TDimensions } from './Dimensions'
import { TEmbedComponent } from '../types'
import { REmbedComponent } from '../lib'

export type TOutputMode = 'iframe' | 'link'

interface OutputProps {
  apiAccess?: boolean
  apiAccessLink: string
  breakpoint: string | null
  dimensions: TDimensions
  embedComponent: TEmbedComponent
  filter: { [key: string]: string[] }
  hideFooter: boolean
  imageExport: boolean
  isPublic: boolean
  mobile: boolean
  outputMode: TOutputMode
  publicLink: string
  setOutputMode: (value: TOutputMode) => void
  sx?: SxProps
  userFilter: boolean
}

const Output = (props: OutputProps) => {
  const {
    apiAccess,
    apiAccessLink,
    breakpoint,
    dimensions,
    embedComponent,
    filter,
    hideFooter,
    imageExport,
    isPublic,
    mobile,
    outputMode,
    publicLink,
    setOutputMode,
    sx,
    userFilter
  } = props

  // Output link
  const outputLink = useMemo(() => {
    const url = new URL(apiAccess ? apiAccessLink : publicLink)

    // token placeholder
    if (apiAccess) {
      url.searchParams.set('token', 'YOUR_TOKEN')
    }

    // responsive mode and breakpoint
    if (mobile) {
      url.searchParams.set('responsive_mode', 'mobile')
      if (breakpoint) {
        url.searchParams.set('mobile_breakpoint', breakpoint)
      }
    }

    // user filter
    if (userFilter) {
      url.searchParams.set('filter', 'true')
    }

    // image export
    if (imageExport) {
      url.searchParams.set('export', 'true')
    }

    // hide footer
    if (hideFooter) {
      url.searchParams.set('hide_footer', 'true')
    }

    // filters
    for (const [key, values] of Object.entries(filter)) {
      values.forEach((value) => {
        url.searchParams.append(`filter[${key}][eq]`, value)
      })
    }

    return url.toString()
  }, [
    apiAccessLink,
    breakpoint,
    filter,
    hideFooter,
    imageExport,
    isPublic,
    mobile,
    publicLink,
    userFilter
  ])

  // iframes
  const outputIframe = `<iframe width="${dimensions.width}" height="${dimensions.height}" src="${outputLink}" title="Embedded ${embedComponent} by Homepal" frameborder="0"></iframe>`
  const previewIframe = `<iframe width="100%" height="100%" src="${outputLink}" title="Embedded ${embedComponent} by Homepal" frameborder="0"></iframe>`

  // Generic output
  const output = useMemo(() => {
    if (outputMode === 'iframe') {
      return outputIframe
    }

    return outputLink
  }, [outputMode, outputLink, outputIframe])

  // Handle copy
  const [showCopiedMessage, setShowCopiedMessage] = useState(false)

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(output)
    setShowCopiedMessage(true)
  }

  const handleCloseCopiedMessage = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return
    }
    setShowCopiedMessage(false)
  }

  return (
    <>
      <Box sx={sx}>
        {isPublic ? (
          <Box
            sx={{
              p: 2,
              mb: 2,
              bgcolor: 'action.hover',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                width: '64vh',
                maxWidth: 640,
                height: '36vh',
                maxHeight: 640,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'background.paper'
              }}
            >
              {isPublic ? (
                <div
                  dangerouslySetInnerHTML={{ __html: previewIframe }}
                  style={{ width: '100%', height: '100%' }}
                />
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Icon name={'PublicOffOutlined'} />
                  <Text fontWeight="medium">
                    Privat {REmbedComponent[embedComponent].title.toLowerCase()}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        ) : null}
        <ToggleButton
          items={[
            {
              title: 'Kod',
              iconName: 'CodeOutlined',
              value: 'iframe'
            },
            {
              title: 'Länk',
              iconName: 'LinkOutlined',
              value: 'link'
            }
          ]}
          value={outputMode}
          onChange={(event, value) => setOutputMode(value)}
          size="small"
        />
        <TextField
          multiline={outputMode === 'iframe'}
          rows={5}
          value={output}
          fullWidth
          helperText={
            apiAccess ? (
              <>
                {' '}
                <code>YOUR_TOKEN</code> måste ersättas av den token du får från
                din API-förfrågan ovan.
              </>
            ) : null
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title={
                    outputMode === 'iframe'
                      ? 'Kopiera inbäddningskod'
                      : 'Kopiera inbäddningslänk'
                  }
                >
                  <IconButton color="primary" onClick={copyLinkToClipboard}>
                    <Icon name="ContentCopy" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
            readOnly: true
          }}
          sx={{ mt: 1 }}
        />
      </Box>

      {/*-- feedback --*/}
      <Snackbar
        open={showCopiedMessage}
        autoHideDuration={3000}
        onClose={handleCloseCopiedMessage}
        message="Kopierat till urklipp"
      />
    </>
  )
}

export default Output
