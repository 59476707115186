import React, { useState, useMemo } from 'react'

import { AccountType, RoleReducerType } from 'redux/reducers/Accounts'
import { CompanyGroup, ParsedCompanyGroup } from 'types/GlobalCompanyGroups'
import { Company, PatchCompanyBody } from 'types/GlobalCompany'
import { PostUserBody } from 'types/GlobalUser'
import { AuthenticationState } from 'types/GlobalAuthentication'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Link from 'components_new/atoms/Link'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Switch from 'components_new/atoms/Switch'
import TextField from 'components_new/atoms/TextField'

import AdvancedDialog from 'components_new/molecules/AdvancedDialog'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import DeleteDialog from 'components_new/organisms/dialogs/DeleteDialog'
import EditCompanyGroupDialog from 'components_new/organisms/dialogs/EditCompanyGroupDialog'

import { DELETE_ICON, EDIT_ICON } from 'utils/icons'

interface Props {
  AuthStore: AuthenticationState
  addCompanyToCompanyGroup: (
    id: string,
    companyId: string,
    callback?: (err?: boolean) => void
  ) => void
  companies: Company[]
  companyGroups: CompanyGroup[]
  createCompanyGroup: (name: string) => void
  createUser: (body: PostUserBody) => void
  deleteCompanyGroup: (id: string, callback?: (err?: boolean) => void) => void
  deleteUser: (id: string) => void
  hasIdp: boolean
  isHomepal?: boolean
  removeCompanyFromCompanyGroup: (
    id: string,
    companyId: string,
    callback?: (err?: boolean) => void
  ) => void
  roles: RoleReducerType
  updateCompany: (id: string, body: PatchCompanyBody) => void
  updateCompanyGroup: (
    id: string,
    name?: string,
    economySetId?: string | null,
    callback?: (err?: boolean) => void
  ) => void
  users: AccountType[]
}

const CompaniesTemplate = (props: Props) => {
  const {
    AuthStore,
    addCompanyToCompanyGroup,
    companies,
    companyGroups,
    createCompanyGroup,
    createUser,
    deleteCompanyGroup,
    deleteUser,
    hasIdp,
    removeCompanyFromCompanyGroup,
    updateCompany,
    updateCompanyGroup,
    users
  } = props
  const [
    showCompaniesWithoutCompanyGroup,
    setShowCompaniesWithoutCompanyGroup
  ] = useState<boolean>(false)

  const [createCompanyGroupOpen, setCreateCompanyGroupOpen] =
    useState<boolean>(false)
  const [newCompanyGroupName, setNewCompanyGroupName] = useState<string>('')
  const [idsToShow, setIdsToShow] = useState<{ [id: string]: boolean }>({})

  const [editCompanyGroup, setEditCompanyGroup] =
    useState<ParsedCompanyGroup | null>(null)

  const [companyGroupToDelete, setCompanyGroupToDelete] =
    useState<ParsedCompanyGroup | null>(null)

  const parsedCompanyGroups: ParsedCompanyGroup[] = useMemo(() => {
    const newGroups = companyGroups.map((companyGroup) => {
      const filteredCompanies = companies.filter(
        (company) =>
          companyGroup.companies.includes(company.id) && !company.ignore
      )

      return {
        id: companyGroup.id,
        name: companyGroup.name,
        accounts: users.filter((u) => u.company_group_id === companyGroup.id),
        companies: filteredCompanies
      }
    })

    if (editCompanyGroup) {
      setEditCompanyGroup(
        newGroups.find(
          (g) => g.id === editCompanyGroup.id
        ) as ParsedCompanyGroup
      )
    }

    return newGroups
  }, [companies, companyGroups, users])

  const companiesWithoutCompanyGroup = useMemo(
    () =>
      companies.filter(
        (company) =>
          !companyGroups.some((companyGroup) =>
            companyGroup.companies.includes(company.id)
          ) && !company.ignore
      ),
    [companies, companyGroups]
  )

  const integrateCompanyGroups = AuthStore.customer?.integrate_company_groups

  return (
    <Box sx={{ maxWidth: 'content' }}>
      {AuthStore.customer?.is_management_company ? (
        <SettingsGroup
          helperText={
            companiesWithoutCompanyGroup.length > 0 ? (
              <>
                <strong>
                  <Link
                    color="inherit"
                    // eslint-disable-next-line max-len
                    onClick={() => setShowCompaniesWithoutCompanyGroup(true)}
                  >
                    {companiesWithoutCompanyGroup.length} bolag
                  </Link>{' '}
                  saknar koncern.
                </strong>{' '}
                Data för dessa bolag kommer inte att visas i uppsatta
                dashboards.
              </>
            ) : null
          }
          title="Koncerner"
        >
          {parsedCompanyGroups
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((companyGroup) => (
              <SettingsItem
                actions={
                  <>
                    <IconButton
                      onClick={() => setEditCompanyGroup(companyGroup)}
                      size="small"
                    >
                      <Icon fontSize="small" name={EDIT_ICON} />
                    </IconButton>
                    {integrateCompanyGroups ? null : (
                      <IconButton
                        onClick={() => setCompanyGroupToDelete(companyGroup)}
                        size="small"
                      >
                        <Icon fontSize="small" name={DELETE_ICON} />
                      </IconButton>
                    )}
                  </>
                }
                alignItems={'center'}
                body={
                  <>
                    {companyGroup.companies.length} bolag •{' '}
                    {companyGroup.accounts.length} användare •{' '}
                    {idsToShow[companyGroup.id] ? (
                      companyGroup.id
                    ) : (
                      <Link
                        onClick={() =>
                          setIdsToShow({
                            ...idsToShow,
                            [companyGroup.id]: true
                          })
                        }
                      >
                        Visa id
                      </Link>
                    )}
                  </>
                }
                icon={<Icon color="disabled" name="Business" />}
                key={companyGroup.id}
                title={companyGroup.name}
              />
            ))}
          {integrateCompanyGroups ? null : (
            <SettingsItem
              onClick={() => setCreateCompanyGroupOpen(true)}
              title="Lägg till"
              variant="add"
            />
          )}
        </SettingsGroup>
      ) : null}

      <SettingsGroup
        helperText={
          <>
            <b>Välj vilka bolag som ska inkluderas i datasynkroniseringen.</b>{' '}
            Data som är kopplad till exkluderade bolag kommer inte att vara
            tillgänglig i organisationens dashboards. Detta bör användas för att
            till exempel exkludera testbolag eller inaktiva bolag. Uppdateringar
            kommer att slå igenom i era dashboards vid nästa inläsning.
          </>
        }
        title="Bolag"
      >
        {companies
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((company) => (
            <SettingsItem
              key={company.id}
              actions={
                <Switch
                  checked={!company.ignore}
                  color="success"
                  onChange={() =>
                    updateCompany(company.id, {
                      data: { ignore: !company.ignore }
                    })
                  }
                  size="small"
                />
              }
              icon={<Icon color="disabled" name="ApartmentOutlined" />}
              title={company.name}
            />
          ))}
      </SettingsGroup>

      {/* DIALOGS */}
      <EditCompanyGroupDialog
        addCompany={(companyId, callback) =>
          addCompanyToCompanyGroup(
            editCompanyGroup?.id as string,
            companyId,
            callback
          )
        }
        companiesWithoutCompanyGroup={companiesWithoutCompanyGroup}
        companyGroup={editCompanyGroup}
        createUser={createUser}
        deleteUser={deleteUser}
        hasIdp={hasIdp}
        integrateCompanyGroups={!!integrateCompanyGroups}
        isHomepal={props.isHomepal}
        onClose={() => setEditCompanyGroup(null)}
        open={Boolean(editCompanyGroup)}
        removeCompany={(companyId, callback) =>
          removeCompanyFromCompanyGroup(
            editCompanyGroup?.id as string,
            companyId,
            callback
          )
        }
        updateCompanyGroup={updateCompanyGroup}
        roles={props.roles}
      />

      <DeleteDialog
        handleClose={() => setCompanyGroupToDelete(null)}
        handleDelete={(callback: () => void) => {
          deleteCompanyGroup(
            (companyGroupToDelete as ParsedCompanyGroup).id,
            (err) => {
              if (!err) {
                setCompanyGroupToDelete(null)
                callback()
              }
            }
          )
        }}
        open={Boolean(companyGroupToDelete)}
        title={companyGroupToDelete?.name}
        type="koncern"
      >
        <Alert severity="info">
          Tillhörande användare kommer att raderas. Tillhörande bolag kommer att
          läggas under <i>Saknar koncern</i>.
        </Alert>
      </DeleteDialog>

      <SimpleDialog
        actions={
          <>
            <Button
              variant="text"
              onClick={() => {
                setCreateCompanyGroupOpen(false)
                setNewCompanyGroupName('')
              }}
            >
              Avbryt
            </Button>
            <Button
              onClick={() => {
                createCompanyGroup(newCompanyGroupName)

                setCreateCompanyGroupOpen(false)
                setNewCompanyGroupName('')
              }}
            >
              Lägg till
            </Button>
          </>
        }
        onClose={() => {
          setCreateCompanyGroupOpen(false)
          setNewCompanyGroupName('')
        }}
        open={createCompanyGroupOpen}
        title={'Lägg till koncern'}
      >
        <TextField
          label="Koncernnamn"
          required={true}
          autoComplete="off"
          value={newCompanyGroupName}
          onChange={(event) => setNewCompanyGroupName(event.target.value)}
        />
      </SimpleDialog>

      <AdvancedDialog
        actions={
          <Button
            variant="text"
            onClick={() => setShowCompaniesWithoutCompanyGroup(false)}
          >
            Stäng
          </Button>
        }
        onClose={() => setShowCompaniesWithoutCompanyGroup(false)}
        open={showCompaniesWithoutCompanyGroup}
        title={`${companiesWithoutCompanyGroup.length} bolag saknar koncern`}
      >
        <List>
          {companiesWithoutCompanyGroup
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((company) => (
              <ListItem key={company.id}>
                <Icon color="disabled" name="ApartmentOutlined" />
                <ListItemText
                  sx={{ ml: 3 }}
                  primary={company.name}
                  primaryTypographyProps={{ fontWeight: 500 }}
                />
              </ListItem>
            ))}
        </List>
      </AdvancedDialog>
    </Box>
  )
}

export default CompaniesTemplate
