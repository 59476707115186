import Axios from 'axios'

import { getInternalWithRedirect } from 'utils/axios'

import {
  PatchCustomerAgreementBody,
  PostCustomerAgreementBody
} from 'types/GlobalCustomerAgreement'
import { parseAuthenticationHeader } from 'helpers/Functions'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v2/customers`

export async function createCustomerAgreement(
  customerId: string,
  body: PostCustomerAgreementBody
) {
  return Axios.post(
    `${URL}/${customerId}/agreements`,
    { data: body },
    parseAuthenticationHeader()
  )
}

export async function getCustomerAgreements(customerId: string) {
  return getInternalWithRedirect(`${URL}/${customerId}/agreements`)
}

export async function updateCustomerAgreement(
  customerId: string,
  agreementId: string,
  body: PatchCustomerAgreementBody
) {
  return Axios.patch(
    `${URL}/${customerId}/agreements/${agreementId}`,
    { data: body },
    parseAuthenticationHeader()
  )
}

export async function deleteCustomerAgreement(
  customerId: string,
  agreementId: string
) {
  return Axios.delete(
    `${URL}/${customerId}/agreements/${agreementId}`,
    parseAuthenticationHeader()
  )
}
