import React, { ReactNode } from 'react'

import Box from '../Box'
import Text from '../Text'
import Tooltip from '../Tooltip'

import { Color } from 'themes'
import Icon, { IconName } from '../Icon'

interface TagProps {
  color?:
    | Color
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'brand'
    | 'black'
    | 'white'
  label?: ReactNode
  showTooltip?: boolean
  startIconName?: IconName
  sx?: object
  TextSx?: object
}

/**
 * The Tag component is a compact element
 * that represent an enum value or a status/state.
 */

const Tag = React.forwardRef((props: TagProps, ref: any) => {
  const { color, label, showTooltip, startIconName, sx, TextSx } = props

  return (
    <Tooltip title={showTooltip ? label : undefined}>
      <Box
        component="span"
        ref={ref}
        sx={{
          boxSizing: 'border-box',
          appearance: 'none',
          border: 'none',
          bgcolor: color ? `${color}.transparent` : 'action.selected',
          paddingInline: 0.5,
          display: 'inline-flex',
          alignItems: 'center',
          borderRadius: 1,
          position: 'static',
          overflow: 'hidden',
          fontSize: '0.8em',
          height: !label ? '1.5em' : undefined,
          ...sx
        }}
      >
        {startIconName ? (
          <Icon
            color={color}
            name={startIconName}
            sx={{
              fontSize: 'inherit',
              mr: startIconName && label ? '0.25em' : undefined
            }}
          />
        ) : null}
        <Text
          color={color ? `${color}.text` : undefined}
          component="span"
          sx={{
            boxSizing: 'border-box',
            fontSize: 'inherit',
            fontWeight: 'bold',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textTransform: 'uppercase',
            m: 0,
            p: 0,
            ...TextSx
          }}
        >
          {label}
        </Text>
      </Box>
    </Tooltip>
  )
})

Tag.displayName = 'Tag'
export default Tag
