import { TInputOption } from 'components_new/molecules/SettingsEditDialog/utils'
import { AttributeOption } from 'types/GlobalAttributeOptions'
import { WidgetObject } from 'types/GlobalWidget'

export const getBreakdownOptions = (
  breakdownOptions: AttributeOption[],
  widget: WidgetObject
): TInputOption[] => {
  const newOptions = breakdownOptions
    .filter(
      (option) =>
        !option.disabled && option.id !== widget.settings.segment_by.selected
    )
    .map((option) => {
      return {
        label: option.name,
        value: option.id
      }
    })

  const defaultOptions = [
    {
      disabled: false,
      label: 'Ingen',
      value: 'none'
    }
  ]

  return [...defaultOptions, ...newOptions]
}
