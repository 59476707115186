import React from 'react'

import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import Text from 'components_new/atoms/Text'

import {
  ComparativePeriod,
  DateFilterType,
  ForecastPeriod,
  PeriodFilter,
  WidgetObject,
  translateForecastPeriod
} from 'types/GlobalWidget'

import {
  translateComparativePeriod,
  translateDateFilter
} from 'utils/enumTranslator'
import { translatePeriodType } from 'types/GlobalCustomer'

export const getDateFilterOptions = (
  dateFilterOptions: PeriodFilter[]
): { label: string; value: string }[] => {
  return dateFilterOptions.map((option) => ({
    label: translateDateFilter[option] ?? '',
    value: option
  }))
}

export const getDisabledComparative = (widget: WidgetObject) => {
  const comparativeAvailable = widget.settings.comparative_period.available
  const hasBreakdownBy = !!widget.settings.kpi_options.find(
    (opt) => opt.breakdown_by.selected
  )

  const disabled = !comparativeAvailable || hasBreakdownBy
  let disabledText = ''

  if (hasBreakdownBy) {
    disabledText = 'Det går inte att jämföra när ett nyckeltal har delats upp.'
  } else if (!comparativeAvailable) {
    disabledText =
      'Det går inte att jämföra det här nyckeltalet eller den valda tidsperioden.'
  }

  return {
    disabledComparative: disabled,
    disabledComparativeText: disabledText
  }
}

export const getComparativePeriodOptions = (
  comparativePeriodOptions: ComparativePeriod[],
  dateFilter: PeriodFilter | null,
  dateFilterType: DateFilterType
): { label: string; value: string }[] => {
  if (!dateFilter) {
    return []
  }

  const newOptions = comparativePeriodOptions.map((option) => {
    return {
      label:
        translateComparativePeriod[dateFilterType][dateFilter][option] ?? '',
      value: option
    }
  })

  return [{ label: 'Ingen', value: 'none' }, ...newOptions]

  // @TODO: Implement info tip on this:

  //     if (comparativePeriod === ComparativePeriod.AVERAGE_LAST_180_DAYS) {
  //       return (
  //         <MenuItem key={i} value={comparativePeriod}>
  //           {
  //             translateComparativePeriod[DateFilterType.PERIOD][dateFilter][
  //               comparativePeriod
  //             ]
  //           }
  //           <IconTip title={'Använder data från de senaste 180 dagarna'} />
  //         </MenuItem>
  //       )
  //     }
}

/**
 * Forecast only supports some period filters
 * and only LAST_YEAR comparison.
 */
export const DATE_FITLERS_WITH_FORECAST = [
  PeriodFilter.TODAY,
  PeriodFilter.THIS_WEEK_TO_TODAY,
  PeriodFilter.THIS_MONTH_TO_TODAY,
  PeriodFilter.THIS_QUARTER_TO_TODAY,
  PeriodFilter.THIS_YEAR_TO_TODAY,
  PeriodFilter.LAST_7_DAYS,
  PeriodFilter.LAST_30_DAYS,
  PeriodFilter.LAST_90_DAYS,
  PeriodFilter.LAST_365_DAYS,
  PeriodFilter.LAST_3_MONTHS_TO_TODAY,
  PeriodFilter.LAST_6_MONTHS_TO_TODAY,
  PeriodFilter.LAST_12_MONTHS_TO_TODAY,
  PeriodFilter.LAST_24_MONTHS_TO_TODAY,
  PeriodFilter.LAST_36_MONTHS_TO_TODAY,
  PeriodFilter.LAST_4_QUARTERS_TO_TODAY,
  PeriodFilter.LAST_8_QUARTERS_TO_TODAY,
  PeriodFilter.LAST_12_QUARTERS_TO_TODAY,
  PeriodFilter.LAST_2_YEARS_TO_TODAY,
  PeriodFilter.LAST_3_YEARS_TO_TODAY
]

export const getDisabledForecast = (widget: WidgetObject) => {
  let disabled = false

  const disabledText = (
    <>
      Det går endast att visa prognos tillsammans med:
      <List sx={{ mt: 1 }}>
        <Text color="text.secondary" component="li" variant="body2">
          Historiska tidsperioder
        </Text>
        {DATE_FITLERS_WITH_FORECAST.map((period) => (
          <ListItem dense={true} key={period}>
            {translateDateFilter[period]}
          </ListItem>
        ))}
        <Text
          color="text.secondary"
          component="li"
          variant="body2"
          sx={{ mt: 1 }}
        >
          Jämförelser
        </Text>
        <ListItem dense={true}>
          {
            translateComparativePeriod[DateFilterType.PERIOD][
              widget.settings.date_filter.selected.value
            ][ComparativePeriod.LAST_YEAR]
          }
        </ListItem>
      </List>
    </>
  )

  if (
    widget.settings.comparative_period.selected.value &&
    [
      ComparativePeriod.AVERAGE_LAST_180_DAYS,
      ComparativePeriod.LAST_PERIOD
    ].includes(widget.settings.comparative_period.selected.value)
  ) {
    disabled = true
  } else {
    disabled = !DATE_FITLERS_WITH_FORECAST.includes(
      widget.settings.date_filter.selected.value
    )
  }

  return {
    disabledForecast: disabled,
    disabledForecastText: disabledText
  }
}

export const getForecastPeriodOptions = (): {
  label: string
  value: string
}[] => {
  const newOptions = Object.values(ForecastPeriod).map((option) => {
    return {
      label: translateForecastPeriod[option],
      value: option
    }
  })

  return [{ label: 'Ingen', value: 'none' }, ...newOptions]
}

export const getDisabledRestrictToPeriod = (widget: WidgetObject) => {
  if (widget.settings.date_filter.restrict_to_period.available) {
    return null
  }

  if (!widget.settings.date_filter.restrict_to_period.period_type) {
    return 'Saknar inställning för bokslutsperioder'
  }

  return (
    <span>
      Tidsperioden kan inte begränsas till den senaste stängda bokslutsperioden
      eftersom den inte omfattar hela perioden. Bokslutsperioden är inställd på{' '}
      <b>
        {
          translatePeriodType[
            widget.settings.date_filter.restrict_to_period.period_type
          ]
        }
      </b>
      . Justera tidsintervallet för att inkludera en fullständig bokslutsperiod.
    </span>
  )
}
