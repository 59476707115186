import React from 'react'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Text from 'components_new/atoms/Text'

import SelectSearch, { Option } from 'components_new/molecules/SelectSearch'

import { AttributeOption } from 'types/GlobalAttributeOptions'

interface SegmentMenuProps {
  segmentOptions: AttributeOption[]
  setCustomSegmentBy: (attributeOptionId: string) => void
}

/**
 * Get top 5 suggested options.
 * Currently choosing the 5 "closest" in hierarchy
 * --> in other words, with highest hierarchy below current
 * segment hierarchy level, or if on level 1 then use all.
 */
const getSuggestedOptions = (options: AttributeOption[]) => {
  return [...options]
    .filter((option) => option.suggested)
    .sort((a, b) => b.hierarchy_level - a.hierarchy_level)
    .slice(0, 5)
}

/**
 * Get all available and suggested options.
 * @param attributeOptionId - Current segment attribute option id.
 * @param segmentOptions - All available segmentation options for widget.
 */
const getOptions = (
  segmentOptions: AttributeOption[]
): [Option[], Option[]] => {
  return [
    segmentOptions.map((item) => ({
      id: item.id,
      title: item.name
    })),
    getSuggestedOptions(segmentOptions).map((item) => ({
      id: item.id,
      title: item.name
    }))
  ]
}

const SegmentMenuSubContent = (props: SegmentMenuProps) => {
  const { segmentOptions, setCustomSegmentBy } = props

  const [options, suggested] = getOptions(segmentOptions)

  return (
    <Box
      sx={{ width: 300, px: 2, py: 1 }}
      onClick={(ev) => ev.stopPropagation()}
    >
      <SelectSearch
        disabled={options.length === 0}
        multiple={false}
        onChange={(option: Option) => {
          setCustomSegmentBy(option.id as string)
        }}
        options={options}
        selected={null}
        sx={{ mb: 2 }}
      />
      <Text
        color={'text.secondary'}
        component="div"
        variant="caption"
        sx={{ mb: 1 }}
      >
        Förslag
      </Text>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 0.5
        }}
      >
        {suggested.map((option) => (
          <Chip
            clickable
            key={option.id}
            label={option.title}
            onClick={() => setCustomSegmentBy(option.id as string)}
            size="small"
          />
        ))}
      </Box>
    </Box>
  )
}

export default SegmentMenuSubContent
