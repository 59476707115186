import React from 'react'

import { SxProps } from '@mui/material'
import { UserAgent } from 'types/GlobalUser'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'

import BlockButton from './BlockButton'
import MarkButton from './MarkButton'

interface Props {
  sx?: SxProps
  userAgent: UserAgent
}

const Toolbar = (props: Props) => {
  const { sx, userAgent } = props

  return (
    <Box sx={{ display: 'flex', gap: 1, ...sx }}>
      <Box sx={{ display: 'flex', gap: 0.25 }}>
        <MarkButton
          format="bold"
          iconName="FormatBoldOutlined"
          userAgent={userAgent}
        />
        <MarkButton
          format="italic"
          iconName="FormatItalicOutlined"
          userAgent={userAgent}
        />
        <MarkButton
          format="underline"
          iconName="FormatUnderlinedOutlined"
          userAgent={userAgent}
        />
      </Box>

      <Divider flexItem={true} orientation="vertical" />

      <Box sx={{ display: 'flex', gap: 0.25 }}>
        <BlockButton
          format="bulleted-list"
          iconName="FormatListBulletedOutlined"
        />
        <BlockButton
          format="numbered-list"
          iconName="FormatListNumberedOutlined"
        />
      </Box>

      <Divider flexItem={true} orientation="vertical" />

      <Box sx={{ display: 'flex', gap: 0.25 }}>
        <MarkButton
          format="code"
          iconName="DriveFileRenameOutline"
          userAgent={userAgent}
        />
      </Box>
    </Box>
  )
}

export default Toolbar
