import 'polyfills' // <- import polyfills before everything else

import React from 'react'
import { createRoot } from 'react-dom/client'

import { Provider } from 'react-redux'
import ThunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { configureStore } from '@reduxjs/toolkit'
import reportWebVitals from 'reportWebVitals'

import App from 'App'
import Stores from 'redux/Stores'

import 'antd/dist/antd.css' // <- global css file that *unfortunately* has an impact on the whole app
import 'styles/colors.css'
import 'styles/index.css'

const LoggerMiddleware = createLogger()

const middleware = [
  ThunkMiddleware,
  process.env.NODE_ENV !== 'production' ? LoggerMiddleware : false
].filter(Boolean)

const app = (
  <Provider
    store={configureStore({
      reducer: Stores,
      middleware,
      devTools: process.env.NODE_ENV !== 'production'
    })}
  >
    <App />
  </Provider>
)

const root = createRoot(document.getElementById('root'))

if (window.location.pathname === '/signout') {
  window.close()
}

root.render(
  process.env.NODE_ENV === 'production' ? (
    app
  ) : (
    <React.StrictMode>{app}</React.StrictMode>
  )
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
