import React from 'react'

import EmptyPlaceholder from 'components_new/molecules/EmptyPlaceholder'

interface BrokenProps {
  scaleFactor: number
}

const Broken = (props: BrokenProps) => {
  const { scaleFactor } = props

  return (
    <EmptyPlaceholder
      background={true}
      title="Ditt nyckeltal verkar ha gått sönder."
      icon="HeartBrokenTwoTone"
      scaleFactor={scaleFactor}
    />
  )
}

export default Broken
