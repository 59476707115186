import { Category } from 'redux/reducers/Dashboards'

export enum TargetCondition {
  GTE = '>=',
  GT = '>',
  LTE = '<=',
  LT = '<',
  EQ = '='
}

export enum GeneralConfigType {
  OPTIONS = 'OPTIONS',
  NUMBER = 'NUMBER',
  MONTH = 'MONTH'
}

export interface General {
  attribute: string
  value: number | string
  unit: string | null
  options: { value: number | string; label: string }[]
  type: GeneralConfigType
}

export interface Config {
  category: Category
  targets: Target[]
  generals: General[]
}

export interface Target {
  id: string
  title: string
  description: string
  attribute: string
  category: Category
  condition: TargetCondition
  unit: string
  attribute_values: AttributeValue[]
}

export interface AttributeValue {
  id: string
  name: string
  target_value: TargetValue | null
}
export interface TargetValue {
  id: string
  value: number | null
}

export const translateGeneralConfigAttributes: { [attribute: string]: string } =
  {
    number_of_days_for_newly_rented_objects:
      'Antalet dagar ett objekt betraktas som nyuthyrt sedan avtalsstart',
    period_type: 'Bokslutsperioder',
    latest_closed_period: 'Senaste stängda bokslutsperiod'
  }
