import React from 'react'

import { KpiTemplate } from 'types/GlobalKpiTemplates'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

import SubKpisList from '../SubKpisList'
import Button from 'components_new/atoms/Button'

interface SubKpisDialogProps {
  kpiTitle: string
  open: boolean
  onClose: () => void
  onCreate: (performKpiId: string) => void
  subKpis: KpiTemplate[]
  mainKpiUnit?: string | null
  recommendSameUnit: boolean
}

const SubKpisDialog = (props: SubKpisDialogProps) => {
  const {
    kpiTitle,
    subKpis,
    onClose,
    onCreate,
    open,
    mainKpiUnit,
    recommendSameUnit
  } = props

  return (
    <SimpleDialog
      actions={
        <Button onClick={onClose} variant="text">
          Stäng
        </Button>
      }
      onClose={onClose}
      open={open}
      sx={{ zIndex: 1302 }}
      title="Vilken variant vill du lägga till?"
    >
      <SubKpisList
        kpiTitle={kpiTitle}
        onCreate={onCreate}
        subKpis={subKpis}
        recommendSameUnit={recommendSameUnit}
        mainKpiUnit={mainKpiUnit}
      />
    </SimpleDialog>
  )
}

export default SubKpisDialog
