import React from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import {
  EXTRA_LONG_FORECASTS,
  translateForecastPeriod,
  WidgetObject
} from 'types/GlobalWidget'

interface ExtraLongForecastMissingSupportedKpiProps {
  scaleFactor: number
  widget: WidgetObject
}

const ExtraLongForecastMissingSupportedKpi = (
  props: ExtraLongForecastMissingSupportedKpiProps
) => {
  const { widget, scaleFactor } = props

  if (widget.status.extra_long_forecast_missing_supported_kpi) {
    return (
      <Tooltip
        title={
          <>
            <Text gutterBottom={true} variant="inherit">
              Extra långa prognoser beräknas endast för ett fåtal nyckeltal.
              Inget nyckeltal som används i visualiseringen beräknar extra lång
              prognos.
            </Text>
            <Text gutterBottom={true} variant="inherit">
              Extra lång prognos innefattar:
              {EXTRA_LONG_FORECASTS.map((forecast) => (
                <ul key={translateForecastPeriod[forecast]}>
                  {translateForecastPeriod[forecast]}
                </ul>
              ))}
            </Text>
            <Text gutterBottom={true} variant="inherit">
              För en mer tillförlitligt analys bör du använda en annan prognos.
            </Text>
          </>
        }
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            position: 'relative',
            zIndex: 1,
            fontSize: 14 * scaleFactor
          }}
        >
          <Icon color="warning" fontSize="inherit" name={'Warning'} />
          <Text
            color="warning.text"
            fontSize="inherit"
            fontWeight="medium"
            sx={{
              ml: '0.25em',
              cursor: 'default'
            }}
            noWrap={true}
          >
            Extra lång prognos saknas för nyckeltal
          </Text>
        </Box>
      </Tooltip>
    )
  }

  return null
}

export default ExtraLongForecastMissingSupportedKpi
