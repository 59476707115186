import React from 'react'

import Icon from 'components_new/atoms/Icon'
import Switch from 'components_new/atoms/Switch'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

import { TEmbedComponent } from '../types'
import { REmbedComponent } from '../lib'

interface FeaturesProps {
  embedComponent: TEmbedComponent
  imageExport: boolean
  setImageExport: (value: boolean) => void
  setUserFilter: (value: boolean) => void
  userFilter: boolean
}

const Features = (props: FeaturesProps) => {
  const {
    embedComponent,
    imageExport,
    setImageExport,
    setUserFilter,
    userFilter
  } = props

  return (
    <SettingsGroup title="Funktionalitet">
      <SettingsItem
        actions={
          <Switch
            color="success"
            checked={userFilter}
            onChange={() => setUserFilter(!userFilter)}
            size="medium"
          />
        }
        body={
          <>
            Låter användaren få tillgång till{' '}
            {REmbedComponent[embedComponent].title.toLowerCase()}ens
            användarfilter.
          </>
        }
        icon={<Icon name={'FilterAlt'} />}
        title={'Användarfilter'}
        titleAdornments={<code>?filter=true</code>}
      />

      <SettingsItem
        actions={
          <Switch
            color="success"
            checked={imageExport}
            onChange={() => setImageExport(!imageExport)}
            size="medium"
          />
        }
        body={
          <>
            Låter användaren exportera{' '}
            {REmbedComponent[embedComponent].title.toLowerCase()}en som en bild.
          </>
        }
        icon={<Icon name={'FileDownloadOutlined'} />}
        title={'Bildexport'}
        titleAdornments={<code>?export=true</code>}
      />
    </SettingsGroup>
  )
}

export default Features
