import React from 'react'
import { useSlate } from 'slate-react'

import { CustomAlignTypes, CustomElement } from '../types'

import Icon, { IconName } from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Tooltip from 'components_new/atoms/Tooltip'

import { isBlockActive, toggleBlock } from '../utils'
import { dict, TEXT_ALIGN_TYPES } from '../lib'

interface Props {
  format: CustomElement['type'] | CustomAlignTypes
  iconName: IconName
}

const BlockButton = (props: Props) => {
  const { format, iconName } = props

  const editor = useSlate()

  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )

  return (
    <Tooltip title={dict[format].title}>
      <IconButton
        color={isActive ? 'primary' : 'disabled'}
        disabled={false}
        size="small"
        // onMouseDown (and not onClick) due to blur/focus functionality
        onMouseDown={(event) => {
          event.preventDefault()
          toggleBlock(editor, format)
        }}
        sx={isActive ? { bgcolor: 'action.focus' } : undefined}
        tabIndex={-1}
      >
        <Icon fontSize="small" name={iconName} />
      </IconButton>
    </Tooltip>
  )
}

export default BlockButton
