import React, { useMemo, useState } from 'react'

import Stack from 'components_new/atoms/Stack'
import { parseLabel } from 'components_new/organisms/Dashboard/utils'

import {
  WidgetObject,
  WidgetType,
  WidgetData,
  FunnelStatus,
  DefaultDatasetData,
  ParsedSegmentPath
} from 'types/GlobalWidget'
import { KpiOptionObject } from 'types/GlobalKpiOption'

import { translateWidgetType } from 'utils/enumTranslator'

import NoValue from '../../errors/NoValue'
import SegmentMenu from '../../SegmentMenu'

import FunnelEvent from './FunnelEvent'

interface FunnelProps {
  clipContent?: boolean
  embedded: boolean
  scaleFactor: number
  setDetailsDialog: ((segment: ParsedSegmentPath[]) => void) | null
  setSegmentFilters: ((segment: ParsedSegmentPath[]) => void) | null
  widget: WidgetObject
}

const Funnel = (props: FunnelProps) => {
  const {
    clipContent,
    embedded,
    scaleFactor,
    setDetailsDialog,
    setSegmentFilters,
    widget
  } = props

  const [anchorPosition, setAnchorPosition] = useState<{
    top: number
    left: number
  } | null>(null)
  const [selectedSegment, setSelectedSegment] =
    useState<ParsedSegmentPath | null>(null)
  const allowOnClick = !!setSegmentFilters || !!setDetailsDialog

  const data = widget.data as WidgetData

  const stages = useMemo(
    () => widget.settings.segment_by.stages.filter((stage) => !stage.hidden),
    [widget.settings.segment_by.stages]
  )

  const [indexMapper, totalValue, maxValue] = useMemo(() => {
    const mapper: { [title: string]: number } = {}

    const allValues =
      (data.data?.[0]?.data as DefaultDatasetData)?.map((val) => val || 0) || []
    const includedValues: number[] = []

    const dataLabels = data.labels.data.map((item) => item.label)

    stages.forEach((stage) => {
      const index = dataLabels.indexOf(stage.title)

      mapper[stage.title] = allValues[index] || 0

      if (stage.status === FunnelStatus.UNSET) {
        includedValues.push(allValues[index] || 0)
      }
    })

    const totalValue = includedValues.reduce((acc, curr) => acc + curr, 0)
    const maxValue = Math.max(...includedValues)

    return [mapper, totalValue, maxValue]
  }, [widget.data, stages])

  const handleClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    label: string
  ) => {
    event.stopPropagation()

    const dataLabel = data.labels.data[0]

    const segmentPath: ParsedSegmentPath = {
      attribute_option_id: dataLabel.attribute_option_id,
      display_label: parseLabel({
        label,
        technical_name: dataLabel.technical_name,
        type: dataLabel.type
      }),
      label,
      period: null,
      relation_key: dataLabel.relation_key,
      attribute: dataLabel.attribute,
      type: widget.settings.segment_by.type,
      technical_name: dataLabel.technical_name
    }

    setAnchorPosition({ left: event.clientX, top: event.clientY })
    setSelectedSegment(segmentPath)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()

    setAnchorPosition(null)
    setSelectedSegment(null)
  }

  return data?.data?.length > 0 ? (
    <>
      <Stack
        sx={{
          width: '100%',
          flexGrow: 1,
          overflowY: clipContent ? 'hidden' : undefined
        }}
      >
        {stages.map((stage) => (
          <FunnelEvent
            key={stage.index}
            interactive={!embedded}
            kpiOption={widget.settings.kpi_options[0] as KpiOptionObject}
            maxValue={maxValue}
            onClick={handleClick}
            stage={stage}
            totalValue={totalValue}
            value={indexMapper[stage.title]}
          />
        ))}
      </Stack>
      {allowOnClick && selectedSegment && (
        <SegmentMenu
          anchorPosition={anchorPosition}
          setDetailsDialog={setDetailsDialog}
          setFilters={setSegmentFilters}
          onClose={handleClose}
          segments={[selectedSegment]}
          widget={widget}
        />
      )}
    </>
  ) : (
    <NoValue
      iconName={translateWidgetType[WidgetType.FUNNEL].iconName}
      embedded={embedded}
      scaleFactor={scaleFactor}
    />
  )
}

Funnel.defaultProps = {
  interactive: false
}

export default Funnel
