import React from 'react'

import Button from 'components_new/atoms/Button'
import Text from 'components_new/atoms/Text'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

interface ConfirmationDialogProps {
  content: {
    action: string
    consequence: string
    item: string
  }
  handleClose: () => void
  handleConfirm: () => void
  open: boolean
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { content, handleClose, handleConfirm, open } = props

  return (
    <SimpleDialog
      data-openreplay-obscured
      title={`${
        content.action.slice(0, 1).toUpperCase() + content.action.slice(1)
      } ${content.item}`}
      open={open}
      onClose={handleClose}
      actions={
        <>
          <Button variant="text" onClick={handleClose}>
            Avbryt
          </Button>
          <Button
            onClick={() => {
              handleConfirm()
              handleClose()
            }}
            variant="contained"
          >
            Fortsätt
          </Button>
        </>
      }
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '512px!important'
        }
      }}
    >
      <Text>
        <>
          Är du säker på att du vill {content.action} {content.item}?{' '}
          {content.consequence}
        </>
      </Text>
    </SimpleDialog>
  )
}

export default ConfirmationDialog
