import React from 'react'

import Box from 'components_new/atoms/Box'
import Link from 'components_new/atoms/Link'
import Text from 'components_new/atoms/Text'

import AddAccount from 'components_new/organisms/AddAccount'

interface InviteSectionProps {
  hasIdp: boolean
  onInvite: (email: string, message: string) => void
  onGoBack: () => void
}

const InviteSection = (props: InviteSectionProps) => {
  const { hasIdp, onInvite, onGoBack } = props

  return (
    <>
      <Text variant="subtitle2" gutterBottom={true}>
        Här bjuder du in personer till Homepal som inte redan är användare.
      </Text>
      <Text color="text.secondary" variant="body2" gutterBottom={true}>
        Är personen redan en användare?{' '}
        <Link color="inherit" onClick={onGoBack}>
          Gå tillbaka och lägg till användare
        </Link>{' '}
        under <code>Användare med åtkomst</code> istället.
      </Text>
      <Box sx={{ mt: 2 }}>
        <AddAccount
          hasIdp={hasIdp}
          hideNames={true}
          hideRole={true}
          onInvite={onInvite}
        />
      </Box>
    </>
  )
}

export default InviteSection
