import { KpiTemplate } from 'types/GlobalKpiTemplates'
import { WidgetType } from 'types/GlobalWidget'
import { Category } from 'redux/reducers/Dashboards'
import { sortAlphabeticalAsc } from 'utils/sortHelper'

export const other: {
  title: string
  summary: string
  type: WidgetType.TEXT | WidgetType.COMMENT | WidgetType.IMAGE
}[] = [
  {
    title: 'Text',
    summary: 'Skapa en rubrik eller bara visa text.',
    type: WidgetType.TEXT
  },
  {
    title: 'Kommentar',
    summary: 'Skapa ett kommentarsfält för presentationer.',
    type: WidgetType.COMMENT
  },
  {
    title: 'Bild',
    summary: 'Skapa och visa en bild.',
    type: WidgetType.IMAGE
  }
]

/**
 * Gets the distinct categories from KPIs.
 * @param kpis The KPIs used for identifying distinct categories.
 * @returns An array of categories.
 */
export const getCategoryArray = (kpis: KpiTemplate[]): Category[] => {
  return kpis.reduce((categories: Category[], kpi) => {
    if (!categories.includes(kpi.category)) {
      categories.push(kpi.category)
    }

    return categories
  }, [])
}

/**
 * Get subcategorized KPIs for a specific category.
 * @param kpis Available KPIs.
 * @param cateogry The cateogry to be subcategorized.
 * @returns An object of subcategories each containing an array of KPIs.
 */
export const getSubcategoryKpiArray = (
  kpis: KpiTemplate[],
  category: Category
) => {
  const filteredKpis = kpis.filter((kpi) => kpi.category === category)

  const sortedKpis = filteredKpis.sort((a, b) => {
    if (a.subcategory === b.subcategory) {
      return sortAlphabeticalAsc(a.title, b.title)
    }

    return sortAlphabeticalAsc(a.subcategory, b.subcategory)
  })

  const groupedBySubcategory: Record<string, KpiTemplate[]> = sortedKpis.reduce(
    (acc, item) => {
      const { subcategory } = item

      if (!acc[subcategory]) {
        acc[subcategory] = []
      }
      acc[subcategory].push(item)

      return acc
    },
    {} as Record<string, KpiTemplate[]>
  )

  return groupedBySubcategory
}

/**
 * Gets all KPIs that are to be displayed based on user input.
 * @param kpis all available KPIs,
 * @param showAvailable user option to only show available KPIs,
 * @param showAvailable user search input of KPI titles,
 * @returns An array of KPIs based on user input.
 */
export const getFilteredKpis = (
  kpis: KpiTemplate[],
  showAvailable: boolean,
  query: string,
  showSameUnit: boolean,
  recommendSameUnit: boolean,
  mainKpiUnit?: string | null
): KpiTemplate[] => {
  const sortedKpis = kpis.sort((a, b) => sortAlphabeticalAsc(a.title, b.title))

  const availableKpis = sortedKpis
    .filter((item) => {
      if (showAvailable) {
        const disabled =
          item.status &&
          (!item.status.modelled ||
            !item.status.available_in_license ||
            item.status.required_target_missing)

        return !disabled
      }

      return true
    })
    .filter((item) => {
      if (showSameUnit && recommendSameUnit) {
        return item.unit === mainKpiUnit
      }

      return true
    })

  const filteredKpis = availableKpis.filter((item) => {
    if (!query) return true

    if (item.title.toLowerCase().includes(query.toLowerCase())) {
      return true
    }

    return false
  })

  return filteredKpis
}

/**
 *
 * @param kpis all available KPIs,
 * @param categories all available categories,
 * @returns an iterable object suitable for rendering a category with
 * subcategory, and its KPIs.
 */
export const getSubcategorizedKpis = (
  kpis: KpiTemplate[],
  categories: Category[]
) => {
  const subcategorizedKpis: {
    [category: string]: Record<string, KpiTemplate[]>
  } = {}

  categories.forEach((category) => {
    subcategorizedKpis[category] = getSubcategoryKpiArray(kpis, category)
  })

  return subcategorizedKpis
}
