import React, { useState } from 'react'

import Button from 'components_new/atoms/Button'
import Icon, { IconName } from 'components_new/atoms/Icon'
import Link from 'components_new/atoms/Link'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Text from 'components_new/atoms/Text'

import EmptyPlaceholder from 'components_new/molecules/EmptyPlaceholder'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

export interface NoValueProps {
  embedded?: boolean
  iconName: IconName
  scaleFactor: number
}

const NoValue = (props: NoValueProps) => {
  const { embedded, iconName, scaleFactor } = props

  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      <EmptyPlaceholder
        actions={
          !embedded ? (
            <Link onClick={() => setDialogOpen(true)}>Mer information</Link>
          ) : undefined
        }
        background={true}
        title="Det finns inte några värden att visa utifrån din valda period eller dina valda filter."
        icon={iconName}
        scaleFactor={scaleFactor}
      />

      <SimpleDialog
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
        title={'Mer information'}
        actions={
          <Button onClick={() => setDialogOpen(false)} variant="text">
            Stäng
          </Button>
        }
      >
        <EmptyPlaceholder
          background={true}
          title="Det finns inte några värden att visa utifrån din valda period eller dina valda filter."
          icon={iconName}
          sx={{ mb: 4 }}
        />
        <Text variant="subtitle2" gutterBottom>
          Oroa dig inte! Allting fungerar som det ska 👍
        </Text>
        <Text color="text.secondary" sx={{ mt: 2 }}>
          Att det inte finns några värden att visa beror på någonting (eller en
          kombination) av följande:
        </Text>
        <List>
          <ListItem>
            <ListItemIcon>
              <Icon name="HelpOutline" />
            </ListItemIcon>
            <ListItemText>
              Det finns ingen data under den <b>tidsperiod eller tidpunkt</b>{' '}
              som är vald.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Icon name="HelpOutline" />
            </ListItemIcon>
            <ListItemText>
              Det finns ingen data att visa utifrån <b>nyckeltalets filter</b>{' '}
              som är uppsatt.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Icon name="HelpOutline" />
            </ListItemIcon>
            <ListItemText>
              Det finns ingen data att visa utifrån ditt aktiva{' '}
              <b>dashboardfilter</b>.
            </ListItemText>
          </ListItem>
        </List>
      </SimpleDialog>
    </>
  )
}

export default NoValue
