import React, { useMemo } from 'react'

import { ConnectedProps, connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { ApplicationState } from 'redux/Stores/types'

import * as AuthStoreActions from 'redux/actions/Authentication'
import * as CompanyGroupActions from 'redux/actions/CompanyGroups'

import SelectSearch, { Option } from 'components_new/molecules/SelectSearch'
import Avatar from 'components_new/atoms/Avatar'
import Skeleton from 'components_new/atoms/Skeleton'
import Tooltip from 'components_new/atoms/Tooltip'

interface SelectCustomerProps {
  sx?: object
}

const SELECT_ALL_LIMIT = 500

const SelectCustomer = (props: ComponentProps) => {
  const {
    AuthStore,
    CompanyGroupStore,
    KPIDashboardStore,
    NavigationMenuStore,
    setShowAsCustomerGroup,
    sx
  } = props

  const options = useMemo(() => {
    const groups = Object.values(CompanyGroupStore.data)
      .sort((a, b) => a.name.localeCompare(b.name, 'sv'))
      .map((cg) => ({ id: cg.id, title: cg.name }))

    return groups.length > SELECT_ALL_LIMIT
      ? groups
      : [
          {
            id: null,
            title: 'Alla'
          },
          ...groups
        ]
  }, [CompanyGroupStore])

  const showAsLabel = useMemo(
    () =>
      CompanyGroupStore.showAs
        ? CompanyGroupStore.data[CompanyGroupStore.showAs].name
        : 'Alla',
    [CompanyGroupStore.showAs]
  )

  const collapsed = !NavigationMenuStore.open

  // hide if *our* customer is not management company
  if (!Boolean(AuthStore.customer?.is_management_company)) return null

  // hide if user is a customer of *our* customer
  if (Boolean(AuthStore.user?.is_company_group)) return null

  // loading
  if (!CompanyGroupStore.fetched || !KPIDashboardStore.fetched)
    return <Skeleton height={37} sx={sx} />

  const selectSearch = (
    <SelectSearch
      disableClearable={true}
      multiple={false}
      onChange={(option: Option) => setShowAsCustomerGroup(option.id as string)}
      options={options}
      PopperWidth={300}
      selected={
        CompanyGroupStore.showAs
          ? {
              id: CompanyGroupStore.showAs,
              title: CompanyGroupStore.data[CompanyGroupStore.showAs].name
            }
          : {
              id: null,
              title: 'Alla'
            }
      }
      variant={'select'}
    />
  )

  // success
  return (
    <>
      {
        // default
        !collapsed ? (
          selectSearch
        ) : (
          // collapsed
          <>
            <Tooltip
              placement="right"
              title={
                <>
                  <b>Koncern</b>
                  <br />
                  {showAsLabel}
                </>
              }
            >
              <Avatar alt={showAsLabel} sx={{ height: 40, ...sx }} />
            </Tooltip>
          </>
        )
      }
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  CompanyGroupStore: state.CompanyGroupStore,
  KPIDashboardStore: state.KPIDashboardStore,
  NavigationMenuStore: state.NavigationMenuStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AuthStoreActions,
      ...CompanyGroupActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & SelectCustomerProps

export default connector(SelectCustomer)
