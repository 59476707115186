import {
  DashboardFilter,
  ParsedDashboardFilterOptions
} from 'types/GlobalDashboardFilter'
import { TPutSavedDashboardFilterCondition } from 'types/GlobalSavedDashboardFilter'

export const formatPutConditions = (
  dashboardFilter: DashboardFilter,
  dashboardFilterOptions: Map<string, ParsedDashboardFilterOptions>
) => {
  return Object.values(
    dashboardFilter.reduce<Record<string, TPutSavedDashboardFilterCondition>>(
      (acc, item) => {
        const option = dashboardFilterOptions.get(item.relation_key)

        if (!option) {
          // only process filters from dashboard filters,
          // will exclude onclick filters not present.
          return acc
        }

        if (!acc[item.relation_key]) {
          acc[item.relation_key] = {
            attribute_id: item.attribute,
            fact_dimension_linker_name: option.relation_name,
            values: []
          }
        }
        acc[item.relation_key].values.push(item.value as string)

        return acc
      },
      {}
    )
  )
}
