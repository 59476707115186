import React, { ReactNode, useEffect, useState } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'

import { ApplicationState } from 'redux/Stores/types'
import { Dispatch, bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'

import * as AccountActions from 'redux/actions/Accounts'
import * as AccountRoleActions from 'redux/actions/AccountRoles'

import { AccountRole } from 'types/GlobalUser'

import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'

import AddAccount from 'components_new/organisms/AddAccount'

import Desktop from './desktop'
import Loading from './loading'
import Mobile from './mobile'

interface InviteButtonProps {
  hideRole?: boolean
  sx?: object
}

const InviteButton = (props: ComponentProps) => {
  const {
    hideRole,
    sx,
    // redux stores
    AuthStore,
    AccountStore,
    CompanyGroupStore,
    // redux actions
    tryCreateUser,
    tryGetAllAccountRoles
  } = props

  useEffect(() => {
    if (!AccountStore.rolesFetched) {
      tryGetAllAccountRoles()
    }
  }, [])

  const [dialog, setDialog] = useState<{
    open: boolean
    data: { email: string }
  }>({
    open: false,
    data: {
      email: ''
    }
  })

  const userIsCustomer = Boolean(AuthStore?.user?.is_company_group)

  // A customer should never be able to create anything:
  if (userIsCustomer) return null

  const handleOpenDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: true }))
  }

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  // loading
  if (!CompanyGroupStore.fetched || !AccountStore.rolesFetched) {
    return <Loading />
  }

  // success
  return (
    <>
      <Button
        color="primary"
        onClick={handleOpenDialog}
        size="large"
        startIcon={<Icon name="GroupAddOutlined" />}
        sx={sx}
        variant="contained"
      >
        Bjud in
      </Button>

      {/*-- dialog / drawer --*/}
      <Wrapper
        onClose={handleCloseDialog}
        onOpen={handleOpenDialog}
        open={dialog.open}
        title={'Bjud in'}
      >
        <AddAccount
          companyGroups={Object.values(CompanyGroupStore.data)}
          hasIdp={
            !!AuthStore.customer?.has_idp && !AuthStore.user?.is_homepal_user
          }
          hideRole={hideRole}
          onInvite={(
            email,
            message,
            firstName,
            lastName,
            _,
            companyGroupId
          ) => {
            const role = AccountStore.roles[AccountRole.VIEW]

            tryCreateUser({
              data: {
                email: email,
                first_name: firstName,
                last_name: lastName,
                message: message,
                role: role.id,
                company_group_id: companyGroupId
              }
            })
          }}
          showInviteCompanyGroup={!!AuthStore.customer?.is_management_company}
          sx={{ mt: 1 }}
        />
      </Wrapper>
    </>
  )
}

interface WrapperProps {
  children: ReactNode
  onClose: () => void
  onOpen: () => void
  open: boolean
  title: string
}

const Wrapper = (props: WrapperProps) => {
  const { children, ...rest } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  if (isMobile) return <Mobile {...rest}>{children}</Mobile>

  return <Desktop {...rest}>{children}</Desktop>
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  AuthStore: state.AuthStore,
  CompanyGroupStore: state.CompanyGroupStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountActions,
      ...AccountRoleActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & InviteButtonProps

export default connector(InviteButton)
