import React from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import * as WidgetActions from 'redux/actions/Widgets'
import { ApplicationState } from 'redux/Stores/types'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'

import LocationItem from 'components_new/molecules/LocationItem'
import { getOptions, SelectType } from './utils'

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  DashboardFolderStore: state.DashboardFolderStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...WidgetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface DashboardTreeSelector {
  selected: string | null
  setSelected: (value: string | null) => void
  type?: SelectType
}

type ComponentProps = ConnectedProps<typeof connector> & DashboardTreeSelector

const DashboardTreeSelector = (props: ComponentProps) => {
  const {
    AuthStore,
    DashboardFolderStore,
    selected,
    setSelected,
    type = 'folder'
  } = props

  const options = getOptions(DashboardFolderStore, AuthStore, type)

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <Icon fontSize="large" name="ArrowDownward" />
      </Box>
      <Box
        sx={{
          bgcolor: 'background.default',
          p: 1,
          maxHeight: 400,
          overflowY: 'auto'
        }}
      >
        <LocationItem
          item={{
            allow: true,
            id: 'dummy',
            name: 'Alla dashboards',
            iconName: 'GridViewOutlined'
          }}
          onClick={() => setSelected(null)}
          selected={selected ?? 'dummy'}
        />
        {options.map((option) => (
          <Box key={option.id}>
            <LocationItem
              indent={2}
              item={option}
              key={option.id}
              onClick={setSelected}
              selected={selected}
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default connector(DashboardTreeSelector)
