import React, { useState } from 'react'

import {
  KpiOptionObject,
  KpiOptionPatchAttributes,
  UnitPrefix
} from 'types/GlobalKpiOption'

import { getUnitLabel } from 'components_new/organisms/Widget/types/utils'

import SettingsDrawer from 'components_new/molecules/SettingsDrawer'
import SettingsEditDialog from 'components_new/molecules/SettingsEditDialog'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import { TInputType } from 'components_new/molecules/SettingsEditDialog/utils'

import { getHelpText, getPreviewValue, getUnitPrefixOptions } from './utils'

interface UnitSectionProps {
  kpi: KpiOptionObject
  onClose: () => void
  updateKpiOption: (body: KpiOptionPatchAttributes) => void
}

const UnitSection = (props: UnitSectionProps) => {
  const { kpi, onClose, updateKpiOption } = props

  /*-- drawer --*/
  const [drawer, setDrawer] = useState<{
    open: boolean
    title: string
  }>({
    open: false,
    title: ''
  })

  const handleBackDrawer = () => {
    setDrawer((prevState) => ({ ...prevState, open: false }))
  }

  const handleCloseDrawer = () => {
    handleBackDrawer()
    onClose()
  }

  const handleOpenDrawer = () => {
    setDrawer({
      open: true,
      title: 'Ekvivalent'
    })
  }

  /*-- dialogs --*/
  const [dialog, setDialog] = useState<{
    clearValue?: string
    open: boolean
    property: TProperty
    title: string
    data: {
      options: { label: string; value: any }[]
      value: string
      type: TInputType
    }
  }>({
    clearValue: undefined,
    open: false,
    property: 'factor',
    title: '',
    data: {
      options: [],
      value: '',
      type: 'text'
    }
  })

  // Properties edited in this component.
  type TProperty = 'number_of_decimals' | 'factor' | 'unit' | 'unit_prefix'

  const getPropertyTitle = (property: TProperty) => {
    switch (property) {
    case 'factor':
      return 'Faktor'
    case 'number_of_decimals':
      return 'Antal decimaler'
    case 'unit':
      return 'Ny enhet'
    case 'unit_prefix':
      return 'Enhet'
    }
  }

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const handleOpenDialog = (property: TProperty) => {
    let inputValue = ''
    let clearValue
    let inputType: TInputType = 'text'
    let inputOptions: { label: string; value: any }[] = []

    switch (property) {
    case 'factor':
      inputValue = kpi.equivalent.factor?.toString() ?? ''
      inputType = 'decimal'
      break
    case 'number_of_decimals':
      inputValue = kpi.number_of_decimals.toString()
      inputType = 'number'
      clearValue = '0'
      break
    case 'unit':
      inputValue = kpi.equivalent.unit ?? ''
      inputType = 'text'
      break
    case 'unit_prefix':
      inputValue = kpi.unit_prefix.selected ?? ''
      inputType = 'select'
      inputOptions = getUnitPrefixOptions(kpi.unit, kpi.unit_prefix)
      break
    }

    setDialog({
      clearValue,
      open: true,
      property: property,
      title: getPropertyTitle(property),
      data: {
        options: inputOptions,
        type: inputType,
        value: inputValue
      }
    })
  }

  const handleEditDialogValue = (value: string) => {
    setDialog((prevState) => ({
      ...prevState,
      data: { ...prevState.data, value }
    }))
  }

  const handleSubmitDialog = () => {
    let body: KpiOptionPatchAttributes = { data: {} }

    switch (dialog.property) {
    case 'factor':
      body = {
        data: {
          equivalent: {
            ...kpi.equivalent,
            factor: parseFloat(dialog.data.value)
          }
        }
      }
      break
    case 'number_of_decimals':
      body = {
        data: {
          number_of_decimals: parseInt(dialog.data.value)
        }
      }
      break
    case 'unit':
      body = {
        data: {
          equivalent: {
            ...kpi.equivalent,
            unit: dialog.data.value || null
          }
        }
      }
      break
    case 'unit_prefix':
      body = {
        data: {
          unit_prefix:
              dialog.data.value === ''
                ? null
                : (dialog.data.value as UnitPrefix)
        }
      }
      break
    }

    updateKpiOption(body)
  }

  const kpiUnit = getUnitLabel(kpi.unit, kpi.unit_prefix)

  const unitPrefixOptions = kpi.unit_prefix.options

  return (
    <>
      <SettingsGroup title="Enheter">
        <SettingsItem
          disabled={unitPrefixOptions.length === 0}
          onClick={() => handleOpenDialog('unit_prefix')}
          title="Enhet"
          value={kpi.unit === '%' ? '%' : kpiUnit || ''}
          variant="edit"
        />
        <SettingsItem
          onClick={() => handleOpenDrawer()}
          title="Ekvivalent"
          value={getPreviewValue(kpi)}
        />
        <SettingsItem
          onClick={() => handleOpenDialog('number_of_decimals')}
          title={getPropertyTitle('number_of_decimals')}
          value={kpi.number_of_decimals}
          variant="edit"
        />
      </SettingsGroup>

      {/*-- drawer --*/}
      <SettingsDrawer
        onBack={handleBackDrawer}
        onClose={handleCloseDrawer}
        open={drawer.open}
        title={drawer.title}
      >
        <SettingsGroup helperText={getHelpText(kpi)}>
          <SettingsItem
            onClick={() => handleOpenDialog('factor')}
            title={getPropertyTitle('factor')}
            value={kpi.equivalent.factor}
            variant="edit"
          />
          <SettingsItem
            onClick={() => handleOpenDialog('unit')}
            title={getPropertyTitle('unit')}
            value={kpi.equivalent.unit}
            variant="edit"
          />
        </SettingsGroup>
        <SettingsGroup>
          <SettingsItem
            color="info"
            onClick={() =>
              updateKpiOption({
                data: {
                  equivalent: {
                    factor: null,
                    unit: null
                  }
                }
              })
            }
            title="Återställ"
            variant="action"
          />
        </SettingsGroup>
      </SettingsDrawer>

      {/*-- dialogs --*/}
      <SettingsEditDialog
        clearValue={dialog.clearValue}
        onChange={handleEditDialogValue}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        open={dialog.open}
        options={dialog.data.options}
        title={dialog.title}
        type={dialog.data.type}
        value={dialog.data.value}
      />
    </>
  )
}

export default UnitSection
