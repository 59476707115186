import React, { useEffect, useState } from 'react'

import {
  ElementType,
  InnerWidgetPatchBody,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'
import {
  KpiOptionObject,
  KpiOptionPatchAttributes,
  KpiOptionPostAttributes
} from 'types/GlobalKpiOption'
import {
  WidgetTagObject,
  WidgetTagPatchAttributes,
  WidgetTagPostAttributes
} from 'types/GlobalWidgetTag'
import {
  KpiTemplateFilterOptionsBody,
  KpiTemplateRedux
} from 'types/GlobalKpiTemplates'
import { Customer } from 'types/GlobalCustomer'

import Badge from 'components_new/atoms/Badge'
import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import Link from 'components_new/atoms/Link'
import Tag from 'components_new/atoms/Tag'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import SettingsDrawer from 'components_new/molecules/SettingsDrawer'
import SettingsEditDialog from 'components_new/molecules/SettingsEditDialog'
import { TInputType } from 'components_new/molecules/SettingsEditDialog/utils'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

import CreateKpiDialog from 'components_new/organisms/dialogs/CreateKpiDialog'

import ComparativeDisplayTypeSection from './ComparativeDisplayTypeSection'
import BenchmarkSection from './BenchmarkSection'
import BreakdownSection from './BreakdownSection'
import FilterSection from './FilterSection'
import IndicationSection from './IndicationSection'
import RollingSection from './RollingSection'
import TitleSection from './TitleSection'
import UnitSection from './UnitSection'
import ShareKpiSection from './ShareKpiSection'

import StatusSection from './tag/StatusSection'

import EditOrderDialog from './dialogs/EditOrderDialog'

import {
  getAddTitle,
  getAvailableKpiTemplates,
  getIconName,
  getTagOptions
} from './utils'
import {
  useAttributeOptions,
  useGroupedAttributeOptions,
  useSegmentBy
} from 'redux/hooks/AttributeOptions'

interface DatasetsSectionProps {
  allowBenchmarking: boolean
  createKpiOption: (body: KpiOptionPostAttributes) => void
  createWidgetTag: (body: WidgetTagPostAttributes) => void
  customer: Customer | null
  deleteKpiOption: (id: string) => void
  deleteWidgetTag: (id: string) => void
  filterOptions: KpiTemplateFilterOptionsBody | null
  fullSize: boolean
  kpiTemplates: KpiTemplateRedux
  kpiTemplatesFetched: boolean
  onClose: () => void
  updateKpiOption: (id: string, body: KpiOptionPatchAttributes) => void
  updateWidget: (arg0: string, arg1: InnerWidgetPatchBody) => void
  updateWidgetTag: (id: string, body: WidgetTagPatchAttributes) => void
  widget: WidgetObject
}

type StandardizedElement = {
  id: string
  tag_option_id: string | null
  type: ElementType
  title: string
  index: number
  hidden: boolean
}

const LEGAL_TYPES = [
  WidgetType.BAR_CHART,
  WidgetType.BUBBLE_CHART,
  WidgetType.COMBO_CHART,
  WidgetType.KEY_FIGURE,
  WidgetType.LINE_CHART,
  WidgetType.MAP,
  WidgetType.LIST,
  WidgetType.PIE_CHART
]

const DatasetsSection = (props: DatasetsSectionProps) => {
  const {
    allowBenchmarking,
    createKpiOption,
    createWidgetTag,
    customer,
    deleteKpiOption,
    deleteWidgetTag,
    filterOptions,
    // fullSize,
    kpiTemplates,
    kpiTemplatesFetched,
    onClose,
    updateKpiOption,
    updateWidget,
    updateWidgetTag,
    widget
  } = props

  if (!LEGAL_TYPES.includes(widget.settings.type.selected)) {
    return null
  }

  /*-- main kpi --*/
  const mainKpi = widget.settings.kpi_options.find(
    (kpi) => kpi.index === 0
  ) as KpiOptionObject

  const attributeOptions = useAttributeOptions()
  const groupedAttributeOptions = useGroupedAttributeOptions()
  const segmentBy = useSegmentBy(widget)

  /*-- merge kpis and tags --*/
  const [tempElements, setTempElements] = useState<StandardizedElement[]>([])

  useEffect(() => {
    const kpiElements = widget.settings.kpi_options
      .filter((kpi) => kpi.index !== 0) // Don't include the main KPI.
      .map((kpi) => ({
        id: kpi.id,
        tag_option_id: null,
        type: ElementType.KPI,
        title: kpi.title,
        index: kpi.index,
        hidden: kpi.hidden
      }))

    const tagElements = widget.settings.tags.selected.map((tag) => {
      return {
        id: tag.id,
        tag_option_id: tag.tag_option_id,
        type: ElementType.TAG,
        title: tag.title,
        index: tag.index,
        hidden: false
      }
    })

    const elems: StandardizedElement[] = [...kpiElements, ...tagElements]

    elems.sort((a, b) => a.index - b.index)

    setTempElements(elems)
  }, [widget.settings.kpi_options, widget.settings.tags.selected])

  /*-- drawer --*/
  const [drawer, setDrawer] = useState<{
    open: boolean
    title: string
    type: ElementType
    data: {
      kpi: KpiOptionObject | null
      tag: WidgetTagObject | null
    }
  }>({
    open: false,
    title: '',
    type: ElementType.KPI,
    data: { kpi: null, tag: null }
  })

  const handleBackDrawer = () => {
    setDrawer((prevState) => ({ ...prevState, open: false }))
  }

  const handleCloseDrawer = () => {
    handleBackDrawer()
    onClose()
  }

  // handleUpdateDrawer for kpi
  useEffect(() => {
    if (drawer.open && drawer.data.kpi) {
      handleOpenDrawer(drawer.data.kpi.id, drawer.type)
    }
  }, [widget.settings.kpi_options])

  // handleUpdateDrawer for tag
  useEffect(() => {
    if (drawer.open && drawer.data.tag) {
      handleOpenDrawer(drawer.data.tag.id, drawer.type)
    }
  }, [widget.settings.tags])

  const handleOpenDrawer = (id: string, type: ElementType) => {
    let kpi = null
    let tag = null
    let title = ''

    if (type === ElementType.KPI) {
      kpi = widget.settings.kpi_options.find((kpi) => kpi.id === id)
      title = kpi?.title ?? ''
    } else {
      tag = widget.settings.tags.selected.find((tag) => tag.id === id)
      title = tag?.title ?? ''
    }

    setDrawer({
      open: true,
      title: title,
      type: type,
      data: { kpi: kpi ?? null, tag: tag ?? null }
    })
  }

  const editedKpi = drawer.data.kpi
  const editedTag = drawer.data.tag

  // Properties edited in this component.
  type TProperty = 'tags' | 'title'

  /*-- dialogs --*/
  const [addKpiDialogOpen, setAddKpiDialogOpen] = useState<boolean>(false)
  const [editOrderDialogOpen, setEditOrderDialogOpen] = useState<boolean>(false)

  const [dialog, setDialog] = useState<{
    open: boolean
    property: TProperty
    title: string
    type: 'add' | 'edit'
    data: {
      options: { label: string; value: any }[]
      value: string
      type: TInputType
    }
  }>({
    open: false,
    property: 'tags',
    title: '',
    type: 'edit',
    data: {
      options: [],
      value: '',
      type: 'text'
    }
  })

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const handleOpenDialog = (property: TProperty) => {
    let dialogTitle = ''
    let dialogType: 'add' | 'edit' = 'edit'
    let inputValue = ''
    let inputType: TInputType = 'text'
    let inputOptions: { label: string; value: any }[] = []

    switch (property) {
    case 'title':
      dialogTitle = 'Namn'
      inputType = 'text'
      inputValue = editedTag?.title ?? ''
      break
    case 'tags':
      dialogTitle = 'Attribut'
      dialogType = 'add'
      inputOptions = getTagOptions(widget.settings.tags)
      inputType = 'select'
      inputValue = ''
      break
    }

    setDialog({
      open: true,
      property: property,
      title: dialogTitle,
      type: dialogType,
      data: {
        options: inputOptions,
        type: inputType,
        value: inputValue
      }
    })
  }

  const handleEditDialogValue = (value: string) => {
    setDialog((prevState) => ({
      ...prevState,
      data: { ...prevState.data, value }
    }))
  }

  const handleSubmitDialog = () => {
    switch (dialog.property) {
    case 'title':
      if (editedTag) {
        updateWidgetTag(editedTag.id, {
          data: { title: dialog.data.value }
        })
      }
      break
    case 'tags':
      createWidgetTag({
        data: {
          widget_id: widget.id,
          tag_option_id: dialog.data.value
        }
      })
      break
    }
  }

  const type = widget.settings.type.selected
  const numberOfKpis = widget.settings.kpi_options.length
  const showAdd =
    type === WidgetType.BAR_CHART ||
    type === WidgetType.LINE_CHART ||
    type === WidgetType.LIST ||
    type === WidgetType.KEY_FIGURE ||
    (type === WidgetType.BUBBLE_CHART && numberOfKpis <= 2) ||
    (type === WidgetType.COMBO_CHART && numberOfKpis <= 1) ||
    (type === WidgetType.MAP && numberOfKpis <= 9)

  return (
    <>
      <SettingsGroup
        title="Data"
        actions={
          type === WidgetType.LIST ||
          type === WidgetType.KEY_FIGURE ||
          type === WidgetType.BAR_CHART ||
          type === WidgetType.LINE_CHART ||
          type === WidgetType.BUBBLE_CHART ? (
                <Link onClick={() => setEditOrderDialogOpen(true)} variant="body2">
                  <Icon
                    color="inherit"
                    fontSize="inherit"
                    name={'EditOutlined'}
                    sx={{
                      mr: '0.25em'
                    }}
                  />
              Redigera ordning
                </Link>
              ) : undefined
        }
      >
        <SettingsItem
          icon={
            <Tooltip
              title={
                <>
                  <Text fontWeight="bold" variant="body2" gutterBottom>
                    Det här är ditt huvudnyckeltal.
                  </Text>
                  <Text variant="body2" gutterBottom>
                    Om du lägger till fler nyckeltal så påverkas en del av deras
                    egenskaper (till exempel <code>enhet</code>) av ditt
                    huvudnyckeltal.
                  </Text>
                  <Text variant="body2" gutterBottom>
                    Ett huvudnyckeltal går inte att radera eller flytta.
                  </Text>
                </>
              }
            >
              <Badge color="success" variant="dot">
                <Icon name={getIconName(mainKpi.index, widget)} />
              </Badge>
            </Tooltip>
          }
          onClick={() => handleOpenDrawer(mainKpi.id, ElementType.KPI)}
          title={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {mainKpi.title}
              {widget.settings.type.selected === WidgetType.LIST ? (
                <>
                  <Tooltip placement={'top'} title={'Nyckeltal'}>
                    <Box>
                      <Tag
                        startIconName={'TrendingUpOutlined'}
                        color="primary"
                      />
                    </Box>
                  </Tooltip>
                  {mainKpi.hidden ? (
                    <Tooltip placement={'top'} title={'Kolumnen är dold'}>
                      <Box>
                        <Tag
                          startIconName={'VisibilityOffOutlined'}
                          color="primary"
                        />
                      </Box>
                    </Tooltip>
                  ) : null}
                </>
              ) : null}
            </Box>
          }
        />
        {tempElements.map((item) => (
          <>
            <SettingsItem
              icon={<Icon name={getIconName(item.index, widget)} />}
              onClick={() => handleOpenDrawer(item.id, item.type)}
              title={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {item.title}
                  {widget.settings.type.selected === WidgetType.LIST ? (
                    <>
                      <Tooltip
                        placement={'top'}
                        title={
                          item.type === ElementType.KPI
                            ? 'Nyckeltal'
                            : 'Attribut'
                        }
                      >
                        <Box>
                          <Tag
                            startIconName={
                              item.type === ElementType.KPI
                                ? 'TrendingUpOutlined'
                                : 'SellOutlined'
                            }
                            color="primary"
                          />
                        </Box>
                      </Tooltip>
                      {item.hidden ? (
                        <Tooltip placement={'top'} title={'Kolumnen är dold'}>
                          <Box>
                            <Tag
                              startIconName={'VisibilityOffOutlined'}
                              color="primary"
                            />
                          </Box>
                        </Tooltip>
                      ) : null}
                    </>
                  ) : null}
                </Box>
              }
            />
          </>
        ))}
        {showAdd ? (
          <SettingsItem
            color="success"
            onClick={() => setAddKpiDialogOpen(true)}
            title={`${getAddTitle(widget, numberOfKpis)}`}
            variant="add"
          />
        ) : null}
        {widget.settings.type.selected === WidgetType.LIST ? (
          <SettingsItem
            color="success"
            disabled={widget.settings.tags.options.length === 0}
            disabledText={
              'Det finns inga attribut tillgängliga för vald segmentering.'
            }
            onClick={() => handleOpenDialog('tags')}
            title="Lägg till attribut"
            variant="add"
          />
        ) : null}
      </SettingsGroup>

      {/*-- drawer --*/}
      {/* if kpi */}
      <SettingsDrawer
        open={drawer.open && drawer.type === ElementType.KPI}
        onBack={handleBackDrawer}
        onClose={handleCloseDrawer}
        title={drawer.title}
      >
        {editedKpi ? (
          <>
            <TitleSection
              kpi={editedKpi}
              updateKpiOption={(data) => updateKpiOption(editedKpi.id, data)}
              widget={widget}
            />
            <UnitSection
              kpi={editedKpi}
              onClose={onClose}
              updateKpiOption={(data) => updateKpiOption(editedKpi.id, data)}
            />
            <RollingSection
              kpi={editedKpi}
              kpiTemplates={kpiTemplates}
              updateKpiOption={(data) => updateKpiOption(editedKpi.id, data)}
              widget={widget}
            />
            <BenchmarkSection
              allowBenchmarking={allowBenchmarking}
              kpi={editedKpi}
              updateKpiOption={(data) => updateKpiOption(editedKpi.id, data)}
            />
            <ComparativeDisplayTypeSection
              kpi={editedKpi}
              updateKpiOption={(data) => updateKpiOption(editedKpi.id, data)}
              widget={widget}
            />
            <FilterSection
              filterOptions={filterOptions}
              kpi={editedKpi}
              onClose={handleCloseDrawer}
              updateKpiOption={(data) => updateKpiOption(editedKpi.id, data)}
              widget={widget}
            />
            <BreakdownSection
              kpi={editedKpi}
              updateKpiOption={(data) => updateKpiOption(editedKpi.id, data)}
              widget={widget}
            />
            <IndicationSection
              kpi={editedKpi}
              onClose={handleCloseDrawer}
              updateKpiOption={(data) => updateKpiOption(editedKpi.id, data)}
              widget={widget}
            />
            <ShareKpiSection
              filterOptions={filterOptions}
              kpi={editedKpi}
              onClose={handleCloseDrawer}
              updateKpiOption={(data) => updateKpiOption(editedKpi.id, data)}
              widget={widget}
            />
            <SettingsGroup
              helperText={
                editedKpi.index === 0
                  ? 'Det går inte att radera ett huvudnyckeltal.'
                  : undefined
              }
            >
              <SettingsItem
                color="error"
                disabled={editedKpi.index === 0}
                onClick={() => {
                  deleteKpiOption(editedKpi.id)
                  handleBackDrawer()
                }}
                title="Radera"
                variant="action"
              />
            </SettingsGroup>
          </>
        ) : null}
      </SettingsDrawer>

      {/* if tag */}
      <SettingsDrawer
        open={drawer.open && drawer.type === ElementType.TAG}
        onBack={handleBackDrawer}
        onClose={handleCloseDrawer}
        title={drawer.title}
      >
        {editedTag ? (
          <>
            <SettingsGroup>
              <SettingsItem
                onClick={() => handleOpenDialog('title')}
                title="Namn"
                value={editedTag.title}
                variant="edit"
              />
            </SettingsGroup>

            <StatusSection
              onClose={handleCloseDrawer}
              tag={editedTag}
              widget={widget}
            />

            <SettingsGroup>
              <SettingsItem
                color="error"
                onClick={() => {
                  deleteWidgetTag(editedTag.id)
                  handleBackDrawer()
                }}
                title="Radera"
                variant="action"
              />
            </SettingsGroup>
          </>
        ) : null}
      </SettingsDrawer>

      {/*-- dialogs --*/}
      <CreateKpiDialog
        createItem={(id: string | null, performKpiId?: string) => {
          if (id) {
            createKpiOption({
              data: {
                widget_id: widget.id,
                kpi_template_id: id,
                perform_kpi_template_id: performKpiId
              }
            })
          }
        }}
        customer={customer}
        data={getAvailableKpiTemplates(
          kpiTemplates,
          widget,
          segmentBy,
          attributeOptions,
          groupedAttributeOptions
        )}
        kpisFetched={kpiTemplatesFetched}
        onClose={() => setAddKpiDialogOpen(false)}
        open={addKpiDialogOpen}
        sx={{ zIndex: 1302 }}
        variant="add"
        mainKpiUnit={mainKpi.unit}
        widgetType={widget.settings.type.selected}
      />

      <EditOrderDialog
        onClose={() => setEditOrderDialogOpen(false)}
        open={editOrderDialogOpen}
        updateWidget={updateWidget}
        widget={widget}
      />

      <SettingsEditDialog
        onChange={handleEditDialogValue}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        open={dialog.open}
        options={dialog.data.options}
        required={dialog.property === 'title'}
        title={dialog.title}
        type={dialog.data.type}
        value={dialog.data.value}
        variant={dialog.type}
      />
    </>
  )
}

export default DatasetsSection
