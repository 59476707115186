import React, { useState } from 'react'

import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Portal from 'components_new/atoms/Portal'
import Snackbar from 'components_new/atoms/Snackbar'

import SplitButton from 'components_new/molecules/SplitButton'
import { Context } from 'types/GlobalUserEvents'
import { addRef } from 'utils/queryHandler'

interface ActionsProps {
  showActions: boolean
  isPublic: boolean
  onClose: () => void
  publicLink: string
  setTab: (value: 'email' | 'default') => void
  tab: 'email' | 'default'
}

const Actions = (props: ActionsProps) => {
  const { showActions, isPublic, publicLink, setTab, tab } = props

  const [showCopiedMessage, setShowCopiedMessage] = useState(false)

  const copyCurrentUrl = () => {
    const currentUrl = addRef(window.location.href, Context.SHARE_LINK)

    navigator.clipboard
      .writeText(currentUrl)
      .then(() => setShowCopiedMessage(true))
      .catch((err) => console.error('Failed to copy current URL:', err))
  }

  const copyPublicLink = () => {
    navigator.clipboard
      .writeText(publicLink)
      .then(() => setShowCopiedMessage(true))
      .catch((err) => console.error('Failed to copy public link:', err))
  }

  return (
    <>
      {tab === 'default' ? (
        <>
          {showActions ? (
            <>
              <SplitButton
                onClick={copyCurrentUrl}
                options={[
                  {
                    disabled: !isPublic,
                    iconName: 'PublicOutlined',
                    infoTip: 'Fliken är inte publik.',
                    title: 'Kopiera flikens publika länk',
                    onClick: copyPublicLink
                  }
                ]}
                startIcon={<Icon name="Link" />}
                title={'Kopiera länk'}
              />
              <Button
                startIcon={<Icon name="MailOutlined" />}
                variant="outlined"
                onClick={() => setTab('email')}
              >
                Bjud in
              </Button>
            </>
          ) : null}
        </>
      ) : null}

      {/* ----- Feedback ----- */}
      <Portal>
        <Snackbar
          open={showCopiedMessage}
          autoHideDuration={3000}
          onClose={(event, reason) => {
            if (reason !== 'clickaway') {
              setShowCopiedMessage(false)
            }
          }}
          message="Kopierat till urklipp"
        />
      </Portal>
    </>
  )
}

export default Actions
