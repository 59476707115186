import React from 'react'
import { RenderLeafProps } from 'slate-react'

const Leaf = (props: RenderLeafProps) => {
  const { attributes, children, leaf } = props

  let content = children

  if (leaf.bold) {
    content = <strong>{content}</strong>
  }

  if (leaf.code) {
    content = <code>{content}</code>
  }

  if (leaf.italic) {
    content = <em>{content}</em> // @TODO: <i/>?
  }

  if (leaf.underline) {
    content = <u>{content}</u>
  }

  return <span {...attributes}>{content}</span>
}

export default Leaf
