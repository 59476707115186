import React from 'react'
import { RenderElementProps } from 'slate-react'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Text from 'components_new/atoms/Text'

const Element = (props: RenderElementProps) => {
  const { attributes, children, element } = props

  let style = {}

  if ('align' in element) {
    style = { textAlign: element.align }
  }

  switch (element.type) {
  case 'bulleted-list':
    return (
      <Box
        component="ul"
        sx={{ listStyleType: 'disc', my: '1.25em', pl: '1.625em', ...style }}
        {...attributes}
      >
        {children}
      </Box>
    )
  case 'heading-three':
    return (
      <Text
        variant="h6"
        component="h3"
        color="text.primary"
        gutterBottom
        sx={{ mt: '2em', ...style }}
        {...attributes}
      >
        {children}
      </Text>
    )
  case 'heading-two':
    return (
      <Text
        variant="h5"
        component="h2"
        color="text.primary"
        gutterBottom
        sx={{
          mt: '2em',
          borderBottom: '1px solid',
          borderBottomColor: 'divider',
          pb: '0.5rem',
          ...style
        }}
        {...attributes}
      >
        {children}
      </Text>
    )
  case 'hr':
    return <Divider sx={{ my: 4, ...style }} {...attributes} />
  case 'list-item':
    return (
      <Box
        component="li"
        sx={{
          my: '0.5em',
          pl: '0.375em',
          '&::marker': { color: '#bababb' },
          ...style
        }}
        {...attributes}
      >
        {children}
      </Box>
    )
  case 'numbered-list':
    return (
      <Box
        component="ol"
        sx={{
          listStyleType: 'decimal',
          my: '1.25em',
          pl: '1.625em',
          ...style
        }}
        {...attributes}
      >
        {children}
      </Box>
    )
  default:
    return (
      <Text
        sx={{
          ':not(:first-of-type)': {
            mt: '1.25em'
          },
          ':not(:last-of-type)': {
            mb: '1.25em'
          },
          ...style
        }}
        {...attributes}
      >
        {children}
      </Text>
    )
  }
}

export default Element
