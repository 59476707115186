import React, { useState } from 'react'

import Icon from 'components_new/atoms/Icon'
import Switch from 'components_new/atoms/Switch'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

import ConfirmationDialog from 'components_new/organisms/dialogs/ConfirmationDialog'

import { TEmbedComponent } from '../types'
import { REmbedAccess } from '../lib'

interface AccessToggleProps {
  apiAccess?: boolean
  embedComponent: TEmbedComponent
  isManagementCompany: boolean
  isPublic: boolean
  setApiAccess?: (value: boolean) => void
  setIsPublic: (value: boolean) => void
}

const AccessToggle = (props: AccessToggleProps) => {
  const {
    apiAccess,
    embedComponent,
    isManagementCompany,
    isPublic,
    setApiAccess,
    setIsPublic
  } = props

  const [confirmationDialog, setConfirmationDialog] = useState<{
    open: boolean
    handleConfirm: () => void
      }>({
        open: false,
        handleConfirm: () => {}
      })

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialog({ handleConfirm: () => {}, open: false })
  }

  const handleSetApiAccess = (value: boolean) => {
    if (!setApiAccess) return

    if (isPublic) {
      setConfirmationDialog({
        handleConfirm: () => setApiAccess(value),
        open: true
      })
    } else {
      setApiAccess(value)
    }
  }

  const handleSetIsPublic = (value: boolean) => {
    if (apiAccess) {
      setConfirmationDialog({
        handleConfirm: () => setIsPublic(value),
        open: true
      })
    } else {
      setIsPublic(value)
    }
  }

  return (
    <>
      <SettingsGroup>
        <SettingsItem
          actions={
            <Switch
              color="success"
              checked={isPublic}
              onChange={() => handleSetIsPublic(!isPublic)}
              size="medium"
            />
          }
          body={REmbedAccess['public'].body}
          icon={<Icon name={REmbedAccess['public'].iconName} />}
          title={REmbedAccess['public'].title}
        />

        {embedComponent === 'tab' && setApiAccess && isManagementCompany ? (
          <SettingsItem
            actions={
              <Switch
                color="success"
                checked={apiAccess}
                onChange={() => handleSetApiAccess(!apiAccess)}
                size="medium"
              />
            }
            body={REmbedAccess['api-access'].body}
            icon={<Icon name={REmbedAccess['api-access'].iconName} />}
            title={REmbedAccess['api-access'].title}
          />
        ) : null}
      </SettingsGroup>

      <ConfirmationDialog
        content={{
          action: 'ändra',
          consequence: 'Din nuvarande länk kommer att sluta fungera.',
          item: 'åtkomsttyp'
        }}
        handleClose={handleCloseConfirmationDialog}
        handleConfirm={confirmationDialog.handleConfirm}
        open={confirmationDialog.open}
      />
    </>
  )
}

export default AccessToggle
