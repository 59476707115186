import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as AuthActions from 'redux/actions/Authentication'

import AuthTemplate from 'components_new/templates/AuthTemplate'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import ActivateAccount from './ActivateAccount'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import SignIn from './SignIn'

import { getSession, getUserFromEmail, clearCookies } from './AuthModule'

import {
  LOGIN,
  FORGOT_PASSWORD,
  VERIFICATION_CODE,
  SET_PASSWORD,
  SIGNED_IN,
  LOGIN_WITH_AZURE
} from './utils'

import {
  handleIdp,
  handleLogin,
  handleSetPassword,
  handleForgotPassword,
  handleVerificationCode,
  handleLoginWithAzure
} from './ActionHandlers'
import { signOut } from './AuthModule'
import Button from 'components_new/atoms/Button'
import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

const RESTART_LOCATION =
  process.env.NODE_ENV === 'production'
    ? 'https://portal.homepal.se'
    : 'http://localhost:3000'

const Auth = ({
  AuthStore,
  children,
  // redux actions
  setLoggedIn
}) => {
  const [blank, setBlank] = useState(false)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(null)
  const [user, setUser] = useState(null)
  const [email, setEmail] = useState('')
  const [confirmationCode, setConfirmationCode] = useState('')
  const [invitedBy, setInvitedBy] = useState(null)
  const [dashboardName, setDashboardName] = useState(null)
  const [errors, setErrors] = useState([])
  const [idpSelections, setIdpSelections] = useState([])
  const history = useHistory()

  useEffect(() => {
    setBlank(true)

    if (window.location.search.includes('&confirmation_code=')) {
      const email = window.location.search.split('email=')[1].split('&')[0]
      const code = window.location.search.split('confirmation_code=')[1]

      setConfirmationCode(code)
      setUser(getUserFromEmail(email.toLowerCase()))
      setState(VERIFICATION_CODE)
      setBlank(false)
    } else if (window.location.search.includes('?code=')) {
      // This is triggered twice in React.Strictmode so if testing
      // make sure to deactivate.
      handleIdp(setState, setBlank)
        .then((errors) => {
          if (errors.length > 0) {
            signOut()
              .then(() => {
                history.push('/login')
                setErrors(errors)
              })
              .catch(() => {
                setErrors(errors)
                history.push('/login')
              })
          }
        })
        .catch(() => {
          signOut()
            .then(() => {
              window.location = RESTART_LOCATION
            })
            .catch(() => {
              window.location = RESTART_LOCATION
            })
        })
    } else if (
      window.location.search.includes('email=') &&
      window.location.search.includes('chal=')
    ) {
      const handleCreateAccount = () => {
        const email = window.location.search.split('email=')[1].split('&')[0]

        const chal = window.location.search.split('chal=')[1]
        const redirectOnError = () => {
          history.push('/login')
          setState(LOGIN)
        }

        handleLogin(
          { email: email, password: chal },
          setState,
          setUser,
          false,
          redirectOnError
        )
        setBlank(false)
        setEmail(email)
      }

      signOut()
        .then(() => {
          handleCreateAccount()
        })
        .catch(() => handleCreateAccount())
    } else {
      getSession()
        .then(() => {
          setState(SIGNED_IN)
          setBlank(false)
        })
        .catch(() => {
          clearCookies()
          setState(LOGIN)
          setBlank(false)
        })
    }
  }, [])

  useEffect(() => {
    if (
      state === SIGNED_IN &&
      !AuthStore.isAuthenticated &&
      !AuthStore.validating
    ) {
      setLoggedIn()
    }
  }, [state])

  useEffect(() => {
    if (!AuthStore.isAuthenticated && state) {
      setState(LOGIN)
    }
  }, [AuthStore.isAuthenticated])

  useEffect(() => {
    if (
      state &&
      state !== SIGNED_IN &&
      window.location.pathname.includes('dashboards')
    ) {
      const splitSearch = window.location.search.split('&')
      /*
        When inviting a user, the search link and thereby the split should 
        generate the following array:
        [
          ?email={username},
          chal={####},
          invitedBy=${event.request.clientMetaData.invitedBy}
          dashboardName=${event.request.clientMetaData.dashboardName}
        ]
      */
      let paramRef = ''

      splitSearch.forEach((queryParam) => {
        if (queryParam.includes('invitedBy')) {
          setInvitedBy(queryParam.split('=')[1])
        } else if (queryParam.includes('dashboardName')) {
          setDashboardName(queryParam.split('=')[1])
        } else if (queryParam.includes('ref=')) {
          paramRef = queryParam
        }
      })

      const redirectPath = window.location.pathname + paramRef

      history.push('/login', { redirectPath })
    } else if (
      state &&
      state !== SIGNED_IN &&
      window.location.pathname !== '/login'
    ) {
      history.push('/login')
    }
  }, [state])

  if ((blank && state !== SIGNED_IN) || !state) {
    return null
  }

  return state === SIGNED_IN ? (
    <>{children}</>
  ) : (
    <AuthTemplate>
      <ThemeProvider theme={getTheme('light')}>
        <SimpleDialog
          open={idpSelections.length > 1}
          title={'Logga in med'}
          onClose={() => {
            setIdpSelections([])
            setLoading(false)
          }}
          actions={
            <Button
              variant={'text'}
              onClick={() => {
                setIdpSelections([])
                setLoading(false)
              }}
            >
              Stäng
            </Button>
          }
        >
          {idpSelections}
        </SimpleDialog>
      </ThemeProvider>
      {state === LOGIN ? (
        <SignIn
          errors={errors}
          loading={loading}
          onForgetPassword={() => setState(FORGOT_PASSWORD)}
          onSignIn={async (email, password, rememberMe, onlyEmail) => {
            setLoading(true)
            if (onlyEmail) {
              try {
                await handleLoginWithAzure({ email }, setIdpSelections)
                setLoading(false)

                return false
              } catch (e) {
                setLoading(false)
              }
            } else {
              const errors = await handleLogin(
                { email, password },
                setState,
                setUser,
                rememberMe
              )

              setErrors(errors)
              setLoading(false)
            }

            return true
          }}
          onSignInWithMicrosoft={() => setState(LOGIN_WITH_AZURE)}
          setErrors={(errors) => setErrors(errors)}
        />
      ) : null}

      {state === FORGOT_PASSWORD ? (
        <ForgotPassword
          errors={errors}
          loading={loading}
          onBackToSignIn={() => setState(LOGIN)}
          onForgotPassword={async (email, setPasswordHasBeenReset) => {
            setLoading(true)

            const errors = await handleForgotPassword(
              { email },
              setPasswordHasBeenReset
            )

            setErrors(errors)

            setLoading(false)
          }}
          setErrors={(errors) => setErrors(errors)}
        />
      ) : null}

      {state === VERIFICATION_CODE ? (
        <ResetPassword
          confirmationCode={confirmationCode}
          errors={errors}
          loading={false}
          onBackToSignIn={() => setState(LOGIN)}
          onResetPassword={async (code, password) => {
            setLoading(true)

            const errors = await handleVerificationCode(
              { password, code },
              setState,
              user
            )

            setErrors(errors)

            setLoading(false)
          }}
          setErrors={(errors) => setErrors(errors)}
        />
      ) : null}

      {state === SET_PASSWORD ? (
        <ActivateAccount
          dashboardName={dashboardName}
          email={email || user?.username}
          errors={errors}
          invitedBy={invitedBy}
          loading={loading}
          onActivateAccount={async (password, firstName, lastName) => {
            setLoading(true)

            const errors = await handleSetPassword(
              { password, first_name: firstName, last_name: lastName },
              setState,
              user,
              email || user?.username
            )

            setErrors(errors)

            setLoading(false)
          }}
          setErrors={(errors) => setErrors(errors)}
        />
      ) : null}
    </AuthTemplate>
  )
}

function mapStateToProps({ AuthStore }) {
  return { AuthStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...AuthActions
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
