import React, { useEffect, useRef, useState } from 'react'

import { getTheme } from 'themes'
import { ThemeProvider } from '@mui/material'

import { WhoCanView } from 'redux/reducers/Dashboards'

import { CompanyGroupWithPermission } from 'types/GlobalCompanyGroups'
import { UserType } from 'types/GlobalUser'

import { PermissionPatchType } from 'components_new/organisms/Dashboard/utils'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Collapse from 'components_new/atoms/Collapse'

import TabSwitch from 'components_new/molecules/TabSwitch'

import SectionHeader from 'components_new/molecules/SectionHeader'
import SidebarDialog from 'components_new/molecules/SidebarDialog'

import Actions from '../Actions'

import CustomersSection from './CustomersSection'
import InviteSection from './InviteSection'
import PermissionSection from './PermissionSection'
import Title from './Title'
import WhoCanViewSection from './WhoCanViewSection'

enum SwitchTabValues {
  USERS = 'USERS',
  CUSTOMERS = 'CUSTOMERS'
}

const translateSwitchTabValues: Record<SwitchTabValues, string> = {
  [SwitchTabValues.USERS]: 'Användare',
  [SwitchTabValues.CUSTOMERS]: 'Koncerner'
}

interface DesktopProps {
  allCompanyGroupsHasAccess: boolean
  customerOptions: CompanyGroupWithPermission[]
  fullRights: boolean
  groupTitle: string
  hasIdp: boolean
  isManagementCompany: boolean
  isPublic: boolean
  link: string
  onClose: () => void
  open: boolean
  inviteUser: (user: PermissionPatchType) => void
  publicLink: string
  revokeUser: (accountId?: string, companyGroupId?: string) => void
  setAllCompanyGroupsHasAccess: (allCompanyGroupsHasAccess: boolean) => void
  setWhoCanView: (type: WhoCanView) => void
  userOptions: UserType[]
  userPermissions: UserType[]
  whoCanView: WhoCanView
}

const Desktop = (props: DesktopProps) => {
  const {
    allCompanyGroupsHasAccess,
    customerOptions,
    fullRights,
    groupTitle,
    hasIdp,
    inviteUser,
    isManagementCompany,
    isPublic,
    // link,
    onClose,
    open,
    publicLink,
    revokeUser,
    setAllCompanyGroupsHasAccess,
    setWhoCanView,
    userPermissions,
    userOptions,
    whoCanView
  } = props

  const handleClose = () => {
    onClose()
  }

  const [tab, setTab] = useState<'default' | 'email'>('default')
  const [switchTab, setSwitchTab] = useState<SwitchTabValues>(
    SwitchTabValues.USERS
  )

  const addUserInputRef = useRef<HTMLElement | null>(null)

  const focusAddUser = () => {
    setTimeout(() => {
      if (addUserInputRef.current != null) {
        addUserInputRef.current.focus()
      }
    }, 500)
  }

  useEffect(() => {
    if (!open) {
      setTab('default')
    }
  }, [open])

  return (
    <ThemeProvider theme={getTheme('light')}>
      <SidebarDialog
        actions={
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1
              }}
            >
              {tab === 'default' ? (
                <Actions
                  isPublic={isPublic}
                  onClose={handleClose}
                  publicLink={publicLink}
                  setTab={(value) => setTab(value)}
                  tab={tab}
                  showActions={switchTab === SwitchTabValues.USERS}
                />
              ) : undefined}
            </Box>
            <Button onClick={handleClose} variant="text">
              Stäng
            </Button>
          </Box>
        }
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '512px!important'
          }
        }}
        title={<Title groupTitle={groupTitle} setTab={setTab} tab={tab} />}
      >
        <Collapse
          in={tab === 'default' && isManagementCompany && fullRights}
          unmountOnExit
        >
          <TabSwitch
            options={Object.values(SwitchTabValues).map((value) => ({
              value,
              label: translateSwitchTabValues[value]
            }))}
            value={switchTab}
            onChange={(value) => setSwitchTab(value as SwitchTabValues)}
            color={'primary'}
            sx={{ mt: -2, mb: 2 }}
          />
        </Collapse>
        <Collapse in={switchTab === SwitchTabValues.CUSTOMERS} unmountOnExit>
          <CustomersSection
            allCompanyGroupsHasAccess={allCompanyGroupsHasAccess}
            fullRights={fullRights}
            customerOptions={customerOptions}
            onInvite={(companyGroupId: string) =>
              inviteUser({ companyGroupId })
            }
            onRemove={(companyGroupId: string) =>
              revokeUser(undefined, companyGroupId)
            }
            setAllCompanyGroupsHasAccess={setAllCompanyGroupsHasAccess}
            userOptions={userOptions}
          />
        </Collapse>
        <Collapse in={switchTab === SwitchTabValues.USERS} unmountOnExit>
          <Box>
            <Collapse in={tab === 'default'}>
              <Box sx={{ mb: 2 }}>
                <PermissionSection
                  addUserInputRef={addUserInputRef}
                  permissions={userPermissions}
                  fullRights={fullRights}
                  onInvite={inviteUser}
                  onRemove={revokeUser}
                  userOptions={userOptions}
                  whoCanView={whoCanView}
                />
              </Box>
              <Box>
                <SectionHeader title="Allmän åtkomst" />
                <WhoCanViewSection
                  editable={fullRights}
                  setWhoCanView={setWhoCanView}
                  whoCanView={whoCanView}
                />
              </Box>
            </Collapse>
            <Collapse in={tab === 'email'}>
              <InviteSection
                hasIdp={hasIdp}
                onInvite={(email: string, message: string) =>
                  inviteUser({ message, newUserEmail: email })
                }
                onGoBack={() => {
                  setTab('default')
                  focusAddUser()
                }}
              />
            </Collapse>
          </Box>
        </Collapse>
      </SidebarDialog>
    </ThemeProvider>
  )
}

export default Desktop
