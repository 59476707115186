import { ReactNode } from 'react'

import { IconName } from 'components_new/atoms/Icon'
import { KpiStatus, KpiTemplate } from 'types/GlobalKpiTemplates'

export type TStatus = 'checked' | 'indeterminate' | 'false'

/**
 * Get the actual value of KPI status (with sub KPIs).
 *
 * @param value is the current value of a KPI status,
 * @param kpis are the sub KPIs,
 * @param status is the status to check,
 * @returns 'checked' if a KPI (and its possible sub KPIs) is completely true,
 * 'indeterminate' if its partly true, and 'false' if none is true.
 */
const getOneStatus = (
  status: boolean,
  subKpis: KpiTemplate[],
  variant: keyof KpiStatus
): TStatus => {
  let tempStatus: TStatus = 'checked'

  if (subKpis.length === 0) {
    if (status === true) {
      tempStatus = 'checked'
    } else {
      tempStatus = 'false'
    }
  } else {
    if (subKpis.every((kpi) => kpi.status && kpi.status[variant])) {
      tempStatus = 'checked'
    } else if (subKpis.some((kpi) => kpi.status && kpi.status[variant])) {
      tempStatus = 'indeterminate'
    } else {
      tempStatus = 'false'
    }
  }

  // If required target is missing return opposite:
  if (variant === 'required_target_missing') {
    switch (tempStatus) {
    case 'checked':
      return 'false'
    case 'false':
      return 'checked'
    default:
      return 'indeterminate'
    }
  } else {
    // return default
    return tempStatus
  }
}

type TStatuses = Exclude<keyof KpiStatus, 'perform_template_id'>[]

/**
 * Get all statuses of a KPI (based on its sub KPIs).
 *
 * @param kpi is the parent KPI,
 * @returns 'checked', 'indeterminate' or 'false' for every KPI status.
 */
export const getAllStatuses = (kpi: KpiTemplate) => {
  if (kpi.status !== null) {
    const keys = Object.keys(kpi.status).filter(
      (key) => key !== 'perform_template_id'
    )

    const statuses = (keys as TStatuses).map((variant) => {
      return {
        value: getOneStatus(
          kpi.status ? kpi.status[variant] : false,
          kpi.sub_kpis,
          variant
        ),
        variant: variant
      }
    })

    const order = [
      'available_in_license',
      'ordered',
      'integrated',
      'modelled',
      'quality_assured',
      'required_target_missing'
    ]

    return statuses.sort(
      (a, b) => order.indexOf(a.variant) - order.indexOf(b.variant)
    )
  }

  return null
}

export const translateKpiTemplateStatus: Record<
  Exclude<keyof KpiStatus, 'perform_template_id'>,
  Record<
    'checked' | 'indeterminate' | 'false',
    { title: string; body: ReactNode; iconName: IconName }
  >
> = {
  available_in_license: {
    checked: {
      title: 'Upplåst',
      body: 'Nyckeltalet ingår i din licens.',
      iconName: 'LockOpenOutlined'
    },
    indeterminate: {
      title: 'Delvis upplåst',
      body: 'Nyckeltalet ingår delvis i din licens.',
      iconName: 'LockOpenOutlined'
    },
    false: {
      title: 'Ej upplåst',
      body: 'Nyckeltalet ingår inte i din licens.',
      iconName: 'LockOutlined'
    }
  },
  ordered: {
    checked: {
      title: 'Beställt',
      body: 'Nyckeltalet har beställts av din organisation.',
      iconName: 'ShoppingCartOutlined'
    },
    indeterminate: {
      title: 'Delvis beställt',
      body: 'Nyckeltalet har delvis beställts av din organisation.',
      iconName: 'ShoppingCartOutlined'
    },
    false: {
      title: 'Ej beställt',
      body: 'Nyckeltalet har inte beställts av din organisation.',
      iconName: 'ShoppingCartOutlined'
    }
  },
  integrated: {
    checked: {
      title: 'Anslutet',
      body: 'Data som krävs för nyckeltalet är ansluten.',
      iconName: 'StorageOutlined'
    },
    indeterminate: {
      title: 'Delvis anslutet',
      body: 'Data som krävs för nyckeltalet är delvis ansluten.',
      iconName: 'StorageOutlined'
    },
    false: {
      title: 'Ej anslutet',
      body: 'Data som krävs för nyckeltalet har inte anslutits.',
      iconName: 'StorageOutlined'
    }
  },
  modelled: {
    checked: {
      title: 'Modellerat',
      body: 'Nyckeltalet har modellerats och kan användas.',
      iconName: 'ViewInArOutlined'
    },
    indeterminate: {
      title: 'Delvis modellerat',
      body: 'Nyckeltalet har delvis modellerats och kan delvis användas.',
      iconName: 'ViewInArOutlined'
    },
    false: {
      title: 'Ej modellerat',
      body: 'Nyckeltalet har inte modellerats.',
      iconName: 'ViewInArOutlined'
    }
  },
  quality_assured: {
    checked: {
      title: 'Kvalitetssäkrat',
      body: 'Nyckeltalet har kvalitetssäkrats.',
      iconName: 'VerifiedOutlined'
    },
    indeterminate: {
      title: 'Delvis kvalitetssäkrat',
      body: 'Nyckeltalet har delvis kvalitetssäkrats.',
      iconName: 'VerifiedOutlined'
    },
    false: {
      title: 'Ej kvalitetssäkrat',
      body: 'Nyckeltalet har inte kvalitetssäkrats.',
      iconName: 'VerifiedOutlined'
    }
  },
  required_target_missing: {
    checked: {
      title: 'Konfigurerat',
      body: 'Nyckeltalet har konfigurerats.',
      iconName: 'TuneOutlined'
    },
    indeterminate: {
      title: 'Delvis konfigurerat',
      body: 'Nyckeltalet har delvis konfigurerats.',
      iconName: 'TuneOutlined'
    },
    false: {
      title: 'Ej konfigurerat',
      body: 'Nyckeltalet kräver konfigurering för att kunna aktiveras.',
      iconName: 'TuneOutlined'
    }
  }
}
