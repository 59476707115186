import { ReactNode } from 'react'
import { PeriodFilter } from 'types/GlobalWidget'
import { translateDateFilter } from 'utils/enumTranslator'

import dayjs from 'utils/dayjs'

import { AccountRole, User } from 'types/GlobalUser'

import { useDashboard, useDashboardGroup } from 'redux/hooks/Dashboards'

export type PeriodFilterOption = {
  value: PeriodFilter | null
  label: ReactNode | string
}

export const getPeriodFilterOptions = (): PeriodFilterOption[] => [
  ...Object.values(PeriodFilter)
    .filter((period) => period !== PeriodFilter.LATEST_MONTH_WITH_DATA)
    .map((value) => ({
      value: value,
      label: translateDateFilter[value]
    }))
]

/**
 * Get min date for specific dates.
 * @returns 5 years ago from today.
 */
export const getMinDate = (): string => {
  return dayjs().subtract(5, 'year').startOf('year').format('YYYY-MM-DD')
}

/**
 * Get max date for specific dates.
 * @param upperLimit - Optional upper limit for date.
 * @returns End of month 12 months from today.
 */
export const getMaxDate = (upperLimit?: string | null): string => {
  if (upperLimit) {
    const maxDate = dayjs(upperLimit)

    return maxDate.format('YYYY-MM-DD')
  }
  let today = dayjs()

  today = today.add(12, 'month')

  return today.endOf('month').format('YYYY-MM-DD')
}

/**
 * Get from and to date based on a predfined period filter.
 * @param filter - Period filter enum.
 * @returns from date and to date on YYYY-MM-DD format.
 */
export const getDatesOfPeriod = (
  filter: PeriodFilter
): { from: string | null; to: string | null } => {
  const today = dayjs()

  switch (filter) {
  case PeriodFilter.THIS_WEEK_TO_TODAY:
    return {
      from: today.startOf('week').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.THIS_MONTH_TO_TODAY:
    return {
      from: today.startOf('month').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.THIS_QUARTER_TO_TODAY:
    return {
      from: today.startOf('quarter').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.THIS_YEAR_TO_TODAY:
    return {
      from: today.startOf('year').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_2_YEARS_TO_TODAY:
    return {
      from: today.subtract(2, 'year').startOf('year').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_3_YEARS_TO_TODAY:
    return {
      from: today.subtract(3, 'year').startOf('year').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.TODAY:
    return {
      from: today.format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.YESTERDAY:
    return {
      from: today.subtract(1, 'day').format('YYYY-MM-DD'),
      to: today.subtract(1, 'day').format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_WEEK:
    return {
      from: today.subtract(1, 'week').startOf('week').format('YYYY-MM-DD'),
      to: today.subtract(1, 'week').endOf('week').format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_MONTH:
    return {
      from: today.subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
      to: today.subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_QUARTER:
    return {
      from: today
        .subtract(1, 'quarter')
        .startOf('quarter')
        .format('YYYY-MM-DD'),
      to: today.subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_YEAR:
    return {
      from: today.subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
      to: today.subtract(1, 'year').endOf('year').format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_7_DAYS:
    return {
      from: today.subtract(6, 'days').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_30_DAYS:
    return {
      from: today.subtract(29, 'days').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_90_DAYS:
    return {
      from: today.subtract(89, 'days').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_365_DAYS:
    return {
      from: today.subtract(364, 'days').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_3_MONTHS_TO_TODAY:
    return {
      from: today.subtract(2, 'month').startOf('month').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_6_MONTHS_TO_TODAY:
    return {
      from: today.subtract(5, 'month').startOf('month').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_12_MONTHS_TO_TODAY:
    return {
      from: today.subtract(11, 'month').startOf('month').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_24_MONTHS_TO_TODAY:
    return {
      from: today.subtract(23, 'month').startOf('month').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_36_MONTHS_TO_TODAY:
    return {
      from: today.subtract(35, 'month').startOf('month').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_4_QUARTERS_TO_TODAY:
    return {
      from: today
        .subtract(3, 'quarter')
        .startOf('quarter')
        .format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_8_QUARTERS_TO_TODAY:
    return {
      from: today
        .subtract(7, 'quarter')
        .startOf('quarter')
        .format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_12_QUARTERS_TO_TODAY:
    return {
      from: today
        .subtract(11, 'quarter')
        .startOf('quarter')
        .format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  default:
    return {
      from: null,
      to: null
    }
  }
}

export function isEditable(dashboardId: string, user: User | null) {
  const dashboard = useDashboard(dashboardId)
  const group = useDashboardGroup(dashboardId)

  if (!user || !dashboard || !group) {
    return false
  }

  const ownerId: string | undefined = group.owner

  const isAdmin = user.role === AccountRole.ADMIN
  const isHomepal = user.is_homepal_user
  const isOwner = ownerId === user.id

  return isAdmin || isHomepal || isOwner
}
