import React, { FC, useEffect, useMemo } from 'react'
import { cloneDeep } from 'lodash'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import { ApplicationState } from 'redux/Stores/types'

import LoadingSettings from 'components_new/organisms/pageLoaders/LoadingSettings'

import CompaniesTemplate from 'components_new/templates/settings/Companies'

import * as AccountActions from 'redux/actions/Accounts'
import * as AccountRoleActions from 'redux/actions/AccountRoles'
import * as CompanyActions from 'redux/actions/Companies'
import * as CompanyGroupActions from 'redux/actions/CompanyGroups'

import { AccountRole } from 'types/GlobalUser'
import SettingsTemplate from 'components_new/templates/SettingsTemplate'

const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  AuthStore: state.AuthStore,
  CompanyStore: state.CompanyStore,
  CompanyGroupStore: state.CompanyGroupStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountActions,
      ...AccountRoleActions,
      ...CompanyActions,
      ...CompanyGroupActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type CompaniesPageProps = ConnectedProps<typeof connector>

const CompaniesPage: FC<CompaniesPageProps> = (props: CompaniesPageProps) => {
  const {
    AccountStore,
    AuthStore,
    CompanyStore,
    CompanyGroupStore,
    tryGetAllAccounts,
    tryGetAllAccountRoles,
    tryGetAllCompanies,
    tryGetAllCompanyGroups,
    tryCreateCompanyGroup,
    tryCreateUser,
    tryUpdateCompany,
    tryUpdateCompanyGroup,
    tryDeleteCompanyGroup,
    tryDeleteUser,
    tryAddCompanyToCompanyGroup,
    tryRemoveCompanyFromCompanyGroup
  } = props

  useEffect(() => {
    if (!AccountStore.fetched) {
      tryGetAllAccounts()
    }

    if (!AccountStore.rolesFetched) {
      tryGetAllAccountRoles()
    }

    if (!CompanyGroupStore.fetched && !CompanyGroupStore.fetching) {
      tryGetAllCompanyGroups()
    }

    if (!CompanyStore.fetched) {
      tryGetAllCompanies()
    }
  }, [])

  const parsedRoles = useMemo(() => {
    const roles = cloneDeep(AccountStore.roles)

    if (AuthStore?.customer) {
      delete roles[AccountRole.ACCESS_HUB]
      delete roles[AccountRole.ADMIN]
    }

    return roles
  }, [AccountStore.roles])

  return (
    <>
      <SettingsTemplate>
        {AccountStore.fetched &&
        AccountStore.rolesFetched &&
        CompanyStore.fetched &&
        CompanyGroupStore.fetched ? (
              <CompaniesTemplate
                AuthStore={AuthStore}
                addCompanyToCompanyGroup={tryAddCompanyToCompanyGroup}
                companies={Object.values(CompanyStore.data)}
                companyGroups={Object.values(CompanyGroupStore.data)}
                createCompanyGroup={tryCreateCompanyGroup}
                createUser={tryCreateUser}
                deleteCompanyGroup={tryDeleteCompanyGroup}
                deleteUser={tryDeleteUser}
                // This should hard-coded to false since here we only invite
                // to company groups
                hasIdp={false}
                isHomepal={AuthStore.user?.is_homepal_user}
                removeCompanyFromCompanyGroup={tryRemoveCompanyFromCompanyGroup}
                roles={parsedRoles}
                updateCompany={tryUpdateCompany}
                updateCompanyGroup={tryUpdateCompanyGroup}
                users={Object.values(AccountStore.data)}
              />
            ) : (
              <LoadingSettings
                titles={[
                  'Synkroniserar bolag',
                  'Kopplar bolag',
                  'Läser in användare'
                ]}
              />
            )}
      </SettingsTemplate>
    </>
  )
}

export default connector(CompaniesPage)
