import { createAction, createReducer } from '@reduxjs/toolkit'
import * as Types from 'redux/Types'

import { cloneDeep } from 'lodash'

import { arrayToObject } from 'helpers/Functions'
import { CompanyGroup } from 'types/GlobalCompanyGroups'

export type CompanyGroupBody = {
  [key: string]: CompanyGroup
}
export interface CompanyGroupReducerType {
  data: CompanyGroupBody
  fetched: boolean
  fetching: boolean
  showAs: string | null
}

// Initial state
const INITIAL_STATE: CompanyGroupReducerType = {
  data: {},
  fetched: false,
  fetching: false,
  showAs: null
}

// Actions
const startGetAllAction = createAction(Types.GET_ALL_COMPANY_GROUPS)
const getAllAction = createAction<{ data: CompanyGroup[] }>(
  Types.GET_ALL_COMPANY_GROUPS_SUCCESS
)
const getAllFailedAction = createAction(Types.GET_ALL_COMPANY_GROUPS_FAILED)
const createGroupAction = createAction<{ data: CompanyGroup }>(
  Types.CREATE_COMPANY_GROUP_SUCCESS
)
const updateAction = createAction<{ data: CompanyGroup }>(
  Types.UPDATE_COMPANY_GROUP_SUCCESS
)
const deleteAction = createAction<{ id: string }>(
  Types.DELETE_COMPANY_GROUP_SUCCESS
)
const addCompanyAction = createAction<{ data: CompanyGroup }>(
  Types.ADD_COMPANY_TO_COMPANY_GROUP_SUCCESS
)
const removeCompanyAction = createAction<{ data: CompanyGroup }>(
  Types.REMOVE_COMPANY_FROM_COMPANY_GROUP_SUCCESS
)
const showAsAction = createAction<string | null>(
  Types.SET_SHOW_AS_COMPANY_GROUP
)

const signOutAction = createAction(Types.SIGN_OUT)

const setNewCompanyGroup = (
  state: CompanyGroupReducerType,
  action: {
    payload: {
      data: CompanyGroup
    }
    type: string
  }
) => {
  const { payload } = action

  return {
    ...state,
    data: {
      ...state.data,
      [payload.data.id]: payload.data
    }
  }
}

// Reducer
const comanyGroupReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(startGetAllAction, (state) => {
      return {
        ...state,
        fetching: true
      }
    })
    .addCase(getAllAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: arrayToObject(payload.data) as CompanyGroupBody,
        fetched: true,
        fetching: false,
        showAs: payload.data.length > 500 ? payload.data[0].id : null
      }
    })
    .addCase(getAllFailedAction, (state) => {
      return {
        ...state,
        fetching: false
      }
    })
    .addCase(createGroupAction, setNewCompanyGroup)
    .addCase(updateAction, setNewCompanyGroup)
    .addCase(deleteAction, (state, action) => {
      const { payload } = action

      const newData = cloneDeep(state.data)

      delete newData[payload.id]

      return {
        ...state,
        data: newData
      }
    })
    .addCase(addCompanyAction, setNewCompanyGroup)
    .addCase(removeCompanyAction, setNewCompanyGroup)
    .addCase(showAsAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        showAs: payload
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default comanyGroupReducer
