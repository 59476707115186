import React from 'react'

import Icon from 'components_new/atoms/Icon'
import Switch from 'components_new/atoms/Switch'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

import { TEmbedComponent } from '../types'
import { REmbedComponent } from '../lib'

interface OtherProps {
  embedComponent: TEmbedComponent
  hideFooter: boolean
  setHideFooter: (value: boolean) => void
}

const Other = (props: OtherProps) => {
  const { embedComponent, hideFooter, setHideFooter } = props

  return (
    <SettingsGroup title="Övrigt">
      <SettingsItem
        actions={
          <Switch
            color="success"
            checked={hideFooter}
            onChange={() => setHideFooter(!hideFooter)}
            size="medium"
          />
        }
        body={
          <>
            Ger dig en avskalad variant av{' '}
            {REmbedComponent[embedComponent].title.toLowerCase()}en utan sidfot.
          </>
        }
        icon={<Icon name={'BorderBottomOutlined'} />}
        title={'Dölj sidfot'}
        titleAdornments={<code>?hide_footer=true</code>}
      />
    </SettingsGroup>
  )
}

export default Other
