import React, { useState, useMemo } from 'react'

import Button from 'components_new/atoms/Button'

import LoadingSection from 'components_new/molecules/LoadingSection'
import SidebarDialog from 'components_new/molecules/SidebarDialog'

import { Customer } from 'types/GlobalCustomer'
import { KpiTemplate, KpiTemplateRedux } from 'types/GlobalKpiTemplates'
import { WidgetType } from 'types/GlobalWidget'
import { Category } from 'redux/reducers/Dashboards'
import { LOADING_KPI_TITLES } from 'utils/loadingTitles'

import {
  getCategoryArray,
  getFilteredKpis,
  getSubcategorizedKpis
} from './utils'

import DetailsView from './DetailsView'
import Title from './Title'
import ListView from './ListView'
import Sidebar from './Sidebar'

interface CreateKpiDialogProps {
  createItem: (
    kpiId: string | null,
    performKpiId?: string,
    type?: WidgetType.TEXT | WidgetType.COMMENT | WidgetType.IMAGE
  ) => void
  customer: Customer | null
  data: KpiTemplateRedux
  kpisFetched: boolean
  onClose: () => void
  open: boolean
  requestKpi?: () => void
  sx?: object
  variant: 'add' | 'create'

  // Only used for 'add' variant.
  mainKpiUnit?: string | null
  widgetType?: WidgetType
}

const CreateKpiDialog = (props: CreateKpiDialogProps) => {
  const {
    createItem,
    customer,
    data = {},
    kpisFetched,
    onClose,
    open,
    requestKpi,
    sx,
    variant,
    mainKpiUnit,
    widgetType
  } = props

  const recommendSameUnit =
    variant === 'add' &&
    mainKpiUnit !== undefined &&
    (widgetType === WidgetType.BAR_CHART ||
      widgetType === WidgetType.LINE_CHART)

  const [tab, setTab] = useState<'ALL' | Category | 'OTHER'>('ALL')

  const [showAvailable, setShowAvailable] = useState<boolean>(true)
  const [showSameUnit, setShowSameUnit] = useState<boolean>(false)

  // Base KPIs and categories:
  const [kpis, categories] = useMemo(() => {
    const tempKpis = Object.values(data)
    const tempCategories = getCategoryArray(tempKpis)

    return [tempKpis, tempCategories]
  }, [data])

  // KPIs to be displayed:
  const [query, setQuery] = useState('')

  const [filteredKpis, filteredSubcategorizedKpis] = useMemo(() => {
    const tempFilteredKpis = getFilteredKpis(
      kpis,
      showAvailable,
      query,
      showSameUnit,
      recommendSameUnit,
      mainKpiUnit
    )
    const tempFilteredSubcategorizedKpis = getSubcategorizedKpis(
      tempFilteredKpis,
      categories
    )

    return [tempFilteredKpis, tempFilteredSubcategorizedKpis]
  }, [categories, kpis, query, showAvailable, showSameUnit, recommendSameUnit])

  // Show details view of a KPI:
  const [kpiDetails, setKpisDetails] = useState<KpiTemplate | null>(null)
  const showDetails = Boolean(kpiDetails)

  const parsedOnClose = () => {
    setQuery('')
    onClose()
  }

  return (
    <SidebarDialog
      noGutter={!showDetails}
      disableOverflow={!showDetails}
      fullHeight={!showDetails}
      fullWidth={true}
      maxWidth={kpiDetails ? 'md' : 'lg'}
      scrollToTop={true}
      sx={sx}
      title={
        <Title
          kpiTitle={kpiDetails?.title ?? ''}
          onBack={() => setKpisDetails(null)}
          showDetails={showDetails}
          variant={variant}
        />
      }
      onClose={parsedOnClose}
      open={open}
      actions={
        !showDetails ? (
          <Button onClick={parsedOnClose} variant="text">
            Stäng
          </Button>
        ) : undefined
      }
      leftSidebar={
        !showDetails ? (
          <Sidebar
            tab={tab}
            variant={variant}
            categories={categories}
            kpis={filteredKpis}
            kpisFetched={kpisFetched}
            setShowAvailable={setShowAvailable}
            setTab={setTab}
            showAvailable={showAvailable}
            recommendSameUnit={recommendSameUnit}
            setShowSameUnit={setShowSameUnit}
            showSameUnit={showSameUnit}
          />
        ) : undefined
      }
    >
      {kpisFetched ? (
        <>
          {!showDetails ? (
            <ListView
              kpis={filteredKpis}
              kpisFetched={kpisFetched}
              onCreate={(kpiId, performKpiId, type) => {
                createItem(kpiId, performKpiId, type)
                parsedOnClose()
              }}
              query={query}
              requestKpi={requestKpi}
              setQuery={setQuery}
              setShowDetails={setKpisDetails}
              subcategorizedKpis={filteredSubcategorizedKpis}
              tab={tab}
              variant={variant}
              mainKpiUnit={mainKpiUnit}
              recommendSameUnit={recommendSameUnit}
            />
          ) : kpiDetails ? (
            <DetailsView
              customer={customer}
              kpi={kpiDetails}
              onCreate={(kpiId, performKpiId) => {
                createItem(kpiId, performKpiId)
                setKpisDetails(null)
                parsedOnClose()
              }}
              mainKpiUnit={mainKpiUnit}
              recommendSameUnit={recommendSameUnit}
            />
          ) : null}
        </>
      ) : (
        <LoadingSection titles={LOADING_KPI_TITLES} loading={true} />
      )}
    </SidebarDialog>
  )
}

export default CreateKpiDialog
