import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useMediaQuery, useTheme } from '@mui/material'

import {
  DashboardFilter,
  ParsedDashboardFilterOptions
} from 'types/GlobalDashboardFilter'
import { CustomPeriodFilter, PeriodFilter } from 'types/GlobalWidget'

import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'
import { bindActionCreators, Dispatch } from 'redux'

import * as DashboardFilterOptionActions from 'redux/actions/DashboardFilterOptions'
import * as DashboardActions from 'redux/actions/Dashboards'
import * as SavedDashboardFilterActions from 'redux/actions/SavedDashboardFilters'

import { useDashboard, useDashboardGroup } from 'redux/hooks/Dashboards'

import { isEditable } from './utils'

import Box from 'components_new/atoms/Box'

import PeriodSelector from './PeriodSelector'

import EditUserFiltersDialog from './dialogs/EditUserFilterDialog'

import Loading from './loading'
import {
  formatDashboardFilterOptions,
  isPublicDashboard,
  resolveDashboardFilterResource
} from 'utils/functions'

import * as UserEvents from 'redux/api/internal/UserEvents'
import {
  useSavedDashboardFilters,
  useShouldFetchSavedDashboardFilters
} from 'redux/hooks/SavedDashboardFilters'

import { Action, Context } from 'types/GlobalUserEvents'
import { ISavedDashboardFilter } from 'types/GlobalSavedDashboardFilter'
import DashboardFilterSection from './DashboardFilterSection'
import { getShowAsFilter } from '../Dashboard/utils'

interface DashboardUserFilterProps {
  dashboardFilter: DashboardFilter
  periodFilter: PeriodFilter | CustomPeriodFilter | null
  savedDashboardFilter?: ISavedDashboardFilter | null
  setDashboardFilter: (value: DashboardFilter) => void
  setPeriodFilter: (period: PeriodFilter | CustomPeriodFilter | null) => void
  setSavedDashboardFilter?: (
    value: ISavedDashboardFilter | null,
    skipFetch?: boolean
  ) => void
}

const DashboardUserFilter = (props: ComponentProps) => {
  const {
    CompanyGroupStore,
    dashboardFilter,
    periodFilter,
    savedDashboardFilter,
    setDashboardFilter,
    setPeriodFilter,
    setSavedDashboardFilter,
    // redux stores
    AuthStore,
    DashboardFilterOptionsStore,
    // redux actions
    tryGetAllSavedDashboardFilters,
    tryGetDashboardFilterData,
    tryPutDashboardFilterOptions
  } = props
  const theme = useTheme()

  const [editDashboardUserFilters, setEditDashboardUserFilters] =
    useState<boolean>(false)

  const params = useParams<{ id: string }>()
  const dashboard = useDashboard(params.id)
  const dashboardGroup = useDashboardGroup(params.id)
  const shouldFetchSavedDashboardFilters = useShouldFetchSavedDashboardFilters()
  const savedDashboardFilters = useSavedDashboardFilters()
  const isPublic = !dashboard || isPublicDashboard(dashboard)

  useEffect(() => {
    if (!isPublic && shouldFetchSavedDashboardFilters) {
      tryGetAllSavedDashboardFilters()
    }
  }, [isPublic, shouldFetchSavedDashboardFilters])

  useEffect(() => {
    const { tryGetDashboardFilterOptions } = props

    if (
      !DashboardFilterOptionsStore.fetched &&
      !DashboardFilterOptionsStore.fetching
    ) {
      tryGetDashboardFilterOptions()
    }
  }, [])

  const dfResource = dashboard
    ? resolveDashboardFilterResource(dashboard, dashboardGroup)
    : null

  useEffect(() => {
    if (dfResource) {
      dfResource.dashboard_filters.forEach((dashboardFilter) => {
        const saveKey = `${dashboardFilter.relation_key}${
          CompanyGroupStore.showAs || ''
        }`

        if (!(saveKey in DashboardFilterOptionsStore.data)) {
          tryGetDashboardFilterData(
            dashboardFilter.relation_key,
            saveKey,
            CompanyGroupStore.showAs
              ? { filter: [getShowAsFilter(CompanyGroupStore.showAs)] }
              : null
          )
        }
      })
    }
  }, [dfResource, CompanyGroupStore.showAs])

  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })
  const editable = isEditable(params.id, AuthStore.user) && !isMobile

  // dashboard filters for dashboard
  const dashboardFilterOptions = useMemo(() => {
    if (!dfResource?.dashboard_filters) {
      return new Map<string, ParsedDashboardFilterOptions>()
    }

    return formatDashboardFilterOptions(
      dfResource.dashboard_filters,
      DashboardFilterOptionsStore,
      CompanyGroupStore.showAs
    )
  }, [
    dfResource?.dashboard_filters,
    DashboardFilterOptionsStore.data,
    DashboardFilterOptionsStore.options
  ])

  if (!dfResource || !dashboard || !DashboardFilterOptionsStore.fetched) {
    return <Loading />
  }

  // success
  return (
    <>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen
          })
        }}
      >
        {/*-- time period --*/}
        <Box sx={{ p: isMobile ? 1 : 2 }}>
          <PeriodSelector
            onSubmit={(period: PeriodFilter | CustomPeriodFilter | null) => {
              setPeriodFilter(period)

              if (!isPublic) {
                UserEvents.log({
                  action: period
                    ? Action.TIME_PERIOD_FILTERED
                    : Action.TIME_PERIOD_RESETTED,
                  context: period ? Context.FROM_FILTER_MENU : null,
                  dashboard_id: dashboard.id,
                  dashboard_group_id: dashboard.dashboard_group_id
                })
              }
            }}
            periodFilter={periodFilter}
          />
        </Box>

        {/*-- filters --*/}
        <DashboardFilterSection
          editable={editable}
          isMobile={isMobile}
          isPublic={isPublic}
          dashboardFilter={dashboardFilter}
          dashboardFilterOptions={dashboardFilterOptions}
          savedDashboardFilters={savedDashboardFilters}
          savedDashboardFilter={savedDashboardFilter}
          setEditDashboardUserFilters={setEditDashboardUserFilters}
          setDashboardFilter={setDashboardFilter}
          setSavedDashboardFilter={setSavedDashboardFilter}
        />
      </Box>

      {/*-- dialogs --*/}
      <EditUserFiltersDialog
        availableDashboardFilterOptions={DashboardFilterOptionsStore.options}
        dashboardFilterOptions={dashboardFilterOptions}
        onClose={() => setEditDashboardUserFilters(false)}
        onSave={(options) => {
          if (!dashboardGroup) {
            return
          }
          if (savedDashboardFilter) {
            // if a saved dashboard filter is selected
            // then validate if it still is valid for dashboard.
            const newOptions = new Map(
              options.map((item) => [item.relation_key, true])
            )

            const invalidFilter = savedDashboardFilter.conditions.some(
              (condition) => !newOptions.get(condition.relation_key)
            )

            if (invalidFilter) {
              setSavedDashboardFilter?.(null, true)
            }
          }
          tryPutDashboardFilterOptions(dashboardGroup.id, { data: options })
        }}
        open={editDashboardUserFilters}
      />
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  DashboardFilterOptionsStore: state.DashboardFilterOptionsStore,
  CompanyGroupStore: state.CompanyGroupStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...DashboardActions,
      ...DashboardFilterOptionActions,
      ...SavedDashboardFilterActions
    },
    dispatch
  )
}

type ComponentProps = ConnectedProps<typeof connector> &
  DashboardUserFilterProps

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(DashboardUserFilter)
