import React, {
  ChangeEventHandler,
  ElementType,
  FocusEventHandler,
  KeyboardEventHandler,
  ReactNode,
  MutableRefObject,
  MouseEventHandler
} from 'react'

import { TextField as MUITextField, useTheme } from '@mui/material'

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'

import Box from '../Box'
import Text from '../Text'

export interface TextFieldProps {
  autoComplete?: string
  autoFocus?: boolean
  children?: ReactNode
  component?: ElementType<any>
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  defaultValue?: ReactNode
  disabled?: boolean
  error?: boolean
  externalLabel?: string
  focused?: boolean
  fullWidth?: boolean
  helperText?: ReactNode
  id?: string
  inputRef?: MutableRefObject<any>
  InputLabelProps?: object
  InputProps?: object
  label?: ReactNode
  margin?: 'dense' | 'none' | 'normal'
  minRows?: number | string
  maxRows?: number | string
  multiline?: boolean
  name?: string
  noGutter?: boolean
  onBlur?:
    | (() => void)
    | FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onChange?:
    | (() => void)
    | ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  onFocus?:
    | (() => void)
    | FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onKeyDown?: (() => void) | KeyboardEventHandler
  placeholder?: string
  required?: boolean
  rows?: number | string
  select?: boolean
  // SelectProps?: Partial<SelectProps>
  SelectProps?: any
  size?: 'medium' | 'small'
  slotProps?: {
    formHelperText?: object
    htmlInput?: object
    input?: object
    inputLabel?: object
    select?: object
  }
  step?: string
  sx?: object
  value?: any
  variant?: 'outlined' | 'filled' | 'standard'
  type?: string
}

/**
 * The TextField component let users enter and
 * edit text.
 *
 * It acts as a wrapper component for a
 * complete form control including a label,
 * input, and help text.
 */

const TextField = React.forwardRef((props: TextFieldProps, ref: any) => {
  const { children, fullWidth, onFocus, sx, variant, ...rest } = props

  const theme = useTheme()

  return (
    <Box sx={{ width: '100%', ...(props.noGutter ? null : { mb: 1 }), ...sx }}>
      {props.externalLabel ? (
        <Text
          color="text.primary"
          fontWeight="medium"
          variant="body2"
          sx={{ mb: 0.25 }}
        >
          {props.externalLabel}
        </Text>
      ) : null}
      <MUITextField
        {...rest}
        fullWidth={fullWidth ?? true}
        hiddenLabel={!props.label}
        onFocus={
          // make sure that cursor is set at the end of the input on autoFocus
          props.autoFocus
            ? function (e) {
              const val = e.target.value

              e.target.value = ''
              e.target.value = val
            }
            : onFocus
        }
        sx={{
          ...props.sx,
          my: 0,
          '& .MuiFilledInput-root::after': {
            border: '0px!important'
          },
          '& .MuiFilledInput-root::before': {
            border: '0px!important'
          },
          '& .MuiFilledInput-root, .MuiSelect-select': {
            borderRadius: '8px',
            // To prevent unnecessary zoom in on mobile devices:
            ...(theme.breakpoints.values.mobile !== 0
              ? {
                  fontSize: {
                    xs: '16px',
                    mobile: theme.typography.body1.fontSize
                  }
                }
              : null)
          },
          '& input': {
            // To prevent unnecessary zoom in on mobile devices:
            ...(theme.breakpoints.values.mobile !== 0
              ? {
                  fontSize: {
                    xs: '16px',
                    mobile: theme.typography.body1.fontSize
                  }
                }
              : null)
          },
          borderRadius: '8px'
        }}
        ref={ref}
        SelectProps={{
          ...(props.SelectProps || {}),
          hiddenLabel: !props.label,
          IconComponent: props.SelectProps?.IconComponent
            ? props.SelectProps.IconComponent
            : props.SelectProps?.hideIcon
              ? () => null
              : UnfoldMoreIcon,
          MenuProps: {
            ...(props.SelectProps?.MenuProps || {}),
            PaperProps: {
              sx: {
                maxHeight: 256
              }
            },
            sx: {
              zIndex: 1600,
              ...(props.SelectProps?.MenuProps?.sx || {})
            }
          }
        }}
        variant={variant ?? 'filled'}
        //
        // This prevented our AutoComplete from selecting values
        // by arrow keys.
        // Let's keep it here for a while. It may have been added to solve a
        // bug fix for some other issue:
        //
        // onKeyDown={(e) => {
        //   e.stopPropagation()

        //   if (onKeyDown) {
        //     onKeyDown(e)
        //   }
        // }}
        //
      >
        {children}
      </MUITextField>
    </Box>
  )
})

TextField.displayName = 'TextField'
export default TextField
