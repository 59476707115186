import React, { MouseEvent } from 'react'

import { translateStatus } from 'utils/enumTranslator'

import Box from 'components_new/atoms/Box'

import FitText from 'components_new/molecules/FitText'
import TrendText from 'components_new/molecules/TrendText'

import { KeyFigureVisualizationType, WidgetObject } from 'types/GlobalWidget'

import { parseValue, TParsedKpiValues } from '../utils'

interface MainKpiFocusContainerProps {
  interactive: boolean
  widget: WidgetObject
  scaleFactor: number
  data: TParsedKpiValues[]
  onClick: (event: MouseEvent<HTMLElement>, option: TParsedKpiValues) => void
}

const MainKpiFocusContainer = (props: MainKpiFocusContainerProps) => {
  const { data, interactive, widget, scaleFactor, onClick } = props

  const mainOption = data[0]
  const showComparativePeriod =
    Boolean(widget.settings.comparative_period.selected.value) &&
    data.length > 1

  if (
    mainOption &&
    widget.settings.key_figure_visualization_type ===
      KeyFigureVisualizationType.FOCUS
  ) {
    return (
      <>
        <FitText
          variant="h1"
          onClick={
            interactive ? (event) => onClick(event, mainOption) : () => {}
          }
          sx={{
            color: mainOption.status
              ? `${translateStatus[mainOption.status].colorName}.main`
              : undefined,
            cursor: interactive ? 'pointer' : undefined,
            '&:hover': interactive
              ? {
                  color: mainOption.status
                    ? `${translateStatus[mainOption.status].colorName}.light`
                    : 'text.secondary'
                }
              : undefined
          }}
        >
          <Box
            component="span"
            sx={{
              display: 'block'
            }}
          >
            {parseValue(mainOption.value)}
            <Box component="span" sx={{ fontSize: '0.6em' }}>
              {' '}
              {mainOption.unit}
            </Box>
          </Box>
        </FitText>
        {showComparativePeriod ? (
          <TrendText
            equivalentFactor={mainOption.equivalentFactor}
            difference={mainOption.difference}
            increaseIsPositive={mainOption.increaseIsPositive}
            numberOfDecimals={mainOption.numberOfDecimals || 0}
            percentage={mainOption.percentage || 0}
            unitPrefix={mainOption.unitPrefixSelected}
            sx={{
              overflow: 'visible',
              fontSize: `${1.15 * scaleFactor}rem`,
              mb: 2 * scaleFactor
            }}
          />
        ) : null}
      </>
    )
  }

  return null
}

export default MainKpiFocusContainer
