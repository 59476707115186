import React, { useMemo } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { Dispatch, bindActionCreators } from 'redux'
import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'

import Box from 'components_new/atoms/Box'

import CheckboxItem from 'components_new/molecules/CheckboxItem'
import SectionHeader from 'components_new/molecules/SectionHeader'

import { getQueryObject, updateQueryParam } from 'utils/queryHandler'
import { getUniqueItemsFromField } from 'utils/cmsFunctions'

import Loading from './loading'
import { sortAlphabeticalAsc } from 'utils/sortHelper'

interface DashboardExampleFilterMenuProps {
  sx?: object
}

const DashboardExampleFilterMenu = (props: ComponentProps) => {
  const { DashboardExampleStore, sx } = props

  const location = useLocation()
  const history = useHistory()

  const categoryOptions = useMemo(
    () =>
      getUniqueItemsFromField(
        Object.values(DashboardExampleStore.data),
        'category',
        'slug'
      ).sort((a, b) => sortAlphabeticalAsc(a.fields.title, b.fields.title)),
    [DashboardExampleStore.data]
  )
  const selectedCategories = useMemo(() => {
    return getQueryObject()['category'] ?? []
  }, [location.search])

  const selectedSegments = useMemo(() => {
    return getQueryObject()['segment'] ?? []
  }, [location.search])

  const segmentOptions = useMemo(
    () =>
      getUniqueItemsFromField(
        Object.values(DashboardExampleStore.data),
        'segments',
        'slug'
      ).sort((a, b) => sortAlphabeticalAsc(a.fields.title, b.fields.title)),
    [DashboardExampleStore.data]
  )

  // loading
  if (!DashboardExampleStore.fetched) return <Loading />

  // success
  return (
    <>
      <Box
        sx={{
          minHeight: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          p: 2,
          ...sx
        }}
      >
        <Box sx={{ mb: 2 }}>
          <SectionHeader title="Kategori" />
          {categoryOptions.map((category: any) => (
            <CheckboxItem
              checked={selectedCategories.includes(category.fields.slug)}
              key={category.fields.slug}
              label={category.fields.title}
              onChange={() =>
                updateQueryParam({
                  history: history,
                  key: 'category',
                  value: category.fields.slug
                })
              }
              size="small"
              sx={{ display: 'block' }}
            />
          ))}
        </Box>

        <Box>
          <SectionHeader title="Bolagstyp" />
          {segmentOptions.map((segment: any) => (
            <CheckboxItem
              checked={selectedSegments.includes(segment.fields.slug)}
              key={segment.fields.slug}
              label={segment.fields.title}
              onChange={() =>
                updateQueryParam({
                  history: history,
                  key: 'segment',
                  value: segment.fields.slug
                })
              }
              size="small"
              sx={{ display: 'block' }}
            />
          ))}
        </Box>
      </Box>
    </>
  )
}

/*-- redux --*/

const mapStateToProps = (state: ApplicationState) => ({
  DashboardExampleStore: state.DashboardExampleStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  DashboardExampleFilterMenuProps

export default connector(DashboardExampleFilterMenu)
