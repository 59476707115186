import { useSelector } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import { Dashboard } from 'redux/reducers/Dashboards'
import {
  selectOneDashboard,
  selectOneDashboardGroup,
  selectAllDashboardGroups,
  selectAllDashboards,
  selectGroupDashboardsFromDashboard
} from 'redux/selectors/Dashboards'

// hook for retreiving Platform dashboard
export const useDashboard = <T = Dashboard>(dashboardId: string) => {
  return useSelector((state: ApplicationState) =>
    selectOneDashboard<T>()(state, dashboardId)
  )
}

// hook for retreiving Dashboard group
export const useDashboardGroup = (dashboardId: string) => {
  return useSelector((state: ApplicationState) => {
    return selectOneDashboardGroup(state, dashboardId)
  })
}

export const useDashboardGroups = () => {
  return useSelector((state: ApplicationState) => {
    return selectAllDashboardGroups(state)
  })
}

export const useDashboards = () => {
  return useSelector((state: ApplicationState) => {
    return selectAllDashboards(state)
  })
}

// Get all dashboards from group of given dashboard.
export const useGroupDashboards = (dashboardId: string) => {
  return useSelector((state: ApplicationState) => {
    return selectGroupDashboardsFromDashboard(state, dashboardId)
  })
}
