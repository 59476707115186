import { Dispatch } from 'redux'

import { getAll, update, create, destroy } from 'redux/api/Accounts'
import * as UserEvents from 'redux/api/internal/UserEvents'

import * as Types from 'redux/Types'

import { PatchUserBody, PostUserBody } from 'types/GlobalUser'
import { Action } from 'types/GlobalUserEvents'

export function tryGetAllAccounts() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ALL_ACCOUNTS
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_ACCOUNTS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_ACCOUNTS_FAILED
        })
      })
  }
}

export function tryUpdateUser(id: string, body: PatchUserBody) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_USER
    })

    update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_USER_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Användare uppdaterad'
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_USER_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Kunde inte uppdatera användare'
          }
        })
      })
  }
}

export function tryCreateUser(body: PostUserBody) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_USER
    })

    create(body)
      .then((response) => {
        UserEvents.log({
          action: Action.INVITE_SENT_TO_HOMEPAL,
          context: null,
          dashboard_id: null,
          dashboard_group_id: null
        })

        dispatch({
          type: Types.CREATE_USER_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Användare skapad'
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_USER_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Kunde inte skapa användare'
          }
        })
      })
  }
}

export function tryDeleteUser(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_USER
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_USER_SUCCESS,
          payload: id
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Användare borttagen'
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_USER_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Kunde inte ta bort användare'
          }
        })
      })
  }
}
