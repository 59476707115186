import { cloneDeep } from 'lodash'
import { KpiTemplate, KpiTemplateRedux } from 'types/GlobalKpiTemplates'
import { WidgetObject, WidgetType } from 'types/GlobalWidget'
import {
  AttributeOption,
  DataType,
  GroupedAttributeOptions
} from 'types/GlobalAttributeOptions'
import { WidgetTagObject } from 'types/GlobalWidgetTag'

export const getIconName = (index: number, widget: WidgetObject) => {
  const type = widget.settings.type.selected
  const segmentType = widget.settings.segment_by.type

  switch (type) {
  case WidgetType.BAR_CHART:
    return 'SignalCellularAltOutlined'
  case WidgetType.LINE_CHART:
    return 'ShowChartOutlined'
  case WidgetType.LIST:
    return 'VerticalSplitOutlined'
  case WidgetType.BUBBLE_CHART:
    if (index === 0) {
      return 'BorderBottomOutlined'
    } else if (index === 1) {
      return 'BorderLeftOutlined'
    } else {
      return 'BubbleChartOutlined'
    }
  case WidgetType.COMBO_CHART:
    if (index === 0) {
      return 'BarChartTwoTone'
    } else {
      if (segmentType === DataType.DATE) {
        return 'ShowChartTwoTone'
      }

      return 'WorkspacesTwoTone'
    }
  case WidgetType.KEY_FIGURE: {
    return 'OneKOutlined'
  }
  case WidgetType.PIE_CHART: {
    return 'PieChart'
  }
  case WidgetType.MAP: {
    return 'ListOutlined'
  }
  default:
    return 'CalendarViewWeekOutlined'
  }
}

export const getAddTitle = (widget: WidgetObject, numberOfKpis: number) => {
  const type = widget.settings.type.selected
  const segmentType = widget.settings.segment_by.type

  if (type === WidgetType.BUBBLE_CHART) {
    if (numberOfKpis === 1) {
      return 'Lägg till Y-axel'
    }

    if (numberOfKpis === 2) {
      return 'Lägg till radie'
    }
  }

  if (type === WidgetType.COMBO_CHART) {
    if (segmentType === DataType.DATE) {
      return 'Lägg till linje'
    }

    return 'Lägg till punkter'
  }

  return 'Lägg till nyckeltal'
}

const validateTemplateSegmentation = (
  template: KpiTemplate,
  widget: WidgetObject,
  groupedAttributeOptions: GroupedAttributeOptions,
  filteredRelationKeys: string[],
  selectedSegmentRelationKey: string | null
): boolean => {
  if (
    widget.settings.type.selected === WidgetType.LINE_CHART ||
    widget.settings.type.selected === WidgetType.KEY_FIGURE
  ) {
    return true
  }

  const availableAttributeOptions =
    template.fact_table_id && groupedAttributeOptions[template.fact_table_id]
      ? groupedAttributeOptions[template.fact_table_id]
      : null

  if (!availableAttributeOptions || !selectedSegmentRelationKey) {
    return false
  }

  const segmentRelationKeys = availableAttributeOptions.segment.map(
    (item) => item.relation_key
  )
  const filterRelationKeys = availableAttributeOptions.filter.map(
    (item) => item.relation_key
  )

  return !!(
    segmentRelationKeys.includes(selectedSegmentRelationKey) &&
    filteredRelationKeys.every((relationKey) =>
      filterRelationKeys.includes(relationKey)
    )
  )
}

const validateAvailableKpiTemplate = (
  template: KpiTemplate,
  widget: WidgetObject,
  groupedAttributeOptions: GroupedAttributeOptions,
  filteredRelationKeys: string[],
  selectedSegmentRelationKey: string | null
) => {
  if (template.widget_type === WidgetType.FUNNEL) {
    return false
  }

  if (template.sub_kpis.length > 0) {
    // check if any sub kpi is allowed
    return template.sub_kpis.some((subTemplate) =>
      validateTemplateSegmentation(
        subTemplate,
        widget,
        groupedAttributeOptions,
        filteredRelationKeys,
        selectedSegmentRelationKey
      )
    )
  }

  return validateTemplateSegmentation(
    template,
    widget,
    groupedAttributeOptions,
    filteredRelationKeys,
    selectedSegmentRelationKey
  )
}

export const getAvailableKpiTemplates = (
  kpiTemplates: KpiTemplateRedux,
  widget: WidgetObject,
  segmentBy: AttributeOption | null,
  attributeOptions: {
    [id: string]: AttributeOption
  },
  groupedAttributeOptions: GroupedAttributeOptions
) => {
  const filteredKpiTemplates: KpiTemplateRedux = {}

  // relation keys used in widget
  const selectedSegmentRelationKey = segmentBy?.relation_key || null
  const filteredRelationKeys = widget.settings.filters
    .filter(
      (f) =>
        f.value.selected &&
        f.attribute_option.selected &&
        attributeOptions[f.attribute_option.selected]
    )
    .map(
      (filter) =>
        attributeOptions[filter.attribute_option.selected as string]
          .relation_key
    )

  Object.values(kpiTemplates)
    .filter((template) =>
      validateAvailableKpiTemplate(
        template,
        widget,
        groupedAttributeOptions,
        filteredRelationKeys,
        selectedSegmentRelationKey
      )
    )
    .forEach((kpi) => {
      const newKpi = cloneDeep(kpi)

      if (kpi.sub_kpis.length > 0) {
        // remove any unsupported sub kpis.
        newKpi.sub_kpis = newKpi.sub_kpis.filter((subTemplate) =>
          validateAvailableKpiTemplate(
            subTemplate,
            widget,
            groupedAttributeOptions,
            filteredRelationKeys,
            selectedSegmentRelationKey
          )
        )
      }

      filteredKpiTemplates[newKpi.id] = newKpi
    })

  return filteredKpiTemplates
}

export const getTagOptions = (tags: {
  selected: WidgetTagObject[]
  options: {
    id: string
    name: string
  }[]
}) => {
  const newOptions = tags.options.map((tag) => ({
    value: tag.id,
    label: tag.name,
    disabled: !!tags.selected.find((t) => {
      return t.tag_option_id === tag.id
    })
  }))

  return newOptions
}
