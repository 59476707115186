import { IconName } from 'components_new/atoms/Icon'

export enum Condition {
  IS_NULL = 'IS NULL',
  EQ = '=',
  NE = '!=',
  LTE = '<=',
  LT = '<',
  GTE = '>=',
  GT = '>',
  IS_NOT_NULL = 'IS NOT NULL'
}

export enum ComparativeDisplayType {
  PERCENTAGE = 'PERCENTAGE',
  PERCENTAGE_AND_VALUE = 'PERCENTAGE_AND_VALUE',
  VALUE = 'VALUE',
  HIDDEN = 'HIDDEN'
}

export enum FilterType {
  ONLY_LEFT = 'ONLY_LEFT',
  INPUT_VALUE = 'INPUT_VALUE',
  FROM_TO = 'FROM_TO',
  NUMBER = 'NUMBER'
}

export interface FilterPatchBody {
  attribute_option_id: string
  condition: Condition
  filter_group_id?: string | null
  value: number | string | null
  type: FilterType
  from_value?: string
  to_value?: string
}

export interface FilterConditionFormatBody {
  filter_group_id?: string | null
  attribute_option: {
    selected: string | null
  }
  condition: {
    selected: Condition | null
    options: Condition[]
  }
  type: {
    selected: FilterType | null
    options: FilterType[]
  }
  value: {
    selected: number | (string | null)[] | null
  }
  from_value?: {
    selected: string | null
  }
  to_value?: {
    selected: string | null
  }
}

export enum UnitPrefix {
  THOUSAND = 'THOUSAND',
  MILLION = 'MILLION',
  BILLION = 'BILLION'
}

export enum RollingType {
  R7 = 'R7',
  R30 = 'R30',
  R365 = 'R365',
  R12 = 'R12'
}

export enum ShowPerEnum {
  RENTABLE_SQM = 'RENTABLE_SQM',
  ATEMP = 'ATEMP',
  BTA = 'BTA',
  NUMBER_OF_OBJECTS = 'NUMBER_OF_OBJECTS',
  NUMBER_OF_DWELLINGS = 'NUMBER_OF_DWELLINGS',
  RENTED_SQM = 'RENTED_SQM'
}

export const translateShowPerEnum: Record<
  ShowPerEnum,
  {
    abbreviation: string
    label: string
    iconName: IconName
  }
> = {
  [ShowPerEnum.RENTABLE_SQM]: {
    abbreviation: 'UHYB',
    label: 'Uthyrningsbar m²',
    iconName: 'CropFreeOutlined'
  },
  [ShowPerEnum.ATEMP]: {
    abbreviation: 'ATEMP',
    label: 'Tempererad m² (10 °C)',
    iconName: 'HvacOutlined'
  },
  [ShowPerEnum.BTA]: {
    abbreviation: 'BTA',
    label: 'Bruttoarea m²',
    iconName: 'HouseSidingOutlined'
  },
  [ShowPerEnum.NUMBER_OF_OBJECTS]: {
    abbreviation: 'OBJ',
    label: 'Objekt',
    iconName: 'ViewInArOutlined'
  },
  [ShowPerEnum.NUMBER_OF_DWELLINGS]: {
    abbreviation: 'BOS',
    label: 'Bostad',
    iconName: 'LivingOutlined'
  },
  [ShowPerEnum.RENTED_SQM]: {
    abbreviation: 'UHYD',
    label: 'Uthyrd m²',
    iconName: 'CheckBoxOutlined'
  }
}

export interface KpiOptionObject {
  breakdown_by: {
    selected: string | null
  }
  can_show_details: boolean
  comparative_display_type: {
    selected: ComparativeDisplayType | null
    options: ComparativeDisplayType[]
  }
  entity_id: string | null
  equivalent: {
    factor: number | null
    unit: string | null
  }
  fact_table_id: string
  filters: FilterConditionFormatBody[]
  share_filters: FilterConditionFormatBody[]
  required_target_missing: boolean
  id: string
  index: number
  kpi_template_id: string
  upper_bound_threshold: number | null
  lower_bound_threshold: number | null
  hidden: boolean
  increase_is_positive: boolean
  is_share_kpi: boolean
  number_of_decimals: number
  active_show_per: {
    selected: ShowPerEnum | null
    options: ShowPerEnum[]
  }
  rolling_active: RollingType | null
  title: string
  original_unit: string | null
  unit: string | null
  unit_prefix: {
    selected: UnitPrefix | null
    options: { value: UnitPrefix; label: string }[]
  }
  widget_id: string
}

export type KpiOptionPostAttributes = {
  data: {
    widget_id: string
    kpi_template_id: string
    perform_kpi_template_id?: string
  }
}

export type KpiOptionPatchAttributes = {
  data: {
    active_show_per?: string | null
    breakdown_by?: string | null
    comparative_display_type?: ComparativeDisplayType | null
    equivalent?: {
      factor: number | null
      unit: string | null
    }
    filters?: FilterPatchBody[]
    share_filters?: FilterPatchBody[]
    hidden?: boolean
    increase_is_positive?: boolean
    is_share_kpi?: boolean
    kpi_template_id?: string
    lower_bound_threshold?: number | null
    number_of_decimals?: number
    rolling_active?: RollingType | null
    show_comparative?: boolean
    title?: string
    unit_prefix?: UnitPrefix | null
    upper_bound_threshold?: number | null
  }
}

export type KpiOptionReduxResponse = {
  option: KpiOptionObject
  dashboardId: string
  skipFetchData?: boolean
}

export type KpiOptionPatchResponse = {
  data: KpiOptionObject[]
  dashboardId: string
  skipFetchData?: boolean
}
