import React, { ReactNode, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

import NavigationHeader from 'components_new/molecules/NavigationHeader'

import DashboardExampleFilterMenu from 'components_new/organisms/DashboardExampleFilterMenu'
import PageContainer from 'components_new/organisms/PageContainer'

import RequestDashboardExampleDialog from 'components_new/organisms/dialogs/RequestDashboardExampleDialog'
import SubmitDashboardExampleDialog from 'components_new/organisms/dialogs/SubmitDashboardExampleDialog'

import { iconName as requestIconName } from 'components_new/organisms/dialogs/RequestDashboardExampleDialog/content'
import { iconName as submitIconName } from 'components_new/organisms/dialogs/SubmitDashboardExampleDialog/content'

interface ExploreTemplateProps {
  children?: ReactNode
}

const ExploreTemplate = (props: ExploreTemplateProps) => {
  const { children } = props

  const [dialog, setDialog] = useState<'request' | 'submit' | false>(false)

  const handleCloseDialog = () => {
    setDialog(false)
  }

  const handleOpenDialog = (variant: 'request' | 'submit') => {
    setDialog(variant)
  }

  return (
    <>
      <PageContainer
        NavbarProps={{
          footer: (
            <Box>
              <Text sx={{ mb: 1 }} variant="h6">
                Vi hoppas att den här sidan ger dig inspiration!
              </Text>
              <Text variant="body2">
                Utforska vad dina branschkollegor tittar på och sätt upp en
                likadan dashboard ifall det känns rätt. För visst är det dumt
                att återuppfinna hjulet?
              </Text>
            </Box>
          ),
          header: <NavigationHeader navLock={true} title="Utforska" />,
          locked: true,
          menu: <DashboardExampleFilterMenu />
        }}
        TopbarProps={{
          actions: (
            <>
              <Button
                onClick={() => handleOpenDialog('request')}
                startIcon={<Icon name={requestIconName} />}
                variant="text"
              >
                Föreslå
              </Button>
              <Button
                onClick={() => handleOpenDialog('submit')}
                startIcon={<Icon name={submitIconName} />}
              >
                Skicka in
              </Button>
            </>
          )
        }}
      >
        {children}
      </PageContainer>

      <RequestDashboardExampleDialog
        onClose={handleCloseDialog}
        open={dialog === 'request'}
      />
      <SubmitDashboardExampleDialog
        onClose={handleCloseDialog}
        open={dialog === 'submit'}
      />
    </>
  )
}

export default ExploreTemplate
