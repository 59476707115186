import { Dispatch } from 'redux'
import {
  create,
  destroy,
  getAll,
  update
} from 'redux/api/SavedDashboardFilters'
import * as Types from 'redux/Types'

import {
  ICreateSavedDashboardFilterBody,
  ISavedDashboardFilter,
  IUpdateSavedDashboardFilterBody
} from 'types/GlobalSavedDashboardFilter'

export function tryGetAllSavedDashboardFilters() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_SAVED_DASHBOARD_FILTERS
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_SAVED_DASHBOARD_FILTERS_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_SAVED_DASHBOARD_FILTERS_FAILED
        })
      })
  }
}

export function tryCreateSavedDashboardFilter(
  data: ICreateSavedDashboardFilterBody,
  callback: (err: Error | null, resource: ISavedDashboardFilter | null) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_SAVED_DASHBOARD_FILTER
    })

    create({ data })
      .then((response) => {
        dispatch({
          type: Types.CREATE_SAVED_DASHBOARD_FILTER_SUCCESS,
          payload: response.data.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Filter har sparats' }
        })

        callback(null, response.data.data)
      })
      .catch((e) => {
        dispatch({
          type: Types.CREATE_SAVED_DASHBOARD_FILTER_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Filter kunde inte sparas' }
        })
        callback(e, null)
      })
  }
}

export function tryUpdateSavedDashboardFilter(
  id: string,
  data: IUpdateSavedDashboardFilterBody,
  callback: (err: Error | null, resource: ISavedDashboardFilter | null) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_SAVED_DASHBOARD_FILTER
    })

    update(id, { data })
      .then((response) => {
        dispatch({
          type: Types.UPDATE_SAVED_DASHBOARD_FILTER_SUCCESS,
          payload: response.data.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Filter har uppdaterats' }
        })

        callback(null, response.data.data)
      })
      .catch((e) => {
        dispatch({
          type: Types.UPDATE_SAVED_DASHBOARD_FILTER_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Filter kunde inte uppdateras' }
        })
        callback(e, null)
      })
  }
}

export function tryDeleteSavedDashboardFilter(
  id: string,
  callback: (err?: Error) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_SAVED_DASHBOARD_FILTER
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_SAVED_DASHBOARD_FILTER_SUCCESS,
          payload: id
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Filtret har tagits bort' }
        })

        callback()
      })
      .catch((err) => {
        dispatch({
          type: Types.DELETE_SAVED_DASHBOARD_FILTER_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Filtret kunde inte tas bort' }
        })

        callback(err)
      })
  }
}
