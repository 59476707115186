import React, { FC, useEffect, useState } from 'react'

import { cloneDeep } from 'lodash'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Collapse from 'components_new/atoms/Collapse'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import TextField from 'components_new/atoms/TextField'
import TooltipWrapper from 'components_new/atoms/TooltipWrapper'

import SelectSearch from 'components_new/molecules/SelectSearch'

import { AttributeOption, DataType } from 'types/GlobalAttributeOptions'
import {
  Condition,
  FilterConditionFormatBody,
  FilterPatchBody,
  FilterType
} from 'types/GlobalKpiOption'
import { FilterOption } from 'utils/types'
import { translateFilterCondition } from 'utils/enumTranslator'
import { KpiTemplateFilterOptionsBody } from 'types/GlobalKpiTemplates'
import { sortAlphabeticalAsc } from 'utils/sortHelper'
import {
  formatUpdateFilterBody,
  getFilterValueOptions,
  parseFilterLabelValue,
  parseSingleFilterLabel
} from './utils'
import { onlyLeftCondition } from 'components_new/organisms/FilterCascadeMenu/utils'

interface NativeFilterSectionProps {
  filterAttributeOptions: AttributeOption[]
  filterGroupId?: string | null
  filterOptions: KpiTemplateFilterOptionsBody | null
  filters: FilterConditionFormatBody[]
  id: string
  onSubmit: () => void
  updateFilters: (id: string, body: { filters: FilterPatchBody[] }) => void
}

/** Information:
 * This file is copied and pasted from our native filter section.
 * It should be re-written och merged with 'NativeFilterGroupSection'.
 */

const NativeFilterSection = (props: NativeFilterSectionProps) => {
  const {
    filterAttributeOptions,
    filterGroupId,
    filterOptions,
    filters,
    id,
    onSubmit,
    updateFilters
  } = props

  const filterValueOptions = getFilterValueOptions(
    filterAttributeOptions,
    filterOptions
  )
  const filterConditionOptions = filters[0].condition.options

  const [attributeOption, setAttributeOption] =
    useState<AttributeOption | null>(null)
  const [condition, setCondition] = useState<Condition>(Condition.EQ)

  // Value state-handlers
  const [selectedValueOptions, setSelectedValueOptions] = useState<string[]>([])
  const [numericValue, setNumericValue] = useState<number | null>(null)

  const [selectedFilterOptionValue, setSelectedFilterOptionValue] =
    useState<FilterOption | null>(null)
  const [fromValue, setFromValue] = useState<string | null>(null)
  const [toValue, setToValue] = useState<string | null>(null)

  // The filter to pick values from, i.e. attribute_option and condition is set
  const [activeFilter, setActiveFilter] =
    useState<FilterConditionFormatBody | null>(null)

  useEffect(() => {
    if (attributeOption && condition && fromValue && toValue) {
      const tmpActiveFilter =
        filters.find(
          (filter) =>
            filter.attribute_option.selected === attributeOption.id &&
            filter.condition.selected === condition &&
            filter.from_value?.selected === fromValue &&
            filter.to_value?.selected === toValue
        ) || null

      if (tmpActiveFilter) {
        setNumericValue(tmpActiveFilter.value.selected as number)
        setSelectedValueOptions([])
      } else {
        setNumericValue(null)
      }

      setActiveFilter(tmpActiveFilter)
    } else if (
      attributeOption &&
      condition &&
      !isNumericFilter(attributeOption.type)
    ) {
      const tmpActiveFilter =
        filters.find(
          (filter) =>
            filter.attribute_option.selected === attributeOption.id &&
            filter.condition.selected === condition &&
            (!filter.filter_group_id ||
              (filterGroupId && filter.filter_group_id === filterGroupId))
        ) || null

      if (tmpActiveFilter) {
        setNumericValue(null)
      }

      setActiveFilter(tmpActiveFilter)
    }
  }, [attributeOption, condition, fromValue, toValue])

  const onChangeAttributeOption = (attributeOptionId: string | null) => {
    if (!attributeOptionId) {
      setCondition(Condition.EQ)
      setSelectedValueOptions([])
      setNumericValue(null)
      setAttributeOption(null)
      setFromValue(null)
      setToValue(null)
      setSelectedFilterOptionValue(null)
    } else {
      setSelectedFilterOptionValue(filterValueOptions[attributeOptionId])
    }

    const option =
      filterAttributeOptions.find(
        (attributeOption) => attributeOption.id === attributeOptionId
      ) || null

    setSelectedValueOptions([])

    if (option && !isNumericFilter(option.type)) {
      setFromValue(null)
      setToValue(null)
    }

    if (!condition || (condition && !validConditionOption(condition, option))) {
      const options = filterConditionOptions.filter((condtionOption) =>
        validConditionOption(condtionOption, option)
      )

      // Try to set EQ as default
      if (options.includes(Condition.EQ)) {
        setCondition(Condition.EQ)
      } else {
        setCondition(options[0])
      }
    }

    setAttributeOption(option)
  }

  return (
    <Box sx={{ mb: 2 }}>
      {/*-- attribute --*/}
      <Box>
        <SelectSearch
          closeOnSelect={true}
          externalLabel="Filtrera på"
          multiple={false}
          selected={
            attributeOption
              ? { id: attributeOption.id, title: attributeOption.name }
              : null
          }
          onChange={(option: any) => {
            if (option) {
              onChangeAttributeOption(option.id)
            } else {
              onChangeAttributeOption(null)
            }
          }}
          options={filterAttributeOptions.map((option) => {
            return {
              id: option.id,
              title: option.name || 'Är tom'
            }
          })}
          placeholder="Sök eller välj"
        />
      </Box>

      {/*-- from/to --*/}
      <Collapse
        in={
          !!(
            selectedFilterOptionValue &&
            selectedFilterOptionValue.type === 'FROM_TO'
          )
        }
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            mt: 1
          }}
        >
          <TextField
            onChange={(event) => {
              setFromValue(event.target.value)
            }}
            placeholder="Från"
            select={true}
            size="small"
            sx={{ my: 0 }}
            value={fromValue ?? ''}
          >
            {selectedFilterOptionValue?.from_values?.map((option, i) => (
              <MenuItem key={i} value={option}>
                {option}
              </MenuItem>
            )) || []}
          </TextField>

          <TextField
            onChange={(event) => {
              setToValue(event.target.value)
            }}
            placeholder="Till"
            select={true}
            size="small"
            sx={{
              my: 0
            }}
            value={toValue ?? ''}
          >
            {selectedFilterOptionValue?.to_values?.map((option, i) => (
              <MenuItem key={i} value={option}>
                {option}
              </MenuItem>
            )) || []}
          </TextField>
        </Box>
      </Collapse>

      {/*-- condition --*/}
      <TooltipWrapper
        title={
          !Boolean(attributeOption) ||
          !!(
            selectedFilterOptionValue?.type === 'FROM_TO' &&
            (!fromValue || !toValue)
          )
            ? 'Välj vad du vill filtrera på först'
            : ''
        }
      >
        <TextField
          disabled={
            !Boolean(attributeOption) ||
            !!(
              selectedFilterOptionValue?.type === 'FROM_TO' &&
              (!fromValue || !toValue)
            )
          }
          externalLabel="Villkor"
          onChange={(event) => {
            if (
              !event.target.value ||
              onlyLeftCondition(event.target.value as Condition)
            ) {
              setSelectedValueOptions([])
              setNumericValue(null)
            }

            setCondition(event.target.value as Condition)
          }}
          select={true}
          size="small"
          value={condition ?? ''}
          sx={{ mt: 2 }}
        >
          {(attributeOption
            ? filterConditionOptions.filter((option) =>
              validConditionOption(option, attributeOption)
            )
            : filterConditionOptions
          ).map((option, i) => (
            <MenuItem key={i} value={option}>
              {translateFilterCondition[option].title}
            </MenuItem>
          ))}
        </TextField>
      </TooltipWrapper>

      {/*-- value --*/}
      <Collapse in={!Boolean(onlyLeftCondition(condition))}>
        <TooltipWrapper
          title={
            !Boolean(attributeOption)
              ? 'Välj vad du vill filtrera på först'
              : ''
          }
        >
          <FilterValueInput
            disabled={
              !Boolean(attributeOption) ||
              (selectedFilterOptionValue?.type === 'FROM_TO' &&
                (!fromValue || !toValue))
            }
            filterValueOptions={filterValueOptions}
            activeFilter={activeFilter}
            selectedValueOptions={selectedValueOptions}
            setSelectedValueOptions={setSelectedValueOptions}
            numericValue={numericValue}
            setNumericValue={setNumericValue}
            attributeOption={attributeOption}
          />
        </TooltipWrapper>
      </Collapse>

      {/*-- add --*/}
      <Button
        disabled={
          numericValue === null &&
          selectedValueOptions.length === 0 &&
          !onlyLeftCondition(condition)
        }
        variant="contained"
        fullWidth={true}
        onClick={() => {
          if (
            attributeOption &&
            validFilter(
              attributeOption.id,
              condition,
              selectedValueOptions.length === 0
                ? numericValue
                : selectedValueOptions
            )
          ) {
            let newFilters: FilterPatchBody[] = []
            const numericValueIsSet = !!(numericValue || numericValue === 0)

            if (onlyLeftCondition(condition)) {
              newFilters = [
                {
                  attribute_option_id: attributeOption.id,
                  condition: condition as Condition,
                  value: null,
                  type: FilterType.ONLY_LEFT
                }
              ]
            } else if (selectedValueOptions.length > 0) {
              newFilters = selectedValueOptions.map((value) => ({
                attribute_option_id: attributeOption.id,
                condition: condition as Condition,
                value: parseFilterLabelValue(value),
                type: FilterType.INPUT_VALUE
              }))
            } else if (
              numericValueIsSet &&
              activeFilter?.attribute_option.selected !== attributeOption.id &&
              fromValue &&
              toValue
            ) {
              newFilters = [
                {
                  attribute_option_id: attributeOption.id,
                  condition: condition as Condition,
                  value: numericValue,
                  type: FilterType.FROM_TO,
                  from_value: fromValue as string,
                  to_value: toValue as string
                }
              ]
            } else if (numericValueIsSet) {
              newFilters = [
                {
                  attribute_option_id: attributeOption.id,
                  condition: condition as Condition,
                  value: numericValue,
                  type: FilterType.NUMBER
                }
              ]
            }

            // If no filter it set, only set the new filter
            if (
              filters.length === 1 &&
              !validFilter(
                filters[0].attribute_option.selected,
                filters[0].condition.selected,
                filters[0].value.selected
              )
            ) {
              updateFilters(id, { filters: newFilters })
            } else {
              /**
               * If there is a current filter, format saved body to
               * a valid format for the filter update
               */
              updateFilters(id, {
                filters: [
                  ...formatUpdateFilterBody(
                    filters,
                    attributeOption.id,
                    condition,
                    selectedValueOptions,
                    numericValue,
                    fromValue,
                    toValue
                  ),
                  ...newFilters
                ]
              })
            }

            setSelectedValueOptions([])
            setNumericValue(null)
            setAttributeOption(null)
            setFromValue(null)
            setToValue(null)
            setActiveFilter(null)
            setSelectedFilterOptionValue(null)

            onSubmit()
          }
        }}
        sx={{ mt: 2 }}
      >
        Lägg till
      </Button>
    </Box>
  )
}

export const FilterValueInput: FC<{
  disabled?: boolean
  filterValueOptions: { [attribute_option_id: string]: FilterOption }
  activeFilter: FilterConditionFormatBody | null
  selectedValueOptions: string[] | number
  setSelectedValueOptions: (options: string[]) => void
  numericValue: number | null
  setNumericValue: (option: number | null) => void
  attributeOption: AttributeOption | null
  sx?: object
}> = ({
  disabled,
  filterValueOptions,
  activeFilter,
  selectedValueOptions,
  setSelectedValueOptions,
  numericValue,
  setNumericValue,
  attributeOption,
  sx
}) => {
  const options = cloneDeep(
    (attributeOption && filterValueOptions[attributeOption.id]?.values) || []
  ).sort(sortAlphabeticalAsc)

  return isNumericFilter(attributeOption?.type as DataType) ? (
    <TextField
      disabled={disabled}
      type="number"
      placeholder="Värde"
      InputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*'
      }}
      onChange={(event) => {
        if (event.target.value === '') {
          setNumericValue(null)
        } else {
          setNumericValue(parseInt(event.target.value))
        }
      }}
      size="small"
      value={numericValue ?? ''}
      sx={{ my: 0, ...sx }}
    />
  ) : (
    <>
      <SelectSearch
        closeOnSelect={false}
        disabled={disabled}
        multiple={true}
        selected={(selectedValueOptions as string[]).map((option) => {
          return {
            id: option,
            title: option
          }
        })}
        onChange={(options: any) => {
          setSelectedValueOptions(options.map((option: any) => option.id))
        }}
        options={options
          .map(parseSingleFilterLabel)
          .filter((option) =>
            activeFilter &&
            activeFilter.value.selected &&
            typeof activeFilter.value.selected !== 'number' &&
            activeFilter.value.selected?.length > 0
              ? !activeFilter.value.selected?.includes(option)
              : true
          )
          .map((option) => {
            return {
              id: option,
              title: option
            }
          })}
        placeholder={`Sök eller välj${
          attributeOption ? ' ' + attributeOption?.name.toLowerCase() : ''
        }`}
        sx={sx}
      />
    </>
  )
}

const isNumericFilter = (type: DataType) =>
  [DataType.BIGINT, DataType.DOUBLE].includes(type)

const validConditionOption = (
  condition: Condition,
  attributeOption: AttributeOption | null
) => {
  if (attributeOption && isNumericFilter(attributeOption.type)) {
    return [Condition.EQ, Condition.LT, Condition.GT].includes(condition)
  } else if (attributeOption) {
    return [
      Condition.EQ,
      Condition.NE,
      Condition.IS_NULL,
      Condition.IS_NOT_NULL
    ].includes(condition)
  }

  return false
}

export const validFilter = (
  attributeOption: string | null,
  condition: Condition | null,
  values: number | (string | null)[] | null
) => {
  if (attributeOption && condition && onlyLeftCondition(condition)) {
    return true
  } else if (
    attributeOption &&
    condition &&
    typeof values === 'number' &&
    values !== null &&
    !isNaN(values)
  ) {
    return true
  } else if (
    attributeOption &&
    condition &&
    values &&
    (values as (string | null)[])?.length > 0
  ) {
    return true
  }

  return false
}

export default NativeFilterSection
