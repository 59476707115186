import React, { ReactNode, useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

interface DeleteDialogProps {
  children?: ReactNode
  handleClose: () => void
  handleDelete: (callback: () => void) => void
  open: boolean
  title?: string
  type: string
}

const DeleteDialog = (props: DeleteDialogProps) => {
  const { children, handleClose, handleDelete, open, title = '', type } = props
  const [loading, setLoading] = useState(false)

  const parsedHandleDelete = () => {
    setLoading(true)
    handleDelete(() => setLoading(false))
  }

  return (
    <ThemeProvider theme={getTheme('light')}>
      <SimpleDialog
        data-openreplay-obscured
        title={`Radera ${type}?`}
        open={open}
        onClose={handleClose}
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
            <Button
              color="error"
              loading={loading}
              onClick={parsedHandleDelete}
              variant="contained"
            >
              Radera
            </Button>
          </>
        }
        contentText={
          <>
            {type === 'filter' ? 'Ditt' : 'Din'} {type} <code>{title}</code>{' '}
            kommer att raderas permanent. Är du säker?
          </>
        }
      >
        {children ? <Box sx={{ mt: 2 }}>{children}</Box> : null}
      </SimpleDialog>
    </ThemeProvider>
  )
}

export default DeleteDialog
