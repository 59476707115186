import { IconName } from 'components_new/atoms/Icon'

type TSeverity = 'error' | 'info' | 'success' | 'warning'

export const dict: Record<
  TSeverity,
  {
    iconName: IconName
  }
> = {
  error: {
    iconName: 'ErrorOutlined'
  },
  info: {
    iconName: 'InfoOutlined'
  },
  success: {
    iconName: 'CheckCircleOutlined'
  },
  warning: {
    iconName: 'WarningAmberOutlined'
  }
}
