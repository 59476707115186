import React, { useState } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'

import { bindActionCreators, Dispatch } from '@reduxjs/toolkit'
import { connect, ConnectedProps } from 'react-redux'
import { useParams } from 'react-router-dom'

import * as UserEvents from 'redux/api/internal/UserEvents'
import * as SavedDashboardFilterActions from 'redux/actions/SavedDashboardFilters'
import { useDashboard } from 'redux/hooks/Dashboards'
import { PlatformDashboard } from 'redux/reducers/Dashboards'

import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemButton from 'components_new/atoms/List/ListItemButton'

import OptionsMenu from 'components_new/molecules/OptionsMenu'
import OverflowText from 'components_new/molecules/OverflowText'

import DeleteDialog from 'components_new/organisms/dialogs/DeleteDialog'

import { ISavedDashboardFilter } from 'types/GlobalSavedDashboardFilter'
import { Action, Context } from 'types/GlobalUserEvents'

import EditSavedUserFilterDialog from '../dialogs/EditSavedUserFilterDialog'

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(SavedDashboardFilterActions, dispatch)
}

const connector = connect(null, mapDispatchToProps)

interface SavedFilterItemProps {
  disabled?: boolean
  filter: ISavedDashboardFilter
  onClick: () => void
  savedDashboardFilter: null | ISavedDashboardFilter
  selected: boolean
  setSavedDashboardFilter: (value: ISavedDashboardFilter | null) => void
}

type ComponentProps = ConnectedProps<typeof connector> & SavedFilterItemProps

const SavedFilterItem = (props: ComponentProps) => {
  const {
    disabled,
    filter,
    onClick,
    selected,
    savedDashboardFilter,
    setSavedDashboardFilter,
    // Redux actions
    tryDeleteSavedDashboardFilter,
    tryUpdateSavedDashboardFilter
  } = props
  const params = useParams<{ id: string }>()
  const dashboard = useDashboard<PlatformDashboard>(params.id)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  const [dialog, setDialog] = useState<{
    filter: ISavedDashboardFilter | null
    variant: 'delete' | 'edit' | null
  }>({ filter: null, variant: null })

  // loading
  if (!dashboard) {
    return null
  }

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, variant: null }))
  }

  const handleOpenDialog = (
    filter: ISavedDashboardFilter,
    variant: 'delete' | 'edit'
  ) => {
    setDialog({ filter: filter, variant: variant })
  }

  const handleDelete = (callback: () => void) => {
    const filterId = dialog.filter?.id

    if (!filterId) return

    UserEvents.log({
      action: Action.SAVED_DASHBOARD_FILTER_DELETED,
      context: null,
      dashboard_id: dashboard.id,
      dashboard_group_id: dashboard.dashboard_group_id
    })

    tryDeleteSavedDashboardFilter(filterId, (err) => {
      if (
        !err &&
        savedDashboardFilter &&
        savedDashboardFilter.id === filterId
      ) {
        // de-select saved dashboard filter if deleted
        setSavedDashboardFilter(null)
      }
      callback()
      setDialog({ filter: null, variant: null })
    })
  }

  const handleUpdate = (title: string) => {
    const filterId = dialog.filter?.id

    if (!filterId) return

    UserEvents.log({
      action: Action.SAVED_DASHBOARD_FILTER_EDITED,
      context: Context.TITLE,
      dashboard_id: dashboard.id,
      dashboard_group_id: dashboard.dashboard_group_id
    })

    tryUpdateSavedDashboardFilter(filterId, { title }, (err) => {
      if (!err) {
        setDialog({ filter: null, variant: null })
      }
    })
  }

  // success
  return (
    <>
      <ListItem
        disablePadding={true}
        key={filter.id}
        secondaryAction={
          isMobile ? undefined : (
            <IconButton
              edge="end"
              onClick={(event) => setAnchorEl(event.currentTarget)}
              size="small"
              sx={{
                color: selected ? 'primary.contrastText' : undefined
              }}
            >
              <Icon fontSize="small" name="MoreHoriz" />
            </IconButton>
          )
        }
      >
        <ListItemButton
          dense={!isMobile}
          disabled={disabled}
          onClick={() => {
            onClick()

            UserEvents.log({
              action: Action.SELECTION_FILTERED,
              context: Context.FROM_SAVED_DASHBOARD_FILTER,
              dashboard_id: dashboard.id,
              dashboard_group_id: dashboard.dashboard_group_id
            })
          }}
          selected={selected}
          sx={{
            height: isMobile ? 48 : 32,
            borderRadius: isMobile ? '24px' : '16px',
            '&.Mui-selected': {
              bgcolor: 'primary.background',
              color: 'primary.contrastText',
              '&:hover': {
                bgcolor: 'primary.background',
                color: 'primary.contrastText'
              }
            },
            py: 0.5
          }}
        >
          <OverflowText
            variant="subtitle2"
            color={selected ? 'primary.contrastText' : undefined}
          >
            {filter.title}
          </OverflowText>
        </ListItemButton>
      </ListItem>

      {/*-- menu --*/}
      <OptionsMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        options={[
          {
            iconName: 'EditOutlined',
            onClick: () => handleOpenDialog(filter, 'edit'),
            title: 'Byt namn'
          },
          {
            iconName: 'DeleteOutlined',
            onClick: () => handleOpenDialog(filter, 'delete'),
            title: 'Radera'
          }
        ]}
      />

      {/*-- dialogs --*/}
      {dialog.filter ? (
        <>
          <EditSavedUserFilterDialog
            filter={dialog.filter}
            onClose={handleCloseDialog}
            onSave={handleUpdate}
            open={dialog.variant === 'edit'}
          />

          <DeleteDialog
            handleClose={handleCloseDialog}
            handleDelete={handleDelete}
            open={dialog.variant === 'delete'}
            title={dialog.filter.title}
            type="filter"
          />
        </>
      ) : null}
    </>
  )
}

export default connector(SavedFilterItem)
