import('dayjs/locale/sv')
import dayjs from 'dayjs'
import { TDashboardGroupActivityView } from 'types/GlobalDashboardGroup'

export type TDashboardActivityPeriod =
  | 'THIS_WEEK'
  | 'THIS_MONTH'
  | 'THIS_YEAR'
  | 'SINCE_START'

export const translateDashboardActivityPeriod: Record<
  TDashboardActivityPeriod,
  string
> = {
  ['THIS_WEEK']: 'Den här veckan',
  ['THIS_MONTH']: 'Den här månaden',
  ['THIS_YEAR']: 'Det här året',
  ['SINCE_START']: 'Sedan start'
}

export const filterViewsByPeriod = (
  views: TDashboardGroupActivityView[],
  created_at: string,
  period: TDashboardActivityPeriod
): {
  start_date: dayjs.Dayjs
  views: TDashboardGroupActivityView[]
} => {
  const now = dayjs()
  let startDate: dayjs.Dayjs

  switch (period) {
  case 'THIS_WEEK':
    startDate = now.startOf('week')
    break
  case 'THIS_MONTH':
    startDate = now.startOf('month')
    break
  case 'THIS_YEAR':
    startDate = now.startOf('year')
    break
  default: // 'SINCE_START'
    const firstActivity = getFirstDate(views)
    const createdAt = dayjs(created_at.split('T')[0])

    if (firstActivity && firstActivity.isBefore(createdAt)) {
      startDate = firstActivity
    } else {
      startDate = createdAt
    }

    break
  }

  return {
    start_date: startDate,
    views: views.filter(
      (view) =>
        dayjs(view.date).isAfter(startDate) ||
        dayjs(view.date).isSame(startDate)
    )
  }
}

export type TDashboardUserActivity = {
  user_id: string
  first_name: string
  last_name: string
  email: string
  views: number
  latest_activity: string | null
}

const getFirstDate = (activities: TDashboardGroupActivityView[]) => {
  if (activities.length === 0) return null

  const sortedActivities = activities.sort((a, b) =>
    dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1
  )

  return dayjs(sortedActivities[0].date)
}
