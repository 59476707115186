import { Dispatch } from 'redux'

import * as Types from 'redux/Types'
import * as AttributeOptionApi from 'redux/api/AttributeOptions'

export function tryGetAllAttributeOptions() {
  return (dispatch: Dispatch) => {
    AttributeOptionApi.getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ATTRIBUTE_OPTIONS_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {})
  }
}
