import React, { ElementType, ReactNode } from 'react'
import { List as MUIList, SxProps } from '@mui/material'

export interface ListProps {
  children: ReactNode
  component?: ElementType<any>
  dense?: boolean
  disablePadding?: boolean
  subheader?: ReactNode
  sx?: SxProps
}

/**
 * The List component is a continuous group of text
 * or images. They are composed of items containing
 * primary and supplemental actions, which are
 * represented by icons and text.
 */

const List = React.forwardRef((props: ListProps, ref: any) => {
  const { children, ...rest } = props

  return (
    <MUIList {...rest} ref={ref}>
      {children}
    </MUIList>
  )
})

List.displayName = 'List'
export default List
