import { createSelector } from '@reduxjs/toolkit'

import { Dashboard, PlatformDashboard } from 'redux/reducers/Dashboards'
import { ApplicationState } from 'redux/Stores/types'
import { isPublicDashboard } from 'utils/functions'

// Select one Platform dashboard
export const selectOneDashboard = <T = Dashboard>() =>
  createSelector(
    [
      // Input selectors:
      (state: ApplicationState) => state.KPIDashboardStore.data,
      (_: ApplicationState, id: string) => id
    ],
    (dashboards, id): T | null => {
      if (id in dashboards) {
        return dashboards[id] as T
      }

      return null
    }
  )

// Get dashboard group of dashboard.
// only available for Platform dashboards,
// else return null
export const selectOneDashboardGroup = createSelector(
  [
    // Input selectors:
    (state: ApplicationState) => state.KPIDashboardStore.data,
    (state: ApplicationState) => state.KPIDashboardStore.groups,
    (state: ApplicationState, dashboardId: string) => dashboardId
  ],
  (dashboards, groups, id) => {
    const dashboard = dashboards[id]

    if (dashboard && !isPublicDashboard(dashboard)) {
      return groups[dashboard.dashboard_group_id]
    }

    return null
  }
)

export const selectAllDashboardGroups = createSelector(
  [
    // Input selectors:
    (state: ApplicationState) => state.KPIDashboardStore.groups
  ],
  (groups) => groups
)

// Retreive all platform dashboards
export const selectAllDashboards = createSelector(
  [
    // Input selectors:
    (state: ApplicationState) => state.KPIDashboardStore.data
  ],
  (data) => {
    const filteredData: { [id: string]: PlatformDashboard } = {}

    Object.entries(data).forEach(([key, dashboard]) => {
      if (!isPublicDashboard(dashboard)) {
        filteredData[key] = dashboard
      }
    })

    return filteredData
  }
)

// Selector to get dashboards for a specific group from dashboard
export const selectGroupDashboardsFromDashboard = createSelector(
  [
    (state: ApplicationState) => state.KPIDashboardStore.groups,
    (state: ApplicationState) => state.KPIDashboardStore.data,
    (_: ApplicationState, dashboardId: string) => dashboardId,
    selectOneDashboardGroup
  ],
  (_, dashboards, __, group) => {
    if (!group) {
      return []
    }

    return group.dashboards.map((id: string) => ({
      id,
      title: dashboards[id]?.title
    }))
  }
)
