import React, { useCallback, useMemo, useState } from 'react'

import { createEditor } from 'slate'
import {
  Slate,
  withReact,
  Editable,
  RenderElementProps,
  RenderLeafProps,
  RenderPlaceholderProps
} from 'slate-react'
import { withHistory } from 'slate-history'

import { SxProps } from '@mui/material'
import { UserAgent } from 'types/GlobalUser'

import { INPUT_BACKGROUND } from 'themes'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

import Element from './renders/Element'
import Leaf from './renders/Leaf'
import Placeholder from './renders/Placeholder'
import Toolbar from './Toolbar'

import { RichText } from './types'
import { handleKeyDown, isEditorEmpty } from './utils'
import { EMPTY_VALUE } from './lib'

interface Props {
  autoFocus?: boolean
  edit: boolean
  error?: boolean
  externalLabel?: string
  helperText?: string
  noGutter?: boolean
  onChange: (value: RichText | null) => void
  placeholder?: string
  readOnly?: boolean
  sx?: SxProps
  value: RichText | null
}

const RichTextEditor = React.forwardRef((props: Props, ref: any) => {
  const {
    autoFocus,
    edit,
    error,
    externalLabel,
    helperText,
    noGutter,
    onChange,
    placeholder,
    sx,
    value
  } = props

  const [editor] = useState(() => withReact(withHistory(createEditor())))

  const userAgent: UserAgent = useMemo(() => {
    if (
      typeof navigator !== 'undefined' &&
      navigator.userAgent.includes('Mac')
    ) {
      return 'mac'
    } else {
      return 'windows'
    }
  }, [])

  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  )

  const renderLeaf = useCallback((props: RenderLeafProps) => {
    return <Leaf {...props} />
  }, [])

  const renderPlaceholder = (props: RenderPlaceholderProps) => (
    <Placeholder {...props} />
  )

  return (
    <Box
      sx={{
        width: '100%',
        ...(noGutter ? null : { mb: 1 }),
        ...sx
      }}
      ref={ref}
    >
      {externalLabel ? (
        <Text
          color="text.primary"
          fontWeight="medium"
          variant="body2"
          sx={{ mb: 0.25 }}
        >
          {props.externalLabel}
        </Text>
      ) : null}
      <Slate
        editor={editor}
        initialValue={value ?? EMPTY_VALUE}
        onChange={(value: RichText) => {
          const isAstChange = editor.operations.some(
            (op) => 'set_selection' !== op.type
          )

          if (isAstChange) {
            if (isEditorEmpty(value)) {
              onChange(null)
            } else {
              onChange(value)
            }
          }
        }}
      >
        <Box
          sx={{
            backgroundColor: edit ? INPUT_BACKGROUND : undefined,
            borderRadius: edit ? '8px' : undefined
            // '&:hover': {
            //   '& .Hover': {
            //     display: 'block'
            //   }
            // }
          }}
        >
          {edit ? (
            <Toolbar
              sx={{
                mb: 0.25,
                p: 1,
                borderBottom: '1px solid',
                borderBottomColor: 'divider'
              }}
              userAgent={userAgent}
            />
          ) : null}
          <Box sx={{ p: edit ? '12px' : undefined, position: 'relative' }}>
            <Box
              sx={{
                overflowY: 'hidden',
                position: 'relative'
              }}
            >
              <Editable
                autoFocus={autoFocus}
                onKeyDown={(event) => handleKeyDown(editor, event, userAgent)}
                placeholder={placeholder}
                readOnly={!edit}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                renderPlaceholder={renderPlaceholder}
                spellCheck={true}
                style={{
                  minHeight: edit ? '196px' : undefined,
                  outline: 'none'
                }}
              />
            </Box>
          </Box>
        </Box>
        {helperText && edit ? (
          <Text
            color={error ? 'error' : 'text.secondary'}
            component="p"
            sx={{ pt: '3px', mx: '14px', mb: '0px' }}
            variant="caption"
          >
            {helperText}
          </Text>
        ) : null}
      </Slate>
    </Box>
  )
})

export default RichTextEditor
