import React from 'react'

import { CustomPeriodFilter, PeriodFilter } from 'types/GlobalWidget'
import { useMediaQuery, useTheme } from '@mui/material'

import Desktop from './desktop'
import Mobile from './mobile'

export interface PeriodSelectorProps {
  onSubmit: (periodFilter: PeriodFilter | CustomPeriodFilter | null) => void
  periodFilter: PeriodFilter | CustomPeriodFilter | null
}

const PeriodSelector = (props: PeriodSelectorProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  return isMobile ? <Mobile {...props} /> : <Desktop {...props} />
}

export default PeriodSelector
