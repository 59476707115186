import axios from 'axios'

import { RichText } from 'components_new/molecules/RichTextEditor/types'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { getWithRedirect } from 'utils/axios'
import { DashboardStatus, WhoCanView } from 'redux/reducers/Dashboards'
import { TDashboardGroupActivity } from 'types/GlobalDashboardGroup'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/dashboard-groups`

export type PatchDashboardGroup = {
  data: InnerPatchDashboardGroup
}

export type InnerPatchDashboardGroup = {
  all_company_groups_has_access?: boolean
  category?: string | null
  description?: RichText | null
  folder_id?: string | null
  status?: DashboardStatus
  title?: string
  owner?: string
  permission_type?: WhoCanView
}

export async function copy(id: string) {
  return axios.post(
    `${URL}/${id}/copy?type=DEFAULT`,
    { data: {} },
    parseAuthenticationHeader()
  )
}

export async function update(id: string, body: PatchDashboardGroup) {
  return axios.patch(`${URL}/${id}`, body, parseAuthenticationHeader())
}

export async function destroy(id: string) {
  return axios.delete(`${URL}/${id}`, parseAuthenticationHeader())
}

export async function updateOrder(id: string, body: { data: string[] }) {
  return axios.put(
    `${URL}/${id}/update-order`,
    body,
    parseAuthenticationHeader()
  )
}

export async function getActivities(id: string) {
  return getWithRedirect<TDashboardGroupActivity>(`${URL}/${id}/activities`)
}
