import React, { MouseEvent } from 'react'

import { useLocation } from 'react-router-dom'

import Box from 'components_new/atoms/Box'
import Collapse from 'components_new/atoms/Collapse'
import Icon, { IconName } from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemButton from 'components_new/atoms/List/ListItemButton'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import Tooltip from 'components_new/atoms/Tooltip'
import Chip from 'components_new/atoms/Chip'

import OverflowText from 'components_new/molecules/OverflowText'

export type TNavigationItem = {
  activePaths?: string[]
  divider?: boolean
  hidden?: boolean
  disabled?: boolean
  href: string
  icon?: IconName
  infoChip?: string
  search?: string
  title: string
  neverActive?: boolean
  subItems?: TNavigationItem[]
  onClick?: () => void
}

export interface NavigationItemProps {
  expandedFolders?: string[]
  disableHistory?: boolean
  item: TNavigationItem
  collapsed: boolean
  indent?: number
  toggleFolder?: (folder: string) => void
}

const NavigationItem = (props: NavigationItemProps) => {
  const {
    expandedFolders,
    disableHistory,
    item,
    collapsed,
    indent,
    toggleFolder
  } = props

  if (item.hidden) {
    return null
  }

  const location = useLocation()

  const getActive = () => {
    if (item.activePaths && item.activePaths.length > 0) {
      return (
        location.pathname === item.href ||
        item.activePaths.some((activePath) =>
          location.pathname.includes(activePath)
        )
      )
    }

    return location.pathname === item.href
  }

  const active = getActive()

  const selected = active && !item.neverActive

  // const [expanded, setExpanded] = useState<boolean>(false)
  const expanded = expandedFolders ? expandedFolders.includes(item.href) : false

  const handleExpandMouseDown = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const handleExpandClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()

    if (toggleFolder) {
      toggleFolder(item.href)
    }
  }

  const getIndent = () => {
    if (indent !== undefined) {
      return indent + 2
    }

    return 4
  }

  if (collapsed) {
    return (
      <Box sx={{ display: 'block', mt: item.divider ? 2 : undefined }}>
        <Tooltip title={item.title} placement={'right'}>
          <IconButton
            disabled={item.disabled}
            disableHistory={disableHistory}
            size="small"
            sx={{
              bgcolor: selected ? 'brand.transparent' : undefined,
              color: selected ? 'brand.text' : undefined,
              '&:hover': {
                color: 'brand.text'
              }
            }}
            search={item.search}
            to={item.href}
            onClick={() => {
              if (item.onClick) {
                item.onClick()
              }
            }}
          >
            <Icon
              fontSize="small"
              name={item.icon ?? 'FolderOutlined'}
              color={selected ? 'brand' : undefined}
            />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }

  return (
    <>
      <ListItem
        disablePadding
        sx={{ height: 32, mt: item.divider ? 2 : undefined }}
      >
        <ListItemButton
          color="primary"
          disabled={item.disabled}
          disableHistory={disableHistory}
          search={item.search}
          to={item.href}
          onClick={() => {
            if (item.onClick) {
              item.onClick()
            }
          }}
          selected={selected}
          sx={{
            height: 32,
            borderRadius: 16,
            '&.Mui-selected': {
              bgcolor: 'primary.background',
              color: 'primary.contrastText',
              '&:hover': {
                bgcolor: 'primary.background',
                color: 'primary.contrastText'
              }
            },
            pl: indent,
            py: 0.5
          }}
        >
          {item.icon ? (
            <ListItemIcon
              onMouseDown={handleExpandMouseDown}
              onClick={handleExpandClick}
              sx={{ cursor: 'default', position: 'relative', minWidth: 36 }}
            >
              {item.subItems && item.subItems.length > 0 ? (
                <>
                  <Icon
                    name={
                      expanded ? 'ArrowDropDownOutlined' : 'ArrowRightOutlined'
                    }
                    sx={{
                      color: selected ? 'primary.contrastText' : undefined,
                      cursor: 'default',
                      position: 'absolute',
                      top: 2,
                      left: -13,
                      fontSize: 16
                    }}
                  />
                  <Icon
                    fontSize="small"
                    name={item.icon}
                    sx={{
                      color: selected ? 'primary.contrastText' : undefined,
                      cursor: 'default'
                    }}
                  />
                </>
              ) : (
                <>
                  <Icon
                    fontSize="small"
                    name={item.icon}
                    sx={{
                      color: selected ? 'primary.contrastText' : undefined
                    }}
                  />
                </>
              )}
            </ListItemIcon>
          ) : null}
          <OverflowText
            sx={{ flex: 1 }}
            variant="subtitle2"
            color={selected ? 'primary.contrastText' : undefined}
          >
            {item.title}
          </OverflowText>
          {item.infoChip ? (
            <Chip
              sx={{ ml: 1 }}
              size={'xsmall'}
              color={'info'}
              label={item.infoChip}
            />
          ) : null}
        </ListItemButton>
      </ListItem>
      {item.subItems && item.subItems?.length > 0 ? (
        <Collapse in={expanded}>
          {item.subItems?.map((subItem) => (
            <NavigationItem
              collapsed={false}
              expandedFolders={expandedFolders}
              indent={getIndent()}
              item={subItem}
              key={subItem.href}
              toggleFolder={toggleFolder}
            />
          ))}
        </Collapse>
      ) : null}
    </>
  )
}

NavigationItem.displayName = 'NavigationItem'
export default NavigationItem
