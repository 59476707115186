import React, { ReactNode } from 'react'

import { Helmet } from 'react-helmet'

import { useParams } from 'react-router-dom'
import { useMediaQuery, useTheme } from '@mui/material'

import CustomerPreview from 'components_new/organisms/CustomerPreview'
import FinderNavigation from 'components_new/organisms/FinderNavigation'
import FinderSearch from 'components_new/organisms/FinderSearch'
import GlobalMobileNavigation from 'components_new/organisms/GlobalMobileNavigation'
import GlobalNavigation from 'components_new/organisms/GlobalNavigation'
import InviteButton from 'components_new/organisms/InviteButton'
import MobileFinderLocation from 'components_new/organisms/MobileFinderLocation'
import NewButton from 'components_new/organisms/NewButton'
import PageContainer from 'components_new/organisms/PageContainer'
import SelectCustomer from 'components_new/organisms/SelectCustomer'

interface FinderPageProps {
  children?: ReactNode
  userIsCompanyGroup: boolean
}

const FinderTemplate = (props: FinderPageProps) => {
  const { children, userIsCompanyGroup } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  const params = useParams<{ folderId?: string }>()
  const isFolder = Boolean(params.folderId)

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={theme.palette.background.default} />
      </Helmet>

      <PageContainer
        NavbarProps={{
          content: (
            <>
              <SelectCustomer sx={{ mb: 2 }} />
              <NewButton sx={{ mb: 1 }} />
            </>
          ),
          footer: <GlobalNavigation />,
          menu: isMobile ? <GlobalMobileNavigation /> : <FinderNavigation />
        }}
        topbar={
          isMobile && isFolder ? (
            <MobileFinderLocation />
          ) : isMobile ? (
            <CustomerPreview navLock={true} />
          ) : (
            <FinderSearch />
          )
        }
        TopbarProps={{
          actions:
            isMobile || userIsCompanyGroup ? undefined : (
              <InviteButton hideRole={true} />
            ),
          displayProfile: !isMobile || (isMobile && !isFolder)
        }}
      >
        {children}
      </PageContainer>
    </>
  )
}

export default FinderTemplate
