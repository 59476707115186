import React from 'react'

import { PeriodSelectorProps } from '.'

import { PeriodFilter } from 'types/GlobalWidget'
import { translateDateFilter } from 'utils/enumTranslator'
import { getParsedDateSpan } from 'utils/dateParser'
import { getDatesOfPeriod, getPeriodFilterOptions } from '../utils'
import { EmptyValue } from './utils'

import Box from 'components_new/atoms/Box'

import TextField from 'components_new/atoms/TextField'
import MenuItem from 'components_new/atoms/Menu/MenuItem'

const Mobile = (props: PeriodSelectorProps) => {
  const { onSubmit, periodFilter } = props

  const EMPTY_VALUE = 'STANDARD'

  return (
    <Box
      sx={{
        width: '100%'
      }}
    >
      <TextField
        label={
          periodFilter ? (
            translateDateFilter[periodFilter as PeriodFilter]
          ) : (
            <EmptyValue />
          )
        }
        noGutter={true}
        onChange={(event) => {
          if (event.target.value === EMPTY_VALUE) {
            onSubmit(null)
          } else {
            onSubmit(event.target.value as PeriodFilter)
          }
        }}
        select={true}
        SelectProps={{
          renderValue: (value: PeriodFilter | typeof EMPTY_VALUE) => {
            if (value === EMPTY_VALUE) {
              return 'Enligt dashboard'
            }

            return (
              <>
                {getParsedDateSpan(
                  getDatesOfPeriod(value).from,
                  getDatesOfPeriod(value).to
                )}
              </>
            )
          }
        }}
        value={periodFilter ?? EMPTY_VALUE}
      >
        <MenuItem value={EMPTY_VALUE}>
          <EmptyValue />
        </MenuItem>
        {getPeriodFilterOptions().map((option, i) => {
          return (
            <MenuItem
              key={i}
              selected={periodFilter === option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          )
        })}
      </TextField>
    </Box>
  )
}

export default Mobile
