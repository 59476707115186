import React, { ReactNode } from 'react'

import { Helmet } from 'react-helmet'

import { useMediaQuery, useTheme } from '@mui/material'

import { DashboardFilter } from 'types/GlobalDashboardFilter'
import { CustomPeriodFilter, PeriodFilter } from 'types/GlobalWidget'
import { ISavedDashboardFilter } from 'types/GlobalSavedDashboardFilter'

import DashboardHeader from 'components_new/organisms/DashboardHeader'
import DashboardUserFilter from 'components_new/organisms/DashboardUserFilter'
import DashboardUserFilterBar from 'components_new/organisms/DashboardUserFilterBar'
import FinderNavigation from 'components_new/organisms/FinderNavigation'
import GlobalNavigation from 'components_new/organisms/GlobalNavigation'
import NewButton from 'components_new/organisms/NewButton'
import PageContainer from 'components_new/organisms/PageContainer'
import SelectCustomer from 'components_new/organisms/SelectCustomer'
import ShareDashboardButton from 'components_new/organisms/ShareDashboardButton'
import DashboardTabs from 'components_new/organisms/DashboardTabs'

interface DashboardTemplateProps {
  bgcolor?: string | null
  children?: ReactNode
  dashboardFilter: DashboardFilter
  displayTabs: boolean
  editMode: boolean
  periodFilter: PeriodFilter | CustomPeriodFilter | null
  gridRef: any | null
  savedDashboardFilter: ISavedDashboardFilter | null
  handleToggleEditMode: () => void
  resetAllTempStates: () => void
  setDashboardFilter: (value: DashboardFilter) => void
  setPeriodFilter: (value: PeriodFilter | CustomPeriodFilter | null) => void
  setSavedDashboardFilter: (
    value: ISavedDashboardFilter | null,
    skipFetch?: boolean
  ) => void
  userIsCompanyGroup: boolean
}

const DashboardTemplate = (props: DashboardTemplateProps) => {
  const {
    bgcolor,
    children,
    dashboardFilter,
    displayTabs,
    editMode,
    gridRef,
    handleToggleEditMode,
    periodFilter,
    resetAllTempStates,
    savedDashboardFilter,
    setDashboardFilter,
    setPeriodFilter,
    setSavedDashboardFilter,
    userIsCompanyGroup
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={theme.palette.background.default} />
      </Helmet>

      <PageContainer
        bgcolor={isMobile ? bgcolor : undefined}
        bottombar={!isMobile && displayTabs ? <DashboardTabs /> : undefined}
        NavbarProps={{
          content: (
            <>
              <SelectCustomer sx={{ mb: 2 }} />
              <NewButton sx={{ mb: 1 }} />
            </>
          ),
          footer: <GlobalNavigation />,
          menu: isMobile ? undefined : <FinderNavigation />
        }}
        noGutter={true}
        rightSidebar={
          <DashboardUserFilter
            dashboardFilter={dashboardFilter}
            periodFilter={periodFilter}
            savedDashboardFilter={savedDashboardFilter}
            setDashboardFilter={setDashboardFilter}
            setPeriodFilter={setPeriodFilter}
            setSavedDashboardFilter={setSavedDashboardFilter}
          />
        }
        RightSidebarProps={{
          iconName: 'FilterAlt',
          mobilePreview: (
            <DashboardUserFilterBar
              bgcolor={bgcolor}
              dashboardFilter={dashboardFilter}
              periodFilter={periodFilter}
              resetAllTempStates={resetAllTempStates}
              setDashboardFilter={setDashboardFilter}
              setPeriodFilter={setPeriodFilter}
            />
          ),
          tooltip: 'Filter'
        }}
        toolbar={
          isMobile ? undefined : (
            <DashboardUserFilterBar
              dashboardFilter={dashboardFilter}
              periodFilter={periodFilter}
              resetAllTempStates={resetAllTempStates}
              setDashboardFilter={setDashboardFilter}
              setPeriodFilter={setPeriodFilter}
            />
          )
        }
        ToolbarProps={{
          open: Boolean(periodFilter) || dashboardFilter.length > 0
        }}
        topbar={
          <DashboardHeader
            gridRef={gridRef}
            editMode={editMode}
            handleToggleEditMode={handleToggleEditMode}
          />
        }
        TopbarProps={{
          actions: userIsCompanyGroup ? null : <ShareDashboardButton />,
          displayProfile: !isMobile,
          noGutter: !isMobile
        }}
      >
        {children}
      </PageContainer>
    </>
  )
}

export default DashboardTemplate
