import { Dispatch } from 'redux'

import * as Types from 'redux/Types'
import * as AuthenticationApi from 'redux/api/Authentication'
import { getAllFilterOptions } from 'redux/api/KPITemplates'
import { getAllTagOptions } from 'redux/api/TagOptions'

import { tryGetAllAttributeOptions } from 'redux/actions/AttributeOptions'

import { signOut as signOutFunction } from 'App/Auth/AuthModule'

export function setLoggedIn() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.VALIDATE_USER
    })

    AuthenticationApi.whoAmI()
      .then((response) => {
        AuthenticationApi.checkTenOpens()
          .then((response) => {
            dispatch({
              type: Types.SET_TEN_OPENS,
              payload: {
                ...response.data.data
              }
            })
          })
          .catch(() => {})

        if (!response.data.data.attributes.user.is_company_group) {
          // Fetch misc for logged in users and is not is_company_group

          // attribute-options
          tryGetAllAttributeOptions()(dispatch)

          // filter-options
          getAllFilterOptions()
            .then((response) => {
              dispatch({
                type: Types.GET_FILTER_OPTIONS_SUCCESS,
                payload: response.data
              })
            })
            .catch(() => {})

          // tag-options
          getAllTagOptions()
            .then((response) => {
              dispatch({
                type: Types.GET_ALL_TAG_OPTIONS_SUCCESS,
                payload: response.data
              })
            })
            .catch(() => {})
        }

        dispatch({
          type: Types.SET_SIGNED_IN,
          payload: {
            ...response.data.data.attributes
          }
        })
      })
      .catch(() => {
        signOutFunction().then(() => {
          dispatch({
            type: Types.SIGN_OUT
          })
        })
      })
  }
}

export function signOut() {
  return (dispatch: Dispatch) => {
    signOutFunction().then(() => {
      dispatch({
        type: Types.SIGN_OUT
      })
    })
  }
}
