import React from 'react'

import { INLINES, BLOCKS, MARKS, Document } from '@contentful/rich-text-types'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

export const getFirstStringFromDocument = (
  document: Document | null | undefined
): string => {
  if (!document) return ''

  if (!(document.content[0]?.content[0] as { value: string })?.value) return ''

  return (
    (document.content[0].content[0] as { value: string })?.value.substring(
      0,
      155
    ) + '...'
  )
}

export const options = {
  renderMark: {
    [MARKS.BOLD]: (text: any) => <b>{text}</b>,
    [MARKS.UNDERLINE]: (text: any) => (
      <Box component="span" sx={{ color: 'brand.main' }}>
        {text}
      </Box>
    )
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => (
      <Text
        variant="inherit"
        paragraph
        color="inherit"
        sx={{
          '&:only-child': {
            mb: 0
          }
        }}
      >
        {children}
      </Text>
    ),
    [BLOCKS.UL_LIST]: (node: any, children: any) => (
      <ul className="ul">{children}</ul>
    ),
    [BLOCKS.LIST_ITEM]: (node: any, children: any) => (
      <Box component="li" sx={{ '&::marker': { color: 'inherit' } }}>
        {children}
      </Box>
    ),
    [BLOCKS.QUOTE]: (node: any, children: any) => (
      <Box
        sx={{
          px: 4,
          my: 4,
          borderLeftColor: 'brand.main',
          borderLeftStyle: 'solid',
          borderLeftWidth: '5px',
          '& p, span': {
            color: 'brand.text'
          }
        }}
      >
        {children}
      </Box>
    ),
    [INLINES.HYPERLINK]: ({ data }: any, children: any) => (
      <a
        className="link"
        href={data.uri}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  }
}
