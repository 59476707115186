import React, { useState } from 'react'

import { SxProps } from '@mui/material'

import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import InputAdornment from 'components_new/atoms/InputAdornment'
import Portal from 'components_new/atoms/Portal'
import Snackbar from 'components_new/atoms/Snackbar'
import TextField from 'components_new/atoms/TextField'

interface EndpointProps {
  href: string
  size?: 'small' | 'medium'
  sx?: SxProps
  variant: 'delete' | 'get' | 'patch' | 'post'
}

const Endpoint = React.forwardRef((props: EndpointProps, ref: any) => {
  const { href, size, sx, variant } = props

  const getColor = () => {
    switch (variant) {
    case 'delete':
      return 'error'
    case 'get':
      return 'info'
    case 'patch':
      return 'secondary'
    case 'post':
      return 'success'
    default:
      return undefined
    }
  }

  const [copySuccess, setCopySuccess] = useState<boolean>(false)

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(href)
    setCopySuccess(true)
  }

  return (
    <>
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={copyLinkToClipboard} size="small">
                <Icon fontSize="small" name="ContentCopyOutlined" />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <Chip
                color={getColor()}
                label={variant}
                size="small"
                sx={{ textTransform: 'uppercase' }}
              />
            </InputAdornment>
          ),
          readonly: true,
          sx: {
            fontWeight: 'bold'
          }
        }}
        ref={ref}
        value={href}
        size={size}
        sx={{
          ...sx
        }}
      />

      {/*-- snackbars --*/}
      <Portal>
        <Snackbar
          open={copySuccess}
          autoHideDuration={3000}
          onClose={(event, reason) => {
            if (reason !== 'clickaway') {
              setCopySuccess(false)
            }
          }}
          message="Kopierat till urklipp"
        />
      </Portal>
    </>
  )
})

Endpoint.displayName = 'Endpoint'

export default Endpoint
