import React from 'react'
import { Box, useTheme } from '@mui/material'

interface LogotypeProps {
  mode?: 'dark' | 'light'
  src: string
  sx?: object
}

const Logotype = (props: LogotypeProps) => {
  const { src, sx } = props

  const theme = useTheme()
  const mode = props.mode ?? theme.palette.mode

  // Convert to #1d1a1a from https://codepen.io/sosuke/pen/Pjoqqp
  const filter =
    mode === 'dark'
      ? 'brightness(0) invert(1)'
      : 'brightness(0) saturate(100%) invert(7%) sepia(10%) saturate(634%) hue-rotate(201deg) brightness(93%) contrast(91%)'

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        ...sx
      }}
    >
      <img
        alt="Logotype"
        src={src}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          filter: filter
        }}
      />
    </Box>
  )
}

Logotype.displayName = 'Logotype'
export default Logotype
