import React, { ReactNode } from 'react'

import Box from 'components_new/atoms/Box'
import Tooltip from 'components_new/atoms/Tooltip'

interface DisabledWrapperProps {
  children: ReactNode
  disabled: boolean
}

const DisabledWrapper = (props: DisabledWrapperProps) => {
  const { children, disabled } = props

  return (
    <>
      {disabled ? (
        <Tooltip
          followCursor={true}
          placement="top"
          title={<>Aktivera extern åtkomst för att börja bädda in.</>}
        >
          <Box
            sx={{
              opacity: 0.5,
              cursor: 'not-allowed'
            }}
          >
            <Box
              sx={{
                pointerEvents: 'none'
              }}
            >
              {children}
            </Box>
          </Box>
        </Tooltip>
      ) : (
        children
      )}
    </>
  )
}

export default DisabledWrapper
