import React, { useEffect, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Grid from 'components_new/atoms/Grid'

// This will only be used in this file, no need to store in Redux.
import * as ActivationApi from 'redux/api/internal/Activation'

import { ActivationDashboard } from 'types/GlobalTracking'
import DegreeOfActivationKpiBox from './DegreeOfActivationKpiBox'
import { calculateComboChart, calculate, getDateFourWeeksAgo } from './utils'
import DegreeOfActivationComboChart from './DegreeOfActivationComboChart'

const ActivationDashboardTemplate = () => {
  const [activationData, setActivationData] = useState<{
    loading: boolean
    data: ActivationDashboard | null
  }>({
    loading: true,
    data: null
  })

  useEffect(() => {
    ActivationApi.getActivationDashboard()
      .then((response: { data: ActivationDashboard }) =>
        setActivationData({ data: response.data, loading: false })
      )
      .catch(() => {})
  }, [])

  const fromDate = getDateFourWeeksAgo()

  const kpiBoxData = calculate(
    (activationData.data?.accounts || []).filter(
      (account) => new Date(account.created_at) <= new Date(fromDate)
    )
  )
  const comboChartData = calculateComboChart(activationData.data)

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'default.main'
      }}
    >
      <Grid container={true} spacing={2} sx={{ height: 240 }}>
        <Grid item={true} xs={12} sx={{ height: '100%' }}>
          <DegreeOfActivationKpiBox
            title={`Användare skapade innan ${fromDate}`}
            value={kpiBoxData.degreeOfActivation}
            goal={55}
            totalUsers={kpiBoxData.total}
            activatedUsers={kpiBoxData.nrOfActivatedUsers}
            loading={activationData.loading}
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} sx={{ flex: 1 }}>
        <Grid item={true} xs={12} sx={{ height: '100%', pb: 2 }}>
          <DegreeOfActivationComboChart
            loading={activationData.loading}
            total={comboChartData.total}
            nrOfActivatedUsers={comboChartData.nrOfActivatedUsers}
            degreeOfActivation={comboChartData.degreeOfActivation}
            labels={comboChartData.labels}
            title={
              'Aktiveringsgrad (%) per månad som en användare blev inbjuden'
            }
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ActivationDashboardTemplate
