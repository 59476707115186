import React, { ReactNode, useEffect, useRef, useState } from 'react'

import Autocomplete from 'components_new/atoms/Autocomplete'
import Icon from 'components_new/atoms/Icon'
import InputAdornment from 'components_new/atoms/InputAdornment'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemAvatar from 'components_new/atoms/List/ListItemAvatar'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import TextField from 'components_new/atoms/TextField'

import { useHistory } from 'react-router-dom'
import Box from 'components_new/atoms/Box'

type Option = {
  title: string
  subtitle?: string
  href: string
  src: string
  group: string
  adornment?: ReactNode
  onClick?: () => void
}

interface SimpleSearchProps {
  options: Option[]
  placeholder?: string
  sortGroups?: boolean
  size?: 'small' | 'medium'
  sx?: object
}

const SimpleSearch = (props: SimpleSearchProps) => {
  const { options, placeholder, sortGroups, size, sx } = props

  const [focus, setFocus] = useState<boolean>(false)
  const history = useHistory()

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Escape') {
        event.preventDefault()

        if (inputRef.current != null) {
          inputRef.current.blur()
        }
        setFocus(false)
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  const getSortedOptions = (): any[] => {
    if (sortGroups) {
      return options.sort((a, b) => {
        if (a.group.toLowerCase() === b.group.toLowerCase()) {
          return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        }

        return a.group.toLowerCase() > b.group.toLowerCase() ? 1 : -1
      })
    }

    return options.sort((a, b) => b.group.localeCompare(a.group))
  }

  return (
    <>
      <Autocomplete
        autoHighlight={true}
        clearOnBlur={true}
        // clearOnEscape={true}
        disableClearable={false}
        forcePopupIcon={false}
        getOptionLabel={(option) => option.title}
        groupBy={(option) => option.group}
        onChange={(e, value: Option) => {
          if (value) {
            history.push(value.href)

            if (value.onClick) {
              value.onClick()
            }
          }
        }}
        openOnFocus={true}
        options={getSortedOptions()}
        size={size}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputRef}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              type: 'text',
              startAdornment: (
                <InputAdornment position="start">
                  <Icon name="Search" />
                </InputAdornment>
              )
            }}
            sx={{
              my: 0,
              '& .MuiInputBase-root': {
                borderRadius: 6,
                bgcolor: 'action.hover',
                height: 48
              },
              '& fieldset': {
                border: 'none'
              }
            }}
          />
        )}
        renderOption={(props, option) => (
          <ListItem {...props} component="li" key={option.id}>
            <ListItemAvatar>
              <Box sx={{ width: 32, height: 32, position: 'relative' }}>
                <img
                  loading="lazy"
                  width="32"
                  src={option.src}
                  srcSet={`${option.src} 2x`}
                  alt=""
                />
                {option.adornment ? (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: -6,
                      left: -6,
                      fontSize: 12
                    }}
                  >
                    {option.adornment}
                  </Box>
                ) : null}
              </Box>
            </ListItemAvatar>
            <ListItemText
              primary={option.title}
              primaryTypographyProps={{ variant: 'subtitle2' }}
              secondary={option.subtitle}
            />
            <ListItemIcon position="end">
              <Icon name="ArrowForward" />
            </ListItemIcon>
          </ListItem>
        )}
        sx={{ width: '100%', zIndex: focus ? 1 : undefined, ...sx }}
      />
    </>
  )
}

export default SimpleSearch
