import { useMemo } from 'react'
import { TagOptionReducerType } from 'types/GlobalTagOptions'
import { DatasetType } from 'types/GlobalWidget'
import {
  TagType,
  TagStatusCondition,
  TagStatusDateValue,
  TagStatusItem,
  TagStatusSeverity,
  WidgetTagObject
} from 'types/GlobalWidgetTag'

export interface ITagStatusData {
  conditional_value: null | number
  condition: TagStatusCondition
  severity: TagStatusSeverity
  value: null | number | string | TagStatusDateValue
}

export const getStatusValue = (statusItem: TagStatusItem): string => {
  const value = statusItem.value

  if (typeof value === 'number') {
    return value.toString()
  }

  if (typeof value === 'object') {
    return (value as any).selected
  }

  return value
}

export const getStatusPreviewValue = (statusItem: TagStatusItem): string => {
  const conditionalValue = statusItem.conditional_value
  const value = statusItem.value
  const type = statusItem.type

  if (type === DatasetType.DATE && typeof value === 'object') {
    if (conditionalValue) {
      return `${conditionalValue} ${
        value.selected
          ? translateStatusDateValue[value.selected as TagStatusDateValue]
          : ''
      }`
    }

    return translateStatusDateValue[value.selected as TagStatusDateValue]
  }

  if (typeof value === 'object') {
    return (value as any).selected
  }

  return value.toString()
}

export const translateStatusCondition: Record<
  TagStatusCondition,
  Record<TagType, string>
> = {
  [TagStatusCondition.EQ]: {
    [DatasetType.DATE]: 'är',
    [DatasetType.ENUM]: 'är',
    [DatasetType.NUMBER_TAG]: 'är lika med',
    [DatasetType.STRING]: 'är exakt'
  },
  [TagStatusCondition.GT]: {
    [DatasetType.DATE]: 'är efter',
    [DatasetType.ENUM]: '',
    [DatasetType.NUMBER_TAG]: 'är större än',
    [DatasetType.STRING]: ''
  },
  [TagStatusCondition.LT]: {
    [DatasetType.DATE]: 'är före',
    [DatasetType.ENUM]: '',
    [DatasetType.NUMBER_TAG]: 'är mindre än',
    [DatasetType.STRING]: ''
  },
  [TagStatusCondition.NE]: {
    [DatasetType.DATE]: 'är inte',
    [DatasetType.ENUM]: 'är inte',
    [DatasetType.NUMBER_TAG]: 'är inte lika med',
    [DatasetType.STRING]: 'är inte exakt'
  }
}

export const translateStatusDateValue: Record<TagStatusDateValue, string> = {
  [TagStatusDateValue.DAYS_FROM_NOW]: 'dagar framåt',
  [TagStatusDateValue.DAYS_SINCE_NOW]: 'dagar bakåt',
  [TagStatusDateValue.TODAY]: 'idag',
  [TagStatusDateValue.TOMORROW]: 'imorgon',
  [TagStatusDateValue.YESTERDAY]: 'igår'
}

export const translateSeverity: Record<
  'error' | 'success' | 'warning',
  string
> = {
  ['error']: 'Negativt',
  ['success']: 'Positivt',
  ['warning']: 'Varning'
}

export const translateStatusValueLabel: Record<TagType, string> = {
  [DatasetType.DATE]: 'Datum',
  [DatasetType.ENUM]: 'Tagg',
  [DatasetType.NUMBER_TAG]: 'Värde',
  [DatasetType.STRING]: 'Text'
}

export const getStatusConditionOptions = (
  options: TagStatusCondition[],
  type: TagType
) => {
  return options.map((option) => {
    return {
      label: translateStatusCondition[option][type],
      value: option
    }
  })
}

export const getStatusDateConditionOptions = (
  options: TagStatusDateValue[]
) => {
  return options.map((option) => {
    return {
      label: translateStatusDateValue[option],
      value: option
    }
  })
}

export const getStatusValueEnumOptions = (options: string[]) => {
  return options.map((option) => {
    return {
      label: option,
      value: option
    }
  })
}

export const getTagOptions = (
  tag: WidgetTagObject,
  TagOptionStore: TagOptionReducerType
): string[] | TagStatusDateValue[] =>
  useMemo(() => {
    if (!TagOptionStore.fetched) {
      return []
    }

    return TagOptionStore.data[tag.tag_option_id]?.values || []
  }, [tag.tag_option_id, TagOptionStore.fetched])

export const isLoading = (
  tag: WidgetTagObject,
  TagOptionStore: TagOptionReducerType
) =>
  !TagOptionStore.fetched &&
  (tag.type === DatasetType.DATE || tag.type === DatasetType.ENUM)

export const DEFAULT_STATE: ITagStatusData = {
  conditional_value: null,
  condition: TagStatusCondition.EQ,
  severity: TagStatusSeverity.SUCCESS,
  value: null
}
