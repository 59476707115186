import React from 'react'

import Icon from 'components_new/atoms/Icon'
import Switch from 'components_new/atoms/Switch'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import TextField from 'components_new/atoms/TextField'
import InputAdornment from 'components_new/atoms/InputAdornment'

import { DEFAULT_MOBILE_BREAKPOINT } from 'themes'

import { TEmbedComponent } from '../types'
import { REmbedComponent } from '../lib'

interface ResponsiveModeProps {
  breakpoint: string | null
  embedComponent: TEmbedComponent
  mobile: boolean
  setBreakpoint: (value: string | null) => void
  setMobile: (value: boolean) => void
}

const ResponsiveMode = (props: ResponsiveModeProps) => {
  const { breakpoint, embedComponent, mobile, setBreakpoint, setMobile } = props

  const handleToggleMobile = () => {
    if (mobile) {
      setBreakpoint(null)
    }
    setMobile(!mobile)
  }

  return (
    <SettingsGroup title="Responsivt läge">
      <SettingsItem
        actions={
          <Switch
            color="success"
            checked={mobile}
            onChange={handleToggleMobile}
            size="medium"
          />
        }
        body={
          <>
            Låter {REmbedComponent[embedComponent].title.toLowerCase()}en övergå
            till mobilt läge vid sin brytpunkt.
          </>
        }
        icon={<Icon name={'MobileFriendlyOutlined'} />}
        title={'Mobil'}
        titleAdornments={<code>?responsive_mode=mobile</code>}
      />

      <SettingsItem
        actions={
          <TextField
            InputProps={{
              inputMode: 'numeric',
              endAdornment: <InputAdornment position="end">px</InputAdornment>,
              pattern: '[0-9]*'
            }}
            noGutter={true}
            onChange={(event) => setBreakpoint(event.target.value)}
            size="small"
            sx={{ width: 128 }}
            type="number"
            value={breakpoint ?? DEFAULT_MOBILE_BREAKPOINT.toString()}
          />
        }
        body={`Ange vid vilken bredd ${REmbedComponent[
          embedComponent
        ].title.toLowerCase()}en ska övergå i mobilt läge.`}
        disabled={!mobile}
        disabledText={
          <>
            Kräver att <b>Mobil</b> är aktiverat som responsivt läge.
          </>
        }
        icon={<Icon name={'SwapHorizOutlined'} />}
        title={'Brytpunkt'}
        titleAdornments={<code>{'?mobile_breakpoint={number}'}</code>}
      />
    </SettingsGroup>
  )
}

export default ResponsiveMode
