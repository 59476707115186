import React from 'react'
import { useSlate } from 'slate-react'

import { CustomMarks } from '../types'

import Icon, { IconName } from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Tooltip from 'components_new/atoms/Tooltip'

import { isMarkActive, toggleMark } from '../utils'
import { dict } from '../lib'

interface Props {
  format: CustomMarks
  iconName: IconName
  userAgent: 'mac' | 'windows'
}

const MarkButton = (props: Props) => {
  const { format, iconName, userAgent } = props

  const editor = useSlate()

  const isActive = isMarkActive(editor, format)

  return (
    <Tooltip
      title={`${dict[format].title}${
        dict[format].hotkey[userAgent]
          ? ' ' + dict[format].hotkey[userAgent]
          : ''
      }`}
    >
      <IconButton
        color={isActive ? 'primary' : 'disabled'}
        disabled={false}
        size="small"
        // onMouseDown (and not onClick) due to blur/focus functionality
        onMouseDown={(event) => {
          event.preventDefault()
          toggleMark(editor, format)
        }}
        sx={isActive ? { bgcolor: 'action.focus' } : undefined}
        tabIndex={-1}
      >
        <Icon fontSize="small" name={iconName} />
      </IconButton>
    </Tooltip>
  )
}

export default MarkButton
