/**
 * Centralized dayjs configuration.
 */
import dayjs from 'dayjs'
import daysv from 'dayjs/locale/sv'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(quarterOfYear)
dayjs.extend(weekOfYear)
dayjs.extend(quarterOfYear)
dayjs.extend(isoWeek)
dayjs.locale({
  ...daysv,
  weekStart: 1
})
dayjs.tz.setDefault('Europe/Stockholm')

export default dayjs
