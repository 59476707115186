import React, { useState } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'
import { useParams } from 'react-router-dom'

import { bindActionCreators, Dispatch } from '@reduxjs/toolkit'
import { connect, ConnectedProps } from 'react-redux'

import { useDashboard } from 'redux/hooks/Dashboards'

import {
  DashboardFilter,
  ParsedDashboardFilterOptions
} from 'types/GlobalDashboardFilter'

import * as UserEvents from 'redux/api/internal/UserEvents'

import { Action, Context } from 'types/GlobalUserEvents'
import { ISavedDashboardFilter } from 'types/GlobalSavedDashboardFilter'
import { Dashboard } from 'redux/reducers/Dashboards'

import * as SavedDashboardFilterActions from 'redux/actions/SavedDashboardFilters'

import Button from 'components_new/atoms/Button'

import SplitButton from 'components_new/molecules/SplitButton'

import { isPublicDashboard } from 'utils/functions'

import SaveUserFilterDialog from '../dialogs/SaveUserFilterDialog'
import { formatPutConditions } from './utils'

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(SavedDashboardFilterActions, dispatch)
}

const connector = connect(null, mapDispatchToProps)

interface ActionsProps {
  dashboardFilter: DashboardFilter
  dashboardFilterOptions: Map<string, ParsedDashboardFilterOptions>
  savedDashboardFilters: ISavedDashboardFilter[]
  setDashboardFilter: (value: DashboardFilter) => void
  setSavedDashboardFilter: (
    value: ISavedDashboardFilter,
    skipFetch?: boolean
  ) => void
  setTab: (tab: 'current' | 'saved') => void
}

type ComponentProps = ConnectedProps<typeof connector> & ActionsProps

const Actions = (props: ComponentProps) => {
  const {
    dashboardFilter,
    dashboardFilterOptions,
    savedDashboardFilters,
    setDashboardFilter,
    setSavedDashboardFilter,
    setTab,
    // Redux actions
    tryCreateSavedDashboardFilter,
    tryUpdateSavedDashboardFilter
  } = props

  const params = useParams<{ id: string }>()
  const dashboard = useDashboard<Dashboard>(params.id)
  const isPublic = dashboard && isPublicDashboard(dashboard)

  const [dialog, setDialog] = useState<'save' | false>(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  // loading
  if (!dashboard) {
    return null
  }

  const handleSave = (title: string) => {
    if (isPublic) return

    UserEvents.log({
      action: Action.SAVED_DASHBOARD_FILTER_CREATED,
      context: null,
      dashboard_id: dashboard.id,
      dashboard_group_id: dashboard.dashboard_group_id
    })

    tryCreateSavedDashboardFilter(
      {
        title,
        dashboard_group_id: dashboard.dashboard_group_id,
        conditions: formatPutConditions(dashboardFilter, dashboardFilterOptions)
      },
      (err, resource) => {
        if (!err && resource) {
          setSavedDashboardFilter(resource, true)
          setTab('saved')
        }
      }
    )
  }

  const handleUpdate = (id: string, title: string) => {
    if (isPublic) return

    UserEvents.log({
      action: Action.SAVED_DASHBOARD_FILTER_EDITED,
      context: Context.CONDITIONS,
      dashboard_id: dashboard.id,
      dashboard_group_id: dashboard.dashboard_group_id
    })

    tryUpdateSavedDashboardFilter(
      id,
      {
        title,
        conditions: formatPutConditions(dashboardFilter, dashboardFilterOptions)
      },
      (err, resource) => {
        if (!err && resource) {
          setSavedDashboardFilter(resource, true)
          setTab('saved')
        }
      }
    )
  }

  // success
  return (
    <>
      <Button
        disabled={dashboardFilter.length === 0}
        fullWidth={isMobile}
        onClick={() => {
          setDashboardFilter([])

          if (!isPublic) {
            UserEvents.log({
              action: Action.SELECTION_RESETTED,
              context: null,
              dashboard_id: dashboard.id,
              dashboard_group_id: dashboard.dashboard_group_id
            })
          }
        }}
        size={isMobile ? 'large' : undefined}
        variant={isMobile ? 'outlined' : 'text'}
      >
        Rensa
      </Button>
      {isMobile ? null : (
        <SplitButton
          color="neutral"
          disabled={dashboardFilter.length === 0}
          onClick={() => setDialog('save')}
          options={[
            {
              onClick: () => setDialog('save'),
              title: 'Spara som'
            },
            {
              disabled: savedDashboardFilters.length === 0,
              nestedOptions:
                savedDashboardFilters.length > 0
                  ? savedDashboardFilters.map((filter) => {
                    return {
                      onClick: () => handleUpdate(filter.id, filter.title),
                      title: filter.title
                    }
                  })
                  : undefined,
              nestedAnchor: 'left',
              onClick: () => {},
              title: 'Uppdatera...'
            }
          ]}
          title="Spara som"
          variant="contained"
        />
      )}

      {/*-- dialogs --*/}
      <SaveUserFilterDialog
        onClose={() => setDialog(false)}
        onSave={handleSave}
        open={dialog === 'save'}
        savedDashboardFilters={savedDashboardFilters}
      />
    </>
  )
}

export default connector(Actions)
