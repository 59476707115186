import React, { ElementType, MouseEventHandler } from 'react'
import { ReactNode } from 'react'
import { Avatar as MUIAvatar, SxProps } from '@mui/material'

interface AvatarProps {
  alt?: string
  bgcolor?: string
  children?: ReactNode
  component?: ElementType<any>
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseDown?: (() => void) | MouseEventHandler<HTMLElement>
  size?: 'small' | 'medium' | 'large'
  sizes?: string
  src?: string
  sx?: SxProps
  variant?: 'circular' | 'rounded' | 'square'
}

export const AVATAR_SMALL_SIZE = 24
export const AVATAR_MEDIUM_SIZE = 32
export const AVATAR_LARGE_SIZE = 48

export const getAvatarSize = (value?: 'small' | 'medium' | 'large') => {
  switch (value) {
  case 'large': {
    return { height: 48, width: 48, fontSize: 'subtitle1.fontSize' }
  }
  case 'small': {
    return { height: 24, width: 24, fontSize: 'body2.fontSize' }
  }
  // 'medium'
  default: {
    return { height: 32, width: 32, fontSize: 'body1.fontSize' }
  }
  }
}

/**
 * An avatar displays an image, icon or initials
 * (most often) representing a user.
 */

const Avatar = React.forwardRef((props: AvatarProps, ref: any) => {
  const { bgcolor, size, sx, ...rest } = props

  return (
    <MUIAvatar
      sx={{
        bgcolor: bgcolor ?? 'grey.100',
        color: 'text.secondary',
        ...getAvatarSize(size),
        ...sx
      }}
      {...rest}
      ref={ref}
    >
      {props.alt && !props.children ? props.alt.charAt(0) : props.children}
    </MUIAvatar>
  )
})

Avatar.displayName = 'Avatar'
export default Avatar
