import { Dispatch } from 'redux'
import * as Types from 'redux/Types'

import * as CustomerAgreementsApi from 'redux/api/internal/CustomerAgreements'

import {
  PatchCustomerAgreementBody,
  PostCustomerAgreementBody
} from 'types/GlobalCustomerAgreement'

export function tryCreateCustomerAgreement(
  customerId: string,
  body: PostCustomerAgreementBody
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_CUSTOMER_AGREEMENT
    })

    CustomerAgreementsApi.createCustomerAgreement(customerId, body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_CUSTOMER_AGREEMENT_SUCCESS,
          payload: response.data.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Avtalet har skapats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_CUSTOMER_AGREEMENT_FAILED
        })
      })
  }
}

export function tryGetCustomerAgreements(customerId: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_CUSTOMER_AGREEMENTS
    })

    CustomerAgreementsApi.getCustomerAgreements(customerId)
      .then((response) => {
        dispatch({
          type: Types.GET_CUSTOMER_AGREEMENTS_SUCCESS,
          payload: { customerId, agreements: response.data.data }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_CUSTOMER_AGREEMENTS_FAILED
        })
      })
  }
}

export function tryUpdatCustomerAgreement(
  customerId: string,
  agreementId: string,
  body: PatchCustomerAgreementBody
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_CUSTOMER_AGREEMENT
    })

    CustomerAgreementsApi.updateCustomerAgreement(customerId, agreementId, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_CUSTOMER_AGREEMENT_SUCCESS,
          payload: response.data.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Avtalet har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_CUSTOMER_AGREEMENT_FAILED
        })
      })
  }
}

export function tryDeleteCustomerAgreement(
  customerId: string,
  agreementId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_CUSTOMER_AGREEMENT
    })

    CustomerAgreementsApi.deleteCustomerAgreement(customerId, agreementId)
      .then(() => {
        dispatch({
          type: Types.DELETE_CUSTOMER_AGREEMENT_SUCCESS,
          payload: { customerId, agreementId }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kunden har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_CUSTOMER_AGREEMENT_FAILED
        })
      })
  }
}
