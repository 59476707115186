import React, { useState } from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Link from 'components_new/atoms/Link'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'

import EmptyPlaceholder from 'components_new/molecules/EmptyPlaceholder'
import SimpleDialog from 'components_new/molecules/SimpleDialog'
import UserListItem from 'components_new/molecules/UserListItem'

export interface NoDataProps {
  embedded: boolean
  scaleFactor: number
}

const NoData = (props: NoDataProps) => {
  const { embedded, scaleFactor } = props

  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      <EmptyPlaceholder
        actions={
          !embedded ? (
            <Link onClick={() => setDialogOpen(true)}>Begär data</Link>
          ) : undefined
        }
        background={true}
        scaleFactor={scaleFactor}
        title="Din organisation verkar inte ha kopplat på den data som krävs för att visa detta nyckeltal."
        icon="InboxTwoTone"
      />
      <SimpleDialog
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
        title={'Begär data'}
        actions={
          <Button onClick={() => setDialogOpen(false)} variant="text">
            Stäng
          </Button>
        }
      >
        <EmptyPlaceholder
          background={true}
          title="Din organisation verkar inte ha kopplat på den data som krävs för att visa detta nyckeltal."
          icon="InboxTwoTone"
          sx={{ mb: 4 }}
        />
        <Text align="center" variant="subtitle1" sx={{ my: 2 }}>
          Vi rekommenderar att du kontaktar
        </Text>
        <Stack
          spacing={2}
          sx={{
            maxWidth: 'content'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '50%'
            }}
          >
            <UserListItem
              component={Box}
              title="Systemansvarig"
              body="i din organisation"
              sx={{ width: 'fit-content' }}
              type="role"
            />
          </Box>
          <Divider flexItem={true} orientation="vertical">
            eller
          </Divider>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '50%'
            }}
          >
            <UserListItem
              component={Box}
              src="/assets/media/frida.jpg"
              title="Frida Björklund"
              body="frida@homepal.se"
              sx={{ width: 'fit-content' }}
            />
          </Box>
        </Stack>
        <Text align="center" variant="subtitle1" sx={{ my: 2 }}>
          ...för att koppla på den data som krävs! 👍
        </Text>
      </SimpleDialog>
    </>
  )
}

export default NoData
