import React, { FC, useEffect, useMemo } from 'react'
import { cloneDeep } from 'lodash'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import { ApplicationState } from 'redux/Stores/types'

import LoadingSettings from 'components_new/organisms/pageLoaders/LoadingSettings'

import UsersTemplate from 'components_new/templates/settings/Users'

import * as AccountActions from 'redux/actions/Accounts'
import * as AccountRoleActions from 'redux/actions/AccountRoles'
import * as CompanyGroupActions from 'redux/actions/CompanyGroups'

import { AccountRole } from 'types/GlobalUser'
import SettingsTemplate from 'components_new/templates/SettingsTemplate'

const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  AuthStore: state.AuthStore,
  CompanyGroupStore: state.CompanyGroupStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountActions,
      ...AccountRoleActions,
      ...CompanyGroupActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type UsersPageProps = ConnectedProps<typeof connector>

const UsersPage: FC<UsersPageProps> = (props: UsersPageProps) => {
  const {
    AccountStore,
    AuthStore,
    CompanyGroupStore,
    tryGetAllAccounts,
    tryGetAllAccountRoles,
    tryGetAllCompanyGroups,
    tryUpdateUser,
    tryCreateUser,
    tryDeleteUser
  } = props

  useEffect(() => {
    if (!AccountStore.fetched) {
      tryGetAllAccounts()
    }

    if (!AccountStore.rolesFetched) {
      tryGetAllAccountRoles()
    }

    if (!CompanyGroupStore.fetched && !CompanyGroupStore.fetching) {
      tryGetAllCompanyGroups()
    }
  }, [])

  const parsedRoles = useMemo(() => {
    const roles = cloneDeep(AccountStore.roles)

    if (AuthStore?.customer && !AuthStore.customer.allow_access.access_hub) {
      delete roles[AccountRole.ACCESS_HUB]
    }

    return roles
  }, [AccountStore.roles])

  const users = Object.values(AccountStore.data)

  return (
    <SettingsTemplate>
      {AccountStore.fetched &&
      AccountStore.rolesFetched &&
      CompanyGroupStore.fetched ? (
            <UsersTemplate
              companyGroups={CompanyGroupStore.data}
              createUser={(body) => tryCreateUser(body)}
              currentUser={AuthStore.user?.id}
              deleteUser={(id) => tryDeleteUser(id)}
              hasIdp={
                // eslint-disable-next-line max-len
                !!AuthStore.customer?.has_idp && !AuthStore.user?.is_homepal_user
              }
              isHomepal={AuthStore.user?.is_homepal_user}
              // eslint-disable-next-line max-len
              isManagementCompany={!!AuthStore.customer?.is_management_company}
              users={users}
              roles={parsedRoles}
              updateUser={(id, body) => tryUpdateUser(id, body)}
            />
          ) : (
            <LoadingSettings
              titles={['Hämtar användare', 'Läser av rättigheter']}
            />
          )}
    </SettingsTemplate>
  )
}

export default connector(UsersPage)
