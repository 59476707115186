import React, { ReactNode } from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Text from 'components_new/atoms/Text'

import SectionHeader from 'components_new/molecules/SectionHeader'

import { dict } from './lib'

export type TImportance = 'optional' | 'recommended' | 'required'

interface FormSectionProps {
  children: ReactNode
  helperText: ReactNode
  importance?: TImportance
  onEdit?: () => void
  sx?: object
  title: string
}

const FormSection = React.forwardRef((props: FormSectionProps, ref: any) => {
  const { children, helperText, importance, onEdit, sx, title } = props

  return (
    <Box
      ref={ref}
      sx={{
        mb: 3,
        ...sx
      }}
    >
      <SectionHeader
        actions={
          onEdit ? (
            <Button onClick={onEdit} size="small" variant="text">
              Redigera
            </Button>
          ) : null
        }
        divider={!helperText}
        gutterBottom={true}
        helperText={helperText}
        size="medium"
        title={
          <>
            {title}

            {importance ? (
              <Text color="text.secondary" component="span">
                {' '}
                ({dict[importance]})
              </Text>
            ) : null}
          </>
        }
      />
      {children}
    </Box>
  )
})

FormSection.displayName = 'FormSection'
export default FormSection
