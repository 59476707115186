import React, { useMemo } from 'react'

import { ThemeProvider } from '@mui/material'

import Box from 'components_new/atoms/Box'
import CardContent from 'components_new/atoms/Card/CardContent'
import CardActionArea from 'components_new/atoms/Card/CardActionArea'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import LinkWrapper from 'components_new/atoms/LinkWrapper'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import * as UserEvents from 'redux/api/internal/UserEvents'

import { Action } from 'types/GlobalUserEvents'

import { User } from 'types/GlobalUser'

import { getDashboardImageUrl, getThumbnail } from 'utils/functions'
import { IDashboardExample } from 'types/GlobalDashboardExample'
import { getTheme } from 'themes'
import UserListItem from 'components_new/molecules/UserListItem'
import { getFirstStringFromDocument } from 'utils/richTextRenderer'

interface DashboardExampleThumbnailProps {
  dashboard: IDashboardExample
  disabled?: boolean
  disableHistory?: boolean
  search?: string
  sx?: object
  thumbnail?: string
  user: User | null
}

const DashboardExampleThumbnail = (props: DashboardExampleThumbnailProps) => {
  const { dashboard, disabled, disableHistory, search, sx, user } = props

  const createdDiff = Date.now() - Date.parse(dashboard.sys.createdAt)
  const createdDayDiff = Math.round(createdDiff / (1000 * 3600 * 24))
  const showNew = createdDayDiff < 30

  const updatedDiff = Date.now() - Date.parse(dashboard.sys.updatedAt)
  const updatedDayDiff = Math.round(updatedDiff / (1000 * 3600 * 24))
  const showUpdated = updatedDayDiff < 14 && !showNew

  const thumbnail = useMemo(() => {
    return `url(${getThumbnail(getDashboardImageUrl(dashboard.fields.id))})`
  }, [dashboard.fields.id])

  const author = dashboard.fields.author
  const company = (author?.fields as any)?.company

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        ...sx
      }}
    >
      <LinkWrapper
        disabled={disabled}
        disableHistory={disableHistory}
        href={`/explore/${dashboard.fields.id}`}
        search={search}
      >
        <CardActionArea
          disabled={disabled}
          disableRipple={true}
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            '.MuiCardActionArea-focusHighlight': {
              background: 'transparent'
            },
            '&:hover': {
              '& .ThumbnailMediaOverlay': {
                opacity: 1
              }
            }
          }}
          onClick={() =>
            UserEvents.log({
              action: Action.DASHBOARD_EXAMPLE_OPENED,
              context: null,
              dashboard_id: dashboard.fields.id,
              dashboard_group_id: null
            })
          }
        >
          <ThemeProvider theme={getTheme('dark')}>
            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
              {showNew || showUpdated ? (
                <Box
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5,
                    p: 1,
                    top: 0,
                    left: 0,
                    zIndex: 1
                  }}
                >
                  {showNew ? (
                    <Tooltip title={`${createdDayDiff} dagar sedan`}>
                      <Chip color="brand" label="Nyhet ✨" size="small" />
                    </Tooltip>
                  ) : null}
                  {showUpdated ? (
                    <Tooltip title={`${updatedDayDiff} dagar sedan`}>
                      <Chip color="brand" label="Uppdaterad 🎉" size="small" />
                    </Tooltip>
                  ) : null}
                </Box>
              ) : null}
              <Box
                className="DashboardImage"
                sx={{
                  height: '100%',
                  width: '100%',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  WebkitBackgroundPosition: 'center',
                  backgroundPosition: 'center',
                  backgroundImage: thumbnail,
                  borderRadius: '16px',
                  paddingTop: `${(3 / 4) * 100}%`
                }}
              />
              <Box
                className="ThumbnailMediaOverlay"
                sx={{
                  alignItems: 'flex-end',
                  background:
                    'linear-gradient(180deg, rgba(26, 26, 29, 0) 40%,rgba(26, 26, 29, 1) 90%)',
                  borderRadius: 1,
                  display: 'flex',
                  left: 0,
                  height: '100%',
                  opacity: 0,
                  p: 2,
                  position: 'absolute',
                  top: 0,
                  transition: 'opacity 300ms ease',
                  width: '100%',
                  zIndex: 1
                }}
              >
                <Box sx={{ minWidth: 0 }}>
                  <Box
                    sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                  >
                    {[
                      ...(dashboard.fields.category?.fields.title
                        ? [dashboard.fields.category?.fields.title]
                        : []),
                      ...(dashboard.fields.segments?.map(
                        (segments: any) => segments.fields.name
                      ) ?? [])
                    ].map((tag) => (
                      <Chip
                        color="primary"
                        key={tag}
                        label={tag}
                        size="small"
                      />
                    ))}
                  </Box>

                  <Text
                    color="text.primary"
                    component="div"
                    gutterBottom={true}
                    noWrap={true}
                    variant="h6"
                  >
                    {dashboard.fields.title}
                  </Text>
                  <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
                    {dashboard.fields.summary ? (
                      <Text
                        color="text.secondary"
                        component="div"
                        variant="body2"
                        sx={{
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitLineClamp: '2',
                          WebkitBoxOrient: 'vertical'
                        }}
                      >
                        {getFirstStringFromDocument(dashboard.fields.summary)}
                      </Text>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </Box>
          </ThemeProvider>
          <CardContent sx={{ pt: 1, px: 0 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 1,
                px: 1,
                width: '100%'
              }}
            >
              <Box sx={{ flex: '0 1 auto', minWidth: 0 }}>
                <UserListItem
                  body={company ? company.fields.name : undefined}
                  component="div"
                  disablePadding={true}
                  size="small"
                  src={
                    company
                      ? 'https:' + company.fields.logotype.fields.file?.url
                      : undefined
                  }
                  sx={{ p: 0 }}
                  title={author ? (author.fields as any).name : 'Homepal'}
                  type={dashboard.fields.author ? 'customer' : 'homepal'}
                />
              </Box>

              <Box sx={{ flex: '1 1 auto', minWidth: 0 }}>
                <Text
                  align="right"
                  color="text.primary"
                  component="h3"
                  fontWeight="bold"
                  noWrap={true}
                  variant="body2"
                >
                  {dashboard.fields.title}
                </Text>
              </Box>

              {dashboard.data.visits.total && user && user.is_homepal_user ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Icon
                    name="VisibilityOutlined"
                    sx={{ color: 'text.secondary', fontSize: 14 }}
                  />
                  <Text color="text.secondary" variant="caption">
                    {dashboard.data.visits.total}
                  </Text>
                </Box>
              ) : null}
            </Box>
          </CardContent>
        </CardActionArea>
      </LinkWrapper>
    </Box>
  )
}

export default DashboardExampleThumbnail
