import { useSelector } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import { selectCustomerAgreements } from 'redux/selectors/CustomerAgreements'

export const useCustomerAgreements = (customerId: string) => {
  return useSelector((state: ApplicationState) =>
    selectCustomerAgreements(state, customerId)
  )
}
