import { createAction, createReducer } from '@reduxjs/toolkit'

import * as Types from 'redux/Types'

import { CustomerAgreement } from 'types/GlobalCustomerAgreement'

export interface ICustomerAgreementsReducerType {
  [customerId: string]: CustomerAgreement[]
}

// Initial state
const INITIAL_STATE: ICustomerAgreementsReducerType = {}

// Actions
const createCustomerAgreement = createAction<CustomerAgreement>(
  Types.CREATE_CUSTOMER_AGREEMENT_SUCCESS
)
const getCustomerAgreements = createAction<{
  customerId: string
  agreements: CustomerAgreement[]
}>(Types.GET_CUSTOMER_AGREEMENTS_SUCCESS)
const updateCustomerAgreement = createAction<CustomerAgreement>(
  Types.UPDATE_CUSTOMER_AGREEMENT_SUCCESS
)
const deleteCustomerAgreement = createAction<{
  customerId: string
  agreementId: string
}>(Types.DELETE_CUSTOMER_AGREEMENT_SUCCESS)
const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const CustomersAndLicensesReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(createCustomerAgreement, (state, action) => {
      const { payload } = action

      const currentCustomersAgreements = state[payload.customer_id]
        ? state[payload.customer_id]
        : []

      return {
        ...state,
        [payload.customer_id]: [...currentCustomersAgreements, payload]
      }
    })
    .addCase(getCustomerAgreements, (state, action) => {
      const { payload } = action

      return {
        ...state,
        [payload.customerId]: payload.agreements
      }
    })
    .addCase(updateCustomerAgreement, (state, action) => {
      const { payload } = action

      const currentCustomersAgreements = (
        state[payload.customer_id] ? state[payload.customer_id] : []
      ).filter((agreement) => agreement.id !== payload.id)

      return {
        ...state,
        [payload.customer_id]: [...currentCustomersAgreements, payload]
      }
    })
    .addCase(deleteCustomerAgreement, (state, action) => {
      const { payload } = action

      const currentCustomersAgreements = (
        state[payload.customerId] ? state[payload.customerId] : []
      ).filter((agreement) => agreement.id !== payload.agreementId)

      return {
        ...state,
        [payload.customerId]: [...currentCustomersAgreements]
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state: any) => state)
})

export default CustomersAndLicensesReducer
