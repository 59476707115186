import React from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'

interface TitleProps {
  groupTitle: string
  setTab: (value: 'email' | 'default') => void
  tab: 'email' | 'default'
}

const Title = (props: TitleProps) => {
  const { groupTitle, setTab, tab } = props

  const translateView: Record<'email', string> = {
    email: 'Bjud in'
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {tab !== 'default' ? (
        <Box
          component="span"
          onClick={() => setTab('default')}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            color: 'text.secondary',
            '&:hover': {
              color: 'text.primary'
            },
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
        >
          <Icon name="ArrowBackOutlined" sx={{ mr: 0.5 }} />
          <Box
            component="span"
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {translateView[tab]}
            {tab === 'email' ? ' till ' : ' '}
            {groupTitle}
          </Box>
        </Box>
      ) : (
        <>
          <Box
            component="span"
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            Dela {groupTitle}
          </Box>
        </>
      )}
    </Box>
  )
}

export default Title
