import React, { MouseEvent, ReactNode } from 'react'

import { PopoverReference, ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Divider from 'components_new/atoms/Divider'
import Icon, { IconName } from 'components_new/atoms/Icon'
import Menu from 'components_new/atoms/Menu/'

import NestedOptionsMenuItem from 'components_new/molecules/NestedOptionsMenuItem'
import OptionsMenuItem from 'components_new/molecules/OptionsMenuItem'
import ListSubheader from 'components_new/atoms/List/ListSubheader'

export interface OptionProps {
  disabled?: boolean
  divider?: boolean
  iconBadge?: boolean
  iconName?: IconName
  iconRotation?: string
  infoTip?: ReactNode
  onClick: () => void
  nestedAnchor?: 'left' | 'right'
  nestedOptions?: OptionProps[]
  nestedTitle?: string
  content?: ReactNode
  title: string
  tooltip?: string
}

interface OptionsMenuProps {
  anchorEl?: HTMLElement | null
  anchorOrigin?: {
    horizontal: 'center' | 'left' | 'right' | number
    vertical: 'bottom' | 'center' | 'top' | number
  }
  anchorReference?: PopoverReference
  anchorPosition?: {
    top: number
    left: number
  }
  disableScrollLock?: boolean
  onClose: (event: MouseEvent<HTMLElement>) => void
  open: boolean
  options: OptionProps[]
  sx?: object
  title?: string
}

/**
 * The OptionsMenu component is used for
 * displaying one or more options (Icon
 * + Text) in a list when clicking an
 * HTML element.
 */

const OptionsMenu = React.forwardRef((props: OptionsMenuProps, ref: any) => {
  const {
    anchorEl,
    anchorOrigin,
    anchorPosition,
    anchorReference = 'anchorEl',
    disableScrollLock,
    onClose,
    open,
    options,
    sx,
    title
  } = props

  return (
    <>
      <ThemeProvider theme={getTheme('light')}>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          anchorPosition={anchorPosition}
          anchorReference={anchorReference}
          disableScrollLock={disableScrollLock}
          onClose={onClose}
          onMouseDown={(event) => event.stopPropagation()}
          open={open}
          PaperProps={{
            sx: {
              maxHeight: 360,
              minWidth: 256
            }
          }}
          ref={ref}
          sx={sx}
        >
          {title ? <ListSubheader border={true}>{title}</ListSubheader> : null}
          {options.map((option: OptionProps, i: number) => [
            option.nestedOptions || option.content ? (
              <NestedOptionsMenuItem
                closeParentMenu={onClose}
                disabled={option.disabled}
                divider={option.divider}
                icon={
                  option.iconName ? (
                    <Icon name={option.iconName} fontSize="small" />
                  ) : undefined
                }
                infoTip={option.infoTip}
                key={i}
                MenuSx={sx}
                nestedAnchor={option.nestedAnchor}
                nestedTitle={option.nestedTitle}
                parentMenuOpen={open}
                title={option.title}
                variant={option.content ? 'content' : 'menu'}
              >
                {option.nestedOptions
                  ? option.nestedOptions.map(
                    (nestedOption: OptionProps, i: number) => [
                      <OptionsMenuItem
                        disabled={nestedOption.disabled}
                        iconBadge={option.iconBadge}
                        iconName={nestedOption.iconName}
                        iconRotation={nestedOption.iconRotation}
                        infoTip={nestedOption.infoTip}
                        key={`nested-${i}`}
                        onClick={(event) => {
                          onClose(event)
                          nestedOption.onClick()
                        }}
                        title={nestedOption.title}
                      />,
                      nestedOption.divider ? (
                        <Divider key={`divider-${i}`} />
                      ) : null
                    ]
                  )
                  : option.content}
              </NestedOptionsMenuItem>
            ) : (
              <OptionsMenuItem
                disabled={option.disabled}
                iconBadge={option.iconBadge}
                iconName={option.iconName}
                iconRotation={option.iconRotation}
                infoTip={option.infoTip}
                key={i}
                onClick={(event) => {
                  onClose(event)
                  option.onClick()
                }}
                title={option.title}
                tooltip={option.tooltip}
              />
            ),
            option.divider ? <Divider /> : null
          ])}
        </Menu>
      </ThemeProvider>
    </>
  )
})

OptionsMenu.displayName = 'OptionsMenu'
export default OptionsMenu
