import React, { ElementType, MouseEvent } from 'react'
import { ToggleButtonGroup as MUIToggleButtonGroup } from '@mui/material'
import { ToggleButton as MUIToggleButton } from '@mui/material'
import Icon, { IconName } from '../Icon'

interface ToggleButtonProps {
  component?: ElementType<any>
  color?:
    | 'standard'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
  exclusive?: boolean
  fullWidth?: boolean
  items: {
    title: string
    iconName?: IconName
    value: any
    disabled?: boolean
  }[]
  onChange?: (event: MouseEvent<HTMLElement>, value: any) => void
  orientation?: 'horizontal' | 'vertical'
  size?: 'small' | 'medium' | 'large'
  sx?: object
  value?: any
}

/**
 * The ToggleButton component can be used
 * to group related options.
 *
 * It can be used to display and control
 * one or more states for the user.
 */

const ToggleButton = React.forwardRef((props: ToggleButtonProps, ref: any) => {
  const { items, size, ...rest } = props

  return (
    <MUIToggleButtonGroup size={size} {...rest} ref={ref}>
      {items.map((item, i) => (
        <MUIToggleButton
          disabled={item.disabled}
          title={item.title}
          value={item.value}
          key={i}
        >
          {item.iconName ? (
            <Icon name={item.iconName} fontSize={size} sx={{ mr: 1 }} />
          ) : null}
          {item.title}
        </MUIToggleButton>
      ))}
    </MUIToggleButtonGroup>
  )
})

ToggleButton.defaultProps = {
  color: 'primary',
  exclusive: true,
  fullWidth: true
}

ToggleButton.displayName = 'ToggleButton'
export default ToggleButton
