import { ButtonGroup } from '@mui/material'
import Button from 'components_new/atoms/Button'
import React, { MouseEvent, ReactNode, useState } from 'react'
import OptionsMenu, { OptionProps } from '../OptionsMenu'
import Icon, { IconName } from 'components_new/atoms/Icon'
import { Color } from 'themes'

interface SplitButtonProps {
  color?:
    | Color
    | 'inherit'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'brand'
    | 'black'
    | 'white'
    | 'neutral'
  disabled?: boolean
  iconName?: IconName
  onClick?: (event: MouseEvent<HTMLElement>) => void
  options: OptionProps[]
  size?: 'small' | 'medium'
  startIcon?: ReactNode
  sx?: object
  title: ReactNode
  variant?: 'contained' | 'outlined' | 'text'
}

/**
 * The SplitButton component is a dual-function
 * menu button that offers a default action
 * (and/or a state) as well as the possibility
 * of choosing a different action by selecting
 * from a set of alternatives.
 */

const SplitButton = React.forwardRef((props: SplitButtonProps, ref: any) => {
  const {
    color,
    disabled,
    iconName,
    onClick,
    options,
    size,
    startIcon,
    sx,
    title,
    variant
  } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <>
      {/*-- button --*/}
      <ButtonGroup
        color={color}
        disabled={disabled}
        size={size}
        variant={variant}
        ref={ref}
        sx={sx}
      >
        <Button
          onClick={onClick}
          startIcon={startIcon}
          sx={{ borderRadius: 'inherit' }}
          variant={variant}
        >
          {title}
        </Button>
        <Button
          aria-haspopup="menu"
          onClick={handleMenuClick}
          sx={{ borderRadius: 'inherit', px: 0 }}
          variant={variant}
        >
          <Icon fontSize={size} name={iconName ?? 'ArrowDropDown'} />
        </Button>
      </ButtonGroup>

      {/*-- menu --*/}
      <OptionsMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        open={open}
        options={options}
      />
    </>
  )
})

SplitButton.displayName = 'SplitButton'
export default SplitButton
