import React, { useMemo } from 'react'

import { useParams } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'
import { PlatformDashboard } from 'redux/reducers/Dashboards'

import {
  useDashboard,
  useDashboards,
  useDashboardGroup
} from 'redux/hooks/Dashboards'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'

import { getChecklistItems } from './utils'

interface ChecklistProps {
  data?: any
  onShowMore?: () => void
}

const Checklist = (props: ComponentProps) => {
  const { onShowMore } = props

  const params = useParams<{ id: string }>()
  const showMore = !Boolean(onShowMore)
  const dashboard = useDashboard<PlatformDashboard>(params.id)
  const group = useDashboardGroup(params.id)
  const dashboardObject = useDashboards()

  const checklistItems = useMemo(
    () => getChecklistItems(dashboard, group, dashboardObject),
    [group, dashboard, dashboardObject]
  )

  return (
    <>
      <Box
        sx={{
          bgcolor: 'success.background',
          color: 'success.contrastText',
          p: showMore ? 4 : 2,
          borderRadius: 2
        }}
      >
        <Text
          color="inherit"
          gutterBottom={true}
          variant={showMore ? 'h4' : 'subtitle1'}
        >
          Grundläggande checklista
        </Text>
        <Text color="inherit" variant={showMore ? 'body1' : 'body2'}>
          Ge din dashboard förutsättningar att lyckas genom att checka av de
          mest grundläggande sakerna.
        </Text>
        <List dense={true} sx={{ py: 2 }}>
          {checklistItems.map((item) => (
            <ListItem
              key={item.title}
              sx={{ opacity: item.value ? 0.5 : 1, px: 0 }}
            >
              <ListItemIcon sx={{ minWidth: showMore ? 48 : 32 }}>
                <Icon
                  name={
                    item.value
                      ? 'CheckBoxOutlined'
                      : 'CheckBoxOutlineBlankOutlined'
                  }
                  sx={{
                    color: 'success.contrastText'
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                  color: 'success.contrastText',
                  fontWeight: 'medium',
                  variant: 'body1'
                }}
                secondary={showMore ? item.body : undefined}
                secondaryTypographyProps={{
                  color: 'success.contrastext',
                  variant: 'body1'
                }}
              />
            </ListItem>
          ))}
        </List>
        {onShowMore ? (
          <Button color="primary" onClick={onShowMore} variant="text">
            Visa mer
          </Button>
        ) : null}
      </Box>
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  KPITemplateStore: state.KPITemplateStore
})

const connector = connect(mapStateToProps)

type ComponentProps = ConnectedProps<typeof connector> & ChecklistProps

export default connector(Checklist)
