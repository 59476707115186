export enum DataType {
  BOOLEAN = 'BOOLEAN',
  BIGINT = 'BIGINT',
  DATE = 'DATE',
  DOUBLE = 'DOUBLE',
  STRING = 'STRING',
  TIMESTAMP = 'TIMESTAMP'
}

export interface AttributeOption {
  allow_custom_segment_by: boolean
  id: string
  is_time_series: boolean
  attribute_id: string
  hierarchy_level: number
  name: string
  type: DataType
  disabled: boolean
  relation_key: string
  suggested: boolean
  fact_table_id: string
  // booleans for type of option
  filter_attribute: boolean
  segment_attribute: boolean
  breakdown_attribute: boolean
}

export interface GroupedAttributeOptions {
  [factTableId: string]: {
    segment: AttributeOption[]
    breakdown: AttributeOption[]
    filter: AttributeOption[]
  }
}
