import React, { ReactElement, ReactNode } from 'react'
import { Tab as MUITab } from '@mui/material'

import Box from 'components_new/atoms/Box'

interface TabProps {
  disabled?: boolean
  endIcon?: ReactElement
  label?: string | ReactNode
  startIcon?: ReactNode
  sx?: object
}

const Tab = React.forwardRef((props: TabProps, ref: any) => {
  const { endIcon, label, startIcon, ...rest } = props

  return (
    <MUITab
      icon={endIcon}
      iconPosition={endIcon ? 'end' : undefined}
      label={
        <>
          {startIcon ? (
            <Box
              sx={{
                mr: '6px'
              }}
            >
              {startIcon}
            </Box>
          ) : null}
          {label}
        </>
      }
      {...rest}
      ref={ref}
    />
  )
})

Tab.displayName = 'Tab'
export default Tab
