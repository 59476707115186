import { TInputOption } from 'components_new/molecules/SettingsEditDialog/utils'
import { AttributeOption } from 'types/GlobalAttributeOptions'
import { WidgetObject } from 'types/GlobalWidget'

export const getOptions = (
  widget: WidgetObject,
  segmentAttributeOptions: AttributeOption[]
): TInputOption[] => {
  const selectedSegments = [
    widget.settings.segment_by.selected,
    ...widget.settings.segment_by.additional.map(
      (item) => item.attribute_option_id
    )
  ]

  return segmentAttributeOptions.map((option) => {
    return {
      disabled: option.disabled || selectedSegments.includes(option.id),
      label: option.name,
      value: option.id
    }
  })
}
