'use client'
import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { cloneDeep } from 'lodash'
import { useLocation } from 'react-router-dom'

import { ApplicationState } from 'redux/Stores/types'

import * as StatusBannerActions from 'redux/actions/StatusBanner'

import {
  getOneAdditionalSegmentApiAccess,
  getOneApiAccess,
  getOneTotalApiAccess
} from 'redux/api/Widgets'
import { GetOneWidget, WidgetObject } from 'types/GlobalWidget'

import PublicWidgetTemplate, {
  ErrorCode
} from 'components_new/templates/PublicWidgetTemplate'
import LoadingSection from 'components_new/molecules/LoadingSection'
import Box from 'components_new/atoms/Box'

import { STATUS_BANNER_TIMER } from 'utils/constants'
import { mergeAdditionalSegments } from 'redux/actions/Widgets'

const mapStateToProps = (state: ApplicationState) => ({
  StatusBannerStore: state.StatusBannerStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...StatusBannerActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

// If more props should be used, extend prop with connectedProps
type ApiAccessWidgetPageProps = ConnectedProps<typeof connector>

const ApiAccessWidgetPage: FC<ApiAccessWidgetPageProps> = (
  props: ApiAccessWidgetPageProps
) => {
  const { StatusBannerStore } = props
  const params = useParams<{ id: string }>()
  const id = params.id

  const [widget, setWidget] = useState<WidgetObject | null>(null)
  const [errorCode, setErrorCode] = useState<ErrorCode>(null)
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token') as string

  useEffect(() => {
    getOneApiAccess(id, token, null)
      .then<GetOneWidget>(async (response) => {
        let data = cloneDeep(response.data.data)

        const totalPromise = data.settings.show_total
          ? getOneTotalApiAccess(id, token, null)
          : null

        const additionalsPromise = Promise.all(
          data.settings.segment_by.additional.map((item) =>
            getOneAdditionalSegmentApiAccess(id, token, item.id, null)
          )
        )

        const [responseTotal, responseAdditionals] = await Promise.all([
          totalPromise,
          additionalsPromise
        ])

        if (responseTotal) {
          data.data.totals = responseTotal.data.data
        }

        if (responseAdditionals.length > 0) {
          data = mergeAdditionalSegments(data, responseAdditionals)
        }

        return data
      })
      .then((data) => setWidget(data))
      .catch((e) => {
        if (e.message.includes('401') || e.message.includes('404')) {
          setErrorCode(404)
        } else if (e.message.includes('500')) {
          setErrorCode(500)
        }
      })
  }, [])

  useEffect(() => {
    if (widget?.customer_id) {
      props.tryGetStatusBanner(widget.customer_id, true)

      const interval = setInterval(
        () => props.tryGetStatusBanner(widget.customer_id as string, true),
        STATUS_BANNER_TIMER
      )

      return () => clearInterval(interval)
    }

    return () => {}
  }, [widget?.customer_id])

  return widget ? (
    <PublicWidgetTemplate
      widget={widget}
      errorCode={errorCode}
      statusBanner={StatusBannerStore.data}
    />
  ) : (
    <Box
      sx={{
        height: '100vh',
        width: '100vw'
      }}
    >
      <LoadingSection loading={true} titles={['Laddar']} />
    </Box>
  )
}

export default connector(ApiAccessWidgetPage)
