import React, { useState } from 'react'

import { useParams } from 'react-router-dom'

import { useDashboard, useDashboardGroup } from 'redux/hooks/Dashboards'

import { DashboardStatus, PlatformDashboard } from 'redux/reducers/Dashboards'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Collapse from 'components_new/atoms/Collapse'
import Text from 'components_new/atoms/Text'
import Icon from 'components_new/atoms/Icon'

import AboutDashboardDialog from 'components_new/organisms/dialogs/AboutDashboardDialog'

interface PublishBarProps {
  editMode: boolean
  sx?: object
}

const PublishBar = (props: PublishBarProps) => {
  const { editMode, sx } = props

  const params = useParams<{ id: string }>()
  const dashboard = useDashboard<PlatformDashboard>(params.id)
  const group = useDashboardGroup(params.id)

  const [dialog, setDialog] = useState<boolean>(false)

  // loading
  if (!dashboard || !group) {
    return null
  }

  // success
  return (
    <Collapse
      in={group.status === DashboardStatus.DRAFT && !editMode}
      unmountOnExit={true}
    >
      <Box
        sx={{
          bgcolor: 'info.background',
          py: 0.5,
          pl: 2,
          pr: 4,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...sx
        }}
      >
        <Text color="info.contastText">
          <b>Opublicerad.</b> Den här dashboarden visas inte för användare.
        </Text>
        <Button
          color="primary"
          onClick={() => setDialog(true)}
          size="small"
          startIcon={<Icon name="PublishedWithChangesOutlined" />}
          variant="text"
        >
          Publicera
        </Button>
      </Box>

      <AboutDashboardDialog
        dashboardGroup={group}
        onClose={() => setDialog(false)}
        open={dialog}
        variant="publish"
      />
    </Collapse>
  )
}

export default PublishBar
