import React, { ReactNode, useState } from 'react'

import { Link } from 'react-router-dom'

import Box from 'components_new/atoms/Box'
import Card from 'components_new/atoms/Card'
import CardMedia from 'components_new/atoms/Card/CardMedia'
import CardContent from 'components_new/atoms/Card/CardContent'
import CardActionArea from 'components_new/atoms/Card/CardActionArea'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Stack from 'components_new/atoms/Stack'
import Tooltip from 'components_new/atoms/Tooltip'

import AvatarGroup from 'components_new/molecules/AvatarGroup'
import OverflowText from 'components_new/molecules/OverflowText'

import EditDashboardMenu from 'components_new/organisms/EditDashboardMenu'

import { parseElapsedTimeSince } from 'helpers/Functions/Date'
import { DashboardGroup, DashboardStatus } from 'redux/reducers/Dashboards'
import { DashboardFolder } from 'types/GlobalDashboardFolder'
import { AuthenticationState } from 'types/GlobalAuthentication'
import { AccountRole } from 'types/GlobalUser'

/**
 * The DashboardThumbnail component is used
 * to display a short summary of a dashboard
 * and let a user navigate to it.
 */

interface DashboardThumbnailProps {
  AuthStore: AuthenticationState
  draggable?: boolean
  folders?: DashboardFolder[]
  group: DashboardGroup
  href: string
  mediaAdornments?: ReactNode
  toggleFavorite?: () => void
  users: string[]
  userIsCustomer?: boolean
  variant: 'dashboard'
  src: string | null
  onClick?: () => void
}

const imageUrl: Record<string, string> = {
  dashboard: '/assets/media/dashboard_thumbnail.svg'
}

const DashboardThumbnail = (props: DashboardThumbnailProps) => {
  const {
    AuthStore,
    draggable,
    folders = [],
    group,
    href,
    mediaAdornments,
    onClick,
    toggleFavorite,
    users,
    userIsCustomer,
    variant,
    src
  } = props

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLAnchorElement | null>(
    null
  )

  const [hover, setHover] = useState(false)
  const [drag, setDrag] = useState(false)

  const handleOpenMenu = (event: any) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setMenuAnchorEl(null)
  }

  const user = AuthStore.user
  const ownerId = group?.owner

  const isAdmin = user?.role === AccountRole.ADMIN
  const isHomepal = Boolean(user?.is_homepal_user)
  const isOwner = ownerId === user?.id

  const editable = isAdmin || isHomepal || isOwner

  return (
    <>
      <Box
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onMouseDown={() => setDrag(true)}
        onMouseUp={() => setDrag(false)}
      >
        <Card
          variant="outlined"
          sx={{
            position: 'relative',
            borderColor: hover ? 'primary.main' : undefined,
            borderWidth: hover ? '1px' : undefined,
            '.MuiCardActionArea-focusHighlight': {
              background: 'transparent'
            },
            bgcolor: 'grey.100'
          }}
        >
          {hover && draggable ? (
            <Tooltip title={!drag ? 'Klicka och dra för att flytta' : ''}>
              <Box
                sx={{
                  height: 12,
                  width: '100%',
                  top: 0,
                  left: 0,
                  bgcolor: 'primary.main',
                  color: 'primary.light',
                  position: 'absolute',
                  zIndex: 100,
                  cursor: 'grab'
                }}
              >
                <Icon
                  name="DragHandle"
                  color="inherit"
                  sx={{
                    position: 'absolute',
                    top: -7.5,
                    left: 0,
                    right: 0,
                    margin: '0 auto'
                  }}
                />
              </Box>
            </Tooltip>
          ) : null}
          <CardActionArea
            component={Link}
            to={href}
            onClick={() => {
              if (onClick) {
                onClick()
              }
            }}
          >
            <CardMedia
              image={src ?? imageUrl[variant]}
              sx={{
                m: 1,
                height: '140px',
                borderRadius: 1,
                overflow: 'hidden',
                bgcolor: 'black.transparent',
                position: 'relative'
              }}
            >
              <Stack sx={{ position: 'absolute', left: 4, top: 6 }}>
                {group.favorite.active !== undefined &&
                toggleFavorite !== undefined &&
                (group.favorite.active || hover) ? (
                      <Tooltip
                        title={
                          group.status !== DashboardStatus.PUBLISHED
                            ? 'Utkast kan inte favoritmarkeras'
                            : group.favorite.active
                              ? 'Ta bort från favoriter'
                              : 'Lägg till i favoriter'
                        }
                      >
                        <Box
                          sx={{
                            '&:hover': {
                              transform: 'translateY(-2px)',
                              transition: 'transform 0.5s'
                            }
                          }}
                        >
                          <IconButton
                            color="default"
                            onClick={(event) => {
                              toggleFavorite()
                              event.stopPropagation()
                              event.preventDefault()
                            }}
                            onMouseDown={(event) => event.stopPropagation()}
                            size="small"
                            sx={{
                              cursor: 'default'
                            }}
                            variant="contained"
                            // eslint-disable-next-line max-len
                            disabled={group.status !== DashboardStatus.PUBLISHED}
                          >
                            <Icon
                              name="Star"
                              fontSize="small"
                              color={group.favorite.active ? 'primary' : 'inherit'}
                            />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    ) : null}
              </Stack>
              <Stack sx={{ position: 'absolute', right: 4, top: 6 }}>
                {mediaAdornments ?? null}
                {users && !userIsCustomer ? (
                  <AvatarGroup sx={{ ml: 0.5 }} users={users} />
                ) : null}
              </Stack>
            </CardMedia>
            <CardContent
              sx={{
                px: 2,
                py: 1,
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  minWidth: 0,
                  transform: hover ? 'translateY(0)' : 'translateY(8px)',
                  transition: 'transform ease 200ms'
                }}
              >
                <OverflowText variant="subtitle2" color="text.primary">
                  {group.title}
                </OverflowText>
                <OverflowText
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    opacity: hover ? 1 : 0,
                    transition: 'opacity ease 200ms'
                  }}
                >
                  {`Ändrades för ${parseElapsedTimeSince(
                    group.updated_at
                  )} sedan`}
                </OverflowText>
              </Box>
              {editable ? (
                <Box
                  sx={{
                    flexGrow: 1,
                    minWidth: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
                  <IconButton
                    edge="end"
                    onClick={(e) => {
                      handleOpenMenu(e)

                      e.stopPropagation()
                      e.preventDefault()
                    }}
                    size="small"
                  >
                    <Icon fontSize="small" name="MoreVertOutlined" />
                  </IconButton>
                </Box>
              ) : null}
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>

      <EditDashboardMenu
        folders={folders}
        group={group}
        handleClose={handleCloseMenu}
        menuAnchorEl={menuAnchorEl}
      />
    </>
  )
}

export default DashboardThumbnail
