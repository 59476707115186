import React, { MouseEvent, ReactNode, useState } from 'react'

import { Popover } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Menu from 'components_new/atoms/Menu/'
import MenuItem, { MenuItemProps } from 'components_new/atoms/Menu/MenuItem'

import IconTip from 'components_new/molecules/IconTip'
import ListSubheader from 'components_new/atoms/List/ListSubheader'

interface NestedOptionsMenuItemProps extends Omit<MenuItemProps, 'onClick'> {
  closeParentMenu: (event: MouseEvent<HTMLElement>) => void
  icon?: ReactNode
  infoTip?: ReactNode
  MenuSx?: object
  nestedAnchor?: 'left' | 'right'
  nestedTitle?: string
  parentMenuOpen: boolean
  title: string
  variant: 'content' | 'menu'
}

const NestedOptionsMenuItem = React.forwardRef(
  (props: NestedOptionsMenuItemProps, ref: any) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: MouseEvent<HTMLElement>) => {
      if (props.disabled) return

      if (anchorEl) {
        handleClose(event)
      } else {
        handleOpen(event)
      }
    }

    const handleClose = (event: MouseEvent<HTMLElement>) => {
      if (props.disabled) return

      event.stopPropagation()
      setAnchorEl(null)
    }

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
      if (props.disabled) return

      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    }

    return (
      <Box
        ref={ref}
        onClick={handleClick}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      >
        <MenuItem
          disabled={props.disabled}
          onClick={(e) => {
            handleOpen(e)
            e.stopPropagation()
          }}
        >
          {props.icon ? <ListItemIcon>{props.icon}</ListItemIcon> : null}
          <ListItemText primary={props.title} />
          <ListItemIcon position="end">
            <Icon name="ArrowRightOutlined" />
            {props.infoTip ? (
              <IconTip
                edge="end"
                placement="right"
                sx={{ ml: 1, pointerEvents: 'all!important' }}
                title={props.infoTip}
              />
            ) : null}
          </ListItemIcon>
        </MenuItem>
        {
          /*-- content --*/
          props.variant === 'content' ? (
            <Popover
              anchorEl={anchorEl}
              anchorOrigin={
                props.nestedAnchor === 'left'
                  ? {
                      vertical: 'top',
                      horizontal: 'left'
                    }
                  : {
                      vertical: 'top',
                      horizontal: 'right'
                    }
              }
              disableScrollLock
              onMouseLeave={handleClose}
              onClose={handleClose}
              onMouseDown={(e) => e.stopPropagation()}
              onKeyDown={(e) => {
                e.stopPropagation()
              }}
              open={open && props.parentMenuOpen}
              PaperProps={{
                sx: {
                  minWidth: 256,
                  py: 1,
                  ...(props.nestedTitle
                    ? {
                        '& .MuiList-root': {
                          pt: 0
                        }
                      }
                    : null)
                }
              }}
              sx={{ pointerEvents: 'none', ...props.MenuSx }}
              transformOrigin={
                props.nestedAnchor === 'left'
                  ? {
                      vertical: 'top',
                      horizontal: 'right'
                    }
                  : {
                      vertical: 'top',
                      horizontal: 'left'
                    }
              }
            >
              {props.nestedTitle ? (
                <ListSubheader border={true}>{props.nestedTitle}</ListSubheader>
              ) : null}
              <Box sx={{ pointerEvents: 'auto' }}>{props.children}</Box>
            </Popover>
          ) : (
            /*-- menu --*/
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={
                props.nestedAnchor === 'left'
                  ? {
                      vertical: 'top',
                      horizontal: 'left'
                    }
                  : {
                      vertical: 'top',
                      horizontal: 'right'
                    }
              }
              disableScrollLock
              MenuListProps={{ onMouseLeave: handleClose }}
              onClose={handleClose}
              onMouseDown={(e) => e.stopPropagation()}
              open={open && props.parentMenuOpen}
              PaperProps={{
                sx: {
                  maxHeight: 304,
                  minWidth: 256,
                  ...(props.nestedTitle
                    ? {
                        '& .MuiList-root': {
                          pt: 0
                        }
                      }
                    : null)
                }
              }}
              sx={{ pointerEvents: 'none', ...props.MenuSx }}
              transformOrigin={
                props.nestedAnchor === 'left'
                  ? {
                      vertical: 'top',
                      horizontal: 'right'
                    }
                  : {
                      vertical: 'top',
                      horizontal: 'left'
                    }
              }
            >
              <Box sx={{ pointerEvents: 'auto' }}>
                {props.nestedTitle ? (
                  <ListSubheader border={true}>
                    {props.nestedTitle}
                  </ListSubheader>
                ) : null}
                {props.children}
              </Box>
            </Menu>
          )
        }
      </Box>
    )
  }
)

NestedOptionsMenuItem.displayName = 'NestedOptionsMenuItem'
export default NestedOptionsMenuItem
