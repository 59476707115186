import { lazy } from 'react'

export const icons = {
  AccountBalance: lazy(() => import('@mui/icons-material/AccountBalance')),
  AccountTreeOutlined: lazy(
    () => import('@mui/icons-material/AccountTreeOutlined')
  ),
  Add: lazy(() => import('@mui/icons-material/Add')),
  AddBusiness: lazy(() => import('@mui/icons-material/AddBusiness')),
  AddchartOutlined: lazy(() => import('@mui/icons-material/AddchartOutlined')),
  AddCircleOutlineOutlined: lazy(
    () => import('@mui/icons-material/AddCircleOutlineOutlined')
  ),
  AddOutlined: lazy(() => import('@mui/icons-material/AddOutlined')),
  AlignVerticalBottomOutlined: lazy(
    () => import('@mui/icons-material/AlignVerticalBottomOutlined')
  ),
  AlternateEmail: lazy(() => import('@mui/icons-material/AlternateEmail')),
  AnalyticsOutlined: lazy(
    () => import('@mui/icons-material/AnalyticsOutlined')
  ),
  ApartmentOutlined: lazy(
    () => import('@mui/icons-material/ApartmentOutlined')
  ),
  ApiOutlined: lazy(() => import('@mui/icons-material/ApiOutlined')),
  ArrowBack: lazy(() => import('@mui/icons-material/ArrowBack')),
  ArrowBackIos: lazy(() => import('@mui/icons-material/ArrowBackIos')),
  ArrowBackOutlined: lazy(
    () => import('@mui/icons-material/ArrowBackOutlined')
  ),
  ArrowDownward: lazy(() => import('@mui/icons-material/ArrowDownward')),
  ArrowDownwardOutlined: lazy(
    () => import('@mui/icons-material/ArrowDownwardOutlined')
  ),
  ArrowDropDown: lazy(() => import('@mui/icons-material/ArrowDropDown')),
  ArrowDropDownOutlined: lazy(
    () => import('@mui/icons-material/ArrowDropDownOutlined')
  ),
  ArrowRightOutlined: lazy(
    () => import('@mui/icons-material/ArrowRightOutlined')
  ),
  ArrowForward: lazy(() => import('@mui/icons-material/ArrowForward')),
  ArrowForwardIos: lazy(() => import('@mui/icons-material/ArrowForwardIos')),
  ArrowForwardOutlined: lazy(
    () => import('@mui/icons-material/ArrowForwardOutlined')
  ),
  ArrowUpwardOutlined: lazy(
    () => import('@mui/icons-material/ArrowUpwardOutlined')
  ),
  AssignmentIndOutlined: lazy(
    () => import('@mui/icons-material/AssignmentIndOutlined')
  ),
  AttachmentOutlined: lazy(
    () => import('@mui/icons-material/AttachmentOutlined')
  ),
  AutoAwesomeTwoTone: lazy(
    () => import('@mui/icons-material/AutoAwesomeTwoTone')
  ),
  AutoFixHighOutlined: lazy(
    () => import('@mui/icons-material/AutoFixHighOutlined')
  ),
  AutoGraphTwoTone: lazy(() => import('@mui/icons-material/AutoGraphTwoTone')),
  BarChartOutlined: lazy(() => import('@mui/icons-material/BarChartOutlined')),
  BorderBottomOutlined: lazy(
    () => import('@mui/icons-material/BorderBottomOutlined')
  ),
  BorderLeftOutlined: lazy(
    () => import('@mui/icons-material/BorderLeftOutlined')
  ),
  BarChart: lazy(() => import('@mui/icons-material/BarChart')),
  BarChartTwoTone: lazy(() => import('@mui/icons-material/BarChartTwoTone')),
  BubbleChartOutlined: lazy(
    () => import('@mui/icons-material/BubbleChartOutlined')
  ),
  Business: lazy(() => import('@mui/icons-material/Business')),
  BusinessOutlined: lazy(() => import('@mui/icons-material/BusinessOutlined')),
  CalendarMonthOutlined: lazy(
    () => import('@mui/icons-material/CalendarMonthOutlined')
  ),
  CalendarViewWeekOutlined: lazy(
    () => import('@mui/icons-material/CalendarViewWeekOutlined')
  ),
  CampaignTwoTone: lazy(() => import('@mui/icons-material/CampaignTwoTone')),
  Cancel: lazy(() => import('@mui/icons-material/Cancel')),
  CancelTwoTone: lazy(() => import('@mui/icons-material/CancelTwoTone')),
  Check: lazy(() => import('@mui/icons-material/Check')),
  CheckBox: lazy(() => import('@mui/icons-material/CheckBox')),
  CheckBoxOutlineBlank: lazy(
    () => import('@mui/icons-material/CheckBoxOutlineBlank')
  ),
  CheckBoxOutlineBlankOutlined: lazy(
    () => import('@mui/icons-material/CheckBoxOutlineBlankOutlined')
  ),
  CheckBoxOutlined: lazy(() => import('@mui/icons-material/CheckBoxOutlined')),
  CheckCircle: lazy(() => import('@mui/icons-material/CheckCircle')),
  CheckCircleOutline: lazy(
    () => import('@mui/icons-material/CheckCircleOutline')
  ),
  CheckCircleOutlined: lazy(
    () => import('@mui/icons-material/CheckCircleOutlined')
  ),
  CheckCircleTwoTone: lazy(
    () => import('@mui/icons-material/CheckCircleTwoTone')
  ),
  ChevronLeft: lazy(() => import('@mui/icons-material/ChevronLeft')),
  ChevronRight: lazy(() => import('@mui/icons-material/ChevronRight')),
  ChevronRightOutlined: lazy(
    () => import('@mui/icons-material/ChevronRightOutlined')
  ),
  ChevronRightTwoTone: lazy(
    () => import('@mui/icons-material/ChevronRightTwoTone')
  ),
  Clear: lazy(() => import('@mui/icons-material/Clear')),
  ClearOutlined: lazy(() => import('@mui/icons-material/ClearOutlined')),
  Close: lazy(() => import('@mui/icons-material/Close')),
  CloseOutlined: lazy(() => import('@mui/icons-material/CloseOutlined')),
  CloudSyncOutlined: lazy(
    () => import('@mui/icons-material/CloudSyncOutlined')
  ),
  CloudUploadOutlined: lazy(
    () => import('@mui/icons-material/CloudUploadOutlined')
  ),
  CodeOutlined: lazy(() => import('@mui/icons-material/CodeOutlined')),
  Construction: lazy(() => import('@mui/icons-material/Construction')),
  ContentCopy: lazy(() => import('@mui/icons-material/ContentCopy')),
  ContentCopyOutlined: lazy(
    () => import('@mui/icons-material/ContentCopyOutlined')
  ),
  CopyAllOutlined: lazy(() => import('@mui/icons-material/CopyAllOutlined')),
  CottageOutlined: lazy(() => import('@mui/icons-material/CopyAllOutlined')),
  CreateNewFolderOutlined: lazy(
    () => import('@mui/icons-material/CreateNewFolderOutlined')
  ),
  CrisisAlertTwoTone: lazy(
    () => import('@mui/icons-material/CrisisAlertTwoTone')
  ),
  CropFreeOutlined: lazy(() => import('@mui/icons-material/CropFreeOutlined')),
  DashboardCustomizeOutlined: lazy(
    () => import('@mui/icons-material/DashboardCustomizeOutlined')
  ),
  DateRange: lazy(() => import('@mui/icons-material/DateRange')),
  DeleteOutline: lazy(() => import('@mui/icons-material/DeleteOutline')),
  DeleteOutlined: lazy(() => import('@mui/icons-material/DeleteOutlined')),
  DesignServicesOutlined: lazy(
    () => import('@mui/icons-material/DesignServicesOutlined')
  ),
  Diversity1TwoTone: lazy(
    () => import('@mui/icons-material/Diversity1TwoTone')
  ),
  DomainOutlined: lazy(() => import('@mui/icons-material/DomainOutlined')),
  Download: lazy(() => import('@mui/icons-material/Download')),
  DragHandle: lazy(() => import('@mui/icons-material/DragHandle')),
  DragHandleOutlined: lazy(
    () => import('@mui/icons-material/DragHandleOutlined')
  ),
  DragIndicatorOutlined: lazy(
    () => import('@mui/icons-material/DragIndicatorOutlined')
  ),
  DriveFileMoveOutlined: lazy(
    () => import('@mui/icons-material/DriveFileMoveOutlined')
  ),
  DriveFileRenameOutline: lazy(
    () => import('@mui/icons-material/DriveFileRenameOutline')
  ),
  East: lazy(() => import('@mui/icons-material/East')),
  Edit: lazy(() => import('@mui/icons-material/Edit')),
  EditTwoTone: lazy(() => import('@mui/icons-material/EditTwoTone')),
  EditCalendarOutlined: lazy(
    () => import('@mui/icons-material/EditCalendarOutlined')
  ),
  EditOffOutlined: lazy(() => import('@mui/icons-material/EditOffOutlined')),
  EditOutlined: lazy(() => import('@mui/icons-material/EditOutlined')),
  EmailOutlined: lazy(() => import('@mui/icons-material/EmailOutlined')),
  EmergencyRecordingOutlined: lazy(
    () => import('@mui/icons-material/EmergencyRecordingOutlined')
  ),
  EmojiEventsOutlined: lazy(
    () => import('@mui/icons-material/EmojiEventsOutlined')
  ),
  EngineeringOutlined: lazy(
    () => import('@mui/icons-material/EngineeringOutlined')
  ),
  EnergySavingsLeaf: lazy(
    () => import('@mui/icons-material/EnergySavingsLeaf')
  ),
  Error: lazy(() => import('@mui/icons-material/Error')),
  ErrorOutline: lazy(() => import('@mui/icons-material/ErrorOutline')),
  ErrorOutlined: lazy(() => import('@mui/icons-material/ErrorOutlined')),
  ErrorOutlineOutlined: lazy(
    () => import('@mui/icons-material/ErrorOutlineOutlined')
  ),
  EventNoteOutlined: lazy(
    () => import('@mui/icons-material/EventNoteOutlined')
  ),
  EventRepeatOutlined: lazy(
    () => import('@mui/icons-material/EventRepeatOutlined')
  ),
  ExpandLess: lazy(() => import('@mui/icons-material/ExpandLess')),
  ExpandLessOutlined: lazy(
    () => import('@mui/icons-material/ExpandLessOutlined')
  ),
  ExpandMore: lazy(() => import('@mui/icons-material/ExpandMore')),
  FeedbackOutlined: lazy(() => import('@mui/icons-material/FeedbackOutlined')),
  FileCopyOutlined: lazy(() => import('@mui/icons-material/FileCopyOutlined')),
  FileDownloadOutlined: lazy(
    () => import('@mui/icons-material/FileDownloadOutlined')
  ),
  FileDownload: lazy(() => import('@mui/icons-material/FileDownload')),
  Filter1Outlined: lazy(() => import('@mui/icons-material/Filter1Outlined')),
  FilterAlt: lazy(() => import('@mui/icons-material/FilterAlt')),
  FilterAltOutlined: lazy(
    () => import('@mui/icons-material/FilterAltOutlined')
  ),
  FolderOutlined: lazy(() => import('@mui/icons-material/FolderOutlined')),
  FormatBoldOutlined: lazy(
    () => import('@mui/icons-material/FormatBoldOutlined')
  ),
  FormatItalicOutlined: lazy(
    () => import('@mui/icons-material/FormatItalicOutlined')
  ),
  FormatListBulletedOutlined: lazy(
    () => import('@mui/icons-material/FormatListBulletedOutlined')
  ),
  FormatListNumberedOutlined: lazy(
    () => import('@mui/icons-material/FormatListNumberedOutlined')
  ),
  FormatUnderlinedOutlined: lazy(
    () => import('@mui/icons-material/FormatUnderlinedOutlined')
  ),
  GridViewOutlined: lazy(() => import('@mui/icons-material/GridViewOutlined')),
  GroupAddOutlined: lazy(() => import('@mui/icons-material/GroupAddOutlined')),
  GroupOutlined: lazy(() => import('@mui/icons-material/GroupOutlined')),
  HeartBrokenTwoTone: lazy(
    () => import('@mui/icons-material/HeartBrokenTwoTone')
  ),
  HelpOutline: lazy(() => import('@mui/icons-material/HelpOutline')),
  HelpOutlined: lazy(() => import('@mui/icons-material/HelpOutlined')),
  HelpOutlineOutlined: lazy(
    () => import('@mui/icons-material/HelpOutlineOutlined')
  ),
  History: lazy(() => import('@mui/icons-material/History')),
  HistoryOutlined: lazy(() => import('@mui/icons-material/History')),
  HomeRounded: lazy(() => import('@mui/icons-material/HomeRounded')),
  HvacOutlined: lazy(() => import('@mui/icons-material/HvacOutlined')),
  HouseSidingOutlined: lazy(
    () => import('@mui/icons-material/HouseSidingOutlined')
  ),
  Image: lazy(() => import('@mui/icons-material/Image')),
  ImageOutlined: lazy(() => import('@mui/icons-material/ImageOutlined')),
  ImportExportOutlined: lazy(
    () => import('@mui/icons-material/ImportExportOutlined')
  ),
  InboxTwoTone: lazy(() => import('@mui/icons-material/InboxTwoTone')),
  InfoOutlined: lazy(() => import('@mui/icons-material/InfoOutlined')),
  KeyboardArrowDown: lazy(
    () => import('@mui/icons-material/KeyboardArrowDown')
  ),
  KeyboardArrowLeft: lazy(
    () => import('@mui/icons-material/KeyboardArrowLeft')
  ),
  KeyboardArrowRight: lazy(
    () => import('@mui/icons-material/KeyboardArrowRight')
  ),
  KeyboardArrowUp: lazy(() => import('@mui/icons-material/KeyboardArrowUp')),
  LanTwoTone: lazy(() => import('@mui/icons-material/LanTwoTone')),
  Link: lazy(() => import('@mui/icons-material/Link')),
  LinkedIn: lazy(() => import('@mui/icons-material/LinkedIn')),
  LinkOffOutlined: lazy(() => import('@mui/icons-material/LinkOffOutlined')),
  LinkOutlined: lazy(() => import('@mui/icons-material/LinkOutlined')),
  ListOutlined: lazy(() => import('@mui/icons-material/ListOutlined')),
  LivingOutlined: lazy(() => import('@mui/icons-material/LivingOutlined')),
  Lock: lazy(() => import('@mui/icons-material/Lock')),
  LockOpenOutlined: lazy(() => import('@mui/icons-material/LockOpenOutlined')),
  LockOutlined: lazy(() => import('@mui/icons-material/LockOutlined')),
  LockPersonOutlined: lazy(
    () => import('@mui/icons-material/LockPersonOutlined')
  ),
  LocalPoliceOutlined: lazy(
    () => import('@mui/icons-material/LocalPoliceOutlined')
  ),
  LogoutTwoTone: lazy(() => import('@mui/icons-material/LogoutTwoTone')),
  LooksOneOutlined: lazy(() => import('@mui/icons-material/LooksOneOutlined')),
  LowPriorityOutlined: lazy(
    () => import('@mui/icons-material/LowPriorityOutlined')
  ),
  MailOutlined: lazy(() => import('@mui/icons-material/MailOutlined')),
  ManageAccountsOutlined: lazy(
    () => import('@mui/icons-material/ManageAccountsOutlined')
  ),
  MapOutlined: lazy(() => import('@mui/icons-material/MapOutlined')),
  Microsoft: lazy(() => import('@mui/icons-material/Microsoft')),
  MobileFriendlyOutlined: lazy(
    () => import('@mui/icons-material/MobileFriendlyOutlined')
  ),
  MobileOffOutlined: lazy(
    () => import('@mui/icons-material/MobileOffOutlined')
  ),
  ModeEditOutlineOutlined: lazy(
    () => import('@mui/icons-material/ModeEditOutlineOutlined')
  ),
  MoreHoriz: lazy(() => import('@mui/icons-material/MoreHoriz')),
  MoreHorizOutlined: lazy(
    () => import('@mui/icons-material/MoreHorizOutlined')
  ),
  MoreVert: lazy(() => import('@mui/icons-material/MoreVert')),
  MoreVertOutlined: lazy(() => import('@mui/icons-material/MoreVertOutlined')),
  MoveDownOutlined: lazy(() => import('@mui/icons-material/MoveDownOutlined')),
  MoveUpOutlined: lazy(() => import('@mui/icons-material/MoveUpOutlined')),
  NavigateBeforeOutlined: lazy(
    () => import('@mui/icons-material/NavigateBeforeOutlined')
  ),
  NavigateNextOutlined: lazy(
    () => import('@mui/icons-material/NavigateNextOutlined')
  ),
  NotificationsActiveOutlined: lazy(
    () => import('@mui/icons-material/NotificationsActiveOutlined')
  ),
  NotificationAddOutlined: lazy(
    () => import('@mui/icons-material/NotificationAddOutlined')
  ),
  NotificationsNoneOutlined: lazy(
    () => import('@mui/icons-material/NotificationsNoneOutlined')
  ),
  NotificationsOffOutlined: lazy(
    () => import('@mui/icons-material/NotificationsOffOutlined')
  ),
  NewReleasesTwoTone: lazy(
    () => import('@mui/icons-material/NewReleasesTwoTone')
  ),
  NorthEast: lazy(() => import('@mui/icons-material/NorthEast')),
  NumbersOutlined: lazy(() => import('@mui/icons-material/NumbersOutlined')),
  OneKOutlined: lazy(() => import('@mui/icons-material/OneKOutlined')),
  OpenInNew: lazy(() => import('@mui/icons-material/OpenInNew')),
  OpenInNewOutlined: lazy(
    () => import('@mui/icons-material/OpenInNewOutlined')
  ),
  PaidOutlined: lazy(() => import('@mui/icons-material/PaidOutlined')),
  PaletteOutlined: lazy(() => import('@mui/icons-material/PaletteOutlined')),
  PercentOutlined: lazy(() => import('@mui/icons-material/PercentOutlined')),
  PersonOutlineOutlined: lazy(
    () => import('@mui/icons-material/PersonOutlineOutlined')
  ),
  PhotoLibrary: lazy(() => import('@mui/icons-material/PhotoLibrary')),
  PhotoLibraryOutlined: lazy(
    () => import('@mui/icons-material/PhotoLibraryOutlined')
  ),
  PieChart: lazy(() => import('@mui/icons-material/PieChart')),
  PieChartOutlined: lazy(() => import('@mui/icons-material/PieChartOutlined')),
  PinDropOutlined: lazy(() => import('@mui/icons-material/PinDropOutlined')),
  PlayCircleFilledWhite: lazy(
    () => import('@mui/icons-material/PlayCircleFilledWhite')
  ),
  PrivacyTip: lazy(() => import('@mui/icons-material/PrivacyTip')),
  PublicOffOutlined: lazy(
    () => import('@mui/icons-material/PublicOffOutlined')
  ),
  PublicOutlined: lazy(() => import('@mui/icons-material/PublicOutlined')),
  PublishedWithChangesOutlined: lazy(
    () => import('@mui/icons-material/PublishedWithChangesOutlined')
  ),
  RemoveCircleOutlined: lazy(
    () => import('@mui/icons-material/RemoveCircleOutlined')
  ),
  RemoveCircleOutlineOutlined: lazy(
    () => import('@mui/icons-material/RemoveCircleOutlineOutlined')
  ),
  ReplyOutlined: lazy(() => import('@mui/icons-material/ReplyOutlined')),
  Report: lazy(() => import('@mui/icons-material/Report')),
  RestartAltOutlined: lazy(
    () => import('@mui/icons-material/RestartAltOutlined')
  ),
  ReviewsTwoTone: lazy(() => import('@mui/icons-material/ReviewsTwoTone')),
  SaveOutlined: lazy(() => import('@mui/icons-material/SaveOutlined')),
  Search: lazy(() => import('@mui/icons-material/Search')),
  SellOutlined: lazy(() => import('@mui/icons-material/SellOutlined')),
  SettingsOutlined: lazy(() => import('@mui/icons-material/SettingsOutlined')),
  ShoppingCartOutlined: lazy(
    () => import('@mui/icons-material/ShoppingCartOutlined')
  ),
  ShowChartOutlined: lazy(
    () => import('@mui/icons-material/ShowChartOutlined')
  ),
  ShowChartTwoTone: lazy(() => import('@mui/icons-material/ShowChartTwoTone')),
  SignalCellularAltOutlined: lazy(
    () => import('@mui/icons-material/SignalCellularAltOutlined')
  ),
  Square: lazy(() => import('@mui/icons-material/Square')),
  StackedBarChart: lazy(() => import('@mui/icons-material/StackedBarChart')),
  StackedBarChartOutlined: lazy(
    () => import('@mui/icons-material/StackedBarChartOutlined')
  ),
  Star: lazy(() => import('@mui/icons-material/Star')),
  StarOutlined: lazy(() => import('@mui/icons-material/StarOutlined')),
  StarBorderOutlined: lazy(
    () => import('@mui/icons-material/StarBorderOutlined')
  ),
  StickyNote2Outlined: lazy(
    () => import('@mui/icons-material/StickyNote2Outlined')
  ),
  StorageOutlined: lazy(() => import('@mui/icons-material/StorageOutlined')),
  StorageTwoTone: lazy(() => import('@mui/icons-material/StorageTwoTone')),
  SupportAgentOutlined: lazy(
    () => import('@mui/icons-material/SupportAgentOutlined')
  ),
  SwapHorizOutlined: lazy(
    () => import('@mui/icons-material/SwapHorizOutlined')
  ),
  TabUnselectedOutlined: lazy(
    () => import('@mui/icons-material/TabUnselectedOutlined')
  ),
  Tag: lazy(() => import('@mui/icons-material/Tag')),
  TableChartOutlined: lazy(
    () => import('@mui/icons-material/TableChartOutlined')
  ),
  TableRowsOutlined: lazy(
    () => import('@mui/icons-material/TableRowsOutlined')
  ),
  TableViewTwoTone: lazy(() => import('@mui/icons-material/TableViewTwoTone')),
  TextRotationNone: lazy(() => import('@mui/icons-material/TextRotationNone')),
  ThumbDownAltOutlined: lazy(
    () => import('@mui/icons-material/ThumbDownAltOutlined')
  ),
  TipsAndUpdatesOutlined: lazy(
    () => import('@mui/icons-material/TipsAndUpdatesOutlined')
  ),
  TitleOutlined: lazy(() => import('@mui/icons-material/TitleOutlined')),
  TravelExploreOutlined: lazy(
    () => import('@mui/icons-material/TravelExploreOutlined')
  ),
  TrendingUpOutlined: lazy(
    () => import('@mui/icons-material/TrendingUpOutlined')
  ),
  ThumbUpAltOutlined: lazy(
    () => import('@mui/icons-material/ThumbUpAltOutlined')
  ),
  TuneOutlined: lazy(() => import('@mui/icons-material/TuneOutlined')),
  UnfoldMore: lazy(() => import('@mui/icons-material/UnfoldMore')),
  UnpublishedOutlined: lazy(
    () => import('@mui/icons-material/UnpublishedOutlined')
  ),
  UpdateOutlined: lazy(() => import('@mui/icons-material/UpdateOutlined')),
  VerifiedOutlined: lazy(() => import('@mui/icons-material/VerifiedOutlined')),
  VerifiedUserOutlined: lazy(
    () => import('@mui/icons-material/VerifiedUserOutlined')
  ),
  VerticalAlignBottomOutlined: lazy(
    () => import('@mui/icons-material/VerticalAlignBottomOutlined')
  ),
  VerticalAlignTopOutlined: lazy(
    () => import('@mui/icons-material/VerticalAlignTopOutlined')
  ),
  VerticalSplitOutlined: lazy(
    () => import('@mui/icons-material/VerticalSplitOutlined')
  ),
  ViewCarouselOutlined: lazy(
    () => import('@mui/icons-material/ViewCarouselOutlined')
  ),
  ViewColumn: lazy(() => import('@mui/icons-material/ViewColumn')),
  ViewInArOutlined: lazy(() => import('@mui/icons-material/ViewInArOutlined')),
  ViewListTwoTone: lazy(() => import('@mui/icons-material/ViewListTwoTone')),
  Visibility: lazy(() => import('@mui/icons-material/Visibility')),
  VisibilityOutlined: lazy(
    () => import('@mui/icons-material/VisibilityOutlined')
  ),
  VisibilityOff: lazy(() => import('@mui/icons-material/VisibilityOff')),
  VisibilityOffOutlined: lazy(
    () => import('@mui/icons-material/VisibilityOffOutlined')
  ),
  VolunteerActivismOutlined: lazy(
    () => import('@mui/icons-material/VolunteerActivismOutlined')
  ),
  VpnKeyOutlined: lazy(() => import('@mui/icons-material/VpnKeyOutlined')),
  VpnLock: lazy(() => import('@mui/icons-material/VpnLock')),
  Warning: lazy(() => import('@mui/icons-material/Warning')),
  WarningAmberOutlined: lazy(
    () => import('@mui/icons-material/WarningAmberOutlined')
  ),
  WarningOutlined: lazy(() => import('@mui/icons-material/WarningOutlined')),
  WorkspacesTwoTone: lazy(
    () => import('@mui/icons-material/WorkspacesTwoTone')
  ),
  SyncAlt: lazy(() => import('@mui/icons-material/SyncAlt')),
  ZoomInOutlined: lazy(() => import('@mui/icons-material/ZoomInOutlined')),
  ZoomOutOutlined: lazy(() => import('@mui/icons-material/ZoomOutOutlined'))
}
