import React, { useState } from 'react'

import { cloneDeep } from 'lodash'

import { useHistory, useParams } from 'react-router-dom'

import { AccountRole } from 'types/GlobalUser'

import { ApplicationState } from 'redux/Stores/types'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import * as AccountActions from 'redux/actions/Accounts'
import * as CompanyGroupActions from 'redux/actions/CompanyGroups'
import * as DashboardActions from 'redux/actions/Dashboards'
import * as DashboardFavoriteActions from 'redux/actions/DashboardFavorites'
import * as DashboardFolderActions from 'redux/actions/DashboardFolders'
import * as DashboardGroupActions from 'redux/actions/DashboardGroups'
import * as KPITemplateActions from 'redux/actions/KPITemplates'
import * as WidgetActions from 'redux/actions/Widgets'

import { PlatformDashboard } from 'redux/reducers/Dashboards'

import { useDashboard, useDashboardGroup } from 'redux/hooks/Dashboards'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Tooltip from 'components_new/atoms/Tooltip'

import OptionsMenu from 'components_new/molecules/OptionsMenu'
import TabSwitch from 'components_new/molecules/TabSwitch'

import DeleteDialog from 'components_new/organisms/dialogs/DeleteDialog'
import EditTitleDialog from 'components_new/organisms/dialogs/EditTitleDialog'
import EmbedDashboardDialog from 'components_new/organisms/dialogs/EmbedDashboardDialog'

import CopyOrMoveDashboardDialog from './dialogs/CopyOrMoveDashboardDialog'

import Loading from './loading'
import { getDashboardTabs, TDialogType } from './utils'

import { Context } from 'types/GlobalUserEvents'
import { addRef } from 'utils/queryHandler'

const DashboardTabs = (props: ComponentProps) => {
  const {
    /*-- redux --*/
    AuthStore,
    KPIDashboardStore,
    tryCreateDashboard,
    tryDeleteDashboard,
    tryUpdateDashboard,
    tryUpdateDashboardOrder
  } = props

  const history = useHistory()

  const params = useParams<{ id: string }>()
  const dashboard = useDashboard<PlatformDashboard>(params.id)
  const group = useDashboardGroup(params.id)

  const user = AuthStore.user
  const ownerId = group?.owner

  const isAdmin = user?.role === AccountRole.ADMIN
  const isHomepal = Boolean(user?.is_homepal_user)
  const isOwner = ownerId === user?.id

  const editable = isAdmin || isHomepal || isOwner

  /*-- dialogs --*/
  const [dialog, setDialog] = useState<{
    type: TDialogType
    open: boolean
    id: string
  }>({
    type: 'delete_dashboard',
    open: false,
    id: params.id
  })

  const handleOpenDialog = (id: string, type: TDialogType) => {
    setDialog({
      type: type,
      open: true,
      id: id
    })
  }

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const handleOpenTab = (dashboardId: string) => {
    history.push(
      addRef(`/dashboards/${dashboardId}`, Context.NAVIGATE_TO_NEW_TAB)
    )
  }

  /*-- functions --*/
  const onAddTab = () => {
    if (!group) return

    tryCreateDashboard(
      {
        data: {
          dashboard_group_id: group.id
        }
      },
      (id) =>
        history.push(
          addRef(
            `/dashboards/${id}?new_dashboard=true`,
            Context.NAVIGATE_TO_NEW_TAB
          )
        )
    )
  }

  const onDuplicate = (id: string) => {
    tryCreateDashboard(
      {
        data: {
          dashboard_id: id
        }
      },
      (createdId) =>
        history.push(addRef(`/dashboards/${createdId}`, Context.DUPLICATE))
    )
  }

  const onMove = (id: string, dir: number) => {
    if (!group) return

    const currentIndex = group.dashboards.indexOf(id)
    const newOrder = cloneDeep(group.dashboards)

    newOrder.splice(currentIndex, 1)
    newOrder.splice(currentIndex + dir, 0, id)

    tryUpdateDashboardOrder(group.id, { data: newOrder })
  }

  const tabs = getDashboardTabs(
    params.id,
    editable,
    onMove,
    onDuplicate,
    handleOpenDialog
  )

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  if (
    !Boolean(AuthStore.user) ||
    !KPIDashboardStore.fetched ||
    !group ||
    !dashboard
  ) {
    return <Loading />
  }

  return (
    <>
      <Box sx={{ display: 'block' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/*-- show all --*/}
          <IconButton
            onClick={(event) => setAnchorEl(event.currentTarget)}
            size="small"
          >
            <Icon name="UnfoldMore" />
          </IconButton>

          <OptionsMenu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            onClose={() => setAnchorEl(null)}
            open={open}
            options={[
              ...tabs.map((tab) => {
                return {
                  disabled: tab.disabled,
                  onClick: () => handleOpenTab(tab.value),
                  title: tab.label as string
                }
              })
            ]}
          />

          {/*-- add --*/}
          {editable ? (
            <Box>
              <Tooltip title="Lägg till flik">
                <IconButton onClick={() => onAddTab()} size="small">
                  <Icon name="AddOutlined" />
                </IconButton>
              </Tooltip>
            </Box>
          ) : null}

          <TabSwitch
            color="primary"
            options={tabs}
            value={dashboard.id}
            onChange={handleOpenTab}
            noGutter={true}
            maxHeight="40px"
          />
        </Box>
      </Box>

      {/*-- dialogs --*/}

      {/*-- delete dashboard --*/}
      <DeleteDialog
        handleClose={handleCloseDialog}
        handleDelete={(callback: () => void) => {
          tryDeleteDashboard(dialog.id, () => {
            if (dialog.id === params.id) {
              // removing current dashboard --> navigate to first
              const nextDashboardId = group.dashboards.filter(
                (dashboardId) => dashboardId !== dialog.id
              )[0]

              history.push(
                addRef(
                  `/dashboards/${nextDashboardId}`,
                  Context.NAVIGATE_TO_NEW_TAB
                )
              )
            }
            handleCloseDialog()
            callback()
          })
        }}
        open={dialog.open && dialog.type === 'delete_dashboard'}
        title={tabs.find((item) => item.value === dialog.id)?.title}
        type="flik"
      />

      {/*-- edit dashboard title --*/}
      <EditTitleDialog
        onClose={handleCloseDialog}
        onSubmit={(value) => {
          tryUpdateDashboard(dialog.id, { data: { title: value } })
        }}
        open={dialog.open && dialog.type === 'edit_dashboard_title'}
        title={tabs.find((item) => item.value === dialog.id)?.title}
      />

      {/*-- copy or move dashboard --*/}
      <CopyOrMoveDashboardDialog
        dashboardId={dialog.id}
        onClose={handleCloseDialog}
        open={
          ['copy_dashboard', 'move_dashboard'].includes(dialog.type) &&
          dialog.open
        }
        type={dialog.type === 'move_dashboard' ? 'move' : 'copy'}
      />

      <EmbedDashboardDialog
        id={dialog.id}
        onClose={handleCloseDialog}
        open={dialog.type === 'embed_dashboard' && dialog.open}
      />
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  DashboardFolderStore: state.DashboardFolderStore,
  KPIDashboardStore: state.KPIDashboardStore,
  KPITemplateStore: state.KPITemplateStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountActions,
      ...CompanyGroupActions,
      ...DashboardActions,
      ...DashboardFavoriteActions,
      ...DashboardFolderActions,
      ...DashboardGroupActions,
      ...KPITemplateActions,
      ...WidgetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector>

export default connector(DashboardTabs)
