import { createSelector } from '@reduxjs/toolkit'

import { ApplicationState } from 'redux/Stores/types'

// select all dashboard folders
export const selectAllDashboardFolders = createSelector(
  [
    // Input selectors:
    (state: ApplicationState) => state.DashboardFolderStore.data
  ],
  (data) => Object.values(data)
)
