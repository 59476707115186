import { createAction, createReducer } from '@reduxjs/toolkit'

import { ISavedDashboardFilter } from 'types/GlobalSavedDashboardFilter'

import { arrayToObject } from 'utils/functions'

import * as Types from 'redux/Types'
import { cloneDeep } from 'lodash'

export interface SavedDashboardFilterReducerType {
  data: Record<string, ISavedDashboardFilter>
  fetched: boolean
  fetching: boolean
}

// Initial state
const INITIAL_STATE: SavedDashboardFilterReducerType = {
  data: {},
  fetched: false,
  fetching: false
}

// Actions
const getAll = createAction(Types.GET_SAVED_DASHBOARD_FILTERS)
const getAllSuccess = createAction<ISavedDashboardFilter[]>(
  Types.GET_SAVED_DASHBOARD_FILTERS_SUCCESS
)
const getAllFailed = createAction(Types.GET_SAVED_DASHBOARD_FILTERS_FAILED)

const createSuccess = createAction<ISavedDashboardFilter>(
  Types.CREATE_SAVED_DASHBOARD_FILTER_SUCCESS
)
const updateSuccess = createAction<ISavedDashboardFilter>(
  Types.UPDATE_SAVED_DASHBOARD_FILTER_SUCCESS
)
const deleteSuccess = createAction<string>(
  Types.DELETE_SAVED_DASHBOARD_FILTER_SUCCESS
)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const savedDashboardFilterReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAll, (state) => {
      return {
        ...state,
        fetched: false,
        fetching: true
      }
    })
    .addCase(getAllSuccess, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: arrayToObject(payload),
        fetched: true,
        fetching: false
      }
    })
    .addCase(getAllFailed, (state) => {
      return {
        ...state,
        data: {},
        fetched: true,
        fetching: false
      }
    })
    .addCase(createSuccess, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.id]: payload
        }
      }
    })
    .addCase(updateSuccess, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.id]: payload
        }
      }
    })
    .addCase(deleteSuccess, (state, action) => {
      const { payload } = action

      const newData = cloneDeep(state.data)

      delete newData[payload]

      return {
        ...state,
        data: newData
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default savedDashboardFilterReducer
