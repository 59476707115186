export enum CustomerAgreementType {
  Pilot = 'Pilot',
  Standard = 'Standard',
  Share = 'Share',
  Department = 'Department',
  Other = 'Other'
}

export const translateCustomerAgreementType: Record<
  CustomerAgreementType,
  { display: string }
> = {
  [CustomerAgreementType.Pilot]: {
    display: 'Pilot'
  },
  [CustomerAgreementType.Standard]: {
    display: 'Standard'
  },
  [CustomerAgreementType.Share]: {
    display: 'Dela'
  },
  [CustomerAgreementType.Department]: {
    display: 'Avdelning'
  },
  [CustomerAgreementType.Other]: {
    display: 'Övrig'
  }
}

export type CustomerAgreement = {
  id: string
  customer_id: string
  type: CustomerAgreementType
  termination_date: string | null
  signed_date: string
  end_date: string | null
  start_date: string
  arr: number
  created_at: string
  updated_at: string
}

export type PostCustomerAgreementBody = {
  type: CustomerAgreementType
  termination_date: string | null
  signed_date: string
  end_date: string | null
  start_date: string
  arr: number
}

export type PatchCustomerAgreementBody = {
  type?: CustomerAgreementType
  termination_date?: string | null
  signed_date?: string
  end_date?: string | null
  start_date?: string
  arr?: number
}
