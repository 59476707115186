import { useSelector } from 'react-redux'

import { ApplicationState } from 'redux/Stores/types'

import {
  selectAll,
  selectAllGrouped,
  selectBreakdownBy,
  selectCommonAttributeOptions,
  selectKpiOptionAttributeOptions,
  selectSegmentBy
} from 'redux/selectors/AttributeOptions'
import { KpiOptionObject } from 'types/GlobalKpiOption'
import { WidgetObject } from 'types/GlobalWidget'

export const useAttributeOptions = () => {
  return useSelector(selectAll)
}

export const useGroupedAttributeOptions = () => {
  return useSelector(selectAllGrouped)
}

export const useCommonAttributeOptions = (
  widget: WidgetObject,
  type: 'segment_attribute' | 'filter_attribute'
) => {
  return useSelector((state: ApplicationState) =>
    selectCommonAttributeOptions(state, widget, type)
  )
}

export const useKpiOptionAttributeOptions = (
  widget: WidgetObject,
  kpiOption: KpiOptionObject,
  type: 'breakdown_attribute' | 'filter_attribute'
) => {
  return useSelector((state: ApplicationState) =>
    selectKpiOptionAttributeOptions(state, widget, kpiOption, type)
  )
}

export const useSegmentBy = (
  widget: WidgetObject,
  customSegmentBy?: string | null
) => {
  return useSelector((state: ApplicationState) =>
    selectSegmentBy(state, widget, customSegmentBy)
  )
}

export const useBreakdownBy = (kpiOption: KpiOptionObject) => {
  return useSelector((state: ApplicationState) =>
    selectBreakdownBy(state, kpiOption)
  )
}
