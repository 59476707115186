import React from 'react'

import {
  FilterPatchBody,
  KpiOptionObject,
  KpiOptionPatchAttributes
} from 'types/GlobalKpiOption'
import { WidgetObject, WidgetType } from 'types/GlobalWidget'
import { KpiTemplateFilterOptionsBody } from 'types/GlobalKpiTemplates'

import { useKpiOptionAttributeOptions } from 'redux/hooks/AttributeOptions'

import WidgetFilterSection from '../../FilterSection'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import Switch from 'components_new/atoms/Switch'
import Divider from 'components_new/atoms/Divider'
import IconTip from 'components_new/molecules/IconTip'
import Text from 'components_new/atoms/Text'

interface ShareKpiSectionProps {
  filterOptions: KpiTemplateFilterOptionsBody | null
  kpi: KpiOptionObject
  onClose: () => void
  updateKpiOption: (body: KpiOptionPatchAttributes) => void
  widget: WidgetObject
}

const LEGAL_TYPES = [
  WidgetType.BAR_CHART,
  WidgetType.BUBBLE_CHART,
  WidgetType.COMBO_CHART,
  WidgetType.KEY_FIGURE,
  WidgetType.LINE_CHART,
  WidgetType.MAP,
  WidgetType.LIST,
  WidgetType.PIE_CHART
]

const ShareKpiSection = (props: ShareKpiSectionProps) => {
  const { filterOptions, kpi, onClose, updateKpiOption, widget } = props

  if (!LEGAL_TYPES.includes(widget.settings.type.selected)) {
    return null
  }
  const filterAttributeOptions = useKpiOptionAttributeOptions(
    widget,
    kpi,
    'filter_attribute'
  )

  return (
    <SettingsGroup
      title="Andel"
      helperText={
        <>
          Genom att visa andel av det här nyckeltalet presenteras en procentsats
          på hur mycket av det ursprungliga resultatet som uppfyller de
          kriterier du lägger till i den här sektionen.{' '}
          <IconTip
            title={
              <>
                <Text variant="body2" color="inherit" gutterBottom>
                  <b>Mer detaljer</b>
                </Text>
                <Text variant="body2" color="inherit">
                  <b>Resultatet av angivna filter i den här sektionen</b> /{' '}
                  <b>Totala resultatet i nyckeltalet</b>
                  <Divider />
                  Visas i procent
                </Text>
              </>
            }
            variant="info"
            size={'small'}
          />
        </>
      }
    >
      <SettingsItem
        actions={
          <Switch
            checked={kpi.is_share_kpi}
            onChange={() =>
              updateKpiOption({ data: { is_share_kpi: !kpi.is_share_kpi } })
            }
            size="small"
          />
        }
        title="Visa andel"
      />
      {kpi.is_share_kpi ? (
        // The same filter section is used for both widget and kpi:
        <WidgetFilterSection
          filterAttributeOptions={filterAttributeOptions}
          filters={kpi.share_filters}
          filterOptions={filterOptions}
          onClose={onClose}
          updateFilters={(filters: FilterPatchBody[]) => {
            updateKpiOption({ data: { share_filters: filters } })
          }}
          skipGroup
          widget={widget}
        />
      ) : null}
    </SettingsGroup>
  )
}

export default ShareKpiSection
