import { CustomAlignTypes, CustomElement, CustomMarks, RichText } from './types'

export const LIST_TYPES = ['numbered-list', 'bulleted-list']
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right']
export const TEXT_VARIANTS = ['heading-three', 'heading-two', 'paragraph']

export const EMPTY_VALUE: RichText = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
]

export const dict: Record<
  CustomElement['type'] | CustomAlignTypes | CustomMarks,
  {
    hotkey: {
      mac: string
      windows: string
    }
    title: string
  }
> = {
  bold: {
    hotkey: {
      mac: '(⌘ + B)',
      windows: '(Ctrl + B)'
    },
    title: 'Fetstil'
  },
  'bulleted-list': {
    hotkey: {
      mac: '',
      windows: ''
    },
    title: 'Punktlista'
  },
  center: {
    hotkey: {
      mac: '',
      windows: ''
    },
    title: 'Centrera'
  },
  code: {
    hotkey: {
      mac: '',
      windows: ''
    },
    title: 'Markerad'
  },
  'heading-three': {
    hotkey: {
      mac: '',
      windows: ''
    },
    title: 'Rubrik 3'
  },
  'heading-two': {
    hotkey: {
      mac: '',
      windows: ''
    },
    title: 'Rubrik 2'
  },
  hr: {
    hotkey: {
      mac: '',
      windows: ''
    },
    title: 'Streck'
  },
  italic: {
    hotkey: {
      mac: '(⌘ + I)',
      windows: '(Ctrl + I)'
    },
    title: 'Kursiv'
  },
  left: {
    hotkey: {
      mac: '',
      windows: ''
    },
    title: 'Vänster'
  },
  'list-item': {
    hotkey: {
      mac: '',
      windows: ''
    },
    title: 'Listelement'
  },
  'numbered-list': {
    hotkey: {
      mac: '',
      windows: ''
    },
    title: 'Numrerad lista'
  },
  paragraph: {
    hotkey: {
      mac: '',
      windows: ''
    },
    title: 'Vanlig text'
  },
  right: {
    hotkey: {
      mac: '',
      windows: ''
    },
    title: 'Höger'
  },
  underline: {
    hotkey: {
      mac: '(⌘ + U)',
      windows: '(Ctrl + U)'
    },
    title: 'Understrykning'
  }
}
