import React, { ElementType, ReactNode } from 'react'
import { ListSubheader as MUIListSubheader } from '@mui/material'

interface ListSubheaderProps {
  border?: boolean
  children: ReactNode
  color?: 'default' | 'inherit' | 'primary'
  component?: ElementType<any>
  disableGutters?: boolean
  disableSticky?: boolean
  inset?: boolean
  sx?: object
  style?: object
}

/**
 * [INSERT DESCRIPTION]
 */

const ListSubheader = React.forwardRef(
  (props: ListSubheaderProps, ref: any) => {
    const { border, children, sx, ...rest } = props

    return (
      <MUIListSubheader
        sx={{
          ...(border
            ? { borderBottom: '1px solid', borderColor: 'divider' }
            : null),
          ...sx
        }}
        {...rest}
        ref={ref}
      >
        {children}
      </MUIListSubheader>
    )
  }
)

ListSubheader.displayName = 'ListSubheader'
export default ListSubheader
