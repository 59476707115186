import React, { ReactNode, useState } from 'react'

import Autocomplete from 'components_new/atoms/Autocomplete'
import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import ListItemAvatar from 'components_new/atoms/List/ListItemAvatar'
import ListItemText from 'components_new/atoms/List/ListItemText'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'

import IconTip from 'components_new/molecules/IconTip'
import UserAvatar from 'components_new/molecules/UserAvatar'

import { sortAlphabeticalAsc } from 'utils/sortHelper'
import { PaperProps, Popper, PopperProps } from '@mui/material'

import { getAvatarSize } from 'components_new/atoms/Avatar'

export type Option = {
  avatar?: boolean
  body?: string
  disabled?: boolean
  disabledTitle?: ReactNode
  group?: string
  id: string | number | null
  title: string
}

interface SelectSearchProps {
  closeOnSelect?: boolean
  color?: 'primary' | 'secondary'
  customIcon?: ReactNode
  disableClearable?: boolean
  disabled?: boolean
  externalLabel?: string
  helperText?: ReactNode
  label?: string
  lastOption?: ReactNode
  multiple?: boolean
  noGutter?: boolean
  // List if multiple=true
  onChange: ((params: Option) => void) | ((params: Option[]) => void)
  options: Option[]
  placeholder?: string
  PopperWidth?: number
  size?: 'small' | 'medium'
  sx?: object
  selected: Option[] | Option | null // List if multiple=true
  sort?: boolean
  variant?: 'add' | 'select'
}

export const OptionItem = (props: { option: Option }) => {
  const { option } = props

  return (
    <>
      {option.avatar ? (
        <ListItemAvatar sx={{ minWidth: getAvatarSize('small').width * 1.5 }}>
          <UserAvatar
            email={option.body}
            name={option.title}
            size="small"
            type="user"
          />
        </ListItemAvatar>
      ) : null}
      <ListItemText
        primary={option.title}
        primaryTypographyProps={{ noWrap: true }}
        secondary={option.body}
      />
      {option.disabled ? <IconTip title={option.disabledTitle} /> : null}
    </>
  )
}

const SelectSearch = React.forwardRef((props: SelectSearchProps, ref: any) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [keepInputValue, setKeepInputValue] = useState<boolean>(false)
  const [hasValue, setHasValue] = useState<boolean>(false)

  const showGroups =
    props.options[0]?.group &&
    !props.options.every(({ group }) => group === props.options[0].group)

  const getSortedOptions = (): any[] => {
    return props.options.sort((a, b) => {
      if (showGroups && a.group && b.group) {
        if (a.group.toLowerCase() === b.group.toLowerCase()) {
          return sortAlphabeticalAsc(
            a.title as string | null,
            b.title as string | null
          )
        }

        return sortAlphabeticalAsc(a.group, b.group)
      }

      return sortAlphabeticalAsc(
        a.title as string | null,
        b.title as string | null
      )
    })
  }

  const PaperComponent = (paperProps: PaperProps) => {
    return (
      <Paper sx={{ overflow: 'hidden' }}>
        {props.multiple ? (
          <>
            <Box
              onClick={() => setKeepInputValue(!keepInputValue)}
              onMouseDown={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              sx={{
                cursor: 'pointer',
                gap: 0.5,
                p: 1
              }}
            >
              <Icon
                className="CheckBoxIcon"
                color={keepInputValue ? 'primary' : 'disabled'}
                name={keepInputValue ? 'CheckBox' : 'CheckBoxOutlineBlank'}
                fontSize="small"
                sx={{
                  display: 'inline-block',
                  verticalAlign: '-0.325rem',
                  mr: '0.25rem'
                }}
              />
              <Text
                className="CheckBoxLabel"
                color={keepInputValue ? 'text.primary' : 'text.disabled'}
                variant="body2"
                sx={{
                  display: 'inline-block',
                  mr: '0.25rem'
                }}
              >
                Behåll sökning vid val
              </Text>
              <IconTip
                sx={{ color: 'action.disabled' }}
                title="Markera för att låta sökfrasen stanna kvar i fältet efter varje val, så att du kan välja flera alternativ utan att behöva skriva om samma sökord."
              />
            </Box>
            <Divider />
          </>
        ) : null}
        {paperProps.children}
        {props.lastOption ? (
          <Box
            onMouseDown={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            {props.lastOption}
          </Box>
        ) : null}
      </Paper>
    )
  }

  const PopperComponent = (popperProps: PopperProps) => {
    return (
      <Popper
        {...popperProps}
        placement="bottom-start"
        sx={
          props.PopperWidth
            ? { width: `${props.PopperWidth}px!important` }
            : null
        }
      />
    )
  }

  return (
    <>
      {props.externalLabel ? (
        <Text
          color="text.primary"
          fontWeight="medium"
          variant="body2"
          sx={{ mb: 0.25 }}
        >
          {props.externalLabel}
        </Text>
      ) : null}
      <Autocomplete
        autoHighlight={true}
        disableClearable={props.disableClearable || false}
        disableCloseOnSelect={props.multiple ?? !props.closeOnSelect}
        disabled={props.disabled}
        filterSelectedOptions={props.multiple}
        getOptionLabel={(option: Option) => `${option.title}`}
        groupBy={showGroups ? (option) => option.group : undefined}
        isOptionEqualToValue={(option: Option, value: Option) =>
          option.id === value.id
        }
        ListboxItemHeight={props.options[0]?.body ? 56 : 40} // @TODO?
        ListboxProps={{
          sx: {
            maxHeight: 256
          }
        }}
        multiple={props.multiple ?? true}
        onChange={(event, newValue: Option & Option[]) => {
          props.onChange(newValue)
          setHasValue(
            (Array.isArray(newValue) && newValue.length !== 0) ||
              (!Array.isArray(newValue) && newValue)
          )
        }}
        onInputChange={(event, value, reason) => {
          if (keepInputValue) {
            if (event && event.type === 'blur') {
              setInputValue('')
            } else if (reason !== 'reset') {
              setInputValue(value)
            }
          } else {
            setInputValue(value)
          }
        }}
        onClose={() => setInputValue('')}
        openOnFocus={true}
        options={props.sort ? getSortedOptions() : props.options}
        PaperComponent={PaperComponent}
        PopperComponent={PopperComponent}
        ref={ref}
        renderTags={(value, getTagProps) =>
          value.map((option: Option, index) => (
            <Chip
              color={props.color ?? undefined}
              label={option.title}
              size={props.size ?? 'small'}
              sx={{
                maxWidth: 'calc(100% - 36px)'
              }}
              {...getTagProps({ index })}
              key={index}
            />
          ))
        }
        inputValue={inputValue}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={props.helperText}
            InputProps={{
              ...params.InputProps,
              startAdornment: props.multiple ? (
                params.InputProps.startAdornment
              ) : props.selected &&
                !Array.isArray(props.selected) &&
                props.selected.avatar ? (
                    <Box sx={{ px: 0.5 }}>
                      <UserAvatar
                        email={props.selected.body}
                        name={props.selected.title}
                        size="small"
                        type="user"
                      />
                    </Box>
                  ) : undefined
            }}
            label={props.label}
            noGutter={true}
            placeholder={props.placeholder ?? 'Sök...'}
            sx={{
              '& .MuiFilledInput-root': {
                pt: hasValue ? '22px' : undefined, // @TODO: if size === 'small',
                pb: hasValue ? '8px' : undefined // @TODO: if size === 'small'.
              }
            }}
            variant="filled"
          />
        )}
        popupIcon={
          <Icon name={props.variant === 'select' ? 'UnfoldMore' : 'Add'} />
        }
        renderOption={(props, option: Option) => (
          <div key={option.id}>
            <MenuItem
              {...props}
              component="div"
              disabled={option.disabled}
              sx={{
                height: '100%',
                '&.Mui-focused': {
                  borderLeft: '2px solid',
                  borderColor: 'primary.main',
                  pl: 'calc(16px - 2px)'
                }
              }}
            >
              <OptionItem option={option} />
            </MenuItem>
          </div>
        )}
        size={props.size ?? 'small'}
        sx={{
          width: '100%',
          maxWidth: '100%',
          minWidth: 0,
          mb: props.noGutter ? 0 : 1,
          ...props.sx
        }}
        value={props.selected}
      />
    </>
  )
})

SelectSearch.displayName = 'SelectSearch'
export default SelectSearch
