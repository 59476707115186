import { createAction, createReducer } from '@reduxjs/toolkit'

import * as Types from 'redux/Types'

import { AttributeOption } from 'types/GlobalAttributeOptions'
import { arrayToObject, groupBy } from 'utils/functions'

export interface AttributeOptionsReducerType {
  data: { [id: string]: AttributeOption }
  optionsByFactTableId: { [factTableId: string]: AttributeOption[] }
  fetched: boolean
}

// Initial state
const INITIAL_STATE: AttributeOptionsReducerType = {
  data: {},
  optionsByFactTableId: {},
  fetched: false
}

// Actions
const getAllSuccess = createAction<AttributeOption[]>(
  Types.GET_ATTRIBUTE_OPTIONS_SUCCESS
)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const kpiTemplateReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAllSuccess, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: arrayToObject(payload),
        optionsByFactTableId: groupBy(payload, 'fact_table_id'),
        fetched: true
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default kpiTemplateReducer
