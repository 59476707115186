import { Dispatch } from 'redux'

import * as Types from 'redux/Types'

import {
  addVisit,
  getDashboardExamples,
  copy
} from 'redux/api/DashboardExamples'
import * as UserEvents from 'redux/api/internal/UserEvents'

import { Action, Context } from 'types/GlobalUserEvents'

export function tryGetAllDashboardExamples() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_DASHBOARD_EXAMPLES
    })

    getDashboardExamples()
      .then((response) => {
        dispatch({
          type: Types.GET_DASHBOARD_EXAMPLES_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_DASHBOARD_EXAMPLES_FAILED
        })
      })
  }
}

export function tryAddDashboardExampleVisit(dashboardId: string) {
  return () => {
    addVisit({ data: { dashboard_id: dashboardId } }).catch(() => {
      // do nothing
    })
  }
}

export function tryCopyDashboardExample(
  dashboardId: string,
  title: string,
  callback: (id: string | null, err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_DASHBOARD
    })

    copy(dashboardId, { data: { title } })
      .then((response) => {
        UserEvents.log({
          action: Action.DASHBOARD_CREATED,
          context: Context.FROM_EXPLORE,
          dashboard_id: response.data.data.dashboard.id,
          dashboard_group_id: response.data.data.group.id
        })

        dispatch({
          type: Types.CREATE_DASHBOARD_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Dashboard aktiverad'
          }
        })

        callback(response.data.data.group.dashboards[0])
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_DASHBOARD_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Dashboard kunde inte aktiveras'
          }
        })

        callback(null, true)
      })
  }
}
