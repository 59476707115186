import React, { ReactNode } from 'react'

import { Helmet } from 'react-helmet'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Logotype from 'assets/logotypes/Logotype'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

import PageContainer from 'components_new/organisms/PageContainer'

import './index.css'

interface AuthTemplateProps {
  children: ReactNode
}

const AuthTemplate = (props: AuthTemplateProps) => {
  const { children } = props

  const theme = getTheme('dark', null, { responsive: true })

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta name="theme-color" content={theme.palette.background.default} />
      </Helmet>

      <PageContainer unauthorized={true}>
        <Box
          sx={{
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            bgcolor: 'background.default'
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <a href="https://homepal.se">
              <Box
                sx={{
                  position: 'absolute',
                  top: 24,
                  left: 24,
                  height: 32,
                  width: 120,
                  color: 'white.main'
                }}
              >
                <Logotype />
              </Box>
            </a>
            <Box sx={{ maxWidth: 600, p: 2 }}>{children}</Box>
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bottom: 24
              }}
            >
              <Text align="center" color="text.secondary" variant="body2">
                Copyright © Homepal AB 2025.
                <br />
                Org. nr: 559201-1521 (SE).
              </Text>
            </Box>
          </Box>
        </Box>
      </PageContainer>
    </ThemeProvider>
  )
}

export default AuthTemplate
