import React, { ChangeEvent, useState } from 'react'

import Button from 'components_new/atoms/Button'
import TextField from 'components_new/atoms/TextField'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

import { ISavedDashboardFilter } from 'types/GlobalSavedDashboardFilter'

interface EditSavedUserFilterDialogProps {
  filter: ISavedDashboardFilter
  onClose: () => void
  onSave: (value: string) => void
  open: boolean
}

const EditSavedUserFilterDialog = (props: EditSavedUserFilterDialogProps) => {
  const { filter, onClose, onSave, open } = props

  const [title, setTitle] = useState<string>(filter.title)

  return (
    <SimpleDialog
      title="Redigera namn"
      open={open}
      onClose={onClose}
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            Avbryt
          </Button>
          <Button
            disabled={!title}
            onClick={() => {
              onSave(title)
              onClose()
            }}
            variant="contained"
          >
            Spara
          </Button>
        </>
      }
    >
      <TextField
        fullWidth
        label={'Namn'}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
          setTitle(event.target.value)
        }}
        value={title}
      />
    </SimpleDialog>
  )
}

export default EditSavedUserFilterDialog
