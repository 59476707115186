import React, { ReactNode, useState } from 'react'

import {
  DashboardFilter,
  ParsedDashboardFilterOptions
} from 'types/GlobalDashboardFilter'

import { ISavedDashboardFilter } from 'types/GlobalSavedDashboardFilter'

import Box from 'components_new/atoms/Box'
import Collapse from 'components_new/atoms/Collapse'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Tooltip from 'components_new/atoms/Tooltip'

import SectionHeader from 'components_new/molecules/SectionHeader'
import TabSwitch from 'components_new/molecules/TabSwitch'

import Actions from '../Actions'
import CurrentFilter from '../CurrentFilter'
import SavedFilters from '../SavedFilters'
import EmptyPlaceholder from 'components_new/molecules/EmptyPlaceholder'
import Button from 'components_new/atoms/Button'

interface DashboardFilterSectionProps {
  editable: boolean
  isMobile: boolean
  isPublic: boolean
  dashboardFilter: DashboardFilter
  dashboardFilterOptions: Map<string, ParsedDashboardFilterOptions>
  savedDashboardFilter?: ISavedDashboardFilter | null
  savedDashboardFilters: ISavedDashboardFilter[]
  setEditDashboardUserFilters: (value: boolean) => void
  setDashboardFilter: (value: DashboardFilter) => void
  setSavedDashboardFilter?: (
    value: ISavedDashboardFilter | null,
    skipFetch?: boolean
  ) => void
}

const OuterContainer = (props: { isMobile: boolean; children: ReactNode }) => (
  <Box
    sx={{
      p: props.isMobile ? 1 : 2,
      pt: 0,
      display: 'flex',
      flexDirection: 'column',
      minHeight: '0px',
      flex: '1 1 auto',
      overflowY: 'auto'
    }}
  >
    {props.children}
  </Box>
)

const DashboardFilterSection = (props: DashboardFilterSectionProps) => {
  const {
    dashboardFilter,
    dashboardFilterOptions,
    editable,
    isMobile,
    isPublic,
    savedDashboardFilter,
    savedDashboardFilters,
    setDashboardFilter,
    setEditDashboardUserFilters,
    setSavedDashboardFilter
  } = props

  const [tab, setTab] = useState<'current' | 'saved'>('current')

  // if state and state function is set then allow it.
  const showSavedFilters =
    savedDashboardFilter !== undefined && setSavedDashboardFilter !== undefined
  const emptyDashboardFilters = dashboardFilterOptions.size === 0
  const hideFilterSection = !editable && emptyDashboardFilters

  if (hideFilterSection) {
    return null
  }

  if (isPublic || !showSavedFilters) {
    // saved dashboard filter not allowed for api-access
    return (
      <OuterContainer isMobile={isMobile}>
        <SectionHeader
          actions={
            <Tooltip title="Återställ">
              <IconButton
                onClick={() => {
                  setDashboardFilter([])
                }}
                size="small"
              >
                <Icon fontSize="small" name="RestartAltOutlined" />
              </IconButton>
            </Tooltip>
          }
          divider={true}
          sx={{ mb: 2 }}
          title="Filter"
        />
        <CurrentFilter
          dashboardFilter={dashboardFilter}
          dashboardFilterOptions={dashboardFilterOptions}
          setDashboardFilter={setDashboardFilter}
        />
      </OuterContainer>
    )
  }

  return (
    <OuterContainer isMobile={isMobile}>
      {editable && emptyDashboardFilters ? (
        <EmptyPlaceholder
          actions={
            <Button
              variant="text"
              onClick={() => setEditDashboardUserFilters(true)}
            >
              Lägg till filter
            </Button>
          }
          background={true}
          title="Det finns inga tillagda filter."
        />
      ) : (
        <>
          <TabSwitch
            color={'primary'}
            maxHeight="40px"
            noGutter={true}
            onChange={(value) => {
              setTab(value)
            }}
            options={[
              {
                label: 'Filter',
                menuOptions: editable
                  ? [
                      {
                        iconName: 'Edit',
                        onClick: () => setEditDashboardUserFilters(true),
                        title: 'Redigera'
                      }
                    ]
                  : undefined,
                value: 'current'
              },
              {
                label: 'Sparade',
                number: savedDashboardFilters.length,
                value: 'saved'
              }
            ]}
            value={tab}
            sx={{
              bgcolor: isMobile ? 'background.paper' : 'background.default',
              mb: 1,
              position: 'sticky',
              top: 0,
              zIndex: 1
            }}
          />

          {/*-- current --*/}
          <Collapse in={tab === 'current'} unmountOnExit={true}>
            <CurrentFilter
              dashboardFilter={dashboardFilter}
              dashboardFilterOptions={dashboardFilterOptions}
              setDashboardFilter={setDashboardFilter}
            />

            <Box
              sx={{
                bgcolor: isMobile ? undefined : 'background.default',
                bottom: 0,
                display: 'flex',
                gap: 1,
                position: 'sticky',
                pt: 1,
                zIndex: 2
              }}
            >
              <Actions
                dashboardFilter={dashboardFilter}
                dashboardFilterOptions={dashboardFilterOptions}
                savedDashboardFilters={savedDashboardFilters}
                setDashboardFilter={setDashboardFilter}
                setSavedDashboardFilter={setSavedDashboardFilter}
                setTab={setTab}
              />
            </Box>
          </Collapse>

          {/*-- saved --*/}
          <Collapse in={tab === 'saved'} unmountOnExit={true}>
            <SavedFilters
              dashboardFilterOptions={dashboardFilterOptions}
              savedDashboardFilters={savedDashboardFilters}
              savedDashboardFilter={savedDashboardFilter}
              setSavedDashboardFilter={setSavedDashboardFilter}
            />
          </Collapse>
        </>
      )}
    </OuterContainer>
  )
}

export default DashboardFilterSection
