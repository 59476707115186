import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { DashboardGroup, DashboardStatus } from 'redux/reducers/Dashboards'

import * as UserEvents from 'redux/api/internal/UserEvents'

import * as DashboardGroupActions from 'redux/actions/DashboardGroups'

import Button from 'components_new/atoms/Button'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import TextField from 'components_new/atoms/TextField'

import OptionsMenu from 'components_new/molecules/OptionsMenu'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import AboutDashboardDialog from 'components_new/organisms/dialogs/AboutDashboardDialog'

import { DashboardFolder } from 'types/GlobalDashboardFolder'
import { ApplicationState } from 'redux/Stores/types'

import { getDashboardLimitReached } from 'utils/functions'
import { Action, Context } from 'types/GlobalUserEvents'
import { addRef } from 'utils/queryHandler'

/**
 * The EditDashboardMenu component is used
 * to edit dashboard settings.
 */

interface EditDashboardMenuProps {
  deleteCallback?: () => void
  folders: DashboardFolder[]
  group: DashboardGroup
  handleClose: () => void
  menuAnchorEl: HTMLAnchorElement | null
}

const EditDashboardMenu = (props: ComponentProps) => {
  const history = useHistory()

  const { AuthStore, deleteCallback = () => {} } = props

  const [deleteOpen, setDeleteOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [moveOpen, setMoveOpen] = useState(false)
  const [publishOpen, setPublishOpen] = useState(false)
  const [newFolderId, setNewFolderId] = useState<string | null>(
    props.group.folder_id
  )
  const [newTitle, setNewTitle] = useState(props.group.title)

  const handleCloseEdit = () => {
    setEditOpen(false)

    setNewTitle(props.group.title)
  }

  const handleCloseMove = () => {
    setMoveOpen(false)

    setNewFolderId(props.group.folder_id)
  }

  const isPublished = props.group.status === DashboardStatus.PUBLISHED

  const dashboardLimitReached = getDashboardLimitReached(AuthStore)

  return (
    <>
      <OptionsMenu
        anchorEl={props.menuAnchorEl}
        disableScrollLock={true} // ?
        onClose={props.handleClose}
        open={Boolean(props.menuAnchorEl)}
        options={[
          {
            iconName: 'EditOutlined',
            onClick: () => {
              props.handleClose()

              setEditOpen(true)
            },
            title: 'Byt namn'
          },
          {
            iconName: 'DriveFileMoveOutlined',
            onClick: () => {
              props.handleClose()

              setMoveOpen(true)
            },
            title: 'Flytta',
            disabled: !isPublished,
            tooltip: !isPublished
              ? 'En dashboard kan inte flyttas innan den har publicerats.'
              : undefined
          },
          {
            disabled: dashboardLimitReached,
            divider: true,
            iconName: 'ContentCopyOutlined',
            onClick: () => {
              props.handleClose()

              props.tryCopyDashboardGroup(props.group.id, (id) =>
                history.push(addRef(`/dashboards/${id}`, Context.DUPLICATE))
              )
            },
            title: 'Duplicera',
            tooltip: dashboardLimitReached
              ? 'Din organisation använder alla dashboards som ingår i er licens. Ta bort en dashboard eller kontakta oss för att utöka din licens.'
              : undefined
          },
          {
            iconName: isPublished
              ? 'UnpublishedOutlined'
              : 'PublishedWithChangesOutlined',
            onClick: () => {
              if (isPublished) {
                props.tryUpdateDashboardGroup(
                  props.group.id,
                  {
                    data: { status: DashboardStatus.DRAFT }
                  },
                  (e) => {
                    if (!e) {
                      UserEvents.log({
                        action: Action.DASHBOARD_UNPUBLISHED,
                        context: null,
                        dashboard_id: null,
                        dashboard_group_id: props.group.id
                      })
                    }
                  }
                )
              } else {
                setPublishOpen(true)
              }

              props.handleClose()
            },
            title: isPublished ? 'Avpublicera' : 'Publicera'
          },
          {
            iconName: 'DeleteOutlined',
            onClick: () => {
              props.handleClose()

              setDeleteOpen(true)
            },
            title: 'Radera'
          }
        ]}
      />

      <SimpleDialog
        title="Radera dashboard?"
        contentText={
          <>
            Din dashboard <code>{props.group.title}</code> kommer att raderas
            permanent. Är du säker?
          </>
        }
        onClose={() => setDeleteOpen(false)}
        open={deleteOpen}
        actions={
          <>
            <Button onClick={() => setDeleteOpen(false)} variant="text">
              Avbryt
            </Button>
            <Button
              onClick={() =>
                props.tryDeleteDashboardGroup(props.group.id, deleteCallback)
              }
              color="error"
              variant="contained"
            >
              Radera
            </Button>
          </>
        }
      />
      <SimpleDialog
        actions={
          <Button variant="text" onClick={handleCloseMove}>
            Stäng
          </Button>
        }
        onClose={handleCloseMove}
        open={moveOpen}
        title={`Flytta dashboarden "${props.group.title}"`}
      >
        <TextField
          fullWidth={true}
          label="Välj mapp..."
          select={true}
          autoComplete="off"
          value={newFolderId || null}
          onChange={(event) => setNewFolderId(event.target.value)}
          sx={{ mt: 1, mb: 2 }}
        >
          <MenuItem value={null}>
            <em>Ingen</em>
          </MenuItem>
          {props.folders.map((folder) => {
            return (
              <MenuItem key={folder.id} value={folder.id}>
                {folder.name}
              </MenuItem>
            )
          })}
        </TextField>
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={() => {
            props.tryUpdateDashboardGroup(props.group.id, {
              data: { folder_id: newFolderId || null }
            })

            setMoveOpen(false)
          }}
        >
          Spara
        </Button>
      </SimpleDialog>

      <SimpleDialog
        actions={
          <>
            <Button variant="text" onClick={handleCloseEdit}>
              Avbryt
            </Button>
            <Button
              disabled={newTitle.length === 0}
              variant="contained"
              onClick={() => {
                props.tryUpdateDashboardGroup(props.group.id, {
                  data: { title: newTitle }
                })

                setEditOpen(false)
              }}
            >
              Spara
            </Button>
          </>
        }
        onClose={handleCloseEdit}
        open={editOpen}
        title="Byt namn"
      >
        <TextField
          fullWidth={true}
          label="Titel"
          autoComplete="off"
          value={newTitle}
          onChange={(event) => setNewTitle(event.target.value)}
          sx={{ mt: 1, mb: 2 }}
          required
        />
      </SimpleDialog>

      <AboutDashboardDialog
        dashboardGroup={props.group}
        onClose={() => setPublishOpen(false)}
        open={publishOpen}
        variant="publish"
      />
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...DashboardGroupActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & EditDashboardMenuProps

export default connector(EditDashboardMenu)
