import React from 'react'

import { TVariant } from '.'
import { DashboardGroup } from 'redux/reducers/Dashboards'
import Text from 'components_new/atoms/Text'

const DESCRIPTION_TEXT = {
  EMPTY: 'Beskrivning saknas...',
  HELPER: 'Förklara syftet med dashboarden och hur den ska användas.',
  PLACEHOLDER:
    'Ex. Den här dashboarden ger en tydlig överblick över hur mycket hyresintäkter som går förlorade till följd av vakanta lokaler eller outhyrda bostäder. Genom att visualisera de faktiska vakansernas påverkan på intäkterna kan du snabbt identifiera riskområden och möjligheter till förbättringar.\n\nSå här bör du använda dashboarden:\n\t• Total hyresbortfall: Börja med att titta på den totala summan av förlorade hyresintäkter.\n\t• Detta ger en snabb uppfattning om vakansernas påverkan på din verksamhet.\n\t• Vakansgrad per fastighet/område: Identifiera vilka fastigheter eller områden som...'
}
const FOLDER_TEXT = {
  DISABLED: 'Publicera din dashboard för att välja det här',
  HELPER: 'Placera din dashboard i en mapp så att den blir lätt att hitta.'
}
const OWNER_TEXT = {
  DISABLED: 'Publicera din dashboard för att välja det här',
  HELPER: 'Välj en ansvarig som hanterar dashboarden och agerar kontaktperson.',
  TITLE: 'Ägare'
}
const TITLE_TEXT = {
  ERROR:
    'Din dashboard måste ha ett namn, och inte heta "Namnlös" eller innehålla "Kopia".',
  HELPER:
    'Ge din dashboard ett namn som är tydligt och som skiljer sig åt från mängden.'
}

export const getIntroText = (group: DashboardGroup) => (
  <>
    Den här dashboarden ger dig insikter och data som förhoppningsvis
    underlättar ditt arbete.
    <br />
    Läs mer om vad ägaren har att säga om <code>{group.title}</code> nedan:
  </>
)

export const INFO_TEXT = (
  <>
    <Text gutterBottom={true}>
      Den här informationen visas första gången användaren öppnar dashboarden.
      Därefter nås den via informationsknappen vid dashboardens rubrik.
    </Text>
    <Text>Det går alltid att redigera det här vid ett senare tillfälle.</Text>
  </>
)

export const dict: Record<
  TVariant,
  {
    closeLabel: string
    descriptionEmptyText: string | undefined
    descriptionHelperText: string | undefined
    descriptionPlaceholder: string | undefined
    descriptionTitle: string
    dialogTitle: string | undefined
    folderDisabledText: string | undefined
    folderHelperText: string | undefined
    folderTitle: string
    ownerDisabledText: string | undefined
    ownerHelperText: string | undefined
    ownerTitle: string
    submitLabel: string
    titleErrorText: string | undefined
    titleHelperText: string | undefined
    titleTitle: string
  }
> = {
  edit: {
    closeLabel: 'Avbryt',
    descriptionEmptyText: undefined,
    descriptionHelperText: DESCRIPTION_TEXT.HELPER,
    descriptionPlaceholder: DESCRIPTION_TEXT.PLACEHOLDER,
    descriptionTitle: 'Beskrivning',
    dialogTitle: 'Redigera information',
    folderDisabledText: FOLDER_TEXT.DISABLED,
    folderHelperText: FOLDER_TEXT.HELPER,
    folderTitle: 'Organisera',
    ownerDisabledText: OWNER_TEXT.DISABLED,
    ownerHelperText: OWNER_TEXT.HELPER,
    ownerTitle: OWNER_TEXT.TITLE,
    submitLabel: 'Spara',
    titleErrorText: TITLE_TEXT.ERROR,
    titleHelperText: TITLE_TEXT.HELPER,
    titleTitle: 'Rubrik'
  },
  info: {
    closeLabel: 'Stäng',
    descriptionEmptyText: DESCRIPTION_TEXT.EMPTY,
    descriptionHelperText: undefined,
    descriptionPlaceholder: undefined,
    descriptionTitle: 'Om',
    dialogTitle: undefined,
    folderDisabledText: undefined,
    folderHelperText: undefined,
    folderTitle: 'Placering',
    ownerDisabledText: undefined,
    ownerHelperText: undefined,
    ownerTitle: OWNER_TEXT.TITLE,
    submitLabel: '',
    titleErrorText: undefined,
    titleHelperText: undefined,
    titleTitle: ''
  },
  publish: {
    closeLabel: 'Avbryt',
    descriptionEmptyText: undefined,
    descriptionHelperText: DESCRIPTION_TEXT.HELPER,
    descriptionPlaceholder: DESCRIPTION_TEXT.PLACEHOLDER,
    descriptionTitle: 'Beskrivning',
    dialogTitle: 'Publicera dashboard',
    folderDisabledText: undefined,
    folderHelperText: FOLDER_TEXT.HELPER,
    folderTitle: 'Organisera',
    ownerDisabledText: undefined,
    ownerHelperText: OWNER_TEXT.HELPER,
    ownerTitle: OWNER_TEXT.TITLE,
    submitLabel: 'Publicera',
    titleErrorText: TITLE_TEXT.ERROR,
    titleHelperText: TITLE_TEXT.HELPER,
    titleTitle: 'Rubrik'
  },
  welcome: {
    closeLabel: 'Kom igång',
    descriptionEmptyText: DESCRIPTION_TEXT.EMPTY,
    descriptionHelperText: undefined,
    descriptionPlaceholder: undefined,
    descriptionTitle: 'Om dashboarden',
    dialogTitle: undefined,
    folderDisabledText: undefined,
    folderHelperText: undefined,
    folderTitle: 'Placering',
    ownerDisabledText: undefined,
    ownerHelperText: undefined,
    ownerTitle: OWNER_TEXT.TITLE,
    submitLabel: '',
    titleErrorText: undefined,
    titleHelperText: undefined,
    titleTitle: ''
  }
}
