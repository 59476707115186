import React from 'react'

import { KpiTemplate } from 'types/GlobalKpiTemplates'

import Box from 'components_new/atoms/Box'
import Collapse from 'components_new/atoms/Collapse'
import TableRow from 'components_new/atoms/Table/TableRow'
import TableCell from 'components_new/atoms/Table/TableCell'

import SubKpisList from '../SubKpisList'

interface SubKpisDropdownProps {
  columns: number
  disabled: boolean
  kpiTitle?: string
  onCreate: (performKpiId: string) => void
  subKpis: KpiTemplate[]
  open: boolean
  mainKpiUnit?: string | null
  recommendSameUnit: boolean
}

const SubKpisDropdown = (props: SubKpisDropdownProps) => {
  const {
    columns,
    disabled,
    kpiTitle,
    onCreate,
    subKpis,
    open,
    recommendSameUnit,
    mainKpiUnit
  } = props

  return (
    <TableRow>
      <TableCell
        sx={{ border: !open ? '0!important' : undefined, p: 0 }}
        colSpan={columns}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box
            sx={{
              bgcolor: 'action.hover',
              p: 2,
              '& .MuiTypography-root': {
                color: disabled ? 'text.secondary' : undefined
              }
            }}
          >
            <SubKpisList
              kpiTitle={kpiTitle}
              onCreate={onCreate}
              subKpis={subKpis}
              recommendSameUnit={recommendSameUnit}
              mainKpiUnit={mainKpiUnit}
            />
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default SubKpisDropdown
