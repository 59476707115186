import React, { useEffect, useMemo, useState } from 'react'
import { cloneDeep } from 'lodash'

import Button from 'components_new/atoms/Button'
import SimpleDialog from 'components_new/molecules/SimpleDialog'
import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'

import DndList from 'components_new/molecules/DndList'
import Text from 'components_new/atoms/Text'

import {
  ParsedDashboardFilterOptions,
  DashboardFilterOptions
} from 'types/GlobalDashboardFilter'
import SelectSearch, { Option } from 'components_new/molecules/SelectSearch'
import Tooltip from 'components_new/atoms/Tooltip'

interface EditUserFiltersDialogProps {
  availableDashboardFilterOptions: DashboardFilterOptions[]
  dashboardFilterOptions: Map<string, ParsedDashboardFilterOptions>
  onClose: () => void
  onSave: (
    dashboardFilterOptions: {
      index: number
      attribute_id: string
      relation_name: string | null
      relation_key: string
    }[]
  ) => void
  open: boolean
}

const EditUserFiltersDialog = (props: EditUserFiltersDialogProps) => {
  const {
    availableDashboardFilterOptions,
    dashboardFilterOptions,
    onClose,
    onSave,
    open
  } = props

  const [editedFilters, setEditedFilters] = useState<
    ParsedDashboardFilterOptions[]
  >([])

  useEffect(() => {
    setEditedFilters(cloneDeep(Array.from(dashboardFilterOptions.values())))
  }, [open])

  const dndItems = useMemo(() => {
    return editedFilters
      .sort((a, b) => a.index - b.index)
      .map((filter) => {
        return {
          id: filter.relation_key,
          content: (
            <Box
              key={filter.relation_key}
              sx={{
                px: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Text
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                variant="subtitle2"
              >
                {filter.title}
              </Text>
              <Tooltip title="Dölj">
                <IconButton
                  onClick={() => handleToggleHidden(filter.relation_key)}
                  size="small"
                >
                  <Icon
                    color={'disabled'}
                    fontSize="small"
                    name={'RemoveCircleOutlineOutlined'}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          ),
          containerSx: {
            mb: 1,
            p: 1
          }
        }
      })
  }, [editedFilters])

  const handleToggleHidden = (id: string) => {
    setEditedFilters(
      editedFilters.filter((filter) => filter.relation_key !== id)
    )
  }

  return (
    <SimpleDialog
      title="Redigera filter"
      open={open}
      onClose={onClose}
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            Avbryt
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onSave(
                editedFilters
                  .sort((a, b) => a.index - b.index)
                  .map((filter, i) => {
                    return {
                      attribute_id: filter.attribute_id,
                      relation_name: filter.relation_name,
                      index: i,
                      // used for validating saved dashboard filters
                      relation_key: filter.relation_key
                    }
                  })
              )
              onClose()
            }}
          >
            Spara
          </Button>
        </>
      }
    >
      <SelectSearch
        closeOnSelect={true}
        label={'Lägg till filter'}
        onChange={(option: Option) => {
          const newFilter = availableDashboardFilterOptions.find(
            (opt) => opt.relation_key === option.id
          ) as DashboardFilterOptions

          setEditedFilters([
            ...editedFilters,
            { ...newFilter, index: editedFilters.length, options: undefined }
          ])
        }}
        multiple={false}
        options={availableDashboardFilterOptions
          .filter(
            (opt) =>
              !editedFilters.find(
                (filter) => filter.relation_key === opt.relation_key
              )
          )
          .map((filter) => ({
            id: filter.relation_key,
            title: filter.title
          }))}
        selected={null}
        sort={true}
        sx={{ mb: 2 }}
      />
      <DndList
        handleDragEnd={(newOrder) => {
          setEditedFilters((prevFilters) => {
            return newOrder.map((id, index) => {
              const filter = prevFilters.find(
                (filter) => filter.relation_key === id
              ) as ParsedDashboardFilterOptions

              return {
                ...filter,
                index
              }
            })
          })
        }}
        items={dndItems}
      />
    </SimpleDialog>
  )
}

export default EditUserFiltersDialog
