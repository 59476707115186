import React from 'react'

import { SxProps } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import Link from 'components_new/atoms/Link'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'

import Endpoint from 'components_new/molecules/Endpoint'
import FormSection from 'components_new/molecules/FormSection'

import PreviewWidgets from './PreviewWidgets'

interface Props {
  sx?: SxProps
}

const ApiDetails = (props: Props) => {
  const { sx } = props

  return (
    <Box sx={sx}>
      <FormSection
        helperText={
          <>
            Använd informationen nedan för att generera en tillfällig länk som
            ger åtkomst till fliken i upp till en timme. Länken finns i svaret
            du får från din API-förfrågan.
          </>
        }
        title="Generera tillfällig länk"
      >
        <Endpoint
          href="https://api.homepal.se/v1/third-party/dashboards"
          variant="get"
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{''}</TableCell>
              <TableCell>{'Nyckel'}</TableCell>
              <TableCell>{'Obligatorisk'}</TableCell>
              <TableCell>{'Beskrivning'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>Query parameter</b>
              </TableCell>
              <TableCell>
                <code>{'company_group_id'}</code>
              </TableCell>
              <TableCell>
                <Icon fontSize="small" name="Check" />
              </TableCell>
              <TableCell>
                Skapa och hantera koncerner under{' '}
                <Link href="/admin/settings/companies" newTab={true}>
                  Bolag
                </Link>
                .
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Header</b>
              </TableCell>
              <TableCell>
                <code>{'x-api-key'}</code>
              </TableCell>
              <TableCell>
                <Icon fontSize="small" name="Check" />
              </TableCell>
              <TableCell>
                Skapa och hantera API-nycklar under{' '}
                <Link href="/admin/settings/api-keys" newTab={true}>
                  API-nycklar
                </Link>
                .
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </FormSection>

      <FormSection
        helperText={
          <>
            Du kan välja att inkludera specifika widgets i svaret du får från
            API-förfrågan ovan. T.ex. för att kunna skapa en anpassad
            förhandsvisning i en tredjepartsapplikation.
          </>
        }
        title="Inkludera widgets"
      >
        <PreviewWidgets />
      </FormSection>
    </Box>
  )
}

export default ApiDetails
