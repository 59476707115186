import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Dispatch, bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Collapse from 'components_new/atoms/Collapse'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import CreateKpiDialog from 'components_new/organisms/dialogs/CreateKpiDialog'

import * as DashboardGroupActions from 'redux/actions/DashboardGroups'
import * as KPITemplateActions from 'redux/actions/KPITemplates'
import * as WidgetActions from 'redux/actions/Widgets'
import { useDashboard } from 'redux/hooks/Dashboards'

import { ApplicationState } from 'redux/Stores/types'
import { LayoutItem, PlatformDashboard } from 'redux/reducers/Dashboards'

import { WidgetType } from 'types/GlobalWidget'

interface EditModeBarProps {
  availableSpace: LayoutItem | false
  editMode: boolean
  sx?: object
}

const EditModeBar = (props: ComponentProps) => {
  const {
    AuthStore,
    availableSpace,
    editMode,
    KPITemplateStore,
    sx,
    tryCreateWidget,
    tryRequestKpiTemplate
  } = props

  const [kpiDialogOpen, setKpiDialogOpen] = useState(false)

  const params = useParams<{ id: string }>()
  const dashboard = useDashboard<PlatformDashboard>(params.id)

  // loading
  if (!dashboard) {
    return null
  }

  // success
  return (
    <>
      <Collapse in={editMode}>
        <Box
          sx={{
            bgcolor: 'brand.background',
            py: 0.5,
            pl: 2,
            pr: 4,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...sx
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip
              title={
                <>
                  <b>Begränsad funktionalitet i redigeringsläge</b>
                  <br />I redigeringsläge har vi begränsat vissa funktioner som
                  är avsedda för visningsläge, exempelvis klickfunktioner i
                  widgets, för att göra redigeringen smidigare.
                </>
              }
            >
              <Icon name="InfoOutlined" fontSize="small" sx={{ mr: 1 }} />
            </Tooltip>
            <Text color="text.primary">
              <b>Du är i redigeringsläge.</b> Alla ändringar uppdateras direkt
              och sparas automatiskt.
            </Text>
          </Box>
          <Tooltip
            title={Boolean(!availableSpace) ? 'Din dashboard är full.' : ''}
          >
            <span>
              <Button
                disabled={!availableSpace}
                startIcon={<Icon name="AddOutlined" />}
                onClick={() => setKpiDialogOpen(true)}
                size="small"
                variant="text"
              >
                Lägg till nyckeltal
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Collapse>

      {/*-- create widget for dashboard --*/}
      <CreateKpiDialog
        createItem={(
          kpiId: string | null,
          performKpiId?: string,
          type?: WidgetType.TEXT | WidgetType.COMMENT | WidgetType.IMAGE
        ) => {
          if (availableSpace) {
            tryCreateWidget({
              data: {
                dashboard_id: dashboard.id,
                kpi_id: kpiId,
                perform_kpi_id: performKpiId,
                width: availableSpace.w,
                height: type === WidgetType.TEXT ? 1 : availableSpace.h,
                type,
                x: availableSpace.x,
                y: availableSpace.y
              }
            })
          }
        }}
        customer={AuthStore.customer}
        data={KPITemplateStore.data}
        kpisFetched={KPITemplateStore.fetchedKpis}
        onClose={() => setKpiDialogOpen(false)}
        open={kpiDialogOpen}
        requestKpi={() => tryRequestKpiTemplate(AuthStore.user?.email)}
        variant="create"
      />
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  KPITemplateStore: state.KPITemplateStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...DashboardGroupActions,
      ...KPITemplateActions,
      ...WidgetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & EditModeBarProps

export default connector(EditModeBar)
