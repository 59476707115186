import {
  getAll,
  requestKpi,
  update,
  create,
  destroy
} from 'redux/api/KPITemplates'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'
import { KPI_UNIT } from 'types/Enums'
import { PutKpiTemplateCalculationBlock } from 'types/GlobalKpiTemplates'

export function tryGetAllKPIs() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_KPI_TEMPLATES
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_KPI_TEMPLATES_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_KPI_TEMPLATES_FAILED
        })
      })
  }
}

export function tryUpdateKpiTemplate(
  id: string,
  body: {
    data: {
      name: string
      unit?: KPI_UNIT
      blocks: PutKpiTemplateCalculationBlock
    }
  }
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_KPI_TEMPLATE
    })

    update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_KPI_TEMPLATE_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_KPI_TEMPLATE_FAILED
        })
      })
  }
}

export function tryCreateKpiTemplate(body: {
  data: { name: string; unit: KPI_UNIT; blocks: PutKpiTemplateCalculationBlock }
}) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_KPI_TEMPLATE
    })

    create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_KPI_TEMPLATE_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_KPI_TEMPLATE_FAILED
        })
      })
  }
}

export function tryDeleteKpiTemplate(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_KPI_TEMPLATE
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_KPI_TEMPLATE_SUCCESS,
          payload: id
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_KPI_TEMPLATE_FAILED
        })
      })
  }
}

export function tryRequestKpiTemplate(email: string | undefined) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.REQUEST_KPI_TEMPLATE
    })

    requestKpi(email)
      .then(() => {
        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message:
              'Förfrågan skickad!\nVi återkopplar till dig så snabbt vi kan.'
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Förfrågan kunde inte skickas' }
        })
      })
  }
}
