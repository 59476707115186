import React from 'react'

import { useHistory } from 'react-router-dom'

import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'

import { DashboardStatus } from 'redux/reducers/Dashboards'
import { DashboardFolder } from 'types/GlobalDashboardFolder'

export const isValidTitle = (title: string | null) => {
  if (title === '' || title === null) return false

  return !/kopia|namnlös/i.test(title)
}

export function getFolderChip(
  folder: DashboardFolder | null,
  status: DashboardStatus
) {
  const history = useHistory()

  let icon: 'GridViewOutlined' | 'DesignServicesOutlined' | 'FolderOutlined' =
    'GridViewOutlined'
  let label = 'Alla dashboards'
  let onClick = () => history.push('/dashboards')

  if (status === DashboardStatus.DRAFT) {
    icon = 'DesignServicesOutlined'
    label = 'Mina utkast'
    onClick = () => history.push('/dashboards/drafts')
  } else if (folder) {
    icon = 'FolderOutlined'
    label = folder.name
    onClick = () => history.push(`/dashboards/folders/${folder.id}`)
  }

  return (
    <Chip
      clickable={true}
      icon={<Icon name={icon} />}
      label={label}
      onClick={onClick}
    />
  )
}
