import * as Types from 'redux/Types'
import { createAction, createReducer } from '@reduxjs/toolkit'

import { arrayToObject } from 'utils/functions'

import { TagOption, TagOptionReducerType } from 'types/GlobalTagOptions'

// Initial state
const INITIAL_STATE: TagOptionReducerType = {
  data: {},
  fetched: false
}

// Actions
const getAllActionSuccess = createAction<{
  data: TagOption[]
}>(Types.GET_ALL_TAG_OPTIONS_SUCCESS)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const tagOptionReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAllActionSuccess, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: arrayToObject<TagOption>(payload.data),
        fetched: true
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default tagOptionReducer
