import { createAction, createReducer } from '@reduxjs/toolkit'
import * as Types from 'redux/Types'

import { cloneDeep } from 'lodash'

import { arrayToObject } from 'helpers/Functions'
import { DashboardGroupPermissionBody } from '../Dashboards'
import { AccountRole } from 'types/GlobalUser'

// Types
export interface AccountType {
  activated_at: Date | null
  company_group_id: string | null
  created_at: Date
  email: string
  first_name: string | null
  id: string
  invite_accepted_at: Date | null
  last_name: string | null
  role: AccountRole
  real_estates: string[]
}

export type AccountBody = {
  [key: string]: AccountType
}

export type AccountRoleApi = {
  id: string
  name: AccountRole
  only_visible_to_homepal: boolean
}

export type RoleReducerType = { [roleName: string]: AccountRoleApi }

export interface AccountReducerType {
  data: AccountBody
  fetched: boolean
  roles: RoleReducerType
  rolesFetched: boolean
}

interface UpdateSelfPayload {
  attributes: {
    email: string
    first_name: string
    last_name: string
  }
  id: string
}

// Initial state
const INITIAL_STATE: AccountReducerType = {
  data: {},
  fetched: false,
  roles: {} as RoleReducerType,
  rolesFetched: false
}

// Actions
const getAllAction = createAction<{ data: AccountType[] }>(
  Types.GET_ALL_ACCOUNTS_SUCCESS
)
const getAllRolesAction = createAction<{ data: AccountRoleApi[] }>(
  Types.GET_ALL_ACCOUNT_ROLES_SUCCESS
)
const createAccountAction = createAction<{ data: AccountType }>(
  Types.CREATE_USER_SUCCESS
)
const updateAccountAction = createAction<{ data: AccountType }>(
  Types.UPDATE_USER_SUCCESS
)
const deleteAccountAction = createAction<string>(Types.DELETE_USER_SUCCESS)
const inviteToDashboardAction = createAction<{
  data: DashboardGroupPermissionBody
}>(Types.INVITE_TO_DASHBOARD_SUCCESS)

const updateSelfAction = createAction<UpdateSelfPayload>(
  Types.UPDATE_SELF_SUCCESS
)
const deleteUsersAction = createAction<string[]>(Types.DELETE_USERS_SUCCESS)
const deleteCompanyGroupAction = createAction<{ id: string }>(
  Types.DELETE_COMPANY_GROUP_SUCCESS
)
const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const accountReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAllAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: arrayToObject(payload.data) as AccountBody,
        fetched: true
      }
    })
    .addCase(getAllRolesAction, (state, action) => {
      const { payload } = action
      const roles: RoleReducerType = {}

      payload.data.forEach((role) => {
        roles[role.name] = role
      })

      return {
        ...state,
        roles,
        rolesFetched: true
      }
    })
    .addCase(createAccountAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.id]: payload.data
        }
      }
    })
    .addCase(updateAccountAction, (state, action) => {
      const { payload } = action

      if (payload.data.id in state.data) {
        return {
          ...state,
          data: {
            ...state.data,
            [payload.data.id]: {
              ...state.data[payload.data.id],
              ...payload.data
            }
          }
        }
      }

      return state
    })
    .addCase(deleteAccountAction, (state, action) => {
      const { payload } = action

      const newData = cloneDeep(state.data)

      if (payload in newData) {
        delete newData[payload]
      }

      return {
        ...state,
        data: newData
      }
    })
    .addCase(inviteToDashboardAction, (state, action) => {
      const { payload } = action

      if (payload.data.new_account) {
        return {
          ...state,
          data: {
            ...state.data,
            [payload.data.new_account.id]: payload.data.new_account
          }
        }
      }

      return state
    })
    .addCase(updateSelfAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.id]: {
            ...state.data[payload.id],
            email: payload.attributes.email,
            first_name: payload.attributes.first_name,
            last_name: payload.attributes.last_name
          }
        }
      }
    })
    .addCase(deleteUsersAction, (state, action) => {
      const { payload } = action
      const newData = cloneDeep(state.data)

      payload.forEach((id) => delete newData[id])

      return { ...state, data: newData }
    })
    .addCase(deleteCompanyGroupAction, (state, action) => {
      const { payload } = action

      const newData = cloneDeep(state.data)

      Object.keys(newData).forEach((id) => {
        if (newData[id].company_group_id === payload.id) {
          delete newData[id]
        }
      })

      return { ...state, data: newData }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default accountReducer
