import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { getWithRedirect } from 'utils/axios'
import { DashboardFilterOptionsPutBody } from 'types/GlobalDashboardFilter'
import { parseQueryParam, QueryParams } from '../Widgets'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/dashboard-filters`

export async function getAll() {
  return getWithRedirect(URL)
}

export async function getOne(
  relationKey: string,
  queryParam: QueryParams | null
) {
  const parsedQueryParams = queryParam ? parseQueryParam(queryParam) : ''

  return getWithRedirect(`${URL}/${relationKey}${parsedQueryParams}`)
}

export async function put(
  dashboardGroupId: string,
  body: DashboardFilterOptionsPutBody
) {
  return axios.put(
    `${URL}/${dashboardGroupId}`,
    body,
    parseAuthenticationHeader()
  )
}
