import React from 'react'

import Box from 'components_new/atoms/Box'
import Skeleton from 'components_new/atoms/Skeleton'

export const FilterLoader = () => (
  <Skeleton variant="rounded" height={45} width={'100%'} sx={{ mb: 2 }} />
)

const Loading = () => {
  return (
    <>
      <Box
        sx={{
          p: 2,
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Skeleton variant="rounded" height={136} width={'100%'} />
        <Skeleton variant="rounded" height={'100%'} width={'100%'} />
      </Box>
    </>
  )
}

Loading.displayName = 'Loading'

export default Loading
