import { Dispatch } from 'redux'

import * as Types from 'redux/Types'

import { putFavorite } from 'redux/api/DashboardFavorites'
import * as UserEvents from 'redux/api/internal/UserEvents'

import { Action } from 'types/GlobalUserEvents'

export function tryPutDashboardFavorite(id: string, isFavorite: boolean) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.PUT_DASHBOARD_FAVORITE
    })

    putFavorite(id)
      .then((response) => {
        UserEvents.log({
          action: isFavorite
            ? Action.REMOVED_DASHBOARD_AS_FAVORITE
            : Action.MARKED_DASHBOARD_AS_FAVORITE,
          context: null,
          dashboard_id: null,
          dashboard_group_id: id
        })

        dispatch({
          type: Types.PUT_DASHBOARD_FAVORITE_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_DASHBOARD_FAVORITE_FAILED
        })
      })
  }
}
