import React from 'react'

import { useParams } from 'react-router-dom'
import { SxProps, useMediaQuery, useTheme } from '@mui/material'

import { useDashboard } from 'redux/hooks/Dashboards'

import { Action, Context } from 'types/GlobalUserEvents'
import {
  DashboardFilter,
  ParsedDashboardFilterOptions
} from 'types/GlobalDashboardFilter'

import * as UserEvents from 'redux/api/internal/UserEvents'

import { FilterLoader } from '../loading'
import { parseSingleFilterLabel } from 'components_new/organisms/Widget/WidgetSettings/FilterSection/utils'

import Box from 'components_new/atoms/Box'
import SelectSearch, { Option } from 'components_new/molecules/SelectSearch'

import { isPublicDashboard } from 'utils/functions'

interface CurrentFilterProps {
  dashboardFilter: DashboardFilter
  dashboardFilterOptions: Map<string, ParsedDashboardFilterOptions>
  setDashboardFilter: (value: DashboardFilter) => void
  sx?: SxProps
}

const CurrentFilter = (props: CurrentFilterProps) => {
  const { dashboardFilter, dashboardFilterOptions, setDashboardFilter } = props

  const params = useParams<{ id: string }>()
  const dashboard = useDashboard(params.id)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  // loading
  if (!dashboard) {
    return null
  }

  const isPublic = isPublicDashboard(dashboard)

  // success
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '0px',
        flex: '1 1 auto',
        overflowY: 'auto'
      }}
    >
      {Array.from(dashboardFilterOptions.values())
        .sort((a, b) => a.index - b.index)
        .map((filter) => {
          if (!filter.options) {
            return <FilterLoader key={filter.relation_key} />
          }

          return (
            <SelectSearch
              color="primary"
              key={filter.relation_key}
              label={filter.title}
              onChange={(options: Option[]) => {
                const existingFilter = dashboardFilter.filter(
                  (fil) => fil.relation_key !== filter.relation_key
                )

                if (!isPublic) {
                  UserEvents.log({
                    action: Action.SELECTION_FILTERED,
                    context: Context.FROM_FILTER_MENU,
                    dashboard_id: dashboard.id,
                    dashboard_group_id: dashboard.dashboard_group_id
                  })
                }

                setDashboardFilter([
                  ...existingFilter,
                  ...options.map((opt) => ({
                    attribute_option_id: null,
                    attribute: filter.attribute_id,
                    relation_key: filter.relation_key,
                    value: opt.id
                  }))
                ])
              }}
              multiple={true}
              options={filter.options.map((opt) => ({
                id: opt,
                title: parseSingleFilterLabel(opt)
              }))}
              selected={dashboardFilter
                .filter((item) => item.relation_key === filter.relation_key)
                .map((item) => ({
                  id: item.value,
                  title: parseSingleFilterLabel(item.value as string | null)
                }))}
              size={isMobile ? 'medium' : 'small'}
              sort={true}
            />
          )
        })}
    </Box>
  )
}

export default CurrentFilter
