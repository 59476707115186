import React from 'react'
import { RenderPlaceholderProps, useSlate } from 'slate-react'

import Box from 'components_new/atoms/Box'

import { isBlockEmpty } from '../utils'

const Placeholder = (props: RenderPlaceholderProps) => {
  const { attributes, children } = props

  const editor = useSlate()
  const isEmptyListItem = isBlockEmpty(editor, 'list-item', 'type')

  if (isEmptyListItem) {
    return <></>
  }

  return (
    <Box
      component="span"
      {...attributes}
      sx={{
        color: 'text.secondary',
        pointerEvents: 'none',
        position: 'absolute',
        top: '0px',
        width: '100%',
        maxWidth: '100%',
        display: 'block',
        userSelect: 'none',
        textDecoration: 'none'
      }}
    >
      {children}
    </Box>
  )
}

export default Placeholder
